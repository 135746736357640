<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Formula</h5>
    <button class="btn-close" data-dismiss="modal" aria-label="Close" (click)="closePopup()"></button>
  </div>
  <div class="modal-body">
    <form *ngIf="formFormula" [formGroup]="formFormula">
      <div class="row">
        <div class="col-12">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" formControlName="name" id="name" name="name"
            placeholder="Enter Formula Name">
            <label for="name">Formula Name</label>
          </div>
        </div>
      </div>
      <!-- <div class="row" *ngIf="isEditFormula && dataTypeId !== 12">
        <div class="col">
          <div class="form-floating mb-3">
            <select class="form-select" id="validationFormType" formControlName="validationFormType" (change)="onFormTypeSelect($event.target)">
              <option [value]="null" selected>--Select--</option>
              <option *ngFor="let item of formType" [value]="item.id"><b>{{item.name}}</b></option>
            </select>
            <label for="validationFormType"><b>Form-Type</b></label>
          </div>
        </div>
      </div> -->
      <div class="row" *ngIf="isEditFormula && dataTypeId !== 12">
        <div class="col">
          <div class="form-floating mb-3">
            <select class="form-select" id="validationFormType" formControlName="formType" (change)="onFormTypeSelect($event.target)">
              <option [value]="null" selected>--Select--</option>
              <option *ngFor="let item of formTypeData" [value]="item.id"><b>{{item.name}}</b></option>
            </select>
            <label for="validationFormType"><b>Form-Type</b></label>
          </div>
        </div>
        <div [ngClass]="formType == 0 ? 'col-4' : 'col-6'" [hidden]="formType > 0">
          <div class="form-floating mb-3">
            <select class="form-select" id="visitSelect" (change)="OnVisitSelect($event.target)" formControlName="visitSelect">
              <option [ngValue]="null" selected>--Select--</option>
              <option *ngFor="let item of visitSelect" [value]="item.id"><b>{{item.name}}</b></option>
            </select>
            <label for="visitSelect"><b>Select Visit</b></label>
          </div>
        </div>
        <div [ngClass]="formType == 0 ? 'col-4' : 'col-6'">
          <div class="form-floating mb-3">
            <select class="form-select" id="formSelect" (change)="OnSelectForm($event.target)" formControlName="formSelect">
              <option [ngValue]="null" selected>--Select--</option>
              <option *ngFor="let item of formSelect" [value]="item.formId"><b>{{item.formName}}</b></option>
            </select>
            <label for="formSelect"><b>Select Form</b></label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isEditFormula && dataTypeId !== 12">
        <div [ngClass]="fieldDataTypeId == 17 ? 'col-6':'col-12'">
          <div class="form-floating mb-3">
            <select (change)="fieldChanged()" class="form-select" formControlName="fieldsSelect">
              <option [ngValue]="null" selected>--Select--</option>
              <option *ngFor="let item of fieldsSelect" [value]="item.id"><b>{{item.label}}</b></option>
            </select>
            <label for="fieldsSelect"><b>Select Fields</b></label>
          </div>
        </div>
        <div class="col-6" [hidden]="fieldDataTypeId != 17">
          <div class="form-floating mb-3">
            <select class="form-select" (change)="columnFieldChanged()" formControlName="columnSelect">
              <option [ngValue]="null" selected>--Select--</option>
              <option *ngFor="let item of columnSelect" [value]="item.id"><b>{{item.colHeading}}</b></option>
            </select>
            <label for="fieldsSelect"><b>Select Column</b></label>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="isEditFormula && dataTypeId !== 12">
        <!-- Operators -->
        <div class="row text-center mb-3">
          <div class="btn-group" role="group" aria-label="Basic example">
            <div class="col">
              <button type="button" [autoClose]="'outside'" #p="ngbPopover" [ngbPopover]="popContent"
              [popoverTitle]="popTitle" class="btn btn-outline-primary" (click)="resetClose()">
              <b>Num</b>
            </button>
          </div>
          <div class="col">
            <button (click)="addData('isDateDifference(','isDateDifference(',null)" class="btn btn-outline-primary">
              <b>Date Diff.</b>
            </button>
          </div>
          <div class="col">
            <button type="button" (click)="addData('+','+',null)" class="btn btn-outline-primary"><b>+</b></button>
          </div>
          <div class="col">
            <button type="button" (click)="addData('-','-',null)" class="btn btn-outline-primary"><b>-</b></button>
          </div>
          <div class="col">
            <button type="button" (click)="addData('*','*',null)" class="btn btn-outline-primary"><b>x</b></button>
          </div>
          <div class="col">
            <button type="button" (click)="addData('/','/',null)" class="btn btn-outline-primary"><b>/</b></button>
          </div>
          <div class="col">
            <button type="button" (click)="addData('sqrt(','sqrt(',null)"
            class="btn btn-outline-primary"><b>√</b></button>
          </div>
          <div class="col">
            <button type="button" (click)="addData('^','^',null)" class="btn btn-outline-primary"><b>^</b></button>
          </div>
          <div class="col">
            <button type="button" (click)="addData('(','(',null)" class="btn btn-outline-primary"><b>(</b></button>
          </div>
          <div class="col">
            <button type="button" (click)="addData(')',')',null)" class="btn btn-outline-primary"><b>)</b></button>
          </div>
        </div>
      </div>
    </div>
    
    <div class="date-formula" *ngIf="dataTypeId === 12 && isEditFormula">
      <div class="col-12 date-function">
        <div class="form-floating mb-3">
          <select (change)="onFunctionChange($event.target)" class="form-select" formControlName="dateFunction">
            <option [ngValue]="null" selected>--Select--</option>
            <option *ngFor="let item of dateFunctions" [value]="item.equation"><b>{{item.name}}</b></option>
          </select>
          <label for="dateFunctions"><b>Select Function</b></label>
        </div>
      </div>
      
      <div class="col-12 d-flex" *ngIf="isEditFormula && getSelectedKey('dateFunction')?.value != null">
        <div class="first-parameter col-6 p-2">
          <div class="label">Parameter 1</div>
          <div class="radio-div d-flex justify-content-around p-2">
            <div class="form-check" *ngFor="let parameter of parameterData">
              <input class="form-check-input" type="radio" formControlName="parameterOneFormulaType"
              [value]="parameter.value" (change)="onParameterValueChange(parameter.value)">
              <label class="form-check-label" for="flexRadioDefault1">
                <strong>{{parameter.name}}</strong>
              </label>
            </div>
          </div>
          <div class="form-floating mb-3" *ngIf="formFormula.get('parameterOneFormulaType')?.value === 'question'">
            <select class="form-select" id="validationFormType" formControlName="formType" (change)="onFormTypeSelect($event.target)">
              <option [value]="null" selected>--Select--</option>
              <option *ngFor="let item of formTypeData" [value]="item.id"><b>{{item.name}}</b></option>
            </select>
            <label for="validationFormType"><b>Form-Type</b></label>
          </div>
          <div class="visit-section-div" *ngIf="formFormula.get('parameterOneFormulaType')?.value === 'question'">
            
            <div class="date-visit-details d-flex justify-content-between gap-1">
              <div class="col-6" [hidden]="getSelectedKey('formType')?.value > 0">
                <div class="form-floating mb-3">
                  <select class="form-select" id="visitSelect" (change)="OnFirstParameterVisitSelect($event.target)"
                  formControlName="visitSelect">
                  <option [ngValue]="null" selected>--Select--</option>
                  <option *ngFor="let item of visitSelect" [value]="item.id"><b>{{item.name}}</b></option>
                </select>
                <label for="visitSelect"><b>Select Visit</b></label>
              </div>
            </div>
            <div [ngClass]="getSelectedKey('formType')?.value == 0 ? 'col-6':'col-12'">
              <div class="form-floating mb-3">
                <select class="form-select" id="formSelect" (change)="OnFirstParameterSelectForm($event.target)"
                formControlName="formSelect">
                <option [ngValue]="null" selected>--Select--</option>
                <option *ngFor="let item of getSelectedKey('uiFormSelect1')?.value" [value]="item.formId"><b>{{item.formName}}</b></option>
              </select>
              <label for="formSelect"><b>Select Form</b></label>
            </div>
          </div>
        </div>
        
        <div class="form-floating mb-3">
          <select (change)="on1stFieldChange($event.target,formFormula.get('parameterOneFormulaType')?.value)" class="form-select" formControlName="fieldsSelect">
            <option [ngValue]="null" selected>--Select--</option>
            <option *ngFor="let item of getSelectedKey('uiFieldSelect1')?.value" [value]="item.id"><b>{{item.label}}</b></option>
          </select>
          <label for="fieldsSelect"><b>Select Fields</b></label>
        </div>
        <div class="form-floating mb-3" [hidden]="formFormula.get('firstDataTypeId')?.value != 17">
          <select (change)="on1stColumnChange($event.target,formFormula.get('parameterOneFormulaType')?.value)" class="form-select" formControlName="columnSelect">
            <option [ngValue]="null" selected>--Select--</option>
            <option *ngFor="let item of getSelectedKey('uiColumnSelect1')?.value" [value]="item.id"><b>{{item.colHeading}}</b></option>
          </select>
          <label for="fieldsSelect"><b>Select Column</b></label>
        </div>
      </div>
      
      <div class="constant-div p-2" *ngIf="formFormula.get('parameterOneFormulaType')?.value === 'constant'">
        <div class="input-group">
          <input class="form-control" 
          [placeholder]="'Date'" formControlName="constant" ngbDatepicker #d="ngbDatepicker" 
          [readOnly]="true" [footerTemplate]="footerTemplate" (dateSelect)="onConstantDateSelected($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle();">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
          <ng-template #footerTemplate>
            <hr class="my-0" />
            <button class="btn btn-primary btn-sm m-2 float-start" (click)="getTodayDate();d.close()">Today</button>
            <button class="btn btn-secondary btn-sm m-2 float-end" (click)="d.close()">Close</button>
          </ng-template>
        </div>
      </div>
    </div>
    
    
    <div class="col-6 parameter-two p-2">
      <div class="first-parameter">
        <div class="label">Parameter 2</div>
        <div class="radio-div d-flex justify-content-around p-2">
          <div class="form-check" *ngFor="let parameter of parameterData">
            <input class="form-check-input" type="radio" formControlName="parameterTwoFormulaType"
            [value]="parameter.value" (change)="on2ndParameterValueChange(parameter.value)">
            <label class="form-check-label" for="flexRadioDefault1">
              <strong>{{parameter.name}}</strong>
            </label>
          </div>
        </div>
        <div class="form-floating mb-3" *ngIf="formFormula.get('parameterTwoFormulaType')?.value === 'question'">
          <select class="form-select" id="validationFormType" formControlName="secondFormType" (change)="onFormTypeSelect($event.target)">
            <option [value]="null" selected>--Select--</option>
            <option *ngFor="let item of formTypeData" [value]="item.id"><b>{{item.name}}</b></option>
          </select>
          <label for="validationFormType"><b>Form-Type</b></label>
        </div>
        <div class="visit-section-div" *ngIf="formFormula.get('parameterTwoFormulaType')?.value === 'question'">
          
          <div class="date-visit-details d-flex justify-content-between gap-1">
            <div class="col-6" [hidden]="getSelectedKey('secondFormType')?.value > 0">
              <div class="form-floating mb-3">
                <select class="form-select" id="visitSelect" (change)="OnSecondParameterVisitSelect($event.target)"
                formControlName="secondVisitSelect">
                <option [ngValue]="null" selected>--Select--</option>
                <option *ngFor="let item of visitSelect" [value]="item.id"><b>{{item.name}}</b>
                </option>
              </select>
              <label for="visitSelect"><b>Select Visit</b></label>
            </div>
          </div>
          <div [ngClass]="getSelectedKey('secondFormType')?.value == 0 ? 'col-6':'col-12'">
            <div class="form-floating mb-3">
              <select class="form-select" id="formSelect" (change)="OnSecondParameterSelectForm($event.target)"
              formControlName="secondFormSelect">
              <option [ngValue]="null" selected>--Select--</option>
              <option *ngFor="let item of getSelectedKey('uiFormSelect2')?.value" [value]="item.formId"><b>{{item.formName}}</b></option>
            </select>
            <label for="formSelect"><b>Select Form</b></label>
          </div>
        </div>
      </div>
      
      <div class="form-floating mb-3">
        <select (change)="on2ndFieldChange($event.target,formFormula.get('parameterTwoFormulaType')?.value)" class="form-select" formControlName="secondFieldsSelect">
          <option [ngValue]="null" selected>--Select--</option>
          <option *ngFor="let item of getSelectedKey('uiFieldSelect2')?.value" [value]="item.id"><b>{{item.label}}</b></option>
        </select>
        <label for="fieldsSelect"><b>Select Fields</b></label>
      </div>

      <div class="form-floating mb-3" [hidden]="formFormula.get('secondDataTypeId')?.value != 17">
        <select (change)="on2ndColumnChange($event.target,formFormula.get('parameterTwoFormulaType')?.value)" class="form-select" formControlName="secondColumnSelect">
          <option [ngValue]="null" selected>--Select--</option>
          <option *ngFor="let item of getSelectedKey('uiColumnSelect2')?.value" [value]="item.id"><b>{{item.colHeading}}</b></option>
        </select>
        <label for="fieldsSelect"><b>Select Column</b></label>
      </div>

    </div>
    
    <div class="constant-div p-2" *ngIf="formFormula.get('parameterTwoFormulaType')?.value === 'constant'">
      <div class="form-floating mb-3">
        <input type="number" class="form-control" name="name" formControlName="secondConstant" (change)="onConstantNumberSelected($event.target)"
        placeholder="Enter Constant">
        <label for="name">Constant</label>
      </div>
    </div>
    
  </div>
</div>
</div>


</div>

<div class="col-12 my-3">
  <!-- Your Formula -->
  <div class="row mb-3">
    <div class="col">
      <div class="form-floating">
        <!-- Show formula in Card by pooja -->
        <div *ngIf="!isValid" class="text-danger invalid-text">
          Invalid equation: Please check your syntax!
        </div>
        <div id="expandable">
          <div [innerHTML]="customFormula" class="main-custom-formula"></div>
        </div>
        <div class="resize-handle"></div>
        <!-- Ends -->
      </div>
    </div>
    <div class="col-1 mt-2" *ngIf="isEditFormula">
      <button class="btn btn-outline-danger" (click)="removeData()"><b><i
        class="fa fa-arrow-left"></i></b></button>
      </div>
      <label for=""><i><b>Note: </b>Use mathematical equation</i></label>
    </div>
  </div>
  <div class="row" *ngIf="!isEditFormula" [hidden]="isLocked == 1 || roles != 1">
    <div class="col">
      <button class="btn btn-outline-primary" (click)="editFormula(); "><b>Edit Formula</b></button> &nbsp;
      <button class="btn btn-outline-danger" (click)="deleteFormula(); "><b>Delete Formula</b></button>
    </div>
  </div>
  <ng-template #popTitle>Add Numeric Field</ng-template>
  <ng-template #popContent>
    <div class="input-group">
      <input type="text" class="form-control" id="numericData" (keypress)="onKeyPressed('numericData')"
      placeholder="Enter Number" formControlName="numericData" />
      <button class="btn btn-outline-secondary input-group-addon" (click)="getNumericData();p.close()"><i
        class="fa fa-plus"></i></button>
      </div>
    </ng-template>
  </form>
</div>
<!-- Control Buttons -->
<div class="modal-footer">
  <button type="button" class="btn btn-outline-danger" (click)="closePopup()"><b>Cancel</b></button>
  <button type="button" class="btn btn-outline-success" *ngIf="saveUpdateFlag && isValid" (click)="saveFormula()" [hidden]="roles != 1" [disabled]="saveLoading">
    <b>
      Save <span class="spinner-border spinner-border-sm" *ngIf="saveLoading" role="status" aria-hidden="true"></span>
    </b>
  </button>
  <button type="button" class="btn btn-outline-warning" *ngIf="!saveUpdateFlag && isEditFormula && isValid" (click)="saveFormula()" [hidden]="roles != 1" [disabled]="updateLoading">
    <b>
      Update <span class="spinner-border spinner-border-sm" *ngIf="updateLoading" role="status" aria-hidden="true"></span>
    </b>
  </button>
</div>
</div>