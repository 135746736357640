import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseURL , VERSION } from 'src/app/constant/constant';
import { getTimeZoneHeader } from '../constant/globalFunction';

@Injectable({
  providedIn: 'root'
})
export class FormulaServiceService {

  constructor(private http:HttpClient) { }

  postFormula(data:any):Observable<any>{
    return this.http.post(`${baseURL}formulas/${VERSION}`,data);
  }

  getFormula(pageNo:any,pageSize:any,formulaName:any):Observable<any>{
    // return this.http.get(`${baseURL}formulas/${VERSION}/list?pageNo=${pageNo}&pageSize=${pageSize}&formulaName=${formualName}`)
    let url:string = `${baseURL}formulas/${VERSION}/list?pageNo=${pageNo}&pageSize=${pageSize}`;
    if(formulaName && formulaName.length > 0){
      url = url.concat(`&formulaName=${formulaName}`);
    }
    return this.http.get(url);  
  }

  getFormulaById(id:any):Observable<any>{
    return this.http.get(`${baseURL}formulas/${VERSION}?id=${id}`)
  }

  getFormulaByFieldId(id:any,multiRowId:any):Observable<any>{
    let url:string = `${baseURL}formulas/${VERSION}/field?id=${id}`
    if(multiRowId){
      url = url.concat(`&multiRowId=${multiRowId}`)
    }
    return this.http.get(url)
  }

  updateFormula(data:any):Observable<any>{
    return this.http.put<any>(`${baseURL}formulas/${VERSION}`,data);
  }

  deleteFormula(id:string):Observable<any>{
    const params = new HttpParams({ fromObject: { id: id } })
    return this.http.delete(`${baseURL}formulas/${VERSION}?id=${id}`);
  }

  getVisitList():Observable<any>{
    return this.http.get(`${baseURL}visits/${VERSION}/list`);
  }

  getFormList():Observable<any>{
    return this.http.get(`${baseURL}forms/${VERSION}/list`);
  }

  getFormulaExport():Observable<any>{
    return this.http.get(`${baseURL}formulas/${VERSION}/list/export`,{headers:getTimeZoneHeader()})
  }

}
