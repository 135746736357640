import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FORM_CONFIRM_SOFT_LOCK_MSG, FORM_CONFIRM_SOFT_UNLOCK_MSG, LOG_FORM_LOCK_ALREADY_DONE, LOG_FORM_UNLOCK_ALREADY_DONE, VISIT_CONFIRM_SOFT_LOCK_MSG, VISIT_CONFIRM_SOFT_UNLOCK_MSG, VISIT_LOCK_ALREADY_DONE, VISIT_SELECT_MSG, VISIT_UNLOCK_ALREADY_DONE } from 'src/app/constant/responseMessage';
import { Role } from 'src/app/models/Role';
import { ReviewService } from 'src/app/services/review.service';
import { VisitsService } from 'src/app/services/visits.service';
import { ReasonPopupComponent } from '../reason-popup/reason-popup.component';
import { ERROR_ICON_COLOR, SUCCESS_ICON_COLOR } from 'src/app/constant/color-code';

@Component({
  selector: 'app-unschedule-visit-list-popup',
  templateUrl: './unschedule-visit-list-popup.component.html',
  styleUrls: ['./unschedule-visit-list-popup.component.css']
})
export class UnscheduleVisitListPopupComponent implements OnInit{
  
  @Input() rowData:any

  roles:any

  tableData:any[] = []
  checkedList:any = {}
  masterSelected:any

  visitStatusData:any[] = []

  responseData:any[] = []
  
  successIconColor:any = SUCCESS_ICON_COLOR
  errorIconColor:any = ERROR_ICON_COLOR

  tabsDataFlag:boolean = true
  
  logFormData:any[] = []
  logFormCheckedList:any = {}
  logFormMasterSelected:any
  
  constructor(private modalService:NgbModal,private visitAPI:VisitsService,
    private reviewAPI:ReviewService,private toastr:ToastrService){}

  ngOnInit(): void {
    this.getStorageData()
    // this.getUnScheduleVisitData()
    // this.getVisitAndLogFormData()
  }

  getStorageData(){
    this.roles = sessionStorage.getItem("role")
  }

  // getUnScheduleVisitData(){
  //   this.checkedList = []
  //   this.visitAPI.getVisitsBySubjectId(this.rowData.id).subscribe(res=>{
  //     this.tableData = res.responseObject
  //     if(res.responseObject){
  //       for (let i = 0; i < res.responseObject.length; i++) {
  //         this.checkedList[res.responseObject[i].id] = false
  //       }
  //     }
  //   })
  // }

  getVisitAndLogFormData(){
    this.reviewAPI.setLogFormSoftLock(this.rowData.id).subscribe(res => {
      console.log("RESPONSE :: ",res);
      this.checkedList = []
      this.tableData = res.responseObject.visitStatus
      this.logFormData = res.responseObject.logFormStatus
      if(this.tabsDataFlag){
        if(this.tableData){
          for (let i = 0; i < this.tableData.length; i++) {
            if(this.tableData[i].isLocked == undefined){
              this.tableData[i].status = 0
            }
            if(this.tableData[i].isLocked){
              this.tableData[i].status = 2
            }else{
              this.tableData[i].status = 0
            }
            this.checkedList[this.tableData[i].id] = false
          }
        }
      }else{
        if(this.logFormData){
          for (let i = 0; i < this.logFormData.length; i++) {
            if(this.logFormData[i].isLocked == undefined){
              this.logFormData[i].status = 0
            }
            if(this.logFormData[i].isLocked){
              this.logFormData[i].status = 2
            }else{
              this.logFormData[i].status = 0
            }
            this.checkedList[this.logFormData[i].id] = false
          }
        }
      }
    })
  }

  fillReviewData(){
    Object.keys(this.checkedList).filter((key:any)=>{
      if(this.checkedList[key] == true){
        const formsData = {
          id:key,
        }
        this.visitStatusData.push(formsData)
      }
    })
  }

  isAllSelected(event:any,item:any){
    this.checkedList[item.id] = event.target.checked;
    this.masterSelected = Object.keys(this.checkedList).every((key) => {
      return this.checkedList[key] === true;
    });
  }

  checkUncheckAll(event:any){
    this.masterSelected = event.target.checked;
    Object.keys(this.checkedList).map((key) => {
      this.checkedList[key] = this.masterSelected;
    });
  }

  closeModal(){
    this.modalService.dismissAll()
  }
  
  filterData(status:any){
    let mainData:any[] = []
    if(this.tabsDataFlag){
      Object.keys(this.checkedList).filter((data:any) => {
        if(this.tableData){
          for (let index = 0; index < this.tableData.length; index++) {
            if(this.checkedList[data] == true && this.tableData[index].id == data && this.tableData[index].status != status){
              mainData.push(data)
            }
          }
        }
      })
    }else{
      Object.keys(this.checkedList).filter((data:any) => {
        if(this.logFormData){
          for (let index = 0; index < this.logFormData.length; index++) {
            if(this.checkedList[data] == true && this.logFormData[index].id == data && this.logFormData[index].status != status){
              mainData.push(data)
            }
          }
        }
      })
    }
    return mainData
  }

  setStatus(status:any,rowData:any,isSingleOrMultiple:boolean){
    // let msg = confirm(VISIT_CONFIRM_SOFT_LOCK_MSG)
    // if(msg){
    // }
    let msg:any
    let extendMsg:any
    let requestData:any
    if(this.tabsDataFlag){
      if(status == 2){
        msg = VISIT_CONFIRM_SOFT_LOCK_MSG
        extendMsg = "Lock"
      }else{
        msg = VISIT_CONFIRM_SOFT_UNLOCK_MSG
        extendMsg = "Unlock"
      }
    }else{
      if(status == 2){
        msg = FORM_CONFIRM_SOFT_LOCK_MSG
        extendMsg = "Lock"
      }else{
        msg = FORM_CONFIRM_SOFT_UNLOCK_MSG
        extendMsg = "Unlock"
      }
    }
    if(this.roles == Role.DM){
      let visitId:any[] = []
      let formId:any[] = []
      if(isSingleOrMultiple){
        for(let data in this.checkedList){
          if(this.checkedList[data] == true && this.tabsDataFlag){
            visitId.push(data)
          }else if(this.checkedList[data] == true && !this.tabsDataFlag){
            formId.push(data)
          }
        }
      }else{
        if(rowData != null && this.tabsDataFlag){
          visitId.push(rowData.id)
        }else if(rowData != null && !this.tabsDataFlag){
          formId.push(rowData.id)
        }
      }
      if(visitId.length > 0 || formId.length > 0){
        if(isSingleOrMultiple){
          visitId = []
          formId = []
          if(this.tabsDataFlag){
            visitId = [...this.filterData(status)]
          }else{
            formId = [...this.filterData(status)]
          }
        }
        if(this.tabsDataFlag){
          requestData = {
            visitIds:visitId,
            status:status
          }
  
        }else{
          requestData = {
            formIds:formId,
            status:status
          }
        }
        if(visitId.length > 0 || formId.length > 0){
          let modal = this.modalService.open(ReasonPopupComponent,{size:'lg'})    
          modal.componentInstance.confirmMsg = msg
          modal.componentInstance.extendedTitle = extendMsg
          modal.componentInstance.requestBody = requestData
          modal.componentInstance.isVisitLock = true
          modal.componentInstance.subjectId = this.rowData.id
          modal.componentInstance.modalOkClicked.subscribe((response:any) => {
            this.responseData = []
            // this.getUnScheduleVisitData()
            this.getVisitAndLogFormData()
            let mainResponse = response.responseObject
            if(mainResponse){
              for(let obj in mainResponse){
                let res:any = {
                  subjectId:obj,
                  status:mainResponse[obj]
                }
                this.responseData.push(res)
              }
            }
            this.masterSelected = false
          })
        }else{
          if(this.tabsDataFlag){
            if(status == 2){
              this.toastr.warning(VISIT_LOCK_ALREADY_DONE)
            }else{
              this.toastr.warning(VISIT_UNLOCK_ALREADY_DONE)
            }
          }else{
            if(status == 2){
              this.toastr.warning(LOG_FORM_LOCK_ALREADY_DONE)
            }else{
              this.toastr.warning(LOG_FORM_UNLOCK_ALREADY_DONE)
            }
          }
        }
      }else{
        this.toastr.warning(VISIT_SELECT_MSG)
      }
      //API Called here
      // this.reviewAPI.setSubjectLevelSoftLock(this.rowData.id,requestData).subscribe(res => {
      //   if(res.status != 0){
      //     this.toastr.warning(res.message)
      //   }else{
      //     this.toastr.success(res.message)
      //     this.modalService.dismissAll()
      //   }
      // })
    }else{
      this.toastr.warning("Visit lock done by DM only")
    }
    
  }

  onTabsChange(event:any){
    if(event == "Visit"){
      this.tabsDataFlag = true
    }else{
      this.tabsDataFlag = false
    }
    this.getVisitAndLogFormData()
  }
}
