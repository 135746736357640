import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { CANCEL_MSG, DELETE_MSG, INDICATION_NAME, SELECT_THERAPEUTIC } from 'src/app/constant/responseMessage';
import { IndicationService } from 'src/app/services/indication.service';
import { TherapeuticService } from 'src/app/services/therapeutic.service';

@Component({
  selector: 'app-indication',
  templateUrl: './indication.component.html',
  styleUrls: ['./indication.component.css'],
})
export class IndicationComponent implements OnInit {
  title: string = 'INDICATION';
  therapeuticData: any[] = [];
  indicationsData: any[] = [];
  isEdit: boolean = false;
  indicationForm!: FormGroup;
  indicationDropDownSetting: IDropdownSettings = {};
  indicationRowId: any;
  loading: boolean= false;

  constructor(private indicationService: IndicationService, private formBuilder: FormBuilder,
  private therapeuticSvc: TherapeuticService,private toastr: ToastrService) {
    this.createIndicationForm();
  }

  ngOnInit(): void {
    this.getIndicationTableData();
    this.therapeuticSvc
      .getTherapeuticDropDownList()
      .subscribe((therapeuticData: any) => {
        if (therapeuticData.responseObject) {
          this.therapeuticData = therapeuticData.responseObject;
        }
      });
  }

  createIndicationForm() {
    this.indicationForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      therapeuticId: [null,[Validators.required]],
    });
  }

  getIndicationTableData() {
    this.indicationService
      .getIndicationTableData()
      .subscribe((indicationData: any) => {
        if (indicationData.responseObject) {
          this.indicationsData = indicationData.responseObject;
        }
      });
  }

  setStatus() {
    this.isEdit = false;
  }

  validateIndication(){
    if(this.indicationForm.get('name')?.value == undefined || this.indicationForm.get('name')?.value == null || this.indicationForm.get('name')?.value == ''){
      this.toastr.warning(INDICATION_NAME)
      return false
    }else if(this.indicationForm.get('therapeuticId')?.value == undefined || this.indicationForm.get('therapeuticId')?.value == null){
      this.toastr.warning(SELECT_THERAPEUTIC)
      return false
    }
    return true
  }

  addIndications() {
    this.loading = true;
    let requestBody = {
      name: '',
      therapeuticId: null,
    };
    requestBody.name = this.indicationForm.get('name')?.value;
    requestBody.therapeuticId = this.indicationForm.get('therapeuticId')?.value;

    if (requestBody) {
      this.indicationService.saveIndication(requestBody).subscribe(
        (res: any) => {
          this.indicationForm.reset();
          this.loading = false;
          this.closeModal();
          this.toastr.success(res.message);
          this.getIndicationTableData();
        },
        (error: any) => {
          this.loading= false;
          this.toastr.error(error.message);
        }
      );
    }
  }

  closeModal() {
    let ref = document.getElementById('cancel');
    ref?.click();
    this.indicationForm.reset();
  }

  editIndication(indicationDetailRow: any, index: any) {
    this.isEdit = true;
    this.indicationRowId = indicationDetailRow.value.id;
    this.indicationService
      .getIndicationById(indicationDetailRow.value.id)
      .subscribe((res: any) => {
        console.log("RESPONSE :: ",res);
        if (res && res.responseObject) {
          let indicationFormData = res.responseObject;
          this.indicationForm.get('name')?.setValue(indicationFormData.name);
          this.indicationForm
            .get('therapeuticId')
            ?.setValue(indicationFormData.therapeuticId);
        }
      });
  }

  updateIndications() {
    this.loading = true;
    if (this.indicationForm.valid && this.indicationForm.value) {
      let requestBody = {
        id: '',
        name: '',
        therapeuticId: null,
      };

      requestBody.id = this.indicationRowId;
      requestBody.name = this.indicationForm.get('name')?.value;
      requestBody.therapeuticId = this.indicationForm.get('therapeuticId')?.value;
      if(requestBody){
        this.indicationService.updateIndication(requestBody).subscribe(
          (response: any) => {
            this.closeModal();
            this.toastr.success(response.message);
            this.indicationForm.reset();
            this.isEdit = false;
            this.loading= false;
            this.getIndicationTableData();
          },
          (error) => {
            this.closeModal();
            this.loading= false;
            this.toastr.error(error.message);
          }
        );
      }
    }
  }

  deleteIndication(row: any, index: any) {
    let r = confirm(DELETE_MSG);
    if (r == true) {
      this.indicationService.deleteIndication(row.value.id).subscribe(
        (res: any) => {
          if (res.status != 0) {
            this.toastr.error(res.message);
          } else {
            this.toastr.success(res.message);
            if (this.indicationsData.length) {
              this.indicationsData.splice(index, 1);
            }
          }
        },
        (err) => {
          this.toastr.error(err.message);
        }
      );
    } else {
      this.toastr.error(CANCEL_MSG);
    }
  }
}
