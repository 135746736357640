import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VERSION, baseURL } from '../constant/constant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) { }

  getRoleList(): Observable<any>{
    return this.http.get(`${baseURL}roles/${VERSION}/list`)
  }

  
  getRoleById(id:string):Observable<any>{
    return this.http.get(`${baseURL}roles/${VERSION}?id=${id}`);
  }
  
  saveRole(payload:any):Observable<any>{
    return this.http.post(`${baseURL}roles/${VERSION}`,payload);
  }

  
  updateRole(payload: any): Observable<any> {
    return this.http.put(`${baseURL}roles/${VERSION}`, payload);
  }

  deleteRole(id: string):Observable<any>{
    return this.http.delete(`${baseURL}roles/${VERSION}?id=${id}`);
  }
}
