import { environment } from "src/environments/environment";
export const jsonData: any[] = [];
export const tempFormData: any[] = [];
export const validationUpdateMessage: string = "Validation data updated successfully";
export const validationInsertMessage: string = "Validation data inserted successfully";
export const formulaREGEX = /([-+]?[0-9]*\.?[0-9]+[\/\+\-\*])+([-+]?[0-9]*\.?[0-9]+)/
export const basicAuth = environment.basicAuth;
export const loginAPI = environment.apiUrl + "/api/oauth/token";
export const logOutAPI = environment.apiUrl + "/api/auth/logout";
export const baseURL = environment.apiUrl + "/api/";
export const ENVIRONMENT_NAME = environment.envName;
// export const loginAPI = environment.apiUrl + "/api/oauth/token";
// export const logOutAPI = environment.apiUrl + "/api/auth/logout";
// export const baseURL = environment.apiUrl + "/api/";
export const VERSION = "v1";
export const OBJECT = "object";
export const STRING = "string";
export const NUMBER = "number";
export const EMPTYJSON: any = {};
export const DEFAULT_UUID: any = "00000000-0000-0000-0000-000000000000";
export const DEFAULT_VISIT_NAME: any = "Logs Visit";
export const DEFAULT_PAGE_NO: number = 0;
export const DEFAULT_PAGE_SIZE: number = 10;
export const DEFAULT_TOTAL_PAGE_DIVISION: number = 10;
export const DEFAULT_TOTAL_PAGE_MULTIPLICATION: number = 100;
export const DEFAULT_ROLE_ID: number = -1;
export const DEFAULT_ADMIN_ROLE_ID = 0;
export const REASON_FOR_FORMULA_FIELD_CHANGE = "Change due to dependent field";
export const REASON_FOR_VALIDATION_FIELD_CHANGE = "Change due to validation field";
export const IDLE_USER_TIME = 1800 // 30 min user idle time in seconds
export const MIN_DATE_CONSTANT = { year: 1900, month: 1, day: 1 }
export const MAX_DATE_CONSTANT = { year: 2050, month: 12, day: 31 }
export const MAX_CHARACTER_SHOW = 255
export const REPORT_INTERVAL_START_TIME = 30000 //30 Seconds
export const REPORT_INTERVAL_END_TIME = 300000 //5 minutes
export const ARROW = ' -> ' //5 minutes
export const USER_MANUAL_NAME = "userManual"
export const CONSTANT_DATE = "2024-01-01 " //5 minutes
export const TEXT_TRIM_COUNT = 50
export const DATE_WINDOW_OPERATOR = "+/-";
export const STUDY_LOGO_SIZE = 2097152
export const TOTAL_FILE_LIMIT = 10;
export const INDIVIDUAL_FILE_SIZE = 5242880; // 5 MB
export const MAX_FILE_SIZE_EXCEED_MESSAGE = 'Please remove any file larger than 5 MB.';
export const TOTAL_FILE_LIMIT_REACHED_MESSAGE = 'Uploading file limit is 10, Please remove extra files';
export const ERROR_MESSAGE = 'Some files have already been uploaded.';
export const Confirmation_Prompt_MSG = 'Confirmation Prompt';
export const CANCEL_MSG = 'Cancel';
export const OK_MSG = 'Ok';
export const YOU_ADDED_FILES = 'You have added files but have not clicked  "Upload File". Do you want to proceed without uploading?';
export const NO_MESSAGE = 'No';
export const YES_MESSAGE = 'Yes';
export const ARE_YOU_SURE_MESSAGE = 'Are you sure you want to leave this form? Any data entered will not be saved.';
export const DICT_DEFAULT_MESSAGE = 'Click or drag files here to upload';

export const VALIDATION_TYPE = [
  {id:1,name:"Required"},
  {id:2,name:"Minimum Value"},
  {id:3,name:"Maximum Value"},
  {id:4,name:"Minimum Length"},
  {id:5,name:"Maximum Length"},
];

export const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'image/jpeg',
  'image/png',
  'image/gif'
];

export const QUERY_TYPE = [
  { id: 1, name: "SYSTEM_QUERY" },
  { id: 2, name: "CRA_QUERY" },
  { id: 3, name: "DM_QUERY" },
  { id: 4, name: "DM_QUERY" },
  { id: 5, name: "INV_QUERY" }
];

export const QUERY_TYPE_OPTIONS = [
  { id: 1, name: "SYSTEM_QUERY" },
  { id: 2, name: "CRA_QUERY" },
  { id: 4, name: "DM_QUERY" }
];

export const QUERY_AGE = [
  { id: 1, name: "0-7 Days" },
  { id: 2, name: "8-14 Days" },
  { id: 3, name: "> 14 Days" }
];

export const STATUS_DATA = [
  { id: 0, name: "UnReview" },
  { id: 1, name: "Partial Review" },
  { id: 2, name: "Review" }
];

export const FIELD_STATUS = [
  { id: 0, name: "UN_LOCK" },
  { id: 1, name: "LOCK" }
];

export const QUERY_STATUS = [
  { id: 1, name: "Open" },
  { id: 2, name: "Response" },
  { id: 3, name: "Closed" }
];

export const QUERY_PRIORITY = [
  { id: 1, name: "Low" },
  { id: 2, name: "Medium" },
  { id: 3, name: "High" }
];

export const MISSING_FORMS_REPORT_COLUMNS = [
  'Site Name',
  'Subject Id',
  'Visit Name',
  'CRF/Form Name'
] 

export const Components = [
  "TextView",
  "TextArea",
  "Number",
  "Email",
  "Password",
  "CheckBox",
  "RadioButton",
  "SelectionBox",
  "Button",
  "Heading",
  "SubHeading",
  "Date",
  "Time",
  "Image",
  "Label",
  "HyperLink",
  "Multi-Row",
  "Table",
  "Date & Time"
]

export const componentTypeMap = [
  { 'id': 1, 'name': "TextView" },          // 0
  { 'id': 2, 'name': "TextArea" },          // 1
  { 'id': 3, 'name': "Number" },            // 2 
  { 'id': 4, 'name': "Email" },             // 3
  { 'id': 5, 'name': "Password" },          // 4
  { 'id': 6, 'name': "CheckBox" },          // 5
  { 'id': 7, 'name': "RadioButton" },       // 6
  { 'id': 8, 'name': "SelectionBox" },      // 7
  { 'id': 9, 'name': "Button" },            // 8
  { 'id': 10, 'name': "Heading" },          // 9
  { 'id': 11, 'name': "SubHeading" },       // 10
  { 'id': 12, 'name': "Date" },             // 11
  { 'id': 13, 'name': "Time" },             // 12
  { 'id': 14, 'name': "Image" },            // 13
  { 'id': 15, 'name': "Label" },            // 14
  { 'id': 16, 'name': "HyperLink" },        // 15
  { 'id': 17, 'name': "Multi-Row" },        // 16
  { 'id': 18, 'name': "Table" },            // 17
  { 'id': 19, 'name': "Date & Time" }         // 18
];

export const nonStaticDataType = [1, 2, 3, 4, 5, 6, 7, 8, 12, 13, 15, 19];

export const typeCheck = [
  { id: 0, type: 'Static' },
  { id: 1, type: 'Conditional' },
  { id: 2, type: 'Dependent' }
];

// export const ROLES = [
//   { id: 1, name: "Programer" },
//   { id: 2, name: "CRC" },
//   { id: 3, name: "CRA" },
//   { id: 4, name: "Data Manager" },
//   { id: 5, name: "Investigator" },
//   { id: 6, name: "Admin" },
//   { id: 7, name: "Read-Only" }
//   // {id:7,name:"Monitor"}
// ];

export const DATE_OPERATIONS = [
  { operation: "<", name: "Less than (<)" },
  { operation: ">", name: "Greater than (>)" },
  { operation: "<=", name: "Less than equal to (≤)" },
  { operation: ">=", name: "Greater than equal to (≥)" },
  { operation: "=", name: "Equal to (=)" },
  { operation: "-", name: "Between" }
];

export const EXCEL_REPORTS = [
  {id:1,name:"Site Summary Report"},
  {id:2,name:"Lock Status Report"},
  {id:3,name:"Query Count By Subject Report"},
  {id:4,name:"CRF Report"},
  {id:5,name:"QRS Extration"},
  {id:6,name:"Query Ageing Report"},
  {id:7,name:"Raw Data Extract Report"},
  {id:8,name:"Data Dictionary Export"},
  { id: 9, name: "Query History Export" },
  { 
    id: 10,
    name:"Missing Forms Report"
  }
];

export const PDF_REPORTS = [
  { id: 1, name: "CRF Report" },
  { id: 2, name: "CRF Report with Annotated Name" },
  { id: 3, name: "CRF Report with Data" }
];

export const VALIDATION_ACTION = [
  { id: 1, name: "Collect" },
  { id: 2, name: "Don't collect" },
  { id: 3, name: "Warn" },
  { id: 4, name: "Redirect" }
];

export const VALIDATION_IMPACT_ACTION = [
  { id: 1, name: "Collect" },
  { id: 2, name: "Don't collect" },
  { id: 3, name: "Warn" }
];

export const VALIDATION_ACTION_FILTER_LIST = [
  { id: 1, name: "COLLECT IF" },
  { id: 2, name: "NOT COLLECT IF" },
  { id: 3, name: "WARN IF" },
  { id: 4, name: "LOG MAPPING" }
];

export const VALIDATION_OPERATORS = [
  { id: 1, name: ">" },
  { id: 2, name: "<" },
  { id: 3, name: ">=" },
  { id: 4, name: "<=" },
  { id: 5, name: "=" },
  { id: 6, name: "!=" }
];
type validation = {
  [key: string]: string
}

export const VALIDATION_OPERATORS_ENUMS: validation = {
  "=": "equal",
  "!=": "not equal",
}

export const VALIDATION_OPERATORS_DATE_ENUMS: validation = {
  "=": "isDateEqual",
  "!=": "isDateNotEqual",
  ">": "isDateGreaterThan",
  ">=": "isDateGreaterThanOrEqual",
  "<": "isDateLessThan",
  "<=": "isDateLessThanOrEqual",
  "+/-": "isDateWindowValid",
}

export const VALIDATION_LOGICAL_OPERATORS = [
  { id: 1, name: "and" },
  { id: 2, name: "or" }
];

export const LOG_MAPPING_DATA_TYPES = [
  { id: 1, name: "Visit to Log Mapping" },
  { id: 2, name: "Log to Log Mapping" }
];

export const IS_CONSTANT_SELECTION = [
  { id: 1, name: "Yes" },
  { id: 2, name: "No" }
];

export const IS_LOCKED_SELECTION = [
  { id: 0, name: "No" },
  { id: 1, name: "Yes" }
];

export const DECIMAL_POINT_DATA = [
  { id: 0, name: "0" },
  { id: 0.1, name: "0.1" },
  { id: 0.01, name: "0.01" },
  { id: 0.001, name: "0.001" },
  { id: 0.0001, name: "0.0001" },
  { id: 0.00001, name: "0.00001" }
];

export const USER_STATE = [
  { isActive: true, name: "Enable" },
  { isActive: false, name: "Disable" }
];

//Subject Status
export const SUBJECT_STATUS = [
  { id: 0, name: "Enroll" },
  { id: 1, name: "In Progress" },
  { id: 2, name: "Complete" },
  { id: 3, name: "Withdrawal of consent (Inform consent/PK consent)" },
  { id: 4, name: "Lost to Follow up" },
  { id: 5, name: "Screen Failure" },
  { id: 6, name: "Death" },
  { id: 7, name: "Adverse Event" },
  { id: 8, name: "Serious Adverse Event" },
  { id: 9, name: "Withdrawal by physician" },
  { id: 10, name: "Other" },
];
export const SUBJECT_WITHDRAWN_ERROR_MSG = "subject is withdrawn !";

//Data-Entry Ypdate time reason data
export const UPDATING_DATA_REASON = ["Wrongly entered", "Source changed", REASON_FOR_VALIDATION_FIELD_CHANGE, "New Data"]
//For multi row design
export const MULTI_ROW_DESIGN = [
  { id: 1, name: "Table View" },
  { id: 2, name: "Form View" }
];

export const TABLE_COMPONENT_TYPES = [
  { id: 1, name: "TextView" },          // 0
  { id: 2, name: "TextArea" },          // 1
  { id: 3, name: "Number" },            // 2
  { id: 4, name: "Email" },             // 3
  { id: 5, name: "Password" },          // 4
  { id: 6, name: "CheckBox" },          // 5
  { id: 7, name: "RadioButton" },       // 6
  { id: 8, name: "SelectionBox" },      // 7
  { id: 11, name: "SubHeading" },       // 8
  { id: 12, name: "Date" },             // 9
  { id: 13, name: "Time" },             // 10
  { id: 15, name: "Label" },            // 11
];

export const TABLE_VALIDATION_TYPES = [
  { id: 1, name: "Same rule for all rows" },
  { id: 2, name: "Different rule for all rows" }
];

export const STUDY_LOCKING_TYPE = [
  { id: 1, name: "Visit" },
  { id: 2, name: "Subject" }
];

export const MONTH_DATA = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

export const LOCK_STATUS = [
  { name: "Locked", isLocked: true },
  { name: "Unlocked", isLocked: false },
];

export const IMPORT_DATA_POINTS = [
  { id: 1, name: "Visit & Forms" },
  { id: 2, name: "Log Forms" },
  { id: 3, name: "Formula" },
  { id: 4, name: "Validations" },
  { id: 6, name: "Log Form Validations" },
  { id: 5, name: "Data" },
];

export const VALIDATION_FORM_TYPE = [
  { id: 0, name: "Normal Forms" },
  { id: 1, name: "Log Forms" }
];

export const VALIDATION_LOG_MAPPING_TABS = ["Validation", "Log Mapping"]

export const REPORTS_NAME = [
  {id:1,name:"Validation Report"},
  {id:2,name:"Formula Report"},
  {id:3,name:"Site Summary Report"},
  {id:4,name:"Lock Status Report"},
  {id:5,name:"Query Count By Subject Report"},
  {id:6,name:"CRF Report"},
  {id:7,name:"QRS Report"},
  {id:8,name:"Query Ageing Report"},
  {id:9,name:"Raw Data Extract Report"},
  {id:10,name:"Design Auditlog"},
  {id:11,name:"Datapoint Auditlog"},
  {id:12,name:"Data Entry Auditlog"},
  {id:13,name:"User Auditlog"},
  {id:14,name:"CRF PDF Report"},
  {id:15,name:"CRF Report with Annotated Name"},
  {id:16,name:"CRF Report with Data"},
  {id:17,name:"Data Dictionary Export"},
  {id:18,name:"Query List Report"},
  {id:19,name:"User Data Export"},
  {id:20,name:"Report Logs"},
  { id: 21, name: "customReport" },
  { id: 22, name: "Query History Report" },
  {
    id: 23,
    name: "Missing Forms Report"
  }
]


export const FILE_NAME = [
  "siteSummaryReport",
  "lockStatusReport",
  "queryCountBySubject",
  "CrfReport",
  "QRS_Extraction",
  "Query_Ageing",
  "Raw_Data_Extract",
  "Data_Dictionary_Excel",
  "queryHistoryReport",
  "Missing_Forms_Report"
]

export const EXPORT_TYPES = [
  { id: 1, name: 'xlxs' },
  { id: 2, name: 'pdf' }
];

export const VALIDATION_STATUS = [
  { id: 0, name: "Disabled" },
  { id: 1, name: "Enabled" }
];

export const IMPORT_TYPE = ["Design", "Review"]

export const IMPORT_DATA_REVIEW_POINTS = [
  { id: 2, name: "Users" },
  { id: 1, name: "CRC, Date Manager & Invesigatior" },
  { id: 3, name: "SDV" },
  { id: 4, name: "Queries" }
]
