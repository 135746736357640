import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Role } from '../models/Role';
import { AuthGuardService } from '../services/auth-guard.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authGuard: AuthGuardService) {}

    canActivate(
        route: ActivatedRouteSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.authGuard.isAuthorized()) {
            this.router.navigate(['']);
            return false;
        }

        const roles = route.data && (route.data.roles as Role[]);
        if (roles && !roles.some((role) => this.authGuard.hasRole(role))) {
            this.router.navigate(['']);
            return false;
        }

        return true;
    }
}
