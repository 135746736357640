import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BLACK_COLOR } from 'src/app/constant/color-code';
import { REASON_MSG, REQUIRED_REASON_MSG, VISIT_SOFT_LOCK_MSG } from 'src/app/constant/responseMessage';
import { DataEntryService } from 'src/app/services/data-entry.service';
import { ReviewService } from 'src/app/services/review.service';

@Component({
  selector: 'app-reason-popup',
  templateUrl: './reason-popup.component.html',
  styleUrls: ['./reason-popup.component.css']
})
export class ReasonPopupComponent implements OnInit {

  //FormGroup's
  reasonForm:FormGroup
  
  //LocalStorageData
  isLocked:any

  //Input Data
  //1. For Multi-Row Deletion
  @Input() visitId:any;
  @Input() formId:any;
  @Input() subjectId:any;
  @Input() fieldId:any;
  @Input() rowId:any;
  @Input() confirmMsg:any;
  @Input() extendedTitle:any;
  

  //For Subject Locking
  @Input() requestBody:any;

  //For Visit Locking
  @Input() isVisitLock:any;

  //For Study Locking Component
  @Input() isStudyLocking:any;

  //Output Data
  @Output() modalCancelClicked: EventEmitter<any> = new EventEmitter();
  @Output() modalOkClicked: EventEmitter<any> = new EventEmitter();

  //Dynamic Reason Message
  reasonMessage:any

  //Spinner
  reasonLoading:boolean = false

  blackColor:any = BLACK_COLOR

  constructor(private dataEntryAPI:DataEntryService, private reviewAPI:ReviewService, 
    private toastr:ToastrService, private modalData:NgbActiveModal) {
    this.reasonForm = new FormGroup({
      reason:new FormControl()
    })
  }

  ngOnInit(): void {
    this.storageData()
    this.getDynamicReasonMessage()
  }

  getDynamicReasonMessage(){
    if(this.confirmMsg != undefined || this.confirmMsg != null){
      this.reasonMessage = this.confirmMsg
    }
  }

  storageData(){
    this.isLocked = sessionStorage.getItem("isLocked")
  }

  validateForm(){
    if(this.reasonForm.get("reason")?.value == null || this.reasonForm.get("reason")?.value == ''){
      this.toastr.warning(REQUIRED_REASON_MSG)
      return false
    }
    return true
  }

  applyReason(){
    if(this.validateForm()){
      if((this.requestBody != undefined || this.requestBody != null) && !this.isVisitLock){
        this.requestBody['reason'] = this.reasonForm.get("reason")?.value
        this.reviewAPI.setSoftLock(this.requestBody).subscribe(res => {
          console.log("RESPONSE :: ",res);
          let response:string = res.responseObject
          if( response != null && response.length > 0){
            this.toastr.warning(VISIT_SOFT_LOCK_MSG + "" + res.responseObject)
          }else{
            if(JSON.stringify(res.responseObject) === JSON.stringify({})){
              this.toastr.success(res.message)
            }
            this.modalOkClicked.emit(res)
          }  
          this.modalData.close()
        })
      }else if ((this.requestBody != undefined || this.requestBody != null) && this.isVisitLock) {
        this.requestBody['reason'] = this.reasonForm.get("reason")?.value
        this.reviewAPI.setSubjectLevelSoftLock(this.subjectId,this.requestBody).subscribe(res => {
          console.log("RESPONSE :: ",res);
          if(res.status != 0){
            this.toastr.warning(res.message)
          }else{
            if(JSON.stringify(res.responseObject) === JSON.stringify({})){
              this.toastr.success(res.message)
            }
            this.modalOkClicked.emit(res)
            this.modalData.close()
          }
        })
      }else{
        this.dataEntryAPI.deleteMultiRowDataEntryForm(this.visitId,this.formId,
          this.subjectId,this.rowId,this.fieldId,this.reasonForm.get("reason")?.value).subscribe(res=>{
          console.log("RESPONSE :: ",res);
          if(res.status == 0){
            if(JSON.stringify(res.responseObject) === JSON.stringify({})){
              this.toastr.success(res.message)
            }
          }else{
            this.toastr.error(res.message)
          }
          this.modalOkClicked.emit(res)
          this.modalData.close()
        })
      }
    }
  }

  closeModal(){
    this.modalData.close()
  }
  
}
