import { saveAs } from 'file-saver';
import { Injectable } from '@angular/core';
import { utils, WorkBook, WorkSheet, write } from 'xlsx';
import { EXPORT_TYPES } from './constant';
@Injectable({
  providedIn: 'root'
})
export class ExportExcel {

  constructor(){}

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  public exportExcelFile(jsonData: any[], fileName: string): void {
    const ws: WorkSheet = utils.json_to_sheet(jsonData);
    console.log(ws['!rows']);

    const wb: WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    saveAs(data, fileName + this.fileExtension);
  }
  public base64ToBlob(b64Data:any,type:any, sliceSize=512) {
    let byteCharacters = atob(b64Data); //data.file there
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    if(type == EXPORT_TYPES[0].name){
      return new Blob(byteArrays, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    }else{
      return new Blob(byteArrays, {type: 'application/pdf'});
    }
  }
}
