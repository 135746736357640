import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DEFAULT_UUID, VALIDATION_ACTION, VALIDATION_FORM_TYPE, VALIDATION_IMPACT_ACTION, componentTypeMap } from 'src/app/constant/constant';
import { cloneArray } from 'src/app/constant/globalFunction';
import { FieldService } from 'src/app/services/field.service';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-validation-rule-impact',
  templateUrl: './validation-rule-impact.component.html',
  styleUrls: ['./validation-rule-impact.component.css']
})
export class ValidationRuleImpactComponent implements OnInit{
  
  @Input() formType:any;
  @Input() fetchedVisitId:any;
  @Input() fetchedFormId:any;
  @Input() fetchedFieldId:any;
  @Input() fetchedDataTypeId:any;

  @Input() fetchValidationData:any
  @Input() formRuleValidation!:FormGroup
  @Input() ruleLength:any
  @Input() tabName:any
  @Input() visitListMainData:any
  @Input() visitListData:any
  @Input() logVisitListMainData:any
  @Input() logVisitListData:any
  
  validationAndLogMapping!:FormGroup
  validationAction:any = VALIDATION_IMPACT_ACTION
  defaultUuid:any = DEFAULT_UUID
  validationFormTypeData:any = VALIDATION_FORM_TYPE
  
  fieldsData:any

  //Multi-Select Dropdown Settings
  fieldsDropDownSettings: IDropdownSettings = {}
  columnsDropDownSettings: IDropdownSettings = {}

  constructor(private formAPI:FormService,private fieldAPI:FieldService,private formBuilder:FormBuilder){}

  ngOnInit(): void {
    this.validationAndLogMapping = this.formRuleValidation
    this.setDropdownSettings()
    this.initialize1stImpactData(this.formType,this.fetchValidationData ? true : false)
    
  }
  
  //FormGroup for Adding New Impact
  addImpact():FormGroup{
    return this.formBuilder.group({
      id:new FormControl(),
      ruleId:new FormControl(),
      visit:new FormControl(this.formType == 1 ? DEFAULT_UUID : null),
      form:new FormControl(),
      multiRowIds: new FormControl(),
      fields:[],
      uiFormSelect:new FormControl(),
      uiFieldSelect:new FormControl(),
      uiColumnSelect:new FormControl(),
      validationFormType:new FormControl(this.formType)
    })
  }

  //Add Impact For LogMapping FormGroup
  addLogMappingImpact():FormGroup{
    return new FormGroup({
      id:new FormControl(),
      ruleId:new FormControl(),
      visit:new FormControl(DEFAULT_UUID),
      form:new FormControl()
    })
  }

  //Multi-Select Dropdown menu settings
  setDropdownSettings() {
    
    this.fieldsDropDownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      itemsShowLimit: 2,
      allowSearchFilter: true,
    }

    if(this.fetchedDataTypeId == componentTypeMap[17].id){
      this.columnsDropDownSettings = {
        singleSelection: false,
        idField: 'tableColumnIndex',
        textField: 'colHeading',
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect',
        itemsShowLimit: 2,
        allowSearchFilter: true,
      }
    }else{
      this.columnsDropDownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'colHeading',
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect',
        itemsShowLimit: 2,
        allowSearchFilter: true,
      }
    }
    
  }

  initialize1stImpactData(formType:any,isValidationDataFetched:any){
    let forms:any
    if(formType > 0){
      this.setSelectedKeyValueForConditionAndImpact('impact', 'visit', this.ruleLength, 0, DEFAULT_UUID);
      forms = this.logVisitListMainData['visit'][DEFAULT_UUID].forms
      this.setSelectedKeyValueForConditionAndImpact("impact","uiFormSelect",this.ruleLength,0,this.extractForms(forms))
    }
    if(this.getSelectedRuleKeyValue("id",this.ruleLength) != null){
      if(this.fetchValidationData){
        const impactDataLength = this.fetchValidationData[this.ruleLength].impact.length
        if(impactDataLength > 1 && this.getConditionOrImpactArrayRule("impact",this.ruleLength).length != impactDataLength){
          for (let impactLength = 1; impactLength < impactDataLength; impactLength++) {
            if(this.fetchValidationData[this.ruleLength].action == VALIDATION_ACTION[3].id){
              this.getConditionOrImpactArrayRule("impact",this.ruleLength).push(this.addLogMappingImpact())
            }else{
              this.getConditionOrImpactArrayRule("impact",this.ruleLength).push(this.addImpact())
            }
          }
        }else{
          //This change is taken when -> import validation in warnIf check is taken then 1st form field will be selected.
          if(this.getSelectedKeyValueForConditionAndImpact("impact","validationFormType",this.ruleLength,0)?.value > 0 && this.fetchValidationData[this.ruleLength].action == VALIDATION_ACTION[2].id){
            this.setSelectedKeyValueForConditionAndImpact('impact', 'form', this.ruleLength, 0, this.fetchValidationData[this.ruleLength].condition[0].formId);
            this.fieldAPI.getFieldsByForm(this.getSelectedKeyValueForConditionAndImpact("impact","form",this.ruleLength,0)?.value).subscribe(res => {
              console.log("VALIDATION TYPE :: ",this.getConditionOrImpactArrayKey("impact",this.ruleLength,0));
              this.setSelectedKeyValueForConditionAndImpact('impact', 'uiFieldSelect', this.ruleLength, 0, cloneArray(res.responseObject));
              if(this.fetchValidationData[this.ruleLength].condition[0].dataTypeId == componentTypeMap[16].id){
                this.setSelectedKeyValueForConditionAndImpact('impact', 'uiFieldSelect', this.ruleLength, 0, cloneArray(res.responseObject));
                let selectedFields:any[] = []
                let fieldData = res.responseObject.find((field:any) => field.id == this.fetchValidationData[this.ruleLength].condition[0].fieldId)
                if(this.fetchedDataTypeId == undefined || this.fetchedDataTypeId == null){
                  this.fetchedDataTypeId = fieldData.dataTypeId
                }
                if(fieldData != undefined || fieldData != null){
                  this.onFieldSelect(fieldData,"impact",this.ruleLength,0)
                  selectedFields.push(fieldData)
                }
                this.setSelectedKeyValueForConditionAndImpact('impact', 'fields', this.ruleLength, 0, selectedFields);
              }
            })
          }
        }
        for (let validationImpactIndex = 0; validationImpactIndex < this.fetchValidationData[this.ruleLength].impact.length; validationImpactIndex++) {
          //taken this code at start because user is not able to see formName 
          this.getConditionOrImpactArrayKey("impact",this.ruleLength,validationImpactIndex).reset()
          this.getConditionOrImpactArrayKey("impact",this.ruleLength,validationImpactIndex).patchValue(this.fetchValidationData[this.ruleLength].impact[validationImpactIndex])
          forms = this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].visit == DEFAULT_UUID ? this.logVisitListMainData['visit'][DEFAULT_UUID].forms : this.visitListMainData['visit'][this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].visit].forms
          let extractedForms = this.extractForms(forms)
          this.fieldAPI.getFieldsByForm(this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].form).subscribe(res => {
            this.setSelectedKeyValueForConditionAndImpact('impact', 'id', this.ruleLength, validationImpactIndex, this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].id);
            this.setSelectedKeyValueForConditionAndImpact('impact', 'validationFormType', this.ruleLength, validationImpactIndex, this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].visit == DEFAULT_UUID ? this.validationFormTypeData[1].id : this.validationFormTypeData[0].id);
            this.setSelectedKeyValueForConditionAndImpact('impact', 'visit', this.ruleLength, validationImpactIndex, this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].visit);
            this.setSelectedKeyValueForConditionAndImpact("impact","uiFormSelect",this.ruleLength,validationImpactIndex,extractedForms);
            this.setSelectedKeyValueForConditionAndImpact('impact', 'form', this.ruleLength, validationImpactIndex, this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].form);
            if(this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].form){
              //TODO:check same formId exist then API not called
              this.setSelectedKeyValueForConditionAndImpact('impact', 'uiFieldSelect', this.ruleLength, validationImpactIndex, cloneArray(res.responseObject));
              if(this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].fields){
                let selectedFields:any[] = []
                for (let impactFieldIndex = 0; impactFieldIndex < this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].fields.length; impactFieldIndex++) {
                  if(this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].fields[impactFieldIndex] != null){
                    let fieldData = res.responseObject.find((field:any) => field.id == this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].fields[impactFieldIndex])
                    
                    if(this.fetchedDataTypeId == undefined || this.fetchedDataTypeId == null){
                      this.fetchedDataTypeId = fieldData.dataTypeId
                    }
                    if(fieldData != undefined || fieldData != null){
                      this.onFieldSelect(fieldData,"impact",this.ruleLength,validationImpactIndex)
                      selectedFields.push(fieldData)
                    }
                  }
                }
                this.setSelectedKeyValueForConditionAndImpact('impact', 'fields', this.ruleLength, validationImpactIndex, selectedFields);
              }
              if(this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].multiRowIds){
                let selectedMultiRowFields:any[] = []
                for (let multiRowId = 0; multiRowId < this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].multiRowIds.length; multiRowId++) {
                  if(this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].multiRowIds[multiRowId] != null){
                    if(this.fetchedDataTypeId == componentTypeMap[16].id){
                      let selectedMultiRowIds = this.getSelectedKeyValueForConditionAndImpact("impact","uiColumnSelect",this.ruleLength,validationImpactIndex)?.value.find((data:any)=> data.id == this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].multiRowIds[multiRowId])
                      if(selectedMultiRowIds != undefined || selectedMultiRowIds != null){
                        selectedMultiRowFields.push(selectedMultiRowIds);
                      }
                    }else if(this.fetchedDataTypeId == componentTypeMap[17].id){
                      let selectedMultiRowIds = this.getSelectedKeyValueForConditionAndImpact("impact","uiColumnSelect",this.ruleLength,validationImpactIndex)?.value.find((data:any)=> data.tableColumnIndex == this.fetchValidationData[this.ruleLength].impact[validationImpactIndex].multiRowIds[multiRowId])
                      if(selectedMultiRowIds != undefined || selectedMultiRowIds != null){
                        selectedMultiRowFields.push(selectedMultiRowIds);
                      }
                    }
                  }
                }
                this.setSelectedKeyValueForConditionAndImpact('impact', 'multiRowIds', this.ruleLength, validationImpactIndex, selectedMultiRowFields);
              }
            }
          });
        }
      }
    }else{
      if(formType == 0){
        this.setSelectedKeyValueForConditionAndImpact('impact', 'visit', this.ruleLength, 0, this.fetchedVisitId);
        forms = this.fetchedVisitId == DEFAULT_UUID ? this.logVisitListMainData['visit'][this.fetchedVisitId].forms : this.visitListMainData['visit'][this.fetchedVisitId].forms
        this.setSelectedKeyValueForConditionAndImpact("impact","uiFormSelect",this.ruleLength,0,this.extractForms(forms))
      }
      this.setSelectedKeyValueForConditionAndImpact('impact', 'form', this.ruleLength, 0, this.fetchedFormId);
      if(this.fetchedFormId){
        this.fieldAPI.getFieldsByForm(this.fetchedFormId).subscribe(res => {
          this.setSelectedKeyValueForConditionAndImpact('impact', 'uiFieldSelect', this.ruleLength, 0, cloneArray(res.responseObject));
          // this.setSelectedKeyValueForConditionAndImpact('impact', 'fields', this.ruleLength, 0, this.fetchedFieldId);
          let fieldData = res.responseObject.find((field:any) => field.id == this.fetchedFieldId)
          this.onFieldSelect(fieldData,"impact",this.ruleLength,0)
          if(this.fetchedDataTypeId == componentTypeMap[16].id || this.fetchedDataTypeId == componentTypeMap[17].id){
            this.setSelectedKeyValueForConditionAndImpact('impact', 'fields', this.ruleLength, 0, [fieldData]);
          }
          // this.initializeConditionOperators(selectedFieldsDataTypeId,"impact",0,0)
        });
      }
    }

  }

  //Get Fields from formId API
  getFieldData(formId:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any){
    this.fieldAPI.getFieldsByForm(formId).subscribe(res => {
      this.fieldsData = cloneArray(res.responseObject)
      this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey, 'uiFieldSelect', ruleIndex, conditionOrLogMappingIndex, cloneArray(this.fieldsData));
    });
  }

  //Extracting Forms Data for setting it to selectionBox
  extractForms(visits: any[]) {
    let formsArray:any[] = []
    Object.keys(visits).map((key:any) => {
      formsArray.push({formId: visits[key].formId, formName: visits[key].formName, formType:visits[key].formType})
    })
    return formsArray
  }

  //Getting FormArray Rule Key
  get getValidationOrLogMappingRuleKey(){
    return (this.validationAndLogMapping.get("rule") as FormArray)
  }

  //Getting Selected Rule Key Value
  getSelectedRuleKeyValue(keyName:any,i:any){
    return ((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(keyName)?.value
  }

  //Setting Selected Rule Key Value
  setSelectedRuleKeyValue(keyName:any,i:any,value:any){
    return((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(keyName)?.setValue(value)
  }

  getConditionOrImpactArrayRule(key:any,i:any){
    return (((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(key) as FormArray)
  }

  getConditionOrImpactArrayKey(key:any,i:any,j:any){
    return ((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(key) as FormArray).get(j.toString()) as FormGroup)
  }

  getSelectedKeyValueForConditionAndImpact(mainKey:any,keyName:any,i:any,j:any){
    return ((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(mainKey) as FormArray).get(j.toString()) as FormGroup).get(keyName)
  }

  setSelectedKeyValueForConditionAndImpact(mainKey:any,keyName:any,i:any,j:any,value:any){    
    return ((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(mainKey) as FormArray).get(j.toString()) as FormGroup).get(keyName)?.setValue(value)
  }

  getSelectedKeyValueForDestinationConditionAndImpact(mainKey:any,keyName:any,innerKey:any,i:any,j:any){
    return (((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(mainKey) as FormArray).get(j.toString()) as FormGroup).get(keyName) as FormGroup).get(innerKey)
  }

  setSelectedKeyValueForDestinationConditionAndImpact(mainKey:any,keyName:any,innerKey:any,i:any,j:any,value:any){    
    return (((((this.validationAndLogMapping.get("rule") as FormArray).get(i.toString()) as FormGroup).get(mainKey) as FormArray).get(j.toString()) as FormGroup).get(keyName) as FormGroup).get(innerKey)?.setValue(value)
  }

  //OnChange the ValidationFormType inside the condition
  onValidationFormTypeSelect(event:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any){
    const selectedValidationFormType = event.value
    this.resetFormControls(['visit','uiFormSelect','form','fields','dataTypeId','multiRowIds','multiRowDataTypeId','uiFieldSelect'],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex)
    if(selectedValidationFormType > 0){
      this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"validationFormType",ruleIndex,conditionOrLogMappingIndex,1)
      this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"visit",ruleIndex,conditionOrLogMappingIndex,DEFAULT_UUID)
      const forms = this.logVisitListMainData['visit'][DEFAULT_UUID].forms
      this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiFormSelect",ruleIndex,conditionOrLogMappingIndex,this.extractForms(forms) )
    }
  }

  //OnChange Method for visit selectionBox
  onVisitSelect(event:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any){
    this.resetFormControls(['uiFormSelect','form','fields','dataTypeId','multiRowIds','multiRowDataTypeId','uiFieldSelect'],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex)
    if(event.value != 'null'){
      const forms = this.visitListMainData['visit'][event.value.toString()].forms
      this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey, 'uiFormSelect', ruleIndex, conditionOrLogMappingIndex, this.extractForms(forms));
    }
  }

  //OnChange Method for form selectionBox
  onFormSelect(event:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any){
    this.resetFormControls(['fields','dataTypeId','multiRowIds','multiRowDataTypeId'],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex)
    if(event.value != 'null'){
      const formId = event.value
      this.getFieldData(formId,conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex)
    }
  }

  //OnChange Method for Fields selectionBox
  onFieldSelect(event:any,conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any){
    this.resetFormControls(['dataTypeId','uiColumnSelect','multiRowIds','multiRowDataTypeId'],conditionOrLogMappingKey,ruleIndex,conditionOrLogMappingIndex)
    console.log("CALLED");
    
    if(event.id != 'null'){
      const fieldId = event.id
      const impactAllFields = this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiFieldSelect",ruleIndex,conditionOrLogMappingIndex)?.value
      let dataTypeId = impactAllFields.find((field: any) => field.id == fieldId).dataTypeId
      let fieldData:any
      if(dataTypeId == componentTypeMap[16].id){
        fieldData = impactAllFields.find((field: any) => field.id == fieldId).columnData
        this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiColumnSelect",ruleIndex,conditionOrLogMappingIndex,fieldData)
      }else if(dataTypeId == componentTypeMap[17].id){
        fieldData = impactAllFields.find((field: any) => field.id == fieldId)
        let cellInfoData:any[] = []
        if(fieldData.cellInfo){
          for (let index = 1; index < fieldData.cellInfo[0].length; index++) {
            fieldData.cellInfo[0][index][0].tableColumnIndex = index
            fieldData.cellInfo[0][index][0].colHeading = fieldData.cellInfo[0][index][0].label
            cellInfoData.push(fieldData.cellInfo[0][index][0])
          }
        }
        this.setSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,"uiColumnSelect",ruleIndex,conditionOrLogMappingIndex,cellInfoData)
      }
    }
  }

  addNewImpact(ruleIndex:any,conditionOrLogMappingIndex:any) {
    let impactData = this.getConditionOrImpactArrayRule("impact",ruleIndex)
    if(( conditionOrLogMappingIndex === null && impactData.length > 0) || impactData.length > conditionOrLogMappingIndex ){
      return
    }else{
      impactData.push(this.addImpact())
    }
  }

  removeImpact(ruleIndex:any,conditionOrLogMappingIndex:any){
    if(this.getConditionOrImpactArrayRule("impact",ruleIndex).length > 1){
      this.getConditionOrImpactArrayRule("impact",ruleIndex).removeAt(conditionOrLogMappingIndex)
    }
  }

  //reset unwanted formControls
  resetFormControls(controlNameArray:any[],conditionOrLogMappingKey:any,ruleIndex:any,conditionOrLogMappingIndex:any){
    if(controlNameArray && controlNameArray.length > 0){
      for (let controlName in controlNameArray){
        if(controlNameArray[controlName] == "jsonFieldValue"){
          this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,controlNameArray[controlName],ruleIndex,conditionOrLogMappingIndex)?.setValue({})
        }else{
          this.getSelectedKeyValueForConditionAndImpact(conditionOrLogMappingKey,controlNameArray[controlName],ruleIndex,conditionOrLogMappingIndex)?.reset()
        }
      }
    }
  }

}
