import { baseURL , VERSION } from 'src/app/constant/constant';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudyService {

  constructor(private http:HttpClient) { }

  postStudy(data:any):Observable<any>{
    return this.http.post(`${baseURL}study/${VERSION}`,data);
  }

  getStudy(pageNo:any,pageSize:any,ids?:any[],codes?:any[],protocolNumbers?:any[],protocolTitles?:any[],status?:any[]):Observable<any>{
    let url:string = `${baseURL}study/${VERSION}/list?pageNo=${pageNo}&pageSize=${pageSize}`
    if(ids && ids.length > 0){
      url = url.concat(`&ids=${ids}`)
    }
    if(codes && codes.length > 0){
      url = url.concat(`&codes=${codes}`)
    }
    if(protocolNumbers && protocolNumbers.length > 0){
      url = url.concat(`&protocolNumbers=${protocolNumbers}`)
    }
    if(protocolTitles && protocolTitles.length > 0){
      url = url.concat(`&protocolTitles=${protocolTitles}`)
    }
    if(status && status.length > 0){
      url = url.concat(`&status=${status}`)
    }
    return this.http.get(url)
  }

  updateStudy(data:any):Observable<any>{
    return this.http.put<any>(`${baseURL}study/${VERSION}`,data);
  }

  deleteStudy(id:string):Observable<any>{
    const params = new HttpParams({ fromObject: { id: id } })
    return this.http.delete(`${baseURL}study/${VERSION}?id=${id}`);
  }
  getStudyById(id:string):Observable<any>{
    return this.http.get(`${baseURL}study/${VERSION}?id=${id}`);
  }

  getPhase():Observable<any>{
    return this.http.get(`${baseURL}phases/${VERSION}/list`);
  }

  getIndication():Observable<any>{
    return this.http.get(`${baseURL}therapeutics/${VERSION}/getdata`);
  }

  getTherapeutics():Observable<any>{
    return this.http.get(`${baseURL}therapeutics/${VERSION}/list`);
  }

  getDateformats():Observable<any>{
    return this.http.get(`${baseURL}dateformats/${VERSION}/list`);
  }
  
  getAllStudyMetaData(id:any):Observable<any>{
    return this.http.get(`${baseURL}common/${VERSION}/studyMetaData?roleId=${id}`);
  }

  lockUnlockStudy(id:any,isLocked:any,reason:any):Observable<any>{
    return this.http.put(`${baseURL}study/${VERSION}/lock?studyId=${id}&isLocked=${isLocked}&reason=${reason}`,id);
  }

  getAllStudyDataWithoutDeleteAndLocked():Observable<any>{
    return this.http.get(`${baseURL}study/${VERSION}/studyList`);
  }

  getStudyFilterList(userId:any,roleId:any):Observable<any>{
    return this.http.get(`${baseURL}study/${VERSION}/studyFilterList?userId=${userId}&roleId=${roleId}`)
  }

  getStudyIdNameList():Observable<any>{
    return this.http.get(`${baseURL}study/${VERSION}/studyIdNameList`)
  }

  getStudyDateFormat():Observable<any>{
    return this.http.get(`${baseURL}study/${VERSION}/dateFormat`)
  }

  studyExport(data:any):Observable<any>{
    return this.http.post(`${baseURL}export/study`,data)
  }

  getStudyLogo(id:any):Observable<any>{
    return this.http.get(`${baseURL}study/${VERSION}/logo?id=${id}`)
  }
}
