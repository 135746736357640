import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { VALIDATION_LOG_MAPPING_TABS, componentTypeMap } from 'src/app/constant/constant';
import { cloneArray } from 'src/app/constant/globalFunction';
import { ValidationRuleService } from 'src/app/services/validation-rule.service';

@Component({
  selector: 'app-validation-log-mapping',
  templateUrl: './validation-log-mapping.component.html',
  styleUrls: ['./validation-log-mapping.component.css']
})
export class ValidationLogMappingComponent implements OnInit{
  
  @Input() fieldName = "";
  @Input() isTabValidation:any;
  @Input() fieldId = "";
  @Input() formType:any;
  @Input() dataTypeId:any;
  @Input() validationRuleId:any;
  @Input() isLogMapping:any; // for validaiton componenet
  tabName:any = "validation";
  
  validateDataFlag:boolean = true
  tabsData:any
  activateTab:number = 0

  @Output() onTabChange = new EventEmitter<number>()

  @Output() modalValidationClose: EventEmitter<any> = new EventEmitter();

  constructor(private modalService:NgbModal){}
  
  ngOnInit(): void {
    this.isTabValidation = true
    this.tabsData = cloneArray(VALIDATION_LOG_MAPPING_TABS)
    if(this.dataTypeId == componentTypeMap[17].id){
      delete this.tabsData[1]
    }else{
      this.tabsData = cloneArray(VALIDATION_LOG_MAPPING_TABS)
    }
    
    if(this.isLogMapping){
      this.setTabToLogMapping();
    }
  }

  onOnlylogMapping(data:any){
    if(data.isOnlyLogMapping){
      this.setTabToLogMapping();
    }
  }

  setTabToLogMapping(){
    this.activateTab = 1;
      this.onTabsChange({target:{text:"LogMapping"}})
      this.setTab(this.activateTab);
  }
  //For Clicking on Validation/Log Mapping Tabs
  onTabsChange(event:any){
    
    if(event.target.text == "Validation"){
      this.validateDataFlag = true
      this.tabName = "validation"
      this.isTabValidation = true
    }else{
      this.validateDataFlag = false
      this.tabName = "logMapping"
      this.isTabValidation = false
    }
  }
  
  //Closing Popup
  closePopup(){
    this.modalService.dismissAll("Dismiss");
    this.modalValidationClose.emit(0)
  }
  
  setTab(index:number){
    this.activateTab = index
    this.onTabChange.emit(this.activateTab)
  }

  
}
