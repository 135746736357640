<div class="container d-flex justify-content-center">
  <div class="row">
    <div class="jumbotron jumbotron-fluid">
      <div class="col">
        <form *ngIf="formValue" [formGroup]="formValue" (ngSubmit)="login()" novalidate>
          <div class="card mt-5">
            <div class="card-header">
              <div class="display-5">
                Select Study & Roles
              </div>
            </div>
            <div class="card-body">
              <!-- Study Field -->
              <div class="form-floating">
                <select class="form-select" id="study" name="study" formControlName="study"
                  aria-label="Floating label select example" (change)="onItemSelect($event.target)">
                  <option [ngValue]="null" selected disabled>--Select--</option>
                  <option *ngFor="let e of allData" [value]="e.studyId">{{e.name}}</option>
                </select>
                <label for="study"><b>Select Study</b></label>
              </div>
              <br>
              <!-- Role Field -->
              <div class="form-floating">
                <select name="role" formControlName="role" class="form-select" id="role"
                  aria-label="Floating label select example">
                  <option [ngValue]="null" selected>--Select--</option>
                  <option *ngFor="let r of roleData" [value]="r.id">{{r.name}}</option>
                </select>
                <label for="role"><b>Select Role</b></label>
              </div>
            </div>
            <!-- Submit Button -->
            <div class="card-footer text-center">
              <!-- [routerLink]="['/layout/dashboard']" -->
              <button type="submit" class="btn btn-outline-success" [disabled]="loading">
                <b>
                  Submit <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
                </b>
              </button> &nbsp;&nbsp;
              <button type="reset" class="btn btn-outline-danger" (click)="cancelLogin()" [disabled]="loading"><b>Cancel</b></button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
