import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CANCEL_MSG, DELETE_MSG } from 'src/app/constant/responseMessage';
import { PhaseService } from 'src/app/services/phase.service';

@Component({
  selector: 'app-phase',
  templateUrl: './phase.component.html',
  styleUrls: ['./phase.component.css'],
})
export class PhaseComponent implements OnInit {
  title: string = 'PHASE';
  phaseData: any[] = [];
  indicationsData: any[] = [];
  isEdit: boolean = false;
  phaseId: any;
  phaseForm!: FormGroup;
  loading: boolean = false;

  constructor(
    private phaseService: PhaseService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {
    this.createPhaseForm();
  }

  ngOnInit(): void {
    this.getPhaseList();
  }

  createPhaseForm() {
    this.phaseForm = this.formBuilder.group({
      types: ['', [Validators.required]],
    });
  }

  setStatus() {
    this.isEdit = false;
  }

  getPhaseList() {
    this.phaseService.getPhaseList().subscribe((phaseList: any) => {
      if (phaseList.responseObject) {
        this.phaseData = phaseList.responseObject;
      }
    });
  }

  addPhase() {
    this.loading = true;
    if (this.phaseForm.value) {
      this.phaseService.savePhase(this.phaseForm.value).subscribe(
        (res: any) => {
          this.phaseForm.reset();
          this.closeModal();
          this.getPhaseList();
          this.loading = false;
          this.toastr.success(res.message);
        },
        (error: any) => {
          this.toastr.error(error.message);
          this.phaseForm.reset();
          this.closeModal();
        }
      );
    }
  }

  OnSelect(phaseData: any) {
    this.indicationsData = [];
    this.indicationsData = this.phaseData.find(
      (cntry: any) => cntry.therapeuticId == phaseData.value
    ).indications;
  }

  closeModal() {
    let ref = document.getElementById('cancel');
    ref?.click();
    this.phaseForm.reset();
  }

  editPhase(indicationDetailRow: any, index: any) {
    this.isEdit = true;

    this.phaseId = indicationDetailRow.id;
    this.phaseService.getPhaseById(indicationDetailRow.id).subscribe(
      (response: any) => {
        if (response && response.responseObject) {
          let phaseFormData = response.responseObject;

          this.phaseForm.get('types')?.setValue(phaseFormData.types);
        }
      },
      (error) => {
        this.toastr.error(error.message);
      }
    );
  }

  updatePhase() {
    this.loading = true;
    if (this.phaseForm.valid && this.phaseForm.value) {
      let requestBody = {
        id: this.phaseId,
        types: this.phaseForm.get('types')?.value,
      };

      (requestBody.id = this.phaseId),
        (requestBody.types = this.phaseForm.get('types')?.value);

      this.phaseService.updatePhase(requestBody).subscribe(
        (response: any) => {
          this.toastr.success(response.message);
          this.isEdit = false;
          this.loading = false;
          this.closeModal();
          this.getPhaseList();
        },
        (error) => {
          this.isEdit = false;
          this.phaseForm.reset();
          this.toastr.error(error.message);
        }
      );
    }
  }


  deleteIndication(row: any, index: any) {
    let r = confirm(DELETE_MSG);
    if (r == true) {
      this.phaseService.deletePhase(row.id).subscribe(
        (res: any) => {
          if (res.status != 0) {
            this.toastr.error(res.message);
          } else {
            this.toastr.success(res.message);
            if (this.phaseData.length) {
              this.phaseData.splice(index, 1);
            }
          }
        },
        (err) => {
          this.toastr.error(err.message);
        }
      );
    } else {
      this.toastr.error(CANCEL_MSG);
    }
  }
}
