  <!-- <div style="margin: 10%;">
    <div class="d-flex justify-content-center align-items-center">
      <div class="card" style="max-width: 650px;">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <img src="../../../assets/Images/loginpage.webp" class="img-fluid ColImg" alt="...">
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 CardCol2">
                <span>
                  <img src="../../../assets/Images/meril-logo.png" class="mx-auto d-block" style="width: 25%;">
                </span>
                <div class="text-center">
                  <h3 class="card-title" style="color: blue; margin-top: 10px;">Welcome</h3>
                  <h5><b>Login</b></h5>
                </div>
                <form *ngIf="formGroup" [formGroup] = "formGroup" (ngSubmit) = "loginProcess()">
                  <div class="input-icons">
                    <i class="fa fa-user icon"></i>
                    <input type="text" class="form-control input-field" name="userName" id="userName" formControlName="username" placeholder="Enter UserName" autofocus tabindex="1">
                  </div>
                  <div class="input-icons mt-2">
                    <i class="fa fa-lock icon"></i>
                    <input [type]="type" class="form-control input-field" name="password" id="password" formControlName="password" placeholder="Password" tabindex="1">
                  </div>
                  <div class="text-end">
                    <p><a href="#" class="loginPTag" [routerLinkActive]="['router-link-active']" [routerLink]="['/forgotPassword']" [ariaDisabled]="loading">Have trouble logging in?</a></p> 
                  </div>
                  <app-captcha [config]="captchaConfig" formControlName="captchaData"/>
                  <div class="row">
                    <div class="col">
                      <button type="submit" class="btn btn-outline-success form-control" tabindex="1" [disabled]="loading"><b>Submit<span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span></b></button>
                    </div>
                    <div class="col">
                      <button type="reset" class="btn btn-outline-danger form-control" [disabled]="loading"><b>Reset</b></button>
                    </div>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-2">
      <p class="cardFooter"> © 2024 EDC. All rights reserved. <span> v1.0.1</span></p>
    </div>
  </div>  -->
  
  <!-- <section class="bg-login-form-image">
  <div class="loginFormBox container">
    <div class="row">
      <form *ngIf="formGroup" [formGroup] = "formGroup" (ngSubmit) = "loginProcess()">
        <div>
          <div>
            <div class="edcFontStyle">
              EDC
            </div>
          </div>
          <div class="loginFontStyle">
            Login
          </div>
          <div class="inputBox">
            <div>
              <input type="text"
              name="userName" id="userName" formControlName="username"
              placeholder="Username" autofocus tabindex="1" class="login-input">
              <span class="loginUserIcon"></span>
            </div>
            <div>
                <input [type]="type"
                name="password" id="password" formControlName="password"
                placeholder="Password" tabindex="1" class="login-input">
                <span class="lockIcon"></span>
                <span class="showPassword view-logo" (click)="isVisiblePassword = true; type='text'" *ngIf="!isVisiblePassword"></span>
                <span class="showPasswordView view-logo" (click)="isVisiblePassword = false; type='password'" *ngIf="isVisiblePassword"></span>
            </div>
            <div>
              <a href="#" [routerLinkActive]="['router-link-active']" [routerLink]="['/forgotPassword']" class="forgotPasswordLink"><b>Forgot Password?</b></a>
            </div>
            <app-captcha [config]="captchaConfig" formControlName="captchaData" />
          </div>
          <div class="buttonPosition">
            <button class="btn btn-success buttonStyle" type="submit" >
              <b>
                Submit <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
              </b>
            </button>&nbsp;&nbsp;
            <button type="reset" class="btn btn-primary buttonStyle"><b>Reset</b></button>
          </div>
        </div>
      </form>
    </div>
  </div> 
</section> -->

<!-- /* Try New LoginPage CSS */ -->
<section class="vh-100" style="background-color: #385b9b;">
  <div class="container py-5 h-100">
    <div class="row MainDiv h-100">
      <!-- Login Pannel Size According To Resolution -->
      <div class="col-sm col-md-10 col-lg-9 col-xl-7">
        <div class="card" style="border-radius: 1rem;">
          <div class="row g-0">
            <!-- Login Pannel Img -->
            <div class="col-sm col-md col-lg col-xl d-none d-md-block">
              <img src="../../../assets/Images/loginpage.webp" alt="login form" class="img-fluid ColImg"/>
            </div>
            <!-- Login Pannel -->
            <div class="col-sm col-md col-lg col-xl LoginPannel">
              <!-- Padding Spacing In Logout PANNEL -->
              <div class="card-body p-2 p-md-3 p-lg-4 p-xl-5">
                <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="loginProcess()">
                  <!-- Meril Logo -->
                  <span>
                    <img src="../../../assets/Images/meril-logo.png" style="width: 30%;" class="LoginMerilLogo">
                  </span>
                  <!-- Welcome -->
                  <h2 class="LoginH2"><b>Welcome</b></h2>
                  <!-- Login -->
                  <h5 class="LoginH5"><b>Log In</b></h5>
                  <!-- Username -->
                  <div class="mb-2">
                    <input type="text" class="form-control input-field" name="userName" id="userName" formControlName="username" placeholder="Enter UserName" autofocus tabindex="1">
                  </div>
                  <!-- Password -->
                  <div class="mb-1">
                    <input [type]="type" class="form-control input-field" name="password" id="password" formControlName="password" placeholder="Password" tabindex="1">
                  </div>
                  <!-- Forgot Password -->
                  <div class="text-end">
                    <p><a href="#" class="LoginPTag" [routerLinkActive]="['router-link-active']" [routerLink]="['/forgotPassword']" [ariaDisabled]="loading">Have trouble logging in?</a></p> 
                  </div>
                  <!-- Captcha -->
                  <app-captcha [config]="captchaConfig" formControlName="captchaData"/>
                  <!-- Buttons -->
                  <div class="row mt-3">
                    <div class="col">
                      <button type="submit" class="btn btn-outline-success form-control" tabindex="1" [disabled]="loading"><b>Submit<span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span></b></button>
                    </div>
                    <div class="col">
                      <button type="reset" class="btn btn-outline-danger form-control" [disabled]="loading"><b>Reset</b></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- All Rights reserved - v1.0.1 -->
        <div class="text-center mt-2">
          <p class="cardFooter" style="color: white;"> © 2024 EDC. All rights reserved. <span> v1.0.1</span></p>
        </div>
      </div>
    </div>
  </div>
</section>  