import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VERSION, baseURL } from '../constant/constant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TherapeuticService {
  constructor(private http: HttpClient) {}

  saveTherapeutic(payload: any): Observable<any> {
    return this.http.post(`${baseURL}therapeutics/${VERSION}`, payload);
  }

  updateTherapeutic(payload: any): Observable<any> {
    console.log('payload: ', payload);
    return this.http.put(`${baseURL}therapeutics/${VERSION}`, payload);
  }

  deleteTherapeutic(therapeuticsId: string): Observable<any> {
    return this.http.delete(
      `${baseURL}therapeutics/${VERSION}?id=${therapeuticsId}`
    );
  }

  getTherapeuticList(): Observable<any> {
    return this.http.get(`${baseURL}therapeutics/${VERSION}/list`);
  }

  getTherapeuticAndIndication(): Observable<any> {
    return this.http.get(`${baseURL}/therapeutics/v1`);
  }

  getTherapeuticById(therapeuticsId: string): Observable<any> {
    return this.http.get(
      `${baseURL}therapeutics/${VERSION}?id=${therapeuticsId}`
    );
  }

  getTherapeuticDropDownList() {
    return this.http.get(`${baseURL}therapeutics/${VERSION}/getList`);
  }
}
