import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged } from 'rxjs';
import { ERROR_ICON_COLOR, SUCCESS_ICON_COLOR } from 'src/app/constant/color-code';
import { STUDY_LOCKING_TYPE } from 'src/app/constant/constant';
import { SITE_SELECT_MSG, SOFT_LOCK, SUBJECT_CONFIRM_SOFT_LOCK_MSG, SUBJECT_CONFIRM_SOFT_UNLOCK_MSG, SUBJECT_SELECT_MSG, VISIT_CONFIRM_SOFT_LOCK_MSG, VISIT_CONFIRM_SOFT_UNLOCK_MSG, VISIT_LOCKING, VISIT_SELECT_MSG, VISIT_SOFT_LOCK_MSG } from 'src/app/constant/responseMessage';
import { Role } from 'src/app/models/Role';
import { ReviewService } from 'src/app/services/review.service';
import { SiteService } from 'src/app/services/site.service';
import { VisitsService } from 'src/app/services/visits.service';
import { ReasonPopupComponent } from '../reason-popup/reason-popup.component';

@Component({
  selector: 'app-study-locking-popup',
  templateUrl: './study-locking-popup.component.html',
  styleUrls: ['./study-locking-popup.component.css']
})
export class StudyLockingPopupComponent implements OnInit{
  
  @Input() rowData:any

  title:any = SOFT_LOCK

  studyLockingType:any = STUDY_LOCKING_TYPE
  
  studyLockingForm:FormGroup
  studyLockingFilter:FormGroup
  tableData:any[] = []

  visitDropdownSettings:IDropdownSettings = {}
  visitsList:any[] = []
  subjectDropDownSettings:IDropdownSettings = {}
  siteDropDownSettings:IDropdownSettings = {}

  formData:any
  siteData:any[] = []
  sitesArray:any[] = []
  subjectData:any[] = []

  isLocked:any
  roleId:any
  loading:boolean = false

  successIconColor:any = SUCCESS_ICON_COLOR
  errorIconColor:any = ERROR_ICON_COLOR

  responseData:any[] = []

  constructor(private modalService:NgbModal,private formBuilder:FormBuilder,private toastr:ToastrService,
    private visitAPI:VisitsService,private siteAPI:SiteService,private router:Router,
    private reviewAPI:ReviewService){
    this.studyLockingForm = new FormGroup({
      lockType:new FormControl(1)
    })

    this.studyLockingFilter = formBuilder.group({})

  }

  ngOnInit(): void {
    this.getStorageData()
    this.setDropDownMenu()
    if(this.studyLockingForm.get("lockType")?.value == this.studyLockingType[0].id){
      this.visitsList = []
      this.studyLockingFilter.addControl("visitIds",this.formBuilder.control([]))
      this.getVisitData()
    }
  }

  getStorageData(){
    this.roleId = sessionStorage.getItem("role")
    this.isLocked = sessionStorage.getItem("isLocked")
  }

  getVisitData(){
    let subjectId = this.studyLockingFilter.get("subjectIds")?.value == undefined ? '' : this.studyLockingFilter.get("subjectIds")?.value
    if(subjectId){
      this.visitAPI.getVisitsByStudyIdAndSubjectId(this.rowData.id,subjectId).subscribe(res => {
        if(res.status == 0){
          this.visitsList = res.responseObject
          this.tableData = res.responseObject
        }
      })
    }else{
      this.visitAPI.getVisitsByStudyId(this.rowData.id).subscribe(res => {
        if(res.status == 0){
          this.visitsList = res.responseObject
          this.tableData = res.responseObject
        }
      })
    }
  }

  getSiteData(){
    
    this.siteAPI.getAllSiteSubjectDataByStudyId(this.rowData.id).pipe(distinctUntilChanged()).subscribe(res => {
      console.log("RESPONSE :: ",res);
      this.formData = res.responseObject;
      const sites = this.formData['sites'];
      this.siteData = this.extractSites(sites);
    })
  }

  extractSites(sites: any): any[] {
    this.sitesArray = [];
    let subjectArray: any[] = [];
    Object.keys(sites).map((key) => {
      this.sitesArray.push({ id: key, name: sites[key].name });
      sites[key].subjects.map((key: any, value: any)=>{
        subjectArray.push(key);
      })
    });
    this.subjectData = subjectArray;
    return this.sitesArray;
  }

  setDropDownMenu(){
    this.visitDropdownSettings = this.siteDropDownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      itemsShowLimit: 4,
      allowSearchFilter: true
    };
    this.subjectDropDownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'subjectId',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      itemsShowLimit: 4,
      allowSearchFilter: true
    };
  }

  closeModal(){
    this.modalService.dismissAll()
  }

  onLockTypeChange(item:any){
    this.tableData = []
    
    Object.keys(this.studyLockingFilter.value).find((data:any) => {
      this.studyLockingFilter.removeControl(data)
    })
    if(item.id == STUDY_LOCKING_TYPE[0].id){
      this.visitsList = []
      this.studyLockingFilter.addControl("visitIds",this.formBuilder.control([]))
      if(this.visitsList && this.visitsList.length <= 0){
        this.getVisitData()
      }
    }else{
      this.siteData = []
      this.sitesArray = []
      this.subjectData = []
      this.studyLockingFilter.addControl("siteIds",new FormControl())
      this.studyLockingFilter.addControl("subjectIds",new FormControl())
      if(this.siteData && this.siteData.length <= 0){
        this.getSiteData()
      }
    }
  }

  deSelectVisit(){}
  onSelectVisit(){}

  validateFilterData(){
    let data = this.studyLockingFilter.value
    let formData = this.studyLockingForm.value
    if(formData.lockType != null && formData.lockType == STUDY_LOCKING_TYPE[0].id){
      if(data.visitIds == undefined || data.visitIds == null || data.visitIds.length <= 0){
        this.toastr.warning(VISIT_SELECT_MSG)
        return false
      }
    }else if(formData.lockType != null && formData.lockType == STUDY_LOCKING_TYPE[1].id){
      if(data.siteIds == undefined || data.siteIds == null){
        this.toastr.warning(SITE_SELECT_MSG)
        return false
      }else if(data.subjectIds == undefined || data.subjectIds == null){
        this.toastr.warning(SUBJECT_SELECT_MSG)
        return false
      }
    }
    return true
  }
  
  onPopulateData(){
    if(this.validateFilterData()){
      this.getVisitData()
    }
  }

  onStudyLocking(item:any,isLocking:any){
    let confirmationMsg:any
    let formData = this.studyLockingForm.value
    let data = this.studyLockingFilter.value
    if(formData.lockType != null && formData.lockType == STUDY_LOCKING_TYPE[0].id){
      // confirmationMsg = confirm(VISIT_CONFIRM_SOFT_LOCK_MSG)
      if(isLocking == 2){
        confirmationMsg = VISIT_CONFIRM_SOFT_LOCK_MSG
      }else{
        confirmationMsg = VISIT_CONFIRM_SOFT_UNLOCK_MSG
      }
    }else if(formData.lockType != null && formData.lockType == STUDY_LOCKING_TYPE[1].id){
      // confirmationMsg = confirm(SUBJECT_CONFIRM_SOFT_LOCK_MSG)
      if(isLocking == 2){
        confirmationMsg = SUBJECT_CONFIRM_SOFT_LOCK_MSG
      }else{
        confirmationMsg = SUBJECT_CONFIRM_SOFT_UNLOCK_MSG
      }
    }
    // if(confirmationMsg){
    // }
    let requestBody:any = {
      status:isLocking
    }
    let visitId:any[] = []
    let subjectId:any[] = []
    if(formData.lockType != null && formData.lockType == STUDY_LOCKING_TYPE[0].id){
      if(data.visitIds != null && (data.visitIds as FormArray).length > 0 ){
        for (let i = 0; i < data.visitIds.length; i++) {
          visitId.push(data.visitIds[i].id)
        }
        requestBody['visitIds'] = visitId
      }
      if(visitId && visitId.length == 0){
        this.toastr.warning(VISIT_SELECT_MSG)
        return
      }
    }else if(formData.lockType != null && formData.lockType == STUDY_LOCKING_TYPE[1].id){
      if(data.subjectIds != null && (data.subjectIds as FormArray).length > 0 ){
        for (let i = 0; i < data.subjectIds.length; i++) {
          subjectId.push(data.subjectIds[i].id)
        }
        requestBody['subjectIds'] = subjectId
      }
      if(subjectId && subjectId.length == 0){
        this.toastr.warning(SUBJECT_SELECT_MSG)
        return
      }
    }
    console.log("REQUEST BODY :: ",requestBody);
    
    let modal = this.modalService.open(ReasonPopupComponent,{size:'lg'})    
    modal.componentInstance.confirmMsg = confirmationMsg
    modal.componentInstance.requestBody = requestBody
    modal.componentInstance.isVisitLock = false
    modal.componentInstance.modalOkClicked.subscribe((response:any) => {
      console.log("RESPONSE :: ",response);
      this.responseData = []
      this.studyLockingFilter.reset()
      let mainResponse = response.responseObject
      if(mainResponse){
        for(let obj in mainResponse){
          let res:any = {
            subjectId:obj,
            status:mainResponse[obj]
          }
          this.responseData.push(res)
        }
      }

      // if(this.roleId == Role.DM){
      //   let params:any = {}
      //   if(formData.lockType != null && formData.lockType == STUDY_LOCKING_TYPE[0].id){
      //     params['visitId'] = visitId.join('')
      //   }else if(formData.lockType != null && formData.lockType == STUDY_LOCKING_TYPE[1].id){
      //     params['subjectId'] = subjectId.join('')
      //   }
      //   this.router.navigate(["/layout/data-manager"],{queryParams:params})
      // }
    })
    // this.reviewAPI.setSoftLock(requestBody).subscribe(res => {
    //   let response:string = res.responseObject
    //   if( response != null && response.length > 0){
    //     this.toastr.warning(VISIT_SOFT_LOCK_MSG + "" + res.responseObject)
    //   }else{
    //     this.toastr.success(res.message)
    //     // if(this.roleId == Role.CRA){
    //     //   this.router.navigate(["/layout/"])
  
    //     // }else if(this.roleId == Role.Investigator){
    //     //   this.router.navigate(["/layout/"])
  
    //     // }
    //     // if(this.roleId == Role.DM){
    //     //   let params:any = {}
    //     //   if(formData.lockType != null && formData.lockType == STUDY_LOCKING_TYPE[0].id){
    //     //     params['visitId'] = visitId.join('')
    //     //   }else if(formData.lockType != null && formData.lockType == STUDY_LOCKING_TYPE[1].id){
    //     //     params['subjectId'] = subjectId.join('')
    //     //   }
    //     //   this.router.navigate(["/layout/data-manager"],{queryParams:params})
    //     // }
    //     this.modalService.dismissAll()
    //   }

    // })
  }

  
}
