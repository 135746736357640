<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Validation <span *ngIf="validationRuleId == null">({{fieldName}})</span></h5>
    <button class="btn-close" data-dismiss="modal" aria-label="Close" (click)="closePopup()"></button>
  </div>
  <div class="modal-body">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" (activeIdChange)="onTabsChange($event)">
      <li ngbNavItem domId="validation">
        <a ngbNavLink>Validation</a>
        <ng-template ngbNavContent>
          <br>
          <form *ngIf="formValidation" [formGroup]="formValidation">
            <div class="row">
              <div class="col-10">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" formControlName="name" autocomplete="off"
                  id="name" name="name" placeholder="Enter Validation Name" max="500" maxlength="500">
                  <label for="name">Validation Name</label>
                  <i><b>Note:</b> Maximum character allowed 500</i>
                </div>
              </div>
              <div class="col-2 text-left" *ngIf="formValidation.get('name')?.value != undefined">
                <button title="Add Validation" class="btn btn-outline-primary mt-2" title="Add New Validation" (click)="addNewValidation()">
                  Add Validation
                </button>
              </div>
            </div>
            <div formArrayName="rule">
              <div *ngFor="let item of getRule.controls;let i=index">
                <div class="row" [formGroupName]="i">
                  <div class="col-12">
                    <input type="hidden" name="id" formControlName="ruleId">
                    <div ngbAccordion (click)="populateRuleDesign($event.target,i)">
                      <div ngbAccordionItem>
                        <div ngbAccordionHeader>
                          <button class="accordion-button control-buttons" ngbAccordionToggle>
                            <div class="col-10 wordWrap">
                              <b *ngIf="getSelectedRuleKeyValue(formValidation,'isNameEditable',i) == false">
                                {{getSelectedRuleKeyValue(formValidation,'name',i)}}
                              </b>
                              <input type="text" name="validationName"
                              id="validationName" formControlName="editedName" max="500" maxlength="500"
                              *ngIf="getSelectedRuleKeyValue(formValidation,'isNameEditable',i) == true">
  
                              <button class="btn btn-outline-secondary input-group-addon"
                                *ngIf="getSelectedRuleKeyValue(formValidation,'isNameEditable',i) == true"
                                (click)="editValidationName(formValidation,i)">
                                <b>OK</b>
                              </button>
                              
                              <button class="control-buttons" title="Edit" (click)="onEditName(formValidation,i)" [hidden]="isLocked == 1 || roles != 1">
                                <span class="editIcon"></span>
                              </button>

                            </div>
                            <div class="col mt-2">
                              <!-- <button class="btn btn-sm btn-outline-success " (click)="addSingleValidation(item,formValidation.get('formType')?.value,i)" *ngIf="getSelectedRuleKeyValue(formValidation,'id',i) == null">
                                <b>
                                  Save <span class="spinner-border spinner-border-sm" *ngIf="getSelectedRuleKeyValue(formValidation,'isLoading',i)" role="status" aria-hidden="true"></span>
                                </b>
                              </button>
                              <button class="btn btn-sm btn-outline-warning " (click)="addSingleValidation(item,formValidation.get('formType')?.value,i)" *ngIf="getSelectedRuleKeyValue(formValidation,'id',i) != null">
                                <b>
                                  Update <span class="spinner-border spinner-border-sm" *ngIf="getSelectedRuleKeyValue(formValidation,'isLoading',i)" role="status" aria-hidden="true"></span>
                                </b>
                              </button> &nbsp; -->
                              <span class="slideToggle" [hidden]="roles != 1">
                                <mat-slide-toggle (change)="enableDisableValidation($event,getSelectedRuleKeyValue(formValidation,'status',i),getSelectedRuleKeyValue(formValidation,'id',i),formValidation,i)"
                                [checked]="getSelectedRuleKeyValue(formValidation,'status',i) == 1 ? true : false"
                                [title]="getSelectedRuleKeyValue(formValidation,'status',i) == 1 ? 'Enable':'Disable'"
                                color="primary">
                              </mat-slide-toggle>
                              </span>
                              <button class="control-buttons" title="Save" (click)="addSingleValidation(item,formValidation.get('formType')?.value,i)" *ngIf="getSelectedRuleKeyValue(formValidation,'id',i) == null" [hidden]="isLocked == 1 || roles != 1 || roles != 6">
                                <span class="saveIcon"></span>
                              </button>
                              <button class="control-buttons" title="Save" (click)="addSingleValidation(item,formValidation.get('formType')?.value,i)" *ngIf="getSelectedRuleKeyValue(formValidation,'id',i) != null" [hidden]="isLocked == 1 || roles != 1">
                                <span class="saveIcon"></span>
                              </button>
                              <button class="control-buttons" title="Remove Rule" (click)="removeRule(formValidation,i)" [hidden]="isLocked == 1 || roles != 1">
                                <span class="deleteIcon"></span>
                              </button>
                            </div>
                          </button>
                        </div>
                        <div ngbAccordionCollapse>
                          <div ngbAccordionBody>
                            <ng-template>
                              <!-- Condition Design -->
                              <div formArrayName="condition" *ngIf="formValidation.get('formType')?.value == 0">
                                <div *ngFor="let condition of getArrayRule(formValidation,'condition',i).controls;let j=index">
                                  <div [formGroupName]="j">
                                    <div *ngIf="j == 0">
                                      <h5>IF</h5>
                                    </div>
                                    <div class="row">
                                      <input type="hidden" name="id" formControlName="id">
                                      <div class="col">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="validationFormType" formControlName="validationFormType" (change)="onValidationFormTypeChange($event.target,formValidation,i,j)">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of validationFormTypeData" [value]="item.id"><b>{{item.name}}</b></option>
                                          </select>
                                          <label for="validationFormType"><b>Form-Type</b></label>
                                        </div>
                                      </div>
                                      <!-- Validation Form Type is Normal Form -->
                                      <input type="hidden" formControlName="visitId" [value]="defaultUuid" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value == 1">
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value == 0">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" 
                                            id="visitId" 
                                            
                                            (change)="OnVisitSelect(formValidation,'condition','uiFormSelect',$event.target,i,j)" 
                                            formControlName="visitId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of visitAllData" [value]="item.id"><b>{{item.name}}</b></option>
                                          </select>
                                          <label for="visitId"><b>Visit</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formValidation,'condition','visitId',i,j)?.value != null">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" 
                                            id="formSelect" 
                                            
                                            (change)="OnFormSelect(formValidation,'condition','uiFormSelect','uiFieldSelect',$event.target,i,j)" 
                                            formControlName="formId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','uiFormSelect',i,j)?.value" [value]="item.formId"><b>{{item.formName}}</b></option>
                                          </select>
                                          <label for="formSelect"><b>Form</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formValidation,'condition','formId',i,j)?.value != null">
                                        <div class="form-floating mb-3">
                                          <select  class="form-select" 
                                          
                                          (change)="onFieldSelected(formValidation,'condition','uiFieldSelect','uiColumnSelect',$event.target,i,j)"
                                          formControlName="fieldId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','uiFieldSelect',i,j)?.value" [value]="item.id"><b>{{item.label}}</b></option>
                                          </select>
                                          <label for="fieldsSelect"><b>Field</b></label>
                                        </div>
                                      </div>
                                      <!-- For Table Component -->
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formValidation,'condition','fieldId',i,j)?.value != undefined && [18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value)">
                                        <div class="form-floating mb-3">
                                          <select
                                            (change)="onTableColumnSelected(formValidation,'condition','uiColumnSelect',$event.target,i,j)"
                                            class="form-select" formControlName="multiRowId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','uiColumnSelect',i,j)?.value" [value]="item.tableColumnIndex">
                                              <b>{{item.colHeading}}</b>
                                            </option>
                                          </select>
                                          <label for="columnSelect"><b>Column Heading</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formValidation,'condition','fieldId',i,j)?.value != undefined && [17].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value)">
                                        <div class="form-floating mb-3">
                                          <select
                                            (change)="onColumnSelected(formValidation,'condition','uiColumnSelect',$event.target,i,j)"
                                            class="form-select" formControlName="multiRowId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','uiColumnSelect',i,j)?.value" [value]="item.id">
                                              <b>{{item.colHeading}}</b>
                                            </option>
                                          </select>
                                          <label for="columnSelect"><b>Column</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && (![17,18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) && 
                                        getSelectedKeyValue(formValidation,'condition','fieldId',i,j)?.value != undefined) || 
                                        ([17,18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) && 
                                        getSelectedKeyValue(formValidation,'condition','multiRowId',i,j)?.value != undefined)">
                                        <div class="col">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="operator" formControlName="operator" (change)="onOperatorChange($event.target,i,j,formValidation)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let operator of getSelectedKeyValue(formValidation,'condition','uiOperatorSelect',i,j)?.value" value="{{operator.name}}"><b>{{operator.name}}</b></option>
                                            </select>
                                            <label for="operator"><b>Operator</b></label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && 
                                      getSelectedKeyValue(formValidation,'condition','operator',i,j)?.value != undefined &&
                                      (
                                        (![17,18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) && ![6, 7, 8].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value)) ||
                                        ([17,18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) && (![6, 7, 8].includes(getSelectedKeyValue(formValidation,'condition','multiRowDataTypeId',i,j)?.value)))
                                      )">
                                        <div class="col">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="isConstant" formControlName="isConstant" (change)="IsConstantSelected(formValidation,'condition',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let constant of constantData" value="{{constant.id}}"><b>{{constant.name}}</b></option>
                                            </select>
                                            <label for="isConstant"><b>Is Constant</b></label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && 
                                      getSelectedKeyValue(formValidation,'condition','isConstant',i,j)?.value == 1 &&
                                      ([1,2,3,4,5].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) ||
                                      ([17,18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) && 
                                      ([1,2,3,4,5].includes(getSelectedKeyValue(formValidation,'condition','multiRowDataTypeId',i,j)?.value))))">
                                        <div class="form-floating mb-3">
                                          <input type="text" class="form-control" placeholder="Enter Value" id="fieldValue" formControlName="fieldValue" max="512" maxlength="512">
                                          <label for="fieldValue"><b>Value</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && 
                                      (getSelectedKeyValue(formValidation,'condition','isConstant',i,j)?.value == 1 &&
                                      ([6, 7, 8].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) ||
                                      (getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value == 17 &&
                                      [6, 7, 8].includes(getSelectedKeyValue(formValidation,'condition','multiRowDataTypeId',i,j)?.value))))
                                      ">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="option" formControlName="jsonFieldValue">
                                            <option [value]="'null'" selected>--Select--</option>
                                            <option *ngFor="let option of getSelectedKeyValue(formValidation,'condition','uiOptionSelect',i,j)?.value" value="{{option.id}}"><b>{{option.name}}</b></option>
                                          </select>
                                          <label for="option"><b>Option</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && 
                                      (getSelectedKeyValue(formValidation,'condition','isConstant',i,j)?.value == 1 && 
                                      (getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value == 18 &&
                                      [6, 7, 8].includes(getSelectedKeyValue(formValidation,'condition','multiRowDataTypeId',i,j)?.value)))
                                      ">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="option" formControlName="fieldValue">
                                            <option [value]="'null'" selected>--Select--</option>
                                            <option *ngFor="let option of getSelectedKeyValue(formValidation,'condition','uiOptionSelect',i,j)?.value" value="{{option.name}}"><b>{{option.name}}</b></option>
                                          </select>
                                          <label for="option"><b>Option</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && 
                                      getSelectedKeyValue(formValidation,'condition','isConstant',i,j)?.value == 1 &&
                                      (getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value == 12 || 
                                      ([17,18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) && 
                                      getSelectedKeyValue(formValidation,'condition','multiRowDataTypeId',i,j)?.value == 12))">
                                        <div class="form-floating mb-3">
                                          <div class="input-group">
                                            <input class="form-control" placeholder="Select Date" formControlName="jsonFieldValue" 
                                            ngbDatepicker #dynamicDate="ngbDatepicker" [readOnly]="true" [dayTemplate]="customDay" (click)="dynamicDate.toggle()">
                                            <div class="input-group-append">
                                              <button class="btn btn-outline-secondary" (click)="dynamicDate.toggle()" type="button">
                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                              </button>
                                            </div>
                                          </div>
                                          <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                                            let-disabled="disabled" let-focused="focused">
                                            <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                                              [class.text-muted]="date.month !== currentMonth">
                                              {{ date.day }}
                                            </span>
                                          </ng-template>
                                        </div>
                                      </div>
                                      <div class="row" formGroupName="dest" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value != undefined">
                                        <div class="col-1"></div>
                                        <div class="col">
                                          <input type="hidden" name="id" formControlName="id">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="validationFormType" formControlName="validationFormType" (change)="onDestValidationFormTypeChange($event.target,formValidation,'condition','dest',i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of validationFormTypeData" [value]="item.id"><b>{{item.name}}</b></option>
                                            </select>
                                            <label for="validationFormType"><b>Form-Type</b></label>
                                          </div>
                                        </div>
                                        <!-- Destination Validation Form Type is Normal Form  -->
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 0">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="visitId" formControlName="visitId" (change)="onDestVisitSelect(formValidation,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestVisitSelect" [value]="item.id"><b>{{item.name}}</b></option>
                                            </select>
                                            <label for="visitId"><b>Visit</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.visitId != null">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="formId" formControlName="formId" (change)="onDestFormSelect(formValidation,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestFormSelect" [value]="item.formId"><b>{{item.formName}}</b></option>
                                            </select>
                                            <label for="formId"><b>Form</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.formId != null">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="fieldId" formControlName="fieldId" (change)="onDestFieldSelect(formValidation,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestFieldSelect" [value]="item.id"><b>{{item.label}}</b></option>
                                            </select>
                                            <label for="fieldId"><b>Field</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.dataTypeId == 17">
                                          <div class="form-floating mb-3">
                                            <select
                                              (change)="onDestColumnSelect(formValidation,'condition','dest',$event.target,i,j)"
                                              class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.id">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column</b></label>
                                          </div>
                                        </div>
                                        <!-- For Table Component -->
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.dataTypeId == 18">
                                          <div class="form-floating mb-3">
                                            <select
                                              (change)="onDestColumnSelect(formValidation,'condition','dest',$event.target,i,j)"
                                              class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.tableColumnIndex">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column Heading</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.fieldId != null">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="loperator" formControlName="loperator" (change)="addNewCondition(formValidation,i,j)">
                                              <option [value]="'null'" selected>--Select--</option>
                                              <option *ngFor="let vlo of validationLogicalOprator" [value]="vlo.name"><b>{{vlo.name}}</b></option>
                                            </select>
                                            <label for="lOPerator"><b>and/or</b></label>
                                          </div>
                                        </div>
                                        <!-- Destination Validation Form Type is Log Form  -->
                                        <input type="hidden" formControlName="visitId" [value]="defaultUuid" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 1">
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.visitId != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.visitId == defaultUuid">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="formId" formControlName="formId" (change)="onDestFormSelect(formValidation,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestFormSelect" [value]="item.id"><b>{{item.name}}</b></option>
                                            </select>
                                            <label for="formId"><b>Form</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.formId != null">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="fieldId" formControlName="fieldId" (change)="onDestFieldSelect(formValidation,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestFieldSelect" [value]="item.id"><b>{{item.label}}</b></option>
                                            </select>
                                            <label for="fieldId"><b>Field</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.dataTypeId == 17">
                                          <div class="form-floating mb-3">
                                            <select
                                              (change)="onDestColumnSelect(formValidation,'condition','dest',$event.target,i,j)"
                                              class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.id">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column</b></label>
                                          </div>
                                        </div>
                                        <!-- For Table Component -->
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.dataTypeId == 18">
                                          <div class="form-floating mb-3">
                                            <select
                                              (change)="onDestColumnSelect(formValidation,'condition','dest',$event.target,i,j)"
                                              class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.tableColumnIndex">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column Heading</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.fieldId != null">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="loperator" formControlName="loperator" (change)="addNewCondition(formValidation,i,j)">
                                              <option [value]="'null'" selected>--Select--</option>
                                              <option *ngFor="let vlo of validationLogicalOprator" [value]="vlo.name"><b>{{vlo.name}}</b></option>
                                            </select>
                                            <label for="lOPerator"><b>and/or</b></label>
                                          </div>
                                        </div>
                                        <div class="col mt-3" *ngIf="j != 0">
                                          <button class="btn-close" title="Remove Condition" (click)="removeCondition(formValidation,i,j)"></button>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && 
                                      getSelectedKeyValue(formValidation,'condition','isConstant',i,j)?.value == 1 &&
                                      (getSelectedKeyValue(formValidation,'condition','fieldValue',i,j)?.value != null ||
                                      getSelectedKeyValue(formValidation,'condition','jsonFieldValue',i,j)?.value != emptyJson)">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="loperator" formControlName="loperator" (change)="addNewCondition(formValidation,i,j)">
                                            <option [value]="'null'" selected>--Select--</option>
                                            <option *ngFor="let vlo of validationLogicalOprator" [value]="vlo.name"><b>{{vlo.name}}</b></option>
                                          </select>
                                          <label for="lOPerator"><b>and/or</b></label>
                                        </div>
                                      </div>
                                      <div class="col mt-3" *ngIf="j != 0 && getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null">
                                        <button class="btn-close" title="Remove Condition" (click)="removeCondition(formValidation,i,j)"></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr *ngIf="formValidation.get('formType')?.value == 0">
                              <!-- Impact Design -->
                              <div class="row" *ngIf="formValidation.get('formType')?.value == 0">
                                <div class="col-1 mt-3">
                                  <h5>Then</h5>
                                </div>
                                <div class="col">
                                  <div class="form-floating mb-3">
                                    <select class="form-select" id="action" (change)="OnActionSelect($event.target,i)" formControlName="action">
                                      <option [value]="null" selected>--Select--</option>
                                      <option *ngFor="let ac of action" [value]="ac.id"><b>{{ac.name}}</b></option>
                                    </select>
                                    <label for="action"><b>Select Action</b></label>
                                  </div>
                                </div>
                                <div class="col" *ngIf="getSelectedRuleKeyValue(formValidation,'action',i) != undefined">
                                  <div class="form-floating mb-3">
                                    <input type="text" class="form-control" formControlName="message"
                                    id="message" name="message" placeholder="Enter Message" max="512" maxlength="512">
                                    <label for="message">Message</label>
                                    <i><b>Note:</b> Maximum character allowed 512</i>
                                  </div>
                                </div>
                                <div class="row" *ngIf="getSelectedRuleKeyValue(formValidation,'action',i) != undefined">
                                  <div formArrayName="impact">
                                    <div *ngFor="let condition of getArrayRule(formValidation,'impact',i).controls;let k=index">
                                      <div [formGroupName]="k">
                                        <div class="row">
                                          <input type="hidden" name="id" formControlName="id">
                                          <div class="col">
                                            <div class="form-floating mb-3">
                                              <select class="form-select"
                                                id="validationFormType"
                                                (change)="onImpactValidationFormTypeChange(formValidation,'impact','uiImpactFormSelect',$event.target,i,k)"
                                                
                                                formControlName="validationFormType">
                                                <option [value]="null" selected>--Select--</option>
                                                <option *ngFor="let item of validationFormTypeData" [value]="item.id"><b>{{item.name}}</b></option>
                                              </select>
                                              <label for="validationFormType"><b>Form-Type</b></label>
                                            </div>
                                          </div>
                                          <input type="hidden" formControlName="visit" [value]="defaultUuid" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value == 1">
                                          <div class="col" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value == 0">
                                            <div class="form-floating mb-3">
                                              <select class="form-select"
                                                id="visitId"
                                                (change)="OnVisitSelect(formValidation,'impact','uiImpactFormSelect',$event.target,i,k)"
                                                
                                                formControlName="visit">
                                                <option [value]="null" selected>--Select--</option>
                                                <option *ngFor="let item of visitAllData" [value]="item.id"><b>{{item.name}}</b></option>
                                              </select>
                                              <label for="visitId"><b>Visit</b></label>
                                            </div>
                                          </div>
                                          <div class="col" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedKeyValue(formValidation,'impact','visit',i,k)?.value != undefined">
                                            <div class="form-floating mb-3">
                                              <select class="form-select" 
                                                id="formSelect"
                                                (change)="OnFormSelect(formValidation,'impact','uiImpactFormSelect','uiImpactFieldSelect',$event.target,i,k)"
                                                
                                                formControlName="form">
                                                <option [value]="null" selected>--Select--</option>
                                                <option *ngFor="let item of getSelectedKeyValue(formValidation,'impact','uiImpactFormSelect',i,k)?.value" [value]="item.formId"><b>{{item.formName}}</b></option>
                                              </select>
                                              <label for="formSelect"><b>Form</b></label>
                                            </div>
                                          </div>
                                          <div class="col mt-2" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedKeyValue(formValidation,'impact','form',i,k)?.value != undefined">
                                            <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Fields"
                                              [disabled]="globalFieldDataTypeId == 17 || dataTypeId == 18"
                                              formControlName="fields" (onSelect)="onImpactFieldSelect(formValidation,'impact','uiImpactFieldSelect','uiImpactColumnSelect',$event,i,k)" [settings]="fieldsDropDownSettings"
                                              (onDeSelect)="onImpactFieldSelect(formValidation,'impact','uiImpactFieldSelect','uiImpactColumnSelect',$event,i,k)"
                                              [data]="getSelectedKeyValue(formValidation,'impact','uiImpactFieldSelect',i,k)?.value">
                                            </ng-multiselect-dropdown>
                                          </div>
                                          <!-- (onSelect)="onImpactColumnSelected(formValidation,'impact','uiImpactColumnSelect',$event,i,k)" -->
                                          <div class="col mt-2" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedKeyValue(formValidation,'impact','fields',i,k)?.value != undefined && globalFieldDataTypeId == 17">
                                            <div class="form-floating mb-3">
                                              <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Columns"
                                                formControlName="multiRowIds"
                                                [settings]="columnsDropDownSettings"
                                                [data]="getSelectedKeyValue(formValidation,'impact','uiImpactColumnSelect',i,k)?.value">
                                              </ng-multiselect-dropdown>
                                            </div>
                                          </div>
                                          <div class="col mt-2" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedKeyValue(formValidation,'impact','fields',i,k)?.value != undefined && dataTypeId == 18">
                                            <div class="form-floating mb-3">
                                              <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Columns Headings"
                                                formControlName="multiRowIds"
                                                [settings]="columnsDropDownSettings"
                                                [data]="getSelectedKeyValue(formValidation,'impact','uiImpactColumnSelect',i,k)?.value">
                                              </ng-multiselect-dropdown>
                                            </div>
                                          </div>
                                          <div class="col-1 mt-3" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedRuleKeyValue(formValidation,'action',i) != 3">
                                            <button *ngIf="getSelectedKeyValue(formValidation,'impact','fields',i,k)?.value != {} && globalFieldDataTypeId != 17" class="transparent-button" title="Add Impact" (click)="addNewImpact(formValidation,i,k+1)">
                                              <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button> &nbsp;
                                            <button class="transparent-button" title="Remove Impact" (click)="removeImpact(formValidation,i,k)">
                                              <i class="fa fa-times" aria-hidden="true"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- Individual Save & Update -->
                              <!-- <div class="row">
                                <div class="col text-end">
                                  <button class="btn btn-outline-success" (click)="addValidation()" *ngIf="!saveUpdateFlag">
                                    <b>
                                      Save <span class="spinner-border spinner-border-sm" *ngIf="saveValidationLoader" role="status" aria-hidden="true"></span>
                                    </b>
                                  </button>
                                  <button class="btn btn-outline-warning" (click)="addValidation()" *ngIf="saveUpdateFlag">
                                    <b>
                                      Update <span class="spinner-border spinner-border-sm" *ngIf="updateValidationLoader" role="status" aria-hidden="true"></span>
                                    </b>
                                  </button> &nbsp;
                                  <button class="btn btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closePopup()">
                                    <b>Cancel</b>
                                  </button>
                                </div>
                              </div> -->
                              <!-- Condition Design -->
                              <div formArrayName="condition" *ngIf="formValidation.get('formType')?.value != 0">
                                <div *ngFor="let condition of getArrayRule(formValidation,'condition',i).controls;let j=index">
                                  <div [formGroupName]="j">
                                    <div *ngIf="j == 0">
                                      <h5>IF</h5>
                                    </div>
                                    <div class="row">
                                      <input type="hidden" name="id" formControlName="id">
                                      <div class="col">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="validationFormType" formControlName="validationFormType" (change)="onValidationFormTypeChange($event.target,formValidation,i,j)">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of validationFormTypeData" [value]="item.id"><b>{{item.name}}</b></option>
                                          </select>
                                          <label for="validationFormType"><b>Form-Type</b></label>
                                        </div>
                                      </div>
                                      <!-- Validation Form Type is Normal Form -->
                                      <input type="hidden" formControlName="visitId" [value]="defaultUuid" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value == 1">
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value == 0">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" 
                                            id="visitId" 
                                            
                                            (change)="OnVisitSelect(formValidation,'condition','uiFormSelect',$event.target,i,j)" 
                                            formControlName="visitId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of visitAllData" [value]="item.id"><b>{{item.name}}</b></option>
                                          </select>
                                          <label for="visitId"><b>Visit</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formValidation,'condition','visitId',i,j)?.value != null">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" 
                                            id="formSelect" 
                                            
                                            (change)="OnFormSelect(formValidation,'condition','uiFormSelect','uiFieldSelect',$event.target,i,j)" 
                                            formControlName="formId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','uiFormSelect',i,j)?.value" [value]="item.formId"><b>{{item.formName}}</b></option>
                                          </select>
                                          <label for="formSelect"><b>Form</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formValidation,'condition','formId',i,j)?.value != null">
                                        <div class="form-floating mb-3">
                                          <select  class="form-select" 
                                          
                                          (change)="onFieldSelected(formValidation,'condition','uiFieldSelect','uiColumnSelect',$event.target,i,j)"
                                          formControlName="fieldId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','uiFieldSelect',i,j)?.value" [value]="item.id"><b>{{item.label}}</b></option>
                                          </select>
                                          <label for="fieldsSelect"><b>Field</b></label>
                                        </div>
                                      </div>
                                      <!-- For Table Component -->
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formValidation,'condition','fieldId',i,j)?.value != undefined && [18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value)">
                                        <div class="form-floating mb-3">
                                          <select
                                            (change)="onTableColumnSelected(formValidation,'condition','uiColumnSelect',$event.target,i,j)"
                                            class="form-select" formControlName="multiRowId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','uiColumnSelect',i,j)?.value" [value]="item.tableColumnIndex">
                                              <b>{{item.colHeading}}</b>
                                            </option>
                                          </select>
                                          <label for="columnSelect"><b>Column Heading</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formValidation,'condition','fieldId',i,j)?.value != undefined && [17].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value)">
                                        <div class="form-floating mb-3">
                                          <select
                                            (change)="onColumnSelected(formValidation,'condition','uiColumnSelect',$event.target,i,j)"
                                            class="form-select" formControlName="multiRowId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','uiColumnSelect',i,j)?.value" [value]="item.id">
                                              <b>{{item.colHeading}}</b>
                                            </option>
                                          </select>
                                          <label for="columnSelect"><b>Column</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && (![17,18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) && 
                                        getSelectedKeyValue(formValidation,'condition','fieldId',i,j)?.value != undefined) || 
                                        ([17,18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) && 
                                        getSelectedKeyValue(formValidation,'condition','multiRowId',i,j)?.value != undefined)">
                                        <div class="col">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="operator" formControlName="operator" (change)="onOperatorChange($event.target,i,j,formValidation)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let operator of getSelectedKeyValue(formValidation,'condition','uiOperatorSelect',i,j)?.value" value="{{operator.name}}"><b>{{operator.name}}</b></option>
                                            </select>
                                            <label for="operator"><b>Operator</b></label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && 
                                      getSelectedKeyValue(formValidation,'condition','operator',i,j)?.value != undefined &&
                                      (
                                        (![17,18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) && ![6, 7, 8].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value)) ||
                                        ([17,18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) && (![6, 7, 8].includes(getSelectedKeyValue(formValidation,'condition','multiRowDataTypeId',i,j)?.value)))
                                      )">
                                        <div class="col">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="isConstant" formControlName="isConstant" (change)="IsConstantSelected(formValidation,'condition',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let constant of constantData" value="{{constant.id}}"><b>{{constant.name}}</b></option>
                                            </select>
                                            <label for="isConstant"><b>Is Constant</b></label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && 
                                      getSelectedKeyValue(formValidation,'condition','isConstant',i,j)?.value == 1 &&
                                      ([1,2,3,4,5].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) ||
                                      ([17,18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) && 
                                      ([1,2,3,4,5].includes(getSelectedKeyValue(formValidation,'condition','multiRowDataTypeId',i,j)?.value))))">
                                        <div class="form-floating mb-3">
                                          <input type="text" class="form-control" placeholder="Enter Value" id="fieldValue" formControlName="fieldValue" max="512" maxlength="512">
                                          <label for="fieldValue"><b>Value</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && 
                                      (getSelectedKeyValue(formValidation,'condition','isConstant',i,j)?.value == 1 &&
                                      ([6, 7, 8].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) ||
                                      (getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value == 17 &&
                                      [6, 7, 8].includes(getSelectedKeyValue(formValidation,'condition','multiRowDataTypeId',i,j)?.value))))
                                      ">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="option" formControlName="jsonFieldValue">
                                            <option [value]="'null'" selected>--Select--</option>
                                            <option *ngFor="let option of getSelectedKeyValue(formValidation,'condition','uiOptionSelect',i,j)?.value" value="{{option.id}}"><b>{{option.name}}</b></option>
                                          </select>
                                          <label for="option"><b>Option</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && 
                                      (getSelectedKeyValue(formValidation,'condition','isConstant',i,j)?.value == 1 && 
                                      (getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value == 18 &&
                                      [6, 7, 8].includes(getSelectedKeyValue(formValidation,'condition','multiRowDataTypeId',i,j)?.value)))
                                      ">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="option" formControlName="fieldValue">
                                            <option [value]="'null'" selected>--Select--</option>
                                            <option *ngFor="let option of getSelectedKeyValue(formValidation,'condition','uiOptionSelect',i,j)?.value" value="{{option.name}}"><b>{{option.name}}</b></option>
                                          </select>
                                          <label for="option"><b>Option</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && 
                                      getSelectedKeyValue(formValidation,'condition','isConstant',i,j)?.value == 1 &&
                                      (getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value == 12 || 
                                      ([17,18].includes(getSelectedKeyValue(formValidation,'condition','dataTypeId',i,j)?.value) && 
                                      getSelectedKeyValue(formValidation,'condition','multiRowDataTypeId',i,j)?.value == 12))">
                                        <div class="form-floating mb-3">
                                          <div class="input-group">
                                            <input class="form-control" placeholder="Select Date" formControlName="jsonFieldValue" 
                                            ngbDatepicker #dynamicDate="ngbDatepicker" [readOnly]="true" [dayTemplate]="customDay" (click)="dynamicDate.toggle()">
                                            <div class="input-group-append">
                                              <button class="btn btn-outline-secondary" (click)="dynamicDate.toggle()" type="button">
                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                              </button>
                                            </div>
                                          </div>
                                          <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                                            let-disabled="disabled" let-focused="focused">
                                            <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                                              [class.text-muted]="date.month !== currentMonth">
                                              {{ date.day }}
                                            </span>
                                          </ng-template>
                                        </div>
                                      </div>
                                      <div class="row" formGroupName="dest" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value != undefined">
                                        <div class="col-1"></div>
                                        <div class="col">
                                          <input type="hidden" name="id" formControlName="id">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="validationFormType" formControlName="validationFormType" (change)="onDestValidationFormTypeChange($event.target,formValidation,'condition','dest',i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of validationFormTypeData" [value]="item.id"><b>{{item.name}}</b></option>
                                            </select>
                                            <label for="validationFormType"><b>Form-Type</b></label>
                                          </div>
                                        </div>
                                        <!-- Destination Validation Form Type is Normal Form  -->
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 0">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="visitId" formControlName="visitId" (change)="onDestVisitSelect(formValidation,'condition','dest',$event.target,i,j)">
                                              <option [ngValue]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestVisitSelect" [value]="item.id"><b>{{item.name}}</b></option>
                                            </select>
                                            <label for="visitId"><b>Visit</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.visitId != null">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="formId" formControlName="formId" (change)="onDestFormSelect(formValidation,'condition','dest',$event.target,i,j)">
                                              <option [ngValue]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestFormSelect" [value]="item.formId"><b>{{item.formName}}</b></option>
                                            </select>
                                            <label for="formId"><b>Form</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.formId != null">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="fieldId" formControlName="fieldId" (change)="onDestFieldSelect(formValidation,'condition','dest',$event.target,i,j)">
                                              <option [ngValue]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestFieldSelect" [value]="item.id"><b>{{item.label}}</b></option>
                                            </select>
                                            <label for="fieldId"><b>Field</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.dataTypeId == 17">
                                          <div class="form-floating mb-3">
                                            <select
                                              (change)="onDestColumnSelect(formValidation,'condition','dest',$event.target,i,j)"
                                              class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.id">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column</b></label>
                                          </div>
                                        </div>
                                        <!-- For Table Component -->
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.dataTypeId == 18">
                                          <div class="form-floating mb-3">
                                            <select
                                              (change)="onDestColumnSelect(formValidation,'condition','dest',$event.target,i,j)"
                                              class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.tableColumnIndex">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column Heading</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.fieldId != null">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="loperator" formControlName="loperator" (change)="addNewCondition(formValidation,i,j)">
                                              <option [value]="'null'" selected>--Select--</option>
                                              <option *ngFor="let vlo of validationLogicalOprator" [value]="vlo.name"><b>{{vlo.name}}</b></option>
                                            </select>
                                            <label for="lOPerator"><b>and/or</b></label>
                                          </div>
                                        </div>
                                        <!-- Destination Validation Form Type is Log Form  -->
                                        <input type="hidden" formControlName="visitId" [value]="defaultUuid" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 1">
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.visitId != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.visitId == defaultUuid">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="formId" formControlName="formId" (change)="onDestFormSelect(formValidation,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestFormSelect" [value]="item.id"><b>{{item.name}}</b></option>
                                            </select>
                                            <label for="formId"><b>Form</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.formId != null">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="fieldId" formControlName="fieldId" (change)="onDestFieldSelect(formValidation,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestFieldSelect" [value]="item.id"><b>{{item.label}}</b></option>
                                            </select>
                                            <label for="fieldId"><b>Field</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.dataTypeId == 17">
                                          <div class="form-floating mb-3">
                                            <select
                                              (change)="onDestColumnSelect(formValidation,'condition','dest',$event.target,i,j)"
                                              class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.id">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column</b></label>
                                          </div>
                                        </div>
                                        <!-- For Table Component -->
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.dataTypeId == 18">
                                          <div class="form-floating mb-3">
                                            <select
                                              (change)="onDestColumnSelect(formValidation,'condition','dest',$event.target,i,j)"
                                              class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.tableColumnIndex">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column Heading</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formValidation,'condition','dest',i,j)?.value.fieldId != null">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="loperator" formControlName="loperator" (change)="addNewCondition(formValidation,i,j)">
                                              <option [value]="'null'" selected>--Select--</option>
                                              <option *ngFor="let vlo of validationLogicalOprator" [value]="vlo.name"><b>{{vlo.name}}</b></option>
                                            </select>
                                            <label for="lOPerator"><b>and/or</b></label>
                                          </div>
                                        </div>
                                        <div class="col mt-3" *ngIf="j != 0">
                                          <button class="btn-close" title="Remove Condition" (click)="removeCondition(formValidation,i,j)"></button>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null && 
                                      getSelectedKeyValue(formValidation,'condition','isConstant',i,j)?.value == 1 &&
                                      (getSelectedKeyValue(formValidation,'condition','fieldValue',i,j)?.value != null ||
                                      getSelectedKeyValue(formValidation,'condition','jsonFieldValue',i,j)?.value != emptyJson)">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="loperator" formControlName="loperator" (change)="addNewCondition(formValidation,i,j)">
                                            <option [value]="'null'" selected>--Select--</option>
                                            <option *ngFor="let vlo of validationLogicalOprator" [value]="vlo.name"><b>{{vlo.name}}</b></option>
                                          </select>
                                          <label for="lOPerator"><b>and/or</b></label>
                                        </div>
                                      </div>
                                      <div class="col mt-3" *ngIf="j != 0 && getSelectedKeyValue(formValidation,'condition','validationFormType',i,j)?.value != null">
                                        <button class="btn-close" title="Remove Condition" (click)="removeCondition(formValidation,i,j)"></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr *ngIf="formValidation.get('formType')?.value != 0">
                              <!-- Impact Design -->
                              <div class="row" *ngIf="formValidation.get('formType')?.value != 0">
                                <div class="col-1 mt-3">
                                  <h5>Then</h5>
                                </div>
                                <div class="col">
                                  <div class="form-floating mb-3">
                                    <select class="form-select" id="action" (change)="OnActionSelect($event.target,i)" formControlName="action">
                                      <option [value]="null" selected>--Select--</option>
                                      <option *ngFor="let ac of action" [value]="ac.id"><b>{{ac.name}}</b></option>
                                    </select>
                                    <label for="action"><b>Select Action</b></label>
                                  </div>
                                </div>
                                <div class="col" *ngIf="getSelectedRuleKeyValue(formValidation,'action',i) != undefined">
                                  <div class="form-floating mb-3">
                                    <input type="text" class="form-control" formControlName="message"
                                    id="message" name="message" placeholder="Enter Message">
                                    <label for="message">Message</label>
                                  </div>
                                </div>
                                <div class="row" *ngIf="getSelectedRuleKeyValue(formValidation,'action',i) != undefined">
                                  <div formArrayName="impact">
                                    <div *ngFor="let impact of getArrayRule(formValidation,'impact',i).controls;let k=index">
                                      <div [formGroupName]="k">
                                        <div class="row">
                                          <input type="hidden" name="id" formControlName="id">
                                          <input type="hidden" name="visit" formControlName="visit" [value]="defaultUuid" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value == 1">
                                          <div class="col">
                                            <div class="form-floating mb-3">
                                              <select class="form-select"
                                                id="validationFormType"
                                                (change)="onImpactValidationFormTypeChange(formValidation,'impact','uiImpactFormSelect',$event.target,i,k)"
                                                
                                                formControlName="validationFormType">
                                                <option [value]="null" selected>--Select--</option>
                                                <option *ngFor="let item of validationFormTypeData" [value]="item.id"><b>{{item.name}}</b></option>
                                              </select>
                                              <label for="validationFormType"><b>Form-Type</b></label>
                                            </div>
                                          </div>
                                          <div class="col" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value == 0">
                                            <div class="form-floating mb-3">
                                              <select class="form-select"
                                                id="visitId"
                                                (change)="OnVisitSelect(formValidation,'impact','uiImpactFormSelect',$event.target,i,k)"
                                                
                                                formControlName="visit">
                                                <option [value]="null" selected>--Select--</option>
                                                <option *ngFor="let item of visitAllData" [value]="item.id"><b>{{item.name}}</b></option>
                                              </select>
                                              <label for="visitId"><b>Visit</b></label>
                                            </div>
                                          </div>
                                          <div class="col" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedKeyValue(formValidation,'impact','visit',i,k)?.value != undefined">
                                            <div class="form-floating mb-3">
                                              <select class="form-select"
                                                id="formSelect"
                                                (change)="OnFormSelect(formValidation,'impact','uiImpactFormSelect','uiImpactFieldSelect',$event.target,i,k)"
                                                
                                                formControlName="form">
                                                <option [value]="null" selected>--Select--</option>
                                                <option *ngFor="let item of getSelectedKeyValue(formValidation,'impact','uiImpactFormSelect',i,k)?.value" [value]="item.formId"><b>{{item.formName}}</b></option>
                                              </select>
                                              <label for="formSelect"><b>Form</b></label>
                                            </div>
                                          </div>
                                          <div class="col mt-2" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedKeyValue(formValidation,'impact','form',i,k)?.value != undefined">
                                            <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Fields"
                                              [disabled]="globalFieldDataTypeId == 17"
                                              formControlName="fields" (onSelect)="onImpactFieldSelect(formValidation,'impact','uiImpactFieldSelect','uiImpactColumnSelect',$event,i,k)" [settings]="fieldsDropDownSettings"
                                              [data]="getSelectedKeyValue(formValidation,'impact','uiImpactFieldSelect',i,k)?.value">
                                            </ng-multiselect-dropdown>
                                          </div>
                                          <div class="col mt-2" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedKeyValue(formValidation,'impact','fields',i,k)?.value != undefined && (globalFieldDataTypeId == 17 || dataTypeId == 17)">
                                            <!-- (onSelect)="onImpactColumnSelected(formValidation,'impact','uiImpactColumnSelect',$event,i,k)" -->
                                            <div class="form-floating mb-3">
                                              <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Columns"
                                                formControlName="multiRowIds" 
                                                [settings]="columnsDropDownSettings"
                                                [data]="getSelectedKeyValue(formValidation,'impact','uiImpactColumnSelect',i,k)?.value">
                                              </ng-multiselect-dropdown>
                                            </div>
                                          </div>
                                          <div class="col mt-2" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedKeyValue(formValidation,'impact','fields',i,k)?.value != undefined && dataTypeId == 18">
                                            <div class="form-floating mb-3">
                                              <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Columns Headings"
                                                formControlName="multiRowIds"
                                                [settings]="columnsDropDownSettings"
                                                [data]="getSelectedKeyValue(formValidation,'impact','uiImpactColumnSelect',i,k)?.value">
                                              </ng-multiselect-dropdown>
                                            </div>
                                          </div>
                                          <div class="col-1 mt-3" *ngIf="getSelectedKeyValue(formValidation,'impact','validationFormType',i,k)?.value != null && getSelectedRuleKeyValue(formValidation,'action',i) != 3">
                                            <button *ngIf="getSelectedKeyValue(formValidation,'impact','fields',i,k)?.value != {}" class="transparent-button" title="Add Impact" (click)="addNewImpact(formValidation,i,k+1)">
                                              <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button> &nbsp;
                                            <button class="transparent-button" title="Remove Impact" (click)="removeImpact(formValidation,i,k)">
                                              <i class="fa fa-times" aria-hidden="true"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- Individual Save & Update -->
                              <div class="row" [hidden]="isLocked == 1 || roles != 1">
                                <div class="col text-end">
                                  <button class="btn btn-outline-success" title="Save" (click)="addSingleValidation(item,formValidation.get('formType')?.value,i)" *ngIf="getSelectedRuleKeyValue(formValidation,'id',i) == null">
                                    <b>
                                      Save <span class="spinner-border spinner-border-sm" *ngIf="saveValidationLoader" role="status" aria-hidden="true"></span>
                                    </b>
                                  </button>
                                  <button class="btn btn-outline-success" title="Save" (click)="addSingleValidation(item,formValidation.get('formType')?.value,i)" *ngIf="getSelectedRuleKeyValue(formValidation,'id',i) != null">
                                    <b>
                                      Save <span class="spinner-border spinner-border-sm" *ngIf="updateValidationLoader" role="status" aria-hidden="true"></span>
                                    </b>
                                  </button>
                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </div>                  
                      </div>
                    </div>
                    <br>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </li>
      <li ngbNavItem domId="logMapping" *ngIf="dataTypeId != 18">
        <a ngbNavLink>Log Mapping</a>
        <ng-template ngbNavContent>
          <br>
          <form *ngIf="formLogMapping" [formGroup]="formLogMapping">
            <div class="row">
              <div class="col-10">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" formControlName="name" autocomplete="off"
                  id="name" name="name" placeholder="Enter Log Mapping Name" max="500" maxlength="500">
                  <label for="name">Log Mapping Name</label>
                  <i><b>Note:</b> Maximum character allowed 500</i>
                </div>
              </div>
              <div class="col-2 text-left" *ngIf="formLogMapping.get('name')?.value != undefined">
                <button title="Add Log-Mapping" class="btn btn-outline-primary mt-2" title="Add New Log-Mapping" (click)="addNewLogMappingValidation()">
                  Add Log Mapping
                </button>
              </div>
            </div>
            <div formArrayName="rule">
              <div *ngFor="let item of getLogMappingRule.controls;let i=index">
                <div class="row" [formGroupName]="i">
                  <div class="col-12">
                    <div ngbAccordion>
                      <div ngbAccordionItem>
                        <div ngbAccordionHeader>
                          <button class="accordion-button control-buttons" ngbAccordionToggle>
                            <div class="col-10 wordWrap">
                              <b *ngIf="getSelectedRuleKeyValue(formLogMapping,'isNameEditable',i) == false">
                                {{getSelectedRuleKeyValue(formLogMapping,'name',i)}}
                              </b>
                              
                              <input type="text" name="validationName"
                              id="validationName" formControlName="editedName" max="500" maxlength="500"
                              *ngIf="getSelectedRuleKeyValue(formLogMapping,'isNameEditable',i) == true">
    
                              <button class="btn btn-outline-secondary input-group-addon"
                                *ngIf="getSelectedRuleKeyValue(formLogMapping,'isNameEditable',i) == true"
                                (click)="editValidationName(formLogMapping,i)">
                                <b>OK</b>
                              </button>
    
                              <button class="control-buttons" (click)="onEditName(formLogMapping,i)" [hidden]="isLocked == 1 || roles != 1">
                                <span class="editIcon"></span>
                              </button>
                            </div>
                            <div class="col mt-2">
                              <!-- <button class="btn btn-sm btn-outline-success" (click)="addSingleValidation(item,null,i,false)" *ngIf="getSelectedRuleKeyValue(formLogMapping,'id',i) == null">
                                <b>
                                  Save <span class="spinner-border spinner-border-sm" *ngIf="getSelectedRuleKeyValue(formLogMapping,'isLoading',i)" role="status" aria-hidden="true"></span>
                                </b>
                              </button>
                              <button class="btn btn-sm btn-outline-warning" (click)="addSingleValidation(item,null,i,false)" *ngIf="getSelectedRuleKeyValue(formLogMapping,'id',i) != null">
                                <b>
                                  Update <span class="spinner-border spinner-border-sm" *ngIf="getSelectedRuleKeyValue(formLogMapping,'isLoading',i)" role="status" aria-hidden="true"></span>
                                </b>
                              </button> &nbsp; -->
                              <span class="slideToggle mt-1" [hidden]="roles != 1">
                                <mat-slide-toggle (change)="enableDisableValidation($event,getSelectedRuleKeyValue(formLogMapping,'status',i),getSelectedRuleKeyValue(formLogMapping,'id',i),formLogMapping,i)"
                                  [checked]="getSelectedRuleKeyValue(formLogMapping,'status',i) == 1 ? true : false"
                                  [title]="getSelectedRuleKeyValue(formLogMapping,'status',i) == 1 ? 'Enable':'Disable'" color="primary">
                                </mat-slide-toggle>
                              </span>&nbsp;
                              <button class="control-buttons" title="Save" (click)="addSingleValidation(item,null,i,false)" *ngIf="getSelectedRuleKeyValue(formLogMapping,'id',i) == null" [hidden]="isLocked == 1 || roles != 1">
                                <span class="saveIcon"></span>
                              </button>
                              <!-- For Update -->
                              <button class="control-buttons" title="Save" (click)="addSingleValidation(item,null,i,false)" *ngIf="getSelectedRuleKeyValue(formLogMapping,'id',i) != null" [hidden]="isLocked == 1 || roles != 1">
                                <span class="saveIcon"></span>
                              </button>&nbsp;
                              <button class="control-buttons" title="Remove Rule" (click)="removeRule(formLogMapping,i)" [hidden]="isLocked == 1 || roles != 1">
                                <span class="deleteIcon"></span>
                              </button>
                            </div>

                          </button>
                        </div>
                        <div ngbAccordionCollapse>
                          <div ngbAccordionBody>
                            <ng-template>
                              <!-- <div class="row">
                                <div class="col" *ngFor="let item of logMappingDataType">
                                  <div class="form-check">
                                    <input class="form-check-input" type="radio" name="mappingType"
                                    [value]="item.id" (change)="OnLogMappingSelected(item.id,i)"
                                    formControlName="mappingType">
                                    <label for="mappingType">{{item.name}}</label>
                                  </div>
                                </div>
                              </div> -->
                              <input type="hidden" name="id" formControlName="ruleId">
                              <!-- Condition Desgin when Mapping type is Vist to Log -->
                              <div formArrayName="condition" *ngIf="getSelectedRuleKeyValue(formLogMapping,'mappingType',i) == 1">
                                <div *ngFor="let condition of getArrayRule(formLogMapping,'condition',i).controls;let j=index">
                                  <div [formGroupName]="j">
                                    <div *ngIf="j == 0">
                                      <h5>IF</h5>
                                    </div>
                                    <div class="row">
                                      <input type="hidden" name="id" formControlName="id">
                                      <div class="col">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="validationFormType" formControlName="validationFormType" (change)="onValidationFormTypeChange($event.target,formLogMapping,i,j)">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of validationFormTypeData" [value]="item.id"><b>{{item.name}}</b></option>
                                          </select>
                                          <label for="validationFormType"><b>Form-Type</b></label>
                                        </div>
                                      </div>
                                      <input type="hidden" formControlName="visitId" [value]="defaultUuid" *ngIf="getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value == 1">
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value == 0">
                                        <div class="form-floating mb-3">
                                          <select
                                          
                                           class="form-select" id="visitId" (change)="OnVisitSelect(formLogMapping,'condition','uiFormSelect',$event.target,i,j)" formControlName="visitId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of visitAllData" [value]="item.id"><b>{{item.name}}</b></option>
                                          </select>
                                          <label for="visitId"><b>Visit</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formLogMapping,'condition','visitId',i,j)?.value != null">
                                        <div class="form-floating mb-3">
                                          <select
                                          
                                           class="form-select" id="formSelect" (change)="OnFormSelect(formLogMapping,'condition','uiFormSelect','uiFieldSelect',$event.target,i,j)" formControlName="formId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','uiFormSelect',i,j)?.value" [value]="item.formId"><b>{{item.formName}}</b></option>
                                          </select>
                                          <label for="formSelect"><b>Form</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formLogMapping,'condition','formId',i,j)?.value != null">
                                        <div class="form-floating mb-3">
                                          <select
                                          
                                           (change)="onFieldSelected(formLogMapping,'condition','uiFieldSelect','uiColumnSelect',$event.target,i,j)" class="form-select" formControlName="fieldId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','uiFieldSelect',i,j)?.value" [value]="item.id"><b>{{item.label}}</b></option>
                                          </select>
                                          <label for="fieldsSelect"><b>Field</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formLogMapping,'condition','fieldId',i,j)?.value != undefined && getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value == 17">
                                        <div class="form-floating mb-3">
                                          <select
                                            (change)="onColumnSelected(formLogMapping,'condition','uiColumnSelect',$event.target,i,j)"
                                            class="form-select" formControlName="multiRowId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','uiColumnSelect',i,j)?.value" [value]="item.id">
                                              <b>{{item.colHeading}}</b>
                                            </option>
                                          </select>
                                          <label for="columnSelect"><b>Column</b></label>
                                        </div>
                                      </div>
                                      <!-- For Table Component -->
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formLogMapping,'condition','fieldId',i,j)?.value != undefined && getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value == 18">
                                        <div class="form-floating mb-3">
                                          <select
                                            (change)="onTableColumnSelected(formLogMapping,'condition','uiColumnSelect',$event.target,i,j)"
                                            class="form-select" formControlName="multiRowId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','uiColumnSelect',i,j)?.value" [value]="item.tableColumnIndex">
                                              <b>{{item.colHeading}}</b>
                                            </option>
                                          </select>
                                          <label for="columnSelect"><b>Column Heading</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formLogMapping,'condition','fieldId',i,j)?.value != undefined || 
                                      getSelectedKeyValue(formLogMapping,'condition','multiRowId',i,j)?.value != undefined">
                                        <div class="col">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="operator" formControlName="operator" (change)="onOperatorChange($event.target,i,j,formLogMapping)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let operator of getSelectedKeyValue(formLogMapping,'condition','uiOperatorSelect',i,j)?.value" value="{{operator.name}}"><b>{{operator.name}}</b></option>
                                            </select>
                                            <label for="operator"><b>Operator</b></label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null && getSelectedKeyValue(formLogMapping,'condition','operator',i,j)?.value != undefined &&
                                      (
                                        (getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value != 17 && ![6, 7, 8].includes(getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value)) ||
                                        (getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value == 17 && (![6, 7, 8].includes(getSelectedKeyValue(formLogMapping,'condition','multiRowDataTypeId',i,j)?.value)))
                                      )">
                                        <div class="col">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="isConstant" formControlName="isConstant" (change)="IsConstantSelected(formLogMapping,'condition',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let constant of constantData" value="{{constant.id}}"><b>{{constant.name}}</b></option>
                                            </select>
                                            <label for="isConstant"><b>Is Constant</b></label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null && 
                                      getSelectedKeyValue(formLogMapping,'condition','isConstant',i,j)?.value != undefined &&
                                      getSelectedKeyValue(formLogMapping,'condition','isConstant',i,j)?.value == 1 &&
                                      ([1,2,3,4,5].includes(getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value) ||
                                      ([17,18].includes(getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value) && 
                                      [1,2,3,4,5].includes(getSelectedKeyValue(formLogMapping,'condition','multiRowDataTypeId',i,j)?.value)))">
                                        <div class="form-floating mb-3">
                                          <input type="text" class="form-control" placeholder="Enter Value" id="fieldValue" formControlName="fieldValue" max="512" maxlength="512">
                                          <label for="fieldValue"><b>Value</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null && 
                                      getSelectedKeyValue(formLogMapping,'condition','isConstant',i,j)?.value == 1 &&
                                      ([6, 7, 8].includes(getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value) ||
                                      (getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value == 17 &&
                                      [6, 7, 8].includes(getSelectedKeyValue(formLogMapping,'condition','multiRowDataTypeId',i,j)?.value)))">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="option" formControlName="jsonFieldValue">
                                            <option [value]="'null'" selected>--Select--</option>
                                            <option *ngFor="let option of getSelectedKeyValue(formLogMapping,'condition','uiOptionSelect',i,j)?.value" value="{{option.id}}"><b>{{option.name}}</b></option>
                                          </select>
                                          <label for="option"><b>Option</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null && 
                                      (getSelectedKeyValue(formLogMapping,'condition','isConstant',i,j)?.value == 1 && 
                                      (getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value == 18 &&
                                      [6, 7, 8].includes(getSelectedKeyValue(formLogMapping,'condition','multiRowDataTypeId',i,j)?.value)))
                                      ">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="option" formControlName="fieldValue">
                                            <option [value]="'null'" selected>--Select--</option>
                                            <option *ngFor="let option of getSelectedKeyValue(formLogMapping,'condition','uiOptionSelect',i,j)?.value" value="{{option.name}}"><b>{{option.name}}</b></option>
                                          </select>
                                          <label for="option"><b>Option</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null && 
                                      getSelectedKeyValue(formLogMapping,'condition','isConstant',i,j)?.value != undefined && 
                                      getSelectedKeyValue(formLogMapping,'condition','isConstant',i,j)?.value == 1 &&
                                      (getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value == 12 ||
                                      ([17,18].includes(getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value) && 
                                      getSelectedKeyValue(formLogMapping,'condition','multiRowDataTypeId',i,j)?.value == 12))">
                                        <div class="form-floating mb-3">
                                          <div class="input-group">
                                            <input class="form-control" placeholder="Select Date" formControlName="jsonFieldValue" 
                                            ngbDatepicker #dynamicLogMappingDate="ngbDatepicker" [readOnly]="true" [dayTemplate]="customDay" (click)="dynamicLogMappingDate.toggle()">
                                            <div class="input-group-append">
                                              <button class="btn btn-outline-secondary" (click)="dynamicLogMappingDate.toggle()" type="button">
                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                              </button>
                                            </div>
                                          </div>
                                          <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                                            let-disabled="disabled" let-focused="focused">
                                            <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                                              [class.text-muted]="date.month !== currentMonth">
                                              {{ date.day }}
                                            </span>
                                          </ng-template>
                                        </div>
                                      </div>
                                      <div class="row" formGroupName="dest" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value != undefined">
                                        <div class="col-1"></div>
                                        <input type="hidden" name="id" formControlName="visitId" [value]="defaultUuid" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType == 1">
                                        <div class="col">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="validationFormType" formControlName="validationFormType" (change)="onDestValidationFormTypeChange($event.target,formLogMapping,'condition','dest',i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of validationFormTypeData" [value]="item.id"><b>{{item.name}}</b></option>
                                            </select>
                                            <label for="validationFormType"><b>Form-Type</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.visitId != defaultUuid">
                                          <input type="hidden" name="id" formControlName="id">
                                          <div class="form-floating mb-3">
                                            <select
                                            
                                             class="form-select" id="visitId" formControlName="visitId" (change)="onDestVisitSelect(formLogMapping,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.uiDestVisitSelect" [value]="item.id"><b>{{item.name}}</b></option>
                                            </select>
                                            <label for="visitId"><b>Visit</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.visitId != null">
                                          <div class="form-floating mb-3">
                                            <select 
                                            
                                            class="form-select" id="formId" formControlName="formId" (change)="onDestFormSelect(formLogMapping,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.uiDestFormSelect" [value]="item.formId"><b>{{item.formName}}</b></option>
                                            </select>
                                            <label for="formId"><b>Form</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.formId != null">
                                          <div class="form-floating mb-3">
                                            <select
                                            
                                             class="form-select" id="fieldId" formControlName="fieldId" (change)="onDestFieldSelect(formLogMapping,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.uiDestFieldSelect" [value]="item.id"><b>{{item.label}}</b></option>
                                            </select>
                                            <label for="fieldId"><b>Field</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.dataTypeId == 17">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.id">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column</b></label>
                                          </div>
                                        </div>
                                        <!-- For Table Component -->
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType == 0 && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.dataTypeId == 18">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.tableColumnIndex">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column Heading</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.visitId != null">
                                          <div class="form-floating mb-3">
                                            <select 
                                            
                                            class="form-select" id="formId" formControlName="formId" (change)="onDestFormSelect(formLogMapping,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.uiDestFormSelect" [value]="item.formId"><b>{{item.formName}}</b></option>
                                            </select>
                                            <label for="formId"><b>Form</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.formId != null">
                                          <div class="form-floating mb-3">
                                            <select
                                            
                                             class="form-select" id="fieldId" formControlName="fieldId" (change)="onDestFieldSelect(formLogMapping,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.uiDestFieldSelect" [value]="item.id"><b>{{item.label}}</b></option>
                                            </select>
                                            <label for="fieldId"><b>Field</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.dataTypeId == 17">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.id">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column</b></label>
                                          </div>
                                        </div>
                                        <!-- For Table Component -->
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.validationFormType == 1 && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.dataTypeId == 18">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.tableColumnIndex">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column Heading</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.fieldId != null">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="loperator" formControlName="loperator" (change)="addNewCondition(formLogMapping,i,j)">
                                              <option [value]="'null'" selected>--Select--</option>
                                              <option *ngFor="let vlo of validationLogicalOprator" [value]="vlo.name"><b>{{vlo.name}}</b></option>
                                            </select>
                                            <label for="lOPerator"><b>and/or</b></label>
                                          </div>
                                        </div>
                                        <div class="col mt-3" *ngIf="j != 0">
                                          <button class="btn-close" title="Remove Condition" (click)="removeCondition(formLogMapping,i,j)"></button>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','isConstant',i,j)?.value != undefined &&
                                      getSelectedKeyValue(formLogMapping,'condition','isConstant',i,j)?.value == 1 &&
                                      (getSelectedKeyValue(formLogMapping,'condition','fieldValue',i,j)?.value != null ||
                                      getSelectedKeyValue(formLogMapping,'condition','jsonFieldValue',i,j)?.value != emptyJson)">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="loperator" formControlName="loperator" (change)="addNewCondition(formLogMapping,i,j)">
                                            <option [value]="'null'" selected>--Select--</option>
                                            <option *ngFor="let vlo of validationLogicalOprator" [value]="vlo.name"><b>{{vlo.name}}</b></option>
                                          </select>
                                          <label for="lOPerator"><b>and/or</b></label>
                                        </div>
                                      </div>
                                      <div class="col mt-3" *ngIf="j != 0 && getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null">
                                        <button class="btn-close" title="Remove Condition" (click)="removeCondition(formLogMapping,i,j)"></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
    
                              <!-- Condition Desgin when Mapping type is Log to Log -->
                              <div formArrayName="condition" *ngIf="getSelectedRuleKeyValue(formLogMapping,'mappingType',i) == 2">
                                <div *ngFor="let condition of getArrayRule(formLogMapping,'condition',i).controls;let j=index">
                                  <div [formGroupName]="j">
                                    <div *ngIf="j == 0">
                                      <h5>IF</h5>
                                    </div>
                                    <div class="row">
                                      <input type="hidden" name="id" formControlName="id">
                                      <!-- <input type="hidden" name="id" formControlName="visitId" [value]="defaultUuid"> -->
                                      <div class="col">
                                        <div class="form-floating mb-3">
                                          <select
                                          
                                           class="form-select" id="formSelect" (change)="OnFormSelect(formLogMapping,'condition','uiFormSelect','uiFieldSelect',$event.target,i,j)" formControlName="formId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of logFormFieldSelect" [value]="item.id"><b>{{item.name}}</b></option>
                                          </select>
                                          <label for="formSelect"><b>Form</b></label>
                                        </div>
                                      </div>
                                      <div class="col">
                                        <div class="form-floating mb-3">
                                          <select
                                          
                                           (change)="onFieldSelected(formLogMapping,'condition','uiFieldSelect','',$event.target,i,j)" class="form-select" formControlName="fieldId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','uiFieldSelect',i,j)?.value" [value]="item.id"><b>{{item.label}}</b></option>
                                          </select>
                                          <label for="fieldsSelect"><b>Field</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','fieldId',i,j)?.value != undefined && getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value == 17">
                                        <div class="form-floating mb-3">
                                          <select
                                            (change)="onColumnSelected(formLogMapping,'condition','uiColumnSelect',$event.target,i,j)"
                                            class="form-select" formControlName="multiRowId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','uiColumnSelect',i,j)?.value" [value]="item.id">
                                              <b>{{item.colHeading}}</b>
                                            </option>
                                          </select>
                                          <label for="columnSelect"><b>Column</b></label>
                                        </div>
                                      </div>
                                      <!-- For Table Component -->
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','fieldId',i,j)?.value != undefined && getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value == 18">
                                        <div class="form-floating mb-3">
                                          <select
                                            (change)="onColumnSelected(formLogMapping,'condition','uiColumnSelect',$event.target,i,j)"
                                            class="form-select" formControlName="multiRowId">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','uiColumnSelect',i,j)?.value" [value]="item.tableColumnIndex">
                                              <b>{{item.colHeading}}</b>
                                            </option>
                                          </select>
                                          <label for="columnSelect"><b>Column Heading</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','fieldId',i,j)?.value != undefined || 
                                      getSelectedKeyValue(formLogMapping,'condition','multiRowId',i,j)?.value != undefined">
                                        <div class="col">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="operator" formControlName="operator" (change)="onOperatorChange($event.target,i,j,formLogMapping)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let operator of getSelectedKeyValue(formLogMapping,'condition','uiOperatorSelect',i,j)?.value" value="{{operator.name}}"><b>{{operator.name}}</b></option>
                                            </select>
                                            <label for="operator"><b>Operator</b></label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','operator',i,j)?.value != undefined &&
                                      (
                                        (getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value != 17 && ![6, 7, 8].includes(getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value)) ||
                                        (getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value == 17 && (![6, 7, 8].includes(getSelectedKeyValue(formLogMapping,'condition','multiRowDataTypeId',i,j)?.value)))
                                      )">
                                        <div class="col">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="isConstant" formControlName="isConstant" (change)="IsConstantSelected(formLogMapping,'condition',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let constant of constantData" value="{{constant.id}}"><b>{{constant.name}}</b></option>
                                            </select>
                                            <label for="isConstant"><b>Is Constant</b></label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','isConstant',i,j)?.value == 1 &&
                                      ([1,2,3,4,5].includes(getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value) ||
                                      ([17,18].includes(getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value) && 
                                      [1,2,3,4,5].includes(getSelectedKeyValue(formLogMapping,'condition','multiRowDataTypeId',i,j)?.value)))">
                                        <div class="form-floating mb-3">
                                          <input type="text" class="form-control" placeholder="Enter Value" id="fieldValue" formControlName="fieldValue">
                                          <label for="fieldValue"><b>Value</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','isConstant',i,j)?.value == 1 &&
                                      ([6, 7, 8].includes(getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value) ||
                                      (getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value == 17 &&
                                      [6, 7, 8].includes(getSelectedKeyValue(formLogMapping,'condition','multiRowDataTypeId',i,j)?.value)))">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="option" formControlName="jsonFieldValue">
                                            <option [value]="'null'" selected>--Select--</option>
                                            <option *ngFor="let option of getSelectedKeyValue(formLogMapping,'condition','uiOptionSelect',i,j)?.value" value="{{option.id}}"><b>{{option.name}}</b></option>
                                          </select>
                                          <label for="option"><b>Option</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="(getSelectedKeyValue(formLogMapping,'condition','isConstant',i,j)?.value == 1 && 
                                      (getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value == 18 &&
                                      [6, 7, 8].includes(getSelectedKeyValue(formLogMapping,'condition','multiRowDataTypeId',i,j)?.value)))
                                      ">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="option" formControlName="fieldValue">
                                            <option [value]="'null'" selected>--Select--</option>
                                            <option *ngFor="let option of getSelectedKeyValue(formLogMapping,'condition','uiOptionSelect',i,j)?.value" value="{{option.name}}"><b>{{option.name}}</b></option>
                                          </select>
                                          <label for="option"><b>Option</b></label>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','isConstant',i,j)?.value == 1 &&
                                      (getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value == 12 ||
                                      ([17,18].includes(getSelectedKeyValue(formLogMapping,'condition','dataTypeId',i,j)?.value) && 
                                      getSelectedKeyValue(formLogMapping,'condition','multiRowDataTypeId',i,j)?.value == 12))">
                                        <div class="form-floating mb-3">
                                          <div class="input-group">
                                            <input class="form-control" placeholder="Select Date" formControlName="jsonFieldValue" ngbDatepicker #dynamicLogMappingDate="ngbDatepicker">
                                            <div class="input-group-append">
                                              <button class="btn btn-outline-secondary" (click)="dynamicLogMappingDate.toggle()" type="button">
                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row" formGroupName="dest" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value != undefined">
                                        <div class="col-1"></div>
                                        <input type="hidden" name="id" formControlName="id">
                                        <input type="hidden" name="id" formControlName="visitId" [value]="defaultUuid">
                                        <div class="col">
                                          <div class="form-floating mb-3">
                                            <select class="form-select"
                                            
                                             id="formId" formControlName="formId" (change)="onDestFormSelect(formLogMapping,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.uiDestFormSelect" [value]="item.formId"><b>{{item.formName}}</b></option>
                                            </select>
                                            <label for="formId"><b>Form</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.formId != null">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="fieldId" formControlName="fieldId" (change)="onDestFieldSelect(formLogMapping,'condition','dest',$event.target,i,j)">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.uiDestFieldSelect" [value]="item.id"><b>{{item.label}}</b></option>
                                            </select>
                                            <label for="fieldId"><b>Field</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.dataTypeId == 17">
                                          <div class="form-floating mb-3">
                                            <select
                                              (change)="onDestColumnSelect(formLogMapping,'condition','dest',$event.target,i,j)"
                                              class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.id">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column</b></label>
                                          </div>
                                        </div>
                                        <!-- For Table Component -->
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.fieldId != null && getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.dataTypeId == 18">
                                          <div class="form-floating mb-3">
                                            <select
                                              (change)="onDestColumnSelect(formLogMapping,'condition','dest',$event.target,i,j)"
                                              class="form-select" formControlName="multiRowId">
                                              <option [value]="null" selected>--Select--</option>
                                              <option *ngFor="let item of getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.uiDestColumnSelect" [value]="item.tableColumnIndex">
                                                <b>{{item.colHeading}}</b>
                                              </option>
                                            </select>
                                            <label for="columnSelect"><b>Column Heading</b></label>
                                          </div>
                                        </div>
                                        <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','dest',i,j)?.value.fieldId != null">
                                          <div class="form-floating mb-3">
                                            <select class="form-select" id="loperator" formControlName="loperator" (change)="addNewCondition(formLogMapping,i,j)">
                                              <option [value]="'null'" selected>--Select--</option>
                                              <option *ngFor="let vlo of validationLogicalOprator" [value]="vlo.name"><b>{{vlo.name}}</b></option>
                                            </select>
                                            <label for="lOPerator"><b>and/or</b></label>
                                          </div>
                                        </div>
                                        <div class="col mt-3" *ngIf="j != 0 && getSelectedKeyValue(formLogMapping,'condition','validationFormType',i,j)?.value != null">
                                          <button class="btn-close" title="Remove Condition" (click)="removeCondition(formLogMapping,i,j)"></button>
                                        </div>
                                      </div>
                                      <div class="col" *ngIf="getSelectedKeyValue(formLogMapping,'condition','isConstant',i,j)?.value == 1 &&
                                      (getSelectedKeyValue(formLogMapping,'condition','fieldValue',i,j)?.value != undefined ||
                                      getSelectedKeyValue(formLogMapping,'condition','jsonFieldValue',i,j)?.value != emptyJson)">
                                        <div class="form-floating mb-3">
                                          <select class="form-select" id="loperator" formControlName="loperator" (change)="addNewCondition(formLogMapping,i,j)">
                                            <option [value]="'null'" selected>--Select--</option>
                                            <option *ngFor="let vlo of validationLogicalOprator" [value]="vlo.name"><b>{{vlo.name}}</b></option>
                                          </select>
                                          <label for="lOPerator"><b>and/or</b></label>
                                        </div>
                                      </div>
                                      <div class="col mt-3" *ngIf="j != 0">
                                        <button class="btn-close" title="Remove Condition" (click)="removeCondition(formLogMapping,i,j)"></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr>
    
                              <!-- Impact Design -->
                              <div class="row" *ngIf="getSelectedRuleKeyValue(formLogMapping,'mappingType',i)">
                                <div class="col mt-3">
                                  <h5>Then Goto </h5>
                                </div>
                                <div class="row">
                                  <div formArrayName="impact">
                                    <div *ngFor="let condition of getArrayRule(formLogMapping,'impact',i).controls;let k=index">
                                      <div [formGroupName]="k">
                                        <div class="row">
                                          <div class="col">
                                            <div class="form-floating mb-3">
                                              <select class="form-select" id="formSelect" formControlName="form">
                                                <option [value]="null" selected>--Select--</option>
                                                <option *ngFor="let item of logFormFieldSelect" [value]="item.id"><b>{{item.name}}</b></option>
                                              </select>
                                              <label for="formSelect"><b>Log Form</b></label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
    
                              <!-- Individual Save & Update -->
                              <div class="row" [hidden]="isLocked == 1 || roles != 1">
                                <div class="col text-end">
                                  <button class="btn btn-outline-success" title="Save" (click)="addSingleValidation(item,null,i,false)" *ngIf="getSelectedRuleKeyValue(formLogMapping,'id',i) == null">
                                    <b>
                                      Save <span class="spinner-border spinner-border-sm" *ngIf="saveValidationLoader" role="status" aria-hidden="true"></span>
                                    </b>
                                  </button>
                                  <button class="btn btn-outline-success" title="Save" (click)="addSingleValidation(item,null,i,false)" *ngIf="getSelectedRuleKeyValue(formLogMapping,'id',i) != null">
                                    <b>
                                      Save <span class="spinner-border spinner-border-sm" *ngIf="updateValidationLoader" role="status" aria-hidden="true"></span>
                                    </b>
                                  </button>
                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
  <div class="modal-footer">
    <!-- <button class="btn btn-outline-success" (click)="addValidation()" *ngIf="!saveUpdateFlag">
      <b>
        Save <span class="spinner-border spinner-border-sm" *ngIf="saveValidationLoader" role="status" aria-hidden="true"></span>
      </b>
    </button>
    <button class="btn btn-outline-warning" (click)="addValidation()" *ngIf="saveUpdateFlag">
      <b>
        Update <span class="spinner-border spinner-border-sm" *ngIf="updateValidationLoader" role="status" aria-hidden="true"></span>
      </b>
    </button> -->
    <button class="btn btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closePopup()">
      <b>Close</b>
    </button>
  </div>
</div>
