import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseURL, VERSION } from '../constant/constant';
import { getTimeZoneHeader } from '../constant/globalFunction';

@Injectable({
  providedIn: 'root'
})
export class ValidationRuleService {

  constructor(private http: HttpClient) { }

  
  addValidation(data: any): Observable<any> {
    return this.http.post(`${baseURL}validation/rule/${VERSION}/save`, data);
  }
  
  updateValidation(data: any): Observable<any> {
    return this.http.put(`${baseURL}validation/rule/${VERSION}/update`, data);
  }
  
  deleteValidation(ruleId: string): Observable<any> {
    return this.http.delete(`${baseURL}validation/rule/${VERSION}/delete/${ruleId}`);
  }
  
  getValidation(visitId:any,formId:any,fieldId:any): Observable<any> {
    return this.http.get(`${baseURL}validation/rule/${VERSION}/visit/${visitId}/form/${formId}/field/${fieldId}`);
  }

  getValidationBySubject(subjectIds:any[],visitIds:any[],formIds:any[]): Observable<any> {
    return this.http.get(`${baseURL}validation/rule/${VERSION}/form/dependents?subjectIds=${subjectIds}&visitIds=${visitIds}&formIds=${formIds}`);
  }

  getValidationLogFormBySubject(subjectIds:any[],visitIds:any[],formIds:any[]): Observable<any> {
    return this.http.get(`${baseURL}validation/rule/${VERSION}/logform/dependents?subjectIds=${subjectIds}&visitIds=${visitIds}&formIds=${formIds}`);
  }

  updateValidationStatus(data:any): Observable<any> {
    return this.http.put(`${baseURL}validation/rule/${VERSION}/status`,data);
  }

  getValidationStatus(): Observable<any> {
    return this.http.get(`${baseURL}validation/rule/${VERSION}/status`);
  }

  /*getValidationByDestination(destVisitId:any,destFormId:any,destFieldId:any): Observable<any> {
    return this.http.get(`${baseURL}validations/${VERSION}/destfield/list?destVisitId=${destVisitId}&destFormId=${destFormId}&destFieldId=${destFieldId}`);
  }*/

  getValidationById(ruleId:any): Observable<any> {
    return this.http.get(`${baseURL}validation/rule/${VERSION}/${ruleId}`);
  }

  getValidationListData(pageNo:any,pageSize:any,name?:any,action?:any,status?:any): Observable<any> {
    let url:string = `${baseURL}validation/rule/${VERSION}/list?pageNo=${pageNo}&pageSize=${pageSize}`;
    if(name && name.length > 0){
      url = url.concat(`&name=${name}`)
    }
    if(action && action.length > 0){
      url = url.concat(`&action=${action}`)
    }
    if(status && status.length > 0){
      url = url.concat(`&status=${status}`)
    }
    return this.http.get(url);
  }

  getValidationExport(action:number[],status:number[]): Observable<any> {
    let url:string = `${baseURL}validation/rule/${VERSION}/list/export`;
    if(action && action.length > 0){
      url = url.concat(`?action=${action}`)
    }
    if(status && status.length > 0){
      url = url.concat(`&status=${status}`)
    }
    return this.http.get(url,{headers:getTimeZoneHeader()});
  }

  getValidationCount(formId:any): Observable<any> {
    return this.http.get(`${baseURL}validation/rule/${VERSION}/count?formId=${formId}`);
  }

  validationExport(data:any):Observable<any>{
    return this.http.post(`${baseURL}export/validation`,data)
  }

  getValidationNameList(): Observable<any> {
    return this.http.get(`${baseURL}validation/rule/${VERSION}/list/name`);
  }
}
