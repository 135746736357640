import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, Observable, of } from 'rxjs';
import { baseURL, VERSION } from '../constant/constant';
import { getTimeZoneHeader, getTimeZoneOffset } from '../constant/globalFunction';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  getSiteSummaryReport(pageNo: any, pageSize: any): Observable<any> {
    let url: string = `${baseURL}site/${VERSION}/summary/report?pageNo=${pageNo}&pageSize=${pageSize}`
    // if(siteIds && siteIds.length > 0){
    //   url = url.concat(`&siteIds=${siteIds}`)
    // }
    return this.http.get(url);
  }

  getSiteStatusSummaryReport(pageNo: any, pageSize: any, siteIds?: any[], subjectIds?: any[], visitIds?: any[], formIds?: any[]): Observable<any> {
    let url: string = `${baseURL}site/${VERSION}/form/status/summary/report?pageNo=${pageNo}&pageSize=${pageSize}`
    if (siteIds && siteIds.length > 0) {
      url = url.concat(`&siteIds=${siteIds}`)
    }
    if (subjectIds && subjectIds.length > 0) {
      url = url.concat(`&subjectIds=${subjectIds}`)
    }
    if (visitIds && visitIds.length > 0) {
      url = url.concat(`&visitIds=${visitIds}`)
    }
    if (formIds && formIds.length > 0) {
      url = url.concat(`&formIds=${formIds}`)
    }
    return this.http.get(url, { headers: getTimeZoneHeader() });
  }

  getQueryStatusBySubjectReport(pageNo: any, pageSize: any, siteIds?: any[], subjectIds?: any[], visitIds?: any[], formIds?: any[]): Observable<any> {
    let url: string = `${baseURL}site/${VERSION}/query/status/report?pageNo=${pageNo}&pageSize=${pageSize}`
    if (siteIds && siteIds.length > 0) {
      url = url.concat(`&siteIds=${siteIds}`)
    }
    if (subjectIds && subjectIds.length > 0) {
      url = url.concat(`&subjectIds=${subjectIds}`)
    }
    if (visitIds && visitIds.length > 0) {
      url = url.concat(`&visitIds=${visitIds}`)
    }
    if (formIds && formIds.length > 0) {
      url = url.concat(`&formIds=${formIds}`)
    }
    return this.http.get(url);
  }

  getSubjectFormQueryReport(pageNo: any, pageSize: any, siteIds?: any[], subjectIds?: any[]): Observable<any> {
    let url: string = `${baseURL}subjects/${VERSION}/form/query/report/data?pageNo=${pageNo}&pageSize=${pageSize}`
    if (siteIds && siteIds.length > 0) {
      url = url.concat(`&siteIds=${siteIds}`)
    }
    if (subjectIds && subjectIds.length > 0) {
      url = url.concat(`&subjectIds=${subjectIds}`)
    }
    return this.http.get(url, { headers: getTimeZoneHeader() });
  }

  getQrsExtractionReport(pageNo: any, pageSize: any, siteIds?: any[], subjectIds?: any[], visitIds?: any[], formIds?: any[], queryAge?: number): Observable<any> {
    let url: string = `${baseURL}report/${VERSION}/qrs?pageNo=${pageNo}&pageSize=${pageSize}`
    if (siteIds && siteIds.length > 0) {
      url = url.concat(`&siteIds=${siteIds}`)
    }
    if (subjectIds && subjectIds.length > 0) {
      url = url.concat(`&subjectIds=${subjectIds}`)
    }
    if (visitIds && visitIds.length > 0) {
      url = url.concat(`&visitIds=${visitIds}`)
    }
    if (formIds && formIds.length > 0) {
      url = url.concat(`&formIds=${formIds}`)
    }
    if (queryAge && queryAge > 0) {
      url = url.concat(`&queryAge=${queryAge}`)
    }
    return this.http.get(url, { headers: getTimeZoneHeader() });
  }

  getQueryAgeingReport(pageNo: any, pageSize: any, siteIds?: any[], subjectIds?: any[], visitIds?: any[], formIds?: any[], queryAge?: number): Observable<any> {
    let url: string = `${baseURL}report/${VERSION}/query/ageing?pageNo=${pageNo}&pageSize=${pageSize}`
    if (siteIds && siteIds.length > 0) {
      url = url.concat(`&siteIds=${siteIds}`)
    }
    if (subjectIds && subjectIds.length > 0) {
      url = url.concat(`&subjectIds=${subjectIds}`)
    }
    if (visitIds && visitIds.length > 0) {
      url = url.concat(`&visitIds=${visitIds}`)
    }
    if (formIds && formIds.length > 0) {
      url = url.concat(`&formIds=${formIds}`)
    }
    if (queryAge && queryAge > 0) {
      url = url.concat(`&queryAge=${queryAge}`)
    }
    return this.http.get(url, { headers: getTimeZoneHeader() });
  }

  getQueryReportHistoryData = (pageNo:any,pageSize:any,siteIds?:any[],subjectIds?:any[],visitIds?:any[],formIds?:any[], selectedStatus?: any, selectedTypes?: any, selectedQueryIds?: any): Observable<any> => {
    let url = `${baseURL}report/${VERSION}/query/history?pageNo=${pageNo}&pageSize=${pageSize}`;
    if(siteIds && siteIds.length > 0){
      url = url.concat(`&siteIds=${siteIds}`)
    }
    if(subjectIds && subjectIds.length > 0){
      url = url.concat(`&subjectIds=${subjectIds}`)
    }
    if(visitIds && visitIds.length > 0){
      url = url.concat(`&visitIds=${visitIds}`)
    }
    if(formIds && formIds.length > 0){
      url = url.concat(`&formIds=${formIds}`)
    }
    if(selectedStatus && selectedStatus.length > 0){
      url = url.concat(`&status=${selectedStatus}`)
    }    
    if(selectedTypes && selectedTypes.length > 0){
      url = url.concat(`&types=${selectedTypes}`)
    }
    if(selectedQueryIds && selectedQueryIds.length > 0){
      url = url.concat(`&queryIds=${selectedQueryIds}`)
    }
    return this.http.get(url, {headers:getTimeZoneHeader()});
  }

  getQueryHistoryReportExport = (siteIds?:any[],subjectIds?:any[],visitIds?:any[],formIds?:any[], selectedStatus?: any, selectedTypes?: any, selectedQueryIds?: any): Observable<any> => {
    let url = `${baseURL}report/${VERSION}/query/history/export?`;
    if(siteIds && siteIds.length > 0){
      url = url.concat(`&siteIds=${siteIds}`)
    }
    if(subjectIds && subjectIds.length > 0){
      url = url.concat(`&subjectIds=${subjectIds}`)
    }
    if(visitIds && visitIds.length > 0){
      url = url.concat(`&visitIds=${visitIds}`)
    }
    if(formIds && formIds.length > 0){
      url = url.concat(`&formIds=${formIds}`)
    }
    if(selectedStatus && selectedStatus.length > 0){
      url = url.concat(`&status=${selectedStatus}`)
    }
    if(selectedTypes && selectedTypes.length > 0){
      url = url.concat(`&types=${selectedTypes}`)
    }
    if(selectedQueryIds && selectedQueryIds.length > 0){
      url = url.concat(`&queryIds=${selectedQueryIds}`)
    }
    return this.http.get(url, {headers:getTimeZoneHeader()});
  }

  //Export API's
  getSiteSummaryReportExport(): Observable<any> {
    return this.http.get(`${baseURL}site/${VERSION}/summary/report/export`, { headers: getTimeZoneHeader() });
  }

  getQueryStatusBySubjectReportExport(siteIds?: any[], subjectIds?: any[], visitIds?: any[], formIds?: any[]): Observable<any> {
    return this.http.get(`${baseURL}site/${VERSION}/query/status/report/export?siteIds=${siteIds}&subjectIds=${subjectIds}&visitIds=${visitIds}&formIds=${formIds}`, { headers: getTimeZoneHeader() });
  }

  getSiteStatusSummaryReportExport(siteIds?: any[], subjectIds?: any[], visitIds?: any[], formIds?: any[]): Observable<any> {
    return this.http.get(`${baseURL}site/${VERSION}/form/status/summary/report/export?siteIds=${siteIds}&subjectIds=${subjectIds}&visitIds=${visitIds}&formIds=${formIds}`, { headers: getTimeZoneHeader() });
  }

  getSubjectFormQueryReportExport(siteIds?: any[], subjectIds?: any[]): Observable<any> {
    return this.http.get(`${baseURL}subjects/${VERSION}/form/query/report/export?siteIds=${siteIds}&subjectIds=${subjectIds}`, { headers: getTimeZoneHeader() });
  }

  getQrsExtractionReportExport(siteIds?: any[], subjectIds?: any[], visitIds?: any[], formIds?: any[]): Observable<any> {
    return this.http.get(`${baseURL}report/${VERSION}/qrs/export?siteIds=${siteIds}&subjectIds=${subjectIds}&visitIds=${visitIds}&formIds=${formIds}`, { headers: getTimeZoneHeader() });
  }

  getQueryAgeingReportExport(siteIds?: any[], subjectIds?: any[], visitIds?: any[], formIds?: any[], queryAge?: any): Observable<any> {
    return this.http.get(`${baseURL}report/${VERSION}/query/ageing/export?siteIds=${siteIds}&subjectIds=${subjectIds}&visitIds=${visitIds}&formIds=${formIds}&queryAge=${queryAge}`, { headers: getTimeZoneHeader() });
  }

  getRawDataExtractReportExport(fileName: any, siteIds?: any[], subjectIds?: any[], visitIds?: any[], formIds?: any[]): Observable<any> {
    return this.http.get(`${baseURL}report/${VERSION}/rawData2?filename=${fileName}&siteIds=${siteIds}&subjectIds=${subjectIds}&visitIds=${visitIds}&formIds=${formIds}`, { headers: getTimeZoneHeader() });
  }

  getDataDictionaryExport(): Observable<any> {
    return this.http.get(`${baseURL}export/${VERSION}/dictionary`, { headers: getTimeZoneHeader() });
  }

  //PDF Export API's
  getBlankCrfReport(fileName: any): Observable<any> {
    let headers = new HttpHeaders()
      //.set('Accept','application/pdf')
      .set('Timezone', getTimeZoneOffset())
    //headers.set('Content-Type','application/pdf')
    return this.http.get(`${baseURL}download/v1/pdf?filename=${fileName}`, { headers: headers });
  }

  getAnnotatedCrfReport(fileName: any): Observable<any> {
    let headers = new HttpHeaders()
      //.set('Accept','application/pdf')
      .set('Timezone', getTimeZoneOffset())
    //headers.set('Content-Type','application/pdf')
    return this.http.get(`${baseURL}download/v1/annotated/pdf?filename=${fileName}`, { headers: headers });
  }

  getDataEntryCrfReport(fileName: any, subjectId: any): Observable<any> {
    let headers = new HttpHeaders()
      //.set('Accept','application/pdf')
      .set('Timezone', getTimeZoneOffset())
    //headers.set('Content-Type','application/pdf')
    return this.http.get(`${baseURL}download/v1/subject/${subjectId}/pdf?filename=${fileName}`, { headers: headers });
  }

  //Get Report History Data
  getReportHistory(reportName: any, subjectId?: any): Observable<any> {
    let url: string = `${baseURL}report/${VERSION}/reporthistory`
    if (reportName && reportName.length > 0) {
      url = url.concat(`?reportName=${reportName}`)
    }
    if (subjectId) {
      url = url.concat(`&subjectId=${subjectId}`)
    }
    return this.http.get<reportHistory>(url);
  }

  getReportHistoryById(reportId: any): Observable<any> {
    let url: string = `${baseURL}report/${VERSION}/id`
    if (reportId) {
      url = url.concat(`?reportId=${reportId}`);
    }
    return this.http.get(url);
  }

  getReportHistoryByIdAndSubject(reportId: any, subjectName: any, subjectId: any): Observable<any> {
    let url: string = `${baseURL}report/${VERSION}/id`
    if (reportId) {
      url = url.concat(`?reportId=${reportId}`);
      if (subjectName) {
        url = url.concat(`&subjectName=${subjectName}&subjectId=${subjectId}`);
      }
    }
    return this.http.get(url);
  }

  getUserManual() {
    let url: string = `${baseURL}report/${VERSION}/userManual`;
    return this.http.get(url);
  }
}

type reportHistory = {
  status: number,
  responseObject: {
    inProgress: boolean,
    reportId: number,
    lastImported: string,
  }
}