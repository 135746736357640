<div class="container-fluid">
    <div class="jumbotron jumbotron-fluid">
      <div class="row">
        <div class="col-6">
          <p class="heading">{{title}}</p>
        </div>
        <div class="col-6 text-end mt-3">
          <button (click)="setStatus()" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#addTherapeuticDetailsModal">
            <b>Add Therapeutic</b>
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="row" [formGroup]="filterData">
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Therapeutic Name" formControlName="ids"
          [settings]="TherapeuticNamesDropDownSettings" [data]="TherapeuticNamesDropDownList" (onDeSelect)="deSelectTherapeuticNames()"
          (onDeSelectAll)="deSelectTherapeuticNames()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Therapeutic Code" formControlName="code"
          [settings]="codesDropDownSettings" [data]="codesDropDownList" (onDeSelect)="deSelectCodes()"
          (onDeSelectAll)="deSelectCodes()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Protocol Number" formControlName="protocolNumber"
          [settings]="protocolNumberDropDownSettings" [data]="protocolNumberDropDownList"
          (onDeSelect)="deSelectProtocolNumber()" (onDeSelectAll)="deSelectProtocolNumber()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Protocol Title" formControlName="protocolTitle"
          [settings]="protocolTitleDropDownSettings" [data]="protocolTitleDropDownList"
          (onDeSelect)="deSelectProtocolTitle()" (onDeSelectAll)="deSelectProtocolTitle()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Is Locked" formControlName="status"
          [settings]="statusDropDownSettings" [data]="statusDropDownList"
          (onDeSelect)="deSelectStatus()" (onDeSelectAll)="deSelectStatus()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col-1">
        <button class="btn btn-outline-warning" (click)="getSearchData()"><b>Apply</b></button>
      </div>
      <div class="col-2">
        <button class="btn btn-outline-danger" (click)="clearFilters()"><b>Clear Filters</b></button>
      </div>
    </div> -->
    <hr>
    <div class="col" *ngIf="therapeuticData.length; else noData">
      <table class="table table-light table-hover mt-3">
        <thead class="thead-light">
          <tr>
            <th>S. No.</th>
            <th>Areas</th>
            <th>Indications</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of therapeuticData | keyvalue; let i = index;">
            <td>{{i+1}}</td>
            <td *ngIf="row.value">
                {{row.value.areas}}
            </td>
            <td>
                <div class="indication-data" *ngFor="let indication of row.value.indications" >
                    <span class="d-flex">{{indication.name}}</span>
                </div>
            </td>
            <td colspan="2">
              <button class="control-buttons" (click)="editTherapeutic(row,i);" data-bs-toggle="modal" data-bs-target="#addTherapeuticDetailsModal">
                <span class="updateIcon"></span>
              </button> &nbsp;
              <button class="control-buttons" (click)="deleteTherapeutic(row,i)">
                <span class="deleteIcon"></span>
              </button> &nbsp;
          </td>
          </tr>
        </tbody>
      </table>
      <div class="tableFooterHr"></div>
      <!-- <ngb-pagination [collectionSize]="totalPages" [(page)]="page" [boundaryLinks]="true"
          [rotate]="true" [ellipses]="false" (pageChange)="onChangePagination()">
          <ng-template ngbPaginationPages let-page let-pages="pages">
            <li class="ngb-custom-pages-item" *ngIf="pages.length > 0">
              <div class="mb-3 d-flex flex-nowrap px-2">
                <label id="paginationInputLabel" for="paginationInput" class="col-form-label me-2 ms-1">Page</label>
                <input
                  #i
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  class="form-control custom-pages-input"
                  id="paginationInput"
                  [value]="page"
                  (keyup.enter)="selectPage(i.value)"
                  (blur)="selectPage(i.value)"
                  (input)="formatInput($any($event).target)"
                  aria-labelledby="paginationInputLabel paginationDescription"
                  style="width: 2.5rem"
                />
                <span id="paginationDescription" class="col-form-label text-nowrap px-2"> of {{pages.length}}</span>
              </div>
            </li>
          </ng-template>
        </ngb-pagination> -->
    </div>
    <ng-template #noData>
      <h5>No Data Available! &nbsp;
        <button (click)="addTherapeutic()" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#addTherapeuticDetailsModal">
          <b> {{ isEdit? 'Update Therapeutic':'Add Therapeutic'}}</b>
        </button>
      </h5>
    </ng-template>
  </div>

  
<!-- Modal -->
<div class="modal fade" id="addTherapeuticDetailsModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5  class="modal-title" id="exampleModalLabel" *ngIf="!isEdit">Add Therapeutics Details</h5>
          <h5  class="modal-title" id="exampleModalLabel" *ngIf="isEdit">Update Therapeutic Details</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>
        <form novalidate [formGroup]="therapeuticForm">
          <input type="hidden" class="form-control" name="id" id="id">
          <div class="modal-body">
            <div class="form-floating mb-3">
              <input type="text" formControlName="areas" class="form-control" placeholder="Enter Area">
              <label for="therapeuticId">Enter Area</label>
            </div>
            <div class="form-floating mb-3">
              <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Indications" formControlName="indications"
                [settings]="indicationDropDownSetting" [data]="indicationsData" (onDeSelect)="deSelectIndications()"
                (onDeSelectAll)="deSelectIndications()">
              </ng-multiselect-dropdown>
            </div>
          </div>
        </form>
        <div class="modal-footer">
          <button id="cancel" class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="closeModal()"><b>Cancel</b></button>
          <button (click)="addTherapeutic();" class="btn btn-outline-success" *ngIf="!isEdit" [disabled]="therapeuticForm.invalid || loading">
            <b>
              Save <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
            </b>
          </button>
          <button (click)="updateTherapeutic();" class="btn btn-outline-primary" *ngIf="isEdit" [disabled]="therapeuticForm.invalid || loading">
            <b>
              Update <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
            </b>
          </button>
        </div>
      </div>
    </div>
  </div>
  