import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ENTER_COMMENT_MESSAGE } from 'src/app/constant/responseMessage';
import { SubjectCommentService } from 'src/app/services/subject-comment.service';

@Component({
  selector: 'app-subject-comment-popup',
  templateUrl: './subject-comment-popup.component.html',
  styleUrls: ['./subject-comment-popup.component.css']
})
export class SubjectCommentPopupComponent implements OnInit {
  
  @Input() visitId:any
  @Input() formId:any
  @Input() fieldId:any
  @Input() rowId:any
  @Input() subjectId:any
  @Input() comment:any
  @Input() dataNotAvailable:any

  subjectCommentForm:FormGroup;
  
  isLocked:any
  
  isSaveOrUpdate:boolean = true
  loading:boolean = false
  resetLoading:boolean = false

  @Output() modalCloseData: EventEmitter<any> = new EventEmitter();
  @Output() modalResetData: EventEmitter<any> = new EventEmitter();

  constructor(private modalService:NgbModal,private subjectCommentAPI:SubjectCommentService,
    private toastr:ToastrService){
    this.subjectCommentForm = new FormGroup({
      fieldId:new FormControl(),
      comment: new FormControl('',[Validators.required])
    });
  }
  
  ngOnInit(): void {
    if(this.rowId != null){
      this.subjectCommentForm.addControl("rowId",new FormControl(this.rowId))
    }
    if(this.comment == null){
      this.isSaveOrUpdate = true
    }else{
      this.isSaveOrUpdate = false
      this.subjectCommentForm.get("comment")?.setValue(this.comment)
    }
  }

  closeModal(){
    this.modalService.dismissAll()
  }

  validateCommentData(){
    let commentValue = this.subjectCommentForm.get("comment")?.value
    if(commentValue == null || commentValue == ''){
      this.toastr.warning(ENTER_COMMENT_MESSAGE)
      return false
    }
    return true
  }

  addSubjectComment(){
    this.subjectCommentForm.get("fieldId")?.setValue(this.fieldId)
    if(this.validateCommentData()){
      this.loading = true
      // if((this.comment == null || this.comment == '') && this.dataNotAvailable == undefined){
      //   this.subjectCommentAPI.saveSubjectComment(this.subjectId,this.visitId,this.formId,
      //   this.fieldId,this.subjectCommentForm.value).subscribe((res:any) => {
      //     this.toastr.success(res.message)
      //     this.modalCloseData.emit(res)
      //     this.loading = false
      //     this.closeModal()
      //   })
      // }else{
      // }
      this.subjectCommentAPI.updateSubjectComment(this.subjectId,this.visitId,this.formId,
      this.fieldId,this.subjectCommentForm.value).subscribe((res:any) => {
        console.log("RESPONSE :: ",res);
        this.toastr.success(res.message)
        this.modalCloseData.emit(res)
        this.closeModal()
        this.loading = false
      },err => {
        this.loading = false
      })
    }
  }

  resetSubjectComment(){
    this.subjectCommentForm.get("fieldId")?.setValue(this.fieldId)
    this.resetLoading = true
    this.subjectCommentAPI.resetSubjectComment(this.subjectId,this.visitId,this.formId,
    this.fieldId,this.subjectCommentForm.value).subscribe((res:any) => {
      console.log("RESPONSE :: ",res);
      let response = res
      response['fieldId'] = this.fieldId
      this.toastr.success(res.message)
      this.modalResetData.emit(response)
      this.closeModal()
      this.resetLoading = false
    },err => {
      this.resetLoading = false
    })
  }

}
