<div class="container-fluid">
    <div class="jumbotron jumbotron-fluid">
      <div class="row">
        <div class="col-6">
          <p class="heading">{{title}}</p>
        </div>
        <div class="col-6 text-end mt-3">
          <button (click)="setStatus()" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#addPhaseDetailModal">
            <b>Add Phase</b>
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="row" [formGroup]="filterData">
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Therapeutic Name" formControlName="ids"
          [settings]="TherapeuticNamesDropDownSettings" [data]="TherapeuticNamesDropDownList" (onDeSelect)="deSelectTherapeuticNames()"
          (onDeSelectAll)="deSelectTherapeuticNames()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Therapeutic Code" formControlName="code"
          [settings]="codesDropDownSettings" [data]="codesDropDownList" (onDeSelect)="deSelectCodes()"
          (onDeSelectAll)="deSelectCodes()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Protocol Number" formControlName="protocolNumber"
          [settings]="protocolNumberDropDownSettings" [data]="protocolNumberDropDownList"
          (onDeSelect)="deSelectProtocolNumber()" (onDeSelectAll)="deSelectProtocolNumber()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Select Protocol Title" formControlName="protocolTitle"
          [settings]="protocolTitleDropDownSettings" [data]="protocolTitleDropDownList"
          (onDeSelect)="deSelectProtocolTitle()" (onDeSelectAll)="deSelectProtocolTitle()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col">
        <ng-multiselect-dropdown class="dropdown-list" placeholder="Is Locked" formControlName="status"
          [settings]="statusDropDownSettings" [data]="statusDropDownList"
          (onDeSelect)="deSelectStatus()" (onDeSelectAll)="deSelectStatus()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col-1">
        <button class="btn btn-outline-warning" (click)="getSearchData()"><b>Apply</b></button>
      </div>
      <div class="col-2">
        <button class="btn btn-outline-danger" (click)="clearFilters()"><b>Clear Filters</b></button>
      </div>
    </div> -->
    <hr>
    <div class="col" *ngIf="phaseData.length; else noData">
      <table class="table table-light table-hover mt-3">
        <thead class="thead-light">
          <tr class="d-table-row">
            <th scope="col" class="col-1">S. No.</th>
            <th scope="col">Phases</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let phaseDetail of phaseData; let i = index;">
            <td>{{i+1}}</td>
            <td>{{phaseDetail.types}}</td>
            <td colspan="2">
                <button class="control-buttons" (click)="editPhase(phaseDetail,i);" data-bs-toggle="modal" data-bs-target="#addPhaseDetailModal">
                  <span class="updateIcon"></span>
                </button> &nbsp;
                <button class="control-buttons" (click)="deleteIndication(phaseDetail,i)">
                  <span class="deleteIcon"></span>
                </button> &nbsp;
            </td>
          </tr>
        </tbody>
        <tfoot>
          <!-- <ngb-pagination [collectionSize]="totalPages" [(page)]="page" [boundaryLinks]="true"
              [rotate]="true" [ellipses]="false" (pageChange)="onChangePagination()">
              <ng-template ngbPaginationPages let-page let-pages="pages">
                <li class="ngb-custom-pages-item" *ngIf="pages.length > 0">
                  <div class="mb-3 d-flex flex-nowrap px-2">
                    <label id="paginationInputLabel" for="paginationInput" class="col-form-label me-2 ms-1">Page</label>
                    <input
                      #i
                      type="text"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      class="form-control custom-pages-input"
                      id="paginationInput"
                      [value]="page"
                      (keyup.enter)="selectPage(i.value)"
                      (blur)="selectPage(i.value)"
                      (input)="formatInput($any($event).target)"
                      aria-labelledby="paginationInputLabel paginationDescription"
                      style="width: 2.5rem"
                    />
                    <span id="paginationDescription" class="col-form-label text-nowrap px-2"> of {{pages.length}}</span>
                  </div>
                </li>
              </ng-template>
            </ngb-pagination> -->
        </tfoot>
      </table>
      <div class="tableFooterHr"></div>
    </div>
    <ng-template #noData>
      <h5>No Data Available! &nbsp;
        <button (click)="addPhase()" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#addPhaseDetailModal">
          <b>Add Phase</b>
        </button>
      </h5>
    </ng-template>
  </div>

  
<!-- Modal -->
<div class="modal fade" id="addPhaseDetailModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5  class="modal-title" id="exampleModalLabel" *ngIf="!isEdit">Add Phase Details</h5>
          <h5  class="modal-title" id="exampleModalLabel" *ngIf="isEdit">Update Phase Details</h5>
          <button class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>
        <form novalidate [formGroup]="phaseForm">
          <input type="hidden" class="form-control" name="id" id="id">
          <div class="modal-body">
            <div class="form-floating mb-3">
              <input type="text" formControlName="types" class="form-control">
              <label for="therapeuticId">Enter Phase Type</label>
            </div>
          </div>
        </form>
        <div class="modal-footer">
          <button id="cancel" class="btn btn-outline-danger" data-bs-dismiss="modal" (click)="closeModal()"><b>Cancel</b></button>
          <button (click)="addPhase();" *ngIf="!isEdit" class="btn btn-outline-success" [disabled]="phaseForm.invalid || loading">
            <b>
              Save <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
            </b>
          </button>
          <button *ngIf="isEdit" (click)="updatePhase();" class="btn btn-outline-primary" [disabled]="phaseForm.invalid || loading">
            <b>
              Update <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status" aria-hidden="true"></span>
            </b>
          </button>
        </div>
      </div>
    </div>
  </div>
  