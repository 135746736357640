
export type LogFormRedirect = {
    dataTypeId:string|number,
    fieldId:number,
    fieldLabel:string,
    fieldValue:string,
    formId:number,
    multiRowFieldLabel:string,
    rowId:string,
    subjectId:string,
    visitId:string,
    status:boolean,
}

export class LogFormRedirectDTO implements LogFormRedirect {
    dataTypeId: string|number;
    fieldId: number;
    fieldLabel: string;
    fieldValue: string;
    formId: number;
    multiRowFieldLabel: string;
    rowId: string;
    subjectId: string;
    visitId: string;
    status: boolean;

    constructor(){
        this.dataTypeId = "";
        this.fieldId = -1;
        this.fieldLabel = '';
        this.fieldValue = '';
        this.formId = -1;
        this.multiRowFieldLabel = '';
        this.rowId = '';
        this.subjectId = '';
        this.visitId = '';
        this.status = false;
    }
}
export type TableHeader = {
    formId:string;
    fieldId:string;
    orderId:number;
    label:string;
}

export class TableHeaderDTO implements FilterJson,TableHeader {
    fieldId: string = '';
    formId: string = '';
    orderId: number = -1;
    label: string = '';

    constructor(fieldId:string,orderId:number,label:string){
        this.fieldId = fieldId;
        this.label=label;
        this.orderId=orderId;
    }
}

export type FilterJson = {
    // formId:string;
    fieldId:string;
    orderId:number;
}

export class FilterJsonDTO implements FilterJson {
    // formId: string = '';
    fieldId: string = '';
    orderId: number = -1;

    constructor(fieldId:string,orderId:number){
        // formId:string,
        // this.formId=formId;
        this.fieldId = fieldId;
        this.orderId=orderId;
    }
}
