<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" *ngIf="validationRuleId == undefined || validationRuleId == null">Validation <span>({{fieldName}})</span></h5>
        <h5 class="modal-title" *ngIf="validationRuleId != undefined || validationRuleId != null">Validation</h5>
        <button class="btn-close" data-dismiss="modal" aria-label="Close" (click)="closePopup()"></button>
    </div>
    <div class="modal-body">
        <ul class="nav nav-tabs" (click)="onTabsChange($event)">
            <li class="nav-items" *ngFor="let item of tabsData;let i = index" (click)="setTab(i)">
            <a class="nav-link " [ngClass]="activateTab == i ? 'active' : ''">{{item}}</a>
            </li>
        </ul>
        <app-validation-rule (isMapping)="onOnlylogMapping($event)" [formType]="formType" [tabName]="tabName" [fetchedFieldId]="fieldId" [fetchedDataTypeId]="dataTypeId" [validationRuleId]="validationRuleId" [isTabValidation] = "isTabValidation"></app-validation-rule>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-danger" data-dismiss="modal" aria-label="Close" (click)="closePopup()">
            <b>Close</b>
        </button>
    </div>
</div>