import { jsonData, DATE_OPERATIONS, VALIDATION_ACTION, VALIDATION_OPERATORS, 
  VALIDATION_LOGICAL_OPERATORS, VALIDATION_IMPACT_ACTION, LOG_MAPPING_DATA_TYPES, OBJECT, 
  IS_CONSTANT_SELECTION, DEFAULT_UUID, VALIDATION_OPERATORS_ENUMS, VALIDATION_OPERATORS_DATE_ENUMS, MIN_DATE_CONSTANT, MAX_DATE_CONSTANT, TABLE_VALIDATION_TYPES, componentTypeMap, 
  ARROW} from './../../../constant/constant';
import { Validation } from '../../../models/validation';
import { NgbDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormService } from 'src/app/services/form.service';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ValidationRuleService } from 'src/app/services/validation-rule.service'
import { DEST_LOG_MAPPING_FIELD_REQUIRED_MSG, DEST_LOG_MAPPING_FORM_REQUIRED_MSG, 
  DEST_LOG_MAPPING_VISIT_REQUIRED_MSG, DEST_VALIDATION_COLUMN_REQUIRED_MSG, DEST_VALIDATION_FIELD_REQUIRED_MSG, DEST_VALIDATION_FORM_REQUIRED_MSG, 
  DEST_VALIDATION_VISIT_REQUIRED_MSG, LOGMAPPING_ACTION_REQUIRED_MSG, LOGMAPPING_FIELD_REQUIRED_MSG, 
  LOGMAPPING_FIELD_VALUE_REQUIRED_MSG, LOGMAPPING_FORM_REQUIRED_MSG, LOGMAPPING_JSON_FIELD_VALUE_REQUIRED_MSG, 
  LOGMAPPING_MESSAGE_REQUIRED_MSG, LOGMAPPING_OPERATOR_REQUIRED_MSG, LOGMAPPING_VISIT_REQUIRED_MSG, 
  VALIDATION_ACTION_REQUIRED_MSG, VALIDATION_COLUMN_REQUIRED_MSG, VALIDATION_DELETE_CONFIRMATION, VALIDATION_FIELD_REQUIRED_MSG, 
  VALIDATION_FIELD_VALUE_REQUIRED_MSG, VALIDATION_FORM_REQUIRED_MSG, VALIDATION_JSON_FIELD_VALUE_REQUIRED_MSG, 
  VALIDATION_MESSAGE_REQUIRED_MSG, VALIDATION_OPERATOR_REQUIRED_MSG, VALIDATION_OPTION_REQUIRED_MSG, VALIDATION_STATUS_CONFIRMATION, 
  VALIDATION_VISIT_REQUIRED_MSG } from 'src/app/constant/responseMessage';
import { FieldService } from 'src/app/services/field.service';
import { cloneArray } from 'src/app/constant/globalFunction';
import { firstValueFrom, map } from 'rxjs';
import * as loadashJson from 'lodash';

@Component({
  selector: 'app-add-validaton-popup',
  templateUrl: './add-validaton-popup.component.html',
  styleUrls: ['./add-validaton-popup.component.css']
})
export class AddValidatonPopupComponent implements OnInit {
  
  @Input() fieldId = "";
  @Input() fieldName = "";
  @Input() formType:any;
  @Input() dataTypeId:any;
  @Input() validationRuleId:any;
  
  studyId:any
  formId:any;
  visitId:any;
  
  formValidation: FormGroup;
  formLogMapping:FormGroup

  envData: any = jsonData;

  visitFormMainData:any
  
  visitAllData: any[] = []
  logFormFieldSelect: any [] = []
  //impactVisitSelect:any[] = []
  globalFieldDataTypeId: number = 0;
  //Field Data
  fieldsDropDownSettings: IDropdownSettings = {}
  columnsDropDownSettings: IDropdownSettings = {}
  
  validationAction:any = VALIDATION_ACTION
  validationOperator:any = VALIDATION_OPERATORS
  dateOperators:any = DATE_OPERATIONS
  validationLogicalOprator:any = VALIDATION_LOGICAL_OPERATORS
  action:any = VALIDATION_IMPACT_ACTION
  constantData:any = IS_CONSTANT_SELECTION
  dateTypeList: any[] = ["Before", "Between", "After"];

  saveUpdateFlag:boolean = false

  saveValidationLoader:boolean = false
  updateValidationLoader:boolean = false

  validateDataFlag:boolean = true

  validation:Validation = new Validation()

  logMappingDataType:any = LOG_MAPPING_DATA_TYPES

  emptyJson:any = {}

  defaultUuid:any = DEFAULT_UUID

  //For table Coponent validation
  tableValidationType:any[] = TABLE_VALIDATION_TYPES
  
  validationFormTypeData:any[] = [
    {id:0,name:"Normal Forms"},
    {id:1,name:"Log Forms"}
  ]

  conditionKeys:any[] = ['visitId','formId','fieldId','multiRowId','optionId','operator','isConstant','dataTypeId','multiRowDataTypeId','fieldValue',
  'jsonFieldValue','tableValidationType','loperator','eqOrderId','uiFormSelect','uiFieldSelect','uiColumnSelect',
  'uiOperatorSelect','uiOptionSelect']

  impactKeys:any[] = ['dataTypeId','visit','form','multiRowIds','uiImpactFormSelect','uiImpactFieldSelect','uiImpactColumnSelect']

  @Output() modalValidationClose: EventEmitter<any> = new EventEmitter();

  isLocked:any
  roles:any

  constructor(private formAPI:FormService, private fieldAPI: FieldService,
    private validationAPI:ValidationRuleService,private toastr:ToastrService,
    private formbuilder: FormBuilder,private config:NgbDatepickerConfig,private modalService: NgbModal) {
      
      this.formValidation = new FormGroup({})
      this.formLogMapping = new FormGroup({})

      config.minDate = MIN_DATE_CONSTANT
      config.maxDate = MAX_DATE_CONSTANT
  }

  ngOnInit(): void {
    this.getStorageData()
    if(this.validationRuleId == undefined || this.validationRuleId == null){
      const previewId:any = localStorage.getItem("previewId")
      if(previewId){
        this.formId = previewId
      }else{
        this.formId = localStorage.getItem("formId")
      }
      const visit:any = localStorage.getItem("visitIds")
      
      if(visit != 'null'){
        this.visitId = JSON.parse(visit)
      }
    }

    this.onInit()

    this.getStorageData()
    this.getVisitFormData()
    // this.getAllData()
    
    this.setDropdownSettings()
  }

  onInit(){
    this.formValidation = new FormGroup({
      name:new FormControl(),
      formType:new FormControl(Number(this.formType)),
      rule:new FormArray([])
    })

    this.formLogMapping = new FormGroup({
      name:new FormControl(),
      formType:new FormControl(Number(this.formType)),
      rule:new FormArray([])
    })
  }

  getStorageData(){
    this.studyId = sessionStorage.getItem("studyId")
    this.isLocked = sessionStorage.getItem("isLocked")
    this.roles = sessionStorage.getItem("role")
  }

  setDropdownSettings() {
    
    this.fieldsDropDownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      itemsShowLimit: 2,
      allowSearchFilter: true,
    }

    if(this.dataTypeId == componentTypeMap[17].id){
      this.columnsDropDownSettings = {
        singleSelection: false,
        idField: 'tableColumnIndex',
        textField: 'colHeading',
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect',
        itemsShowLimit: 2,
        allowSearchFilter: true,
      }
    }else{
      this.columnsDropDownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'colHeading',
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect',
        itemsShowLimit: 2,
        allowSearchFilter: true,
      }
    }
    
  }

  addRule():FormGroup{
    return new FormGroup({
      id:new FormControl(),
      ruleId:new FormControl(),
      name:new FormControl(),
      equation:new FormControl(),
      displayEquation:new FormControl(),
      action:new FormControl(),
      message:new FormControl(),
      isNameEditable:new FormControl(false),
      editedName:new FormControl(),
      status:new FormControl(1),
      condition:new FormArray([this.addCondition(0,0)]),
      impact:new FormArray([this.addValidationFormTypeImpact()]),
      isLoading:new FormControl(false)
    })
  }

  addCondition(i:any,j:any):FormGroup{
    return new FormGroup({
      id:new FormControl(),
      ruleId:new FormControl(),
      visitId:new FormControl(),
      formId:new FormControl(),
      fieldId:new FormControl(),
      multiRowId:new FormControl(),
      optionId:new FormControl(),
      operator:new FormControl(),
      isConstant:new FormControl(),
      dataTypeId:new FormControl(),
      multiRowDataTypeId:new FormControl(),
      validationFormType:new FormControl(),
      // tableColumnId:new FormControl(),
      // tableDataTypeId:new FormControl(),
      fieldValue:new FormControl(),
      jsonFieldValue:new FormControl({}),
      tableValidationType:new FormControl(1),
      loperator:new FormControl(""),
      eqOrderId:new FormControl(i),
      uiFormSelect:new FormControl(),
      uiFieldSelect:new FormControl(),
      uiColumnSelect:new FormControl(),
      uiOperatorSelect:new FormControl(),
      uiOptionSelect:new FormControl(),
    })
  }

  addImpact():FormGroup{
    return this.formbuilder.group({
      id:new FormControl(),
      ruleId:new FormControl(),
      visit:new FormControl(),
      form:new FormControl(),
      multiRowIds: new FormControl(),
      // tableColumnIds: new FormControl(),
      fields:[],
      uiImpactFormSelect:new FormControl(),
      uiImpactFieldSelect:new FormControl(),
      uiImpactColumnSelect:new FormControl(),
    })
  }

  addFormTypeRule():FormGroup{
    return new FormGroup({
      id:new FormControl(),
      ruleId:new FormControl(),
      name:new FormControl(),
      equation:new FormControl(),
      displayEquation:new FormControl(),
      action:new FormControl(),
      message:new FormControl(),
      isNameEditable:new FormControl(false),
      editedName:new FormControl(),
      status:new FormControl(1),
      condition:new FormArray([this.addFormTypeCondition(0,0)]),
      impact:new FormArray([this.addValidationFormTypeImpact()]),
    })
  }

  addFormTypeCondition(i:any,j:any):FormGroup{
    
    return new FormGroup({
      id:new FormControl(),
      ruleId:new FormControl(),
      visitId:new FormControl(this.defaultUuid),
      formId:new FormControl(),
      fieldId:new FormControl(),
      multiRowId:new FormControl(),
      optionId:new FormControl(),
      operator:new FormControl(),
      isConstant:new FormControl(),
      dataTypeId:new FormControl(),
      multiRowDataTypeId:new FormControl(),
      validationFormType:new FormControl(),
      fieldValue:new FormControl(),
      jsonFieldValue:new FormControl({}),
      loperator:new FormControl(""),
      eqOrderId:new FormControl(i),
      uiFormSelect:new FormControl(),
      uiFieldSelect:new FormControl(),
      uiColumnSelect:new FormControl(),
      uiOperatorSelect:new FormControl(),
      uiOptionSelect:new FormControl(),
    })
  }

  addFormTypeImpact():FormGroup{
    return this.formbuilder.group({
      id:new FormControl(),
      ruleId:new FormControl(),
      visit:new FormControl(this.defaultUuid),
      form:new FormControl(),
      multiRowIds: new FormControl(),
      fields:[],
      dataTypeId:new FormControl(),
      uiImpactFormSelect:new FormControl(),
      uiImpactFieldSelect:new FormControl(),
      uiImpactColumnSelect:new FormControl(),
    })
  }

  addValidationFormTypeCondition(i:any,j:any):FormGroup{
    return new FormGroup({
      id:new FormControl(),
      ruleId:new FormControl(),
      eqOrderId:new FormControl(i),
      validationFormType:new FormControl()
    })
  }

  addValidationFormTypeImpact():FormGroup{
    return this.formbuilder.group({
      id:new FormControl(),
      ruleId:new FormControl(),
      fields:[],
      dataTypeId:new FormControl(),
      validationFormType:new FormControl()
    })
  }

  getVisitFormData(){
    this.formAPI.getVisitFormData('').subscribe(res => {
      this.visitFormMainData = res.responseObject
      const visits = res.responseObject['visit']
      this.visitAllData = this.extractVisits(visits)
    })
    this.getLogFormFieldData()
  }

  getAllFormData(data:FormGroup,i:any,j:any){
    if(this.visitId && (this.visitId[0] != null && this.visitId.length > 0 && typeof(this.visitId) == 'object' && this.visitId[0] != this.defaultUuid)){
      const forms = this.visitFormMainData['visit'][this.visitId[0].toString()].forms
      this.setSelectedKeyValue(data,"condition","uiFormSelect",i,j,this.extractForms(forms))
      this.setSelectedKeyValue(data,"condition","uiFieldSelect",i,j,this.fieldsApiData)
    }else{
      const forms = this.visitFormMainData['visit'][this.visitId[0].toString()].forms
      this.setSelectedKeyValue(data,"condition","uiFormSelect",i,j,this.extractForms(forms))
    }
  }

  extractVisits(visits: any): any[] {
    this.visitAllData = []
    Object.keys(visits).map((key) => {
      this.visitAllData.push({ id: key, name: visits[key].name })
    })
    return this.visitAllData
  }

  extractForms(visits: any[]) {
    let formsArray:any[] = []
    Object.keys(visits).map((key:any) => {
      formsArray.push({formId: visits[key].formId, formName: visits[key].formName, formType:visits[key].formType})
    })
    return formsArray
  }

  fieldsApiData:any
  getFieldsByForm(formId:any,data:FormGroup,mainKey:any,subKey:any,index:any,subIndex:any,isDest:boolean,columnKey?:any){
    // let data:any = this.fieldAPI.promiseGetFieldsByForm(formId).then((res:any) => {return res});
    this.fieldAPI.getFieldsByForm(formId).subscribe(res => {
      this.fieldsApiData = cloneArray(res.responseObject)
      if(isDest){
        if((this.getSelectedKeyValue(data,mainKey,'dest',index,subIndex) as FormGroup).contains("uiDestFieldSelect")){
          console.log("HAVE");
          
          this.getSelectedKeyValue(data,mainKey,'dest',index,subIndex)?.get("uiDestFieldSelect")?.setValue(cloneArray(res.responseObject))

        }else{
          (this.getSelectedKeyValue(data,mainKey,'dest',index,subIndex) as FormGroup).addControl("uiDestFieldSelect",new FormControl(cloneArray(res.responseObject)));
        }
        if((this.getSelectedKeyValue(data,mainKey,'dest',index,subIndex) as FormGroup).contains("fieldId")){
          this.getSelectedKeyValue(data,mainKey,'dest',index,subIndex)?.get("fieldId")?.setValue(columnKey.fieldId == undefined ? this.fieldId : columnKey.fieldId)
        }else{
          (this.getSelectedKeyValue(data,mainKey,'dest',index,subIndex) as FormGroup).addControl("fieldId",new FormControl(this.fieldId))
        }
        
        console.log("IS DEST DATA :: ",mainKey,data);
      }else{
        if(this.formId == this.getSelectedKeyValue(data,mainKey,"formId",index,subIndex)?.value){
          this.setSelectedKeyValue(data, mainKey, subKey, index, subIndex, res.responseObject)
          this.setSelectedKeyValue(data, mainKey, "fieldId", index, subIndex, this.fieldId)
          this.globalFieldDataTypeId = res.responseObject.find((field:any) => field.id == this.fieldId)?.dataTypeId;
          this.onFieldSelected(data, mainKey, subKey, columnKey, { value: this.fieldId} , index, subIndex);
        }else{
          this.setSelectedKeyValue(data, mainKey, subKey, index, subIndex, res.responseObject)
        }
      }
      // this.globalFieldDataTypeId = this.getSelectedKeyValue(data,mainKey,subKey,index,subIndex)?.value.find((f:any) => f.id == this.fieldId).dataTypeId
      //this.fieldsApiData = cloneArray(res.responseObject)
    })
    
    // this.fieldsApiData = new Observable((sub:any) => {
    //   this.fieldAPI.testGetFieldsByForm(formId).subscribe(res => {
    //     sub.next(cloneArray(res.responseObject))
    //     this.setSelectedKeyValue(this.formValidation, 'condition', 'uiFieldSelect', index, subIndex, res.responseObject)
    //     this.setSelectedKeyValue(this.formValidation, 'condition', 'fieldId', index, subIndex, this.fieldId)
    //     this.onFieldSelected(this.formValidation, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , index, subIndex);
    //     sub.complete()
    //     //this.fieldsApiData = cloneArray(res.responseObject)
    //   })
    // })
    // const value = await firstValueFrom(this.fieldsApiData)
    
    // this.fieldAPI.getFieldsByForm(formId).subscribe(res => {
    //   this.fieldsApiData = cloneArray(res.responseObject)
    //   if(isDest){
    //     // this.setSelectedKeyValue(data,key,subKey,ruleIndex,conditionIndex,cloneArray(res.responseObject))
    //     this.setDestSelectedKeyValue(data,key,dataKey,subKey,ruleIndex,conditionIndex,cloneArray(res.responseObject))
    //   }else{
    //     this.setSelectedKeyValue(data,key,subKey,ruleIndex,conditionIndex,cloneArray(res.responseObject))
    //     this.setSelectedKeyValue(this.formValidation, 'condition', 'fieldId', ruleIndex, conditionIndex, this.fieldId)
    //     this.onFieldSelected(this.formValidation, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , ruleIndex, conditionIndex);
    //   }
    // })
  }

  // getAllData() {
  //   this.fieldAPI.getFormDB().subscribe(res => {
      
  //     this.visitAllData = res
  //     // let testData = new TestData()
  //     // this.visitAllData = testData.visitAllData;

  //     if(this.visitAllData && this.visitAllData.length > 0){
  //       if(this.visitId){
          
  //         if(this.visitId[0] != null && this.visitId.length > 0 && typeof(this.visitId) == 'object' && this.visitId[0] != this.defaultUuid){
  //           let forms = this.visitAllData.find((id:any)=>id.visitId == this.visitId[0]).forms;
    
  //           if(forms.length > 0){
              
  //             let fields = forms.find((form:any)=>form.id == this.formId).fields;
    
  //             if(fields.length > 0){
  //               this.globalFieldDataTypeId = fields.find((field:any) => field.id == this.fieldId)?.dataTypeId;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   })
  //   this.getLogFormFieldData()
  // }
  testLogFormFields:any
  getLogFormFieldData(){
    this.formAPI.getLogFormField().subscribe(res => {
      console.log(res);
      
      let mainData:any = [{
        id:DEFAULT_UUID,
        name:"Default Visit",
        forms:[]
      }]
      if(res.responseObject){
        mainData[0].forms = res.responseObject
        for (let index = 0; index < res.responseObject.length; index++) {
          res.responseObject[index].formId = res.responseObject[index].id
          res.responseObject[index].formName = res.responseObject[index].name
        }
        console.log("MAIN DATA :: ",mainData);
      }
      
      this.logFormFieldSelect = mainData
      this.testLogFormFields = res.responseObject

      //Get Validation API
      // setTimeout(() => {
      // }, 1300);
      if(this.validationRuleId == undefined || this.validationRuleId == null){
        if(this.formType != null && this.formType != 0){
          this.getValidation(DEFAULT_UUID,this.formId,this.fieldId)
        }else{
          this.getValidation(this.visitId[0],this.formId,this.fieldId)
        }
      }else{
        this.getValidationById()
      }
    })
  }

  onEditName(data:FormGroup,i:any){
    let mainData = this.getSelectedRuleKeyValue(data,"name",i)
    this.setSelectedRuleKeyValue(data,"isNameEditable",i,true)
    this.setSelectedRuleKeyValue(data,"editedName",i,mainData)
  }
  
  editValidationName(data:FormGroup,i:any){
    let mainData = this.getSelectedRuleKeyValue(data,"editedName",i)
    this.setSelectedRuleKeyValue(data,"isNameEditable",i,false)
    this.setSelectedRuleKeyValue(data,"name",i,mainData)

    this.setSelectedRuleKeyValue(data,"editedName",i,"")
  }

  addNewValidation(){
    if(this.formValidation.get("formType")?.value == 0){
      (this.formValidation.get("rule") as FormArray).push(this.addRule())
  
      let length = (this.formValidation.get("rule") as FormArray).length-1
      let value = this.formValidation.get("name")?.value;
      
      this.setSelectedRuleKeyValue(this.formValidation,"name",length,value);
      
      this.setSelectedKeyValue(this.formValidation, 'condition', 'visitId', length, 0, this.visitId);
      this.setSelectedKeyValue(this.formValidation, 'condition', 'validationFormType', length, 0, this.formValidation.get("formType")?.value);
      this.onValidationFormTypeChange(this.getSelectedKeyValue(this.formValidation,"condition","validationFormType",length,0)?.value,this.formValidation,length,0,false)
      // this.getAllFormData(this.formValidation,length,0)
      // this.setSelectedKeyValue(this.formValidation, 'impact', 'validationFormType', length, 0, this.formValidation.get("formType")?.value);

      // let visitData = this.visitAllData.find((id:any)=>id.visitId == this.visitId).forms
      // this.setSelectedKeyValue(this.formValidation,'condition','uiFormSelect',length,0,visitData)
      // this.setSelectedKeyValue(this.formValidation, 'condition', 'formId', length, 0, this.formId);
      
      // visitData = this.getSelectedKeyValue(this.formValidation,'condition','uiFormSelect',length,0)?.value.find((form:any)=>form.id == this.formId).fields
      // this.setSelectedKeyValue(this.formValidation,'condition','uiFieldSelect',length,0,visitData)
      // this.setSelectedKeyValue(this.formValidation, 'condition', 'fieldId', length, 0, this.fieldId);
      
      // this.onFieldSelected(this.formValidation, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , length, 0);

    }else{
      (this.formValidation.get("rule") as FormArray).push(this.addFormTypeRule())
  
      let length = (this.formValidation.get("rule") as FormArray).length-1
      let value = this.formValidation.get("name")?.value;
      
      this.setSelectedRuleKeyValue(this.formValidation,"name",length,value);
      this.setSelectedKeyValue(this.formValidation, 'condition', 'validationFormType', length, 0, this.formValidation.get("formType")?.value);
      // this.setSelectedKeyValue(this.formValidation, 'impact', 'validationFormType', length, 0, this.formValidation.get("formType")?.value);
      this.onValidationFormTypeChange(this.getSelectedKeyValue(this.formValidation,"condition","validationFormType",length,0)?.value,this.formValidation,length,0,false)
      if(this.visitId){
        if(this.visitId[0] != null && this.visitId.length > 0 && typeof(this.visitId) == 'object' ){
          this.setSelectedRuleKeyValue(this.formValidation,"mappingType",length.toString(),1);
        }else{
          this.setSelectedRuleKeyValue(this.formValidation,"mappingType",length.toString(),2);
        }
      }else{
        this.setSelectedRuleKeyValue(this.formValidation,"mappingType",length.toString(),2);
      }
      this.setSelectedKeyValue(this.formValidation,'condition','uiFormSelect',length,0,this.logFormFieldSelect[0].forms)
      console.log("TTTTTTTTTTTTTTT :: ",this.logFormFieldSelect[0].forms);
      
      this.setSelectedKeyValue(this.formValidation, 'condition', 'formId', length, 0, this.formId);
      
      let fieldSelect = this.getSelectedKeyValue(this.formValidation,'condition','uiFormSelect',length,0)?.value.find((form:any)=>form.id == this.formId).fields
      this.setSelectedKeyValue(this.formValidation,'condition','uiFieldSelect',length,0,fieldSelect)
      this.setSelectedKeyValue(this.formValidation, 'condition', 'fieldId', length, 0, this.fieldId);
      
      this.onFieldSelected(this.formValidation, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , length, 0);
    }
    if(this.dataTypeId == componentTypeMap[17].id){
      this.addNewControl(this.formValidation,"condition",length,0,"tableValidationType",new FormControl(1))
      this.addNewControl(this.formValidation,"condition",length,0,"multiRowId",new FormControl())
      this.addNewControl(this.formValidation,"condition",length,0,"multiRowDataTypeId",new FormControl())
    }
    this.formValidation.get("name")?.reset()
    
  }

  get getRule(){
    return (this.formValidation.get("rule") as FormArray)
  }

  removeRule(data:FormGroup,i:any){
    let ruleId = ((data.get("rule") as FormArray).get(i.toString()) as FormGroup).get("ruleId")?.value
    let r = confirm(VALIDATION_DELETE_CONFIRMATION)
    if(r==true){
      if(ruleId != null && this.saveUpdateFlag == true){
        this.validationAPI.deleteValidation(ruleId).subscribe(res=>{
          if(res.status == 0){
            this.toastr.success(res.message);
            (data.get("rule") as FormArray).removeAt(i)
            if((data.get("rule") as FormArray).length == 0){
              this.saveUpdateFlag = false
            }else{
              this.saveUpdateFlag = true
            }
          }
        })
      }else{
        (data.get("rule") as FormArray).removeAt(i)
      }
    }
    
  }

  getArrayRule(data:FormGroup,key:any,i:any){
    return (((data.get("rule") as FormArray)
    .get(i.toString()) as FormGroup)
    .get(key) as FormArray)
  }

  removeCondition(data:FormGroup,i:any,j:any){
    if(this.getArrayRule(data,"condition",i).length > 1){

      this.getArrayRule(data,"condition",i).removeAt(j)
      
      this.getSelectedKeyValue(data,"condition","loperator",i,j-1)?.reset();
      
      ((this.getSelectedKeyValue(data,"condition","dest",i,j-1) as FormGroup)
      .get("loperator") as FormControl).reset()
      
    }
  }

  removeImpact(data:FormGroup,i:any,k:any){
    if(this.getArrayRule(data,"impact",i).length > 1){
      this.getArrayRule(data,"impact",i).removeAt(k)
    }
  }

  populateRuleDesign(event:any,i:any){}

  onTabsChange(event:any){
    if(event == "validation"){
      this.validateDataFlag = true
    }else{
      this.validateDataFlag = false
    }
  }

  OnVisitSelect(data:FormGroup,key:any,subKey:any,event:any,ruleIndex:any,conditionIndex:any){
    if(event.value != "null"){
      const forms = this.visitFormMainData['visit'][event.value].forms
      this.setSelectedKeyValue(data,key,subKey,ruleIndex,conditionIndex,this.extractForms(forms))
      // this.getAllFormData(data,ruleIndex,conditionIndex)
      // let visitData = this.visitAllData.find((id:any)=>id.visitId == event.value).forms 
      // this.setSelectedKeyValue(data,key,subKey,ruleIndex,conditionIndex,visitData)
    }
    else{
      this.getSelectedKeyValue(data,key,'visitId',ruleIndex,conditionIndex)?.reset()
    }

    this.getSelectedKeyValue(data,key,'formId',ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,'form',ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,'fieldId',ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,"fields",ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,'dataTypeId',ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,'multiRowDataTypeId',ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,'uiFieldSelect',ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,"operator",ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,"fieldValue",ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,"jsonFieldValue",ruleIndex,conditionIndex)?.setValue({})
    this.getSelectedKeyValue(data,key,"loperator",ruleIndex,conditionIndex)?.reset()
    
  }

  OnFormSelect(data:FormGroup,key:any,dataKey:any,subKey:any,event:any,ruleIndex:any,conditionIndex:any){
    if(event.value != "null"){
      // this.getFieldsByForm(event.value,data,key,dataKey,subKey,ruleIndex,conditionIndex,false);
      if(this.getSelectedRuleKeyValue(data,"mappingType",ruleIndex) != 2){
        
        if(data.get("formType")?.value == 0){
          if(this.getSelectedKeyValue(data,key,"validationFormType",ruleIndex,conditionIndex)?.value == 0){
            // let visitData:any
            // visitData = this.getSelectedKeyValue(data,key,dataKey,ruleIndex,conditionIndex)?.value.find((form:any)=>form.id == event.value).fields
        
            // this.setSelectedKeyValue(data,key,subKey,ruleIndex,conditionIndex,visitData)
            // this.getFieldsByForm(event.value,data,key,subKey,ruleIndex,conditionIndex,false,"uiColumnSelect")
            this.fieldAPI.getFieldsByForm(event.value).subscribe(res => {
              this.setSelectedKeyValue(data,key,"uiFieldSelect",ruleIndex,conditionIndex,cloneArray(res.responseObject))
              this.setSelectedKeyValue(data, key, 'fieldId', ruleIndex, conditionIndex, this.fieldId)
              // this.globalFieldDataTypeId = this.getSelectedKeyValue(data,key,"uiFieldSelect",ruleIndex,conditionIndex)?.value.find((f:any) => f.id == this.fieldId).dataTypeId
              // this.onFieldSelected(data, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , ruleIndex, conditionIndex);
            })
            
          }else{
            let formData:any
            formData = this.testLogFormFields.find((form:any)=>form.id == event.value).fields
            
            this.setSelectedKeyValue(data,key,subKey,ruleIndex,conditionIndex,formData)
            if(key == 'condition'){
              this.setSelectedKeyValue(data,key,'formId',ruleIndex,conditionIndex,event.value)
            }else if(key == 'impact'){
              this.setSelectedKeyValue(data,key,'form',ruleIndex,conditionIndex,event.value)
            }
          }
        }else{
          if(this.getSelectedKeyValue(data,key,"validationFormType",ruleIndex,conditionIndex)?.value == 0){
            // let visitData:any
            // visitData = this.getSelectedKeyValue(data,key,dataKey,ruleIndex,conditionIndex)?.value.find((form:any)=>form.id == event.value).fields
            // this.setSelectedKeyValue(data,key,subKey,ruleIndex,conditionIndex,visitData)
            this.fieldAPI.getFieldsByForm(event.value).subscribe(res => {
              this.setSelectedKeyValue(data,key,"uiFieldSelect",ruleIndex,conditionIndex,cloneArray(res.responseObject))
              this.setSelectedKeyValue(data, key, 'fieldId', ruleIndex, conditionIndex, this.fieldId)
              // this.globalFieldDataTypeId = this.getSelectedKeyValue(data,key,"uiFieldSelect",ruleIndex,conditionIndex)?.value.find((f:any) => f.id == this.fieldId).dataTypeId
            })
          }else{
            let formData:any
            formData = this.testLogFormFields.find((form:any)=>form.id == event.value).fields
            this.setSelectedKeyValue(data,key,subKey,ruleIndex,conditionIndex,formData)
            if(key == 'condition'){
              this.setSelectedKeyValue(data,key,'formId',ruleIndex,conditionIndex,event.value)
            }else if(key == 'impact'){
              this.setSelectedKeyValue(data,key,'form',ruleIndex,conditionIndex,event.value)
            }
          }
        }        
      }else{
        let fieldData = this.testLogFormFields.find((data:any)=>data.id == event.value).fields
        this.setSelectedKeyValue(data,key,subKey,ruleIndex,conditionIndex,fieldData)
      }
    }
    else{
      this.getSelectedKeyValue(data,key,"formId",ruleIndex,conditionIndex)?.reset()
    }
    this.getSelectedKeyValue(data,key,"fieldId",ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,"fields",ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,'dataTypeId',ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,'multiRowDataTypeId',ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,"operator",ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,"fieldValue",ruleIndex,conditionIndex)?.reset()
    this.getSelectedKeyValue(data,key,"jsonFieldValue",ruleIndex,conditionIndex)?.setValue({})
    this.getSelectedKeyValue(data,key,"loperator",ruleIndex,conditionIndex)?.reset()
  }

  onFieldSelected(data:FormGroup,key:any,dataKey:any,subKey:any,event:any,i:any,j:any){
    let eventValue:string = event.value;
    
    this.globalFieldDataTypeId = this.getSelectedKeyValue(data,key,"uiFieldSelect",i,j)?.value.find((f:any) => f.id == this.fieldId) != undefined ? this.getSelectedKeyValue(data,key,"uiFieldSelect",i,j)?.value.find((f:any) => f.id == this.fieldId).dataTypeId : 0
    if(eventValue != "null"){
      
      let fieldData = this.getSelectedKeyValue(data,key,dataKey,i,j)?.value
      
      let dataTypeId = fieldData.find((field:any) => field.id == this.getSelectedKeyValue(data,key,"fieldId",i,j)?.value).dataTypeId
      this.setSelectedKeyValue(data,key,"dataTypeId",i,j,Number(dataTypeId))
      if(dataTypeId == 17){
        
        this.setSelectedKeyValue(data,key,subKey,i,j,fieldData.find((field:any) => field.id == this.getSelectedKeyValue(data,key,"fieldId",i,j)?.value).columnData);

      }else if(dataTypeId == 18){
        fieldData = this.getSelectedKeyValue(data,key,"uiFieldSelect",i,j)?.value.find((f:any) => f.id == this.fieldId)
        let cellInfoData:any[] = []
        if(fieldData.cellInfo){
          
          for (let index = 1; index < fieldData.cellInfo[0].length; index++) {
            fieldData.cellInfo[0][index][0].tableColumnIndex = index
            fieldData.cellInfo[0][index][0].colHeading = fieldData.cellInfo[0][index][0].label
            cellInfoData.push(fieldData.cellInfo[0][index][0])
          }
        }
        this.setSelectedKeyValue(data,key,subKey,i,j,cellInfoData);
      }else if(dataTypeId == 6 || dataTypeId == 7 || dataTypeId == 8){
        this.setSelectedKeyValue(data, key, 'isConstant', i, j, "1");
      }
      
      if(key == "condition"){
        this.onValidationOperator(dataTypeId,i,j,data)
      }  
    }
    else{
      this.getSelectedKeyValue(data,key,"fieldId",i,j)?.reset()
    }

    this.getSelectedKeyValue(data,key,"multiRowId",i,j)?.reset()
    this.getSelectedKeyValue(data,key,"multiRowIds",i,j)?.reset()
    // this.getSelectedKeyValue(data,key,"dataTypeId",i,j)?.reset()
    // this.getSelectedKeyValue(data,key,"multiRowDataTypeId",i,j)?.reset()
    this.getSelectedKeyValue(data,key,"isConstant",i,j)?.reset()
    this.removeNewControl(data,key,i,j,"dest")
    this.getSelectedKeyValue(data,key,"operator",i,j)?.reset()
    this.getSelectedKeyValue(data,key,"fieldValue",i,j)?.reset()
    this.getSelectedKeyValue(data,key,"jsonFieldValue",i,j)?.setValue({})
    this.getSelectedKeyValue(data,key,"loperator",i,j)?.reset()
  }

  onColumnSelected(data:FormGroup,key:any,dataKey:any,event:any,i:any,j:any){

    let eventValue:string = event.value;
    if(eventValue != "null"){
      let fieldData = this.getSelectedKeyValue(data,key,dataKey,i,j)?.value.find((cntry: any) => cntry.id == eventValue);
      let dataTypeId = fieldData.dataTypeId;    
      
      this.setSelectedKeyValue(data,key,"multiRowDataTypeId",i,j,Number(dataTypeId));
  
      if(dataTypeId == 6 || dataTypeId == 7 || dataTypeId == 8){
        this.setSelectedKeyValue(data,key,'uiOptionSelect',i,j,fieldData.options);
      }
      
      if(key == "condition"){
        this.onValidationOperator(dataTypeId,i,j,data)
      }
    }
    else{
      this.getSelectedKeyValue(data,key,"multiRowId",i,j)?.reset()
    }

    this.getSelectedKeyValue(data,key,"isConstant",i,j)?.reset()
    this.removeNewControl(data,key,i,j,"dest")
    this.getSelectedKeyValue(data,key,"operator",i,j)?.reset()
    this.getSelectedKeyValue(data,key,"fieldValue",i,j)?.reset()
    this.getSelectedKeyValue(data,key,"jsonFieldValue",i,j)?.setValue({})
    this.getSelectedKeyValue(data,key,"loperator",i,j)?.reset()
  }

  onTableColumnSelected(data:FormGroup,key:any,dataKey:any,event:any,i:any,j:any){
    let eventValue:string = event.value;
    if(eventValue != "null"){
      let tableFieldData = this.getSelectedKeyValue(data,key,"uiFieldSelect",i,j)?.value.find((cntry: any) => cntry.id == this.getSelectedKeyValue(data,key,"fieldId",i,j)?.value)
      
      let fieldData = this.getSelectedKeyValue(data,key,dataKey,i,j)?.value.find((cntry: any) => cntry.tableColumnIndex == eventValue);
      let dataTypeId:any;
      let tableData:any;
      
      if(tableFieldData.cellInfo){
        for (let i = 1; i < tableFieldData.cellInfo[0].length; i++) {
          if(i == fieldData.tableColumnIndex){
            dataTypeId = tableFieldData.cellInfo[1][i][0].dataTypeId
            tableData = tableFieldData.cellInfo[1][i][0]
          }
        }
      }
      
      this.setSelectedKeyValue(data,key,"multiRowDataTypeId",i,j,Number(dataTypeId));
  
      if(dataTypeId == 6 || dataTypeId == 7 || dataTypeId == 8){
        this.setSelectedKeyValue(data,key,'uiOptionSelect',i,j,tableData.options);
      }
      
      if(key == "condition"){
        this.onValidationOperator(dataTypeId,i,j,data)
      }
    }
    else{
      this.getSelectedKeyValue(data,key,"multiRowDataTypeId",i,j)?.reset()
    }

    this.getSelectedKeyValue(data,key,"isConstant",i,j)?.reset()
    this.removeNewControl(data,key,i,j,"dest")
    this.getSelectedKeyValue(data,key,"operator",i,j)?.reset()
    this.getSelectedKeyValue(data,key,"fieldValue",i,j)?.reset()
    this.getSelectedKeyValue(data,key,"jsonFieldValue",i,j)?.setValue({})
    this.getSelectedKeyValue(data,key,"loperator",i,j)?.reset()
  }

  // onOptionSelected(data:FormGroup,key:any,dataKey:any,event:any,i:any,j:any){

  //   let eventValue:string = event.value;

  //   let fieldData = this.getSelectedKeyValue(data,key,dataKey,i,j)?.value.find((cntry: any) => cntry.id == eventValue);
  //   let dataTypeId = fieldData.dataTypeId
  //   if(dataTypeId == 6 || dataTypeId == 7){
  //     fieldData = fieldData.options
  //   }

  //   this.getSelectedKeyValue(data,key,"isConstant",i,j)?.reset()
  //   this.removeNewControl(data,key,i,j,"dest")
  //   this.getSelectedKeyValue(data,key,"operator",i,j)?.reset()
  //   this.getSelectedKeyValue(data,key,"fieldValue",i,j)?.reset()
  //   this.getSelectedKeyValue(data,key,"jsonFieldValue",i,j)?.setValue({})
  //   this.getSelectedKeyValue(data,key,"loperator",i,j)?.reset()
  // }

  onValidationOperator(dataTypeId:any,i:any,j:any,data:FormGroup){
    let operator:any[] = [] 

    if(dataTypeId == 1 || dataTypeId == 2 || dataTypeId == 4 || dataTypeId == 5){
      operator.push(
        {id:5,name:"="},
        {id:6,name:"!="}
      )
    }else if(dataTypeId == 3){
      operator.push(
        {id:1,name:">"},
        {id:2,name:"<"},
        {id:3,name:">="},
        {id:4,name:"<="},
        {id:5,name:"="},
        {id:6,name:"!="}
      )
    }else if(dataTypeId == 6 || dataTypeId == 7 || dataTypeId == 8){
      operator.push(
        {id:5,name:"="},
        {id:6,name:"!="}
      )
    }else if(dataTypeId == 12){
      operator.push(
        {id:1,name:">"},
        {id:2,name:"<"},
        {id:3,name:">="},
        {id:4,name:"<="},
        {id:5,name:"="},
        {id:6,name:"!="}
      )
    }

    this.setSelectedKeyValue(data,"condition","uiOperatorSelect",i,j,operator)
    operator = []
    
  }

  IsConstantSelected(data:FormGroup,key:any,event:any,i:any,j:any,isUpdate?:any){
    
    let isConstantValue:any
    if(event.value != undefined){
      isConstantValue = event.value
    }else{
      isConstantValue = event
    }
    
    let dataTypeId = this.getSelectedKeyValue(data,key,"dataTypeId",i,j)?.value;
    if(isConstantValue == 1){
      let mainFieldData = dataTypeId != 17 ? this.getSelectedKeyValue(data,"condition","fieldId",i,j)?.value : this.getSelectedKeyValue(data,"condition","multiRowId",i,j)?.value
      
      let mainId = dataTypeId != 17 ? "uiFieldSelect" : "uiColumnSelect";
      let options = this.getSelectedKeyValue(data,key,mainId,i,j)?.value.find((cntry: any) => cntry.id == mainFieldData)?.options;
      if(this.getSelectedKeyValue(data,key,"uiOptionSelect",i,j)?.value == null){
        this.setSelectedKeyValue(data,key,"uiOptionSelect",i,j,options);
      }

      this.getSelectedKeyValue(data,key,"fieldValue",i,j)?.reset()
      this.getSelectedKeyValue(data,key,"jsonFieldValue",i,j)?.setValue({})
      this.getSelectedKeyValue(data,key,"loperator",i,j)?.reset()
      this.removeNewControl(data,key,i,j,"dest")
    
    }else{  
      
      let destJson:any = {}
      destJson['id'] = new FormControl();
      destJson['validationFormType'] = new FormControl(data.get("formType")?.value);
      this.addNewControl(data,key,i,j,"dest",new FormGroup(destJson));
      isUpdate = isUpdate == undefined ? false : true ;
      console.log("IS CONSTANT ELSE PART :: ",isUpdate);
      if(isUpdate == false){
        this.onDestValidationFormTypeChange(data.get("formType")?.value,data,'condition','dest',i,j,isUpdate,destJson)
      }
      // let logMapping = this.getSelectedRuleKeyValue(data,"mappingType",i);
      
      // if(!logMapping){
      //   (this.getSelectedKeyValue(data,key,'dest',i,j) as FormGroup).get("visitId")?.setValue(this.visitId[0]);
      //   this.onDestVisitSelect(data, 'condition', 'dest', { value: this.visitId[0] } , i, j);
        
      //   (this.getSelectedKeyValue(data,key,'dest',i,j) as FormGroup).get("formId")?.setValue(this.formId);
      //   this.onDestFormSelect(data, 'condition', 'dest', { value: this.formId } , i, j);
  
      //   (this.getSelectedKeyValue(data,key,'dest',i,j) as FormGroup).get("fieldId")?.setValue(this.fieldId);
      //   this.onDestFieldSelect(data, 'condition', 'dest', { value: this.fieldId } , i, j);
      // }      
    }
  }

  onJsonFieldValueSelected(event:any,i:any,j:any,data:FormGroup){
    let prepareJson:any = {}
    prepareJson[event.value] = true
    
    this.setSelectedKeyValue(data,"condition","jsonFieldValue",i,j,prepareJson)
  }

  setDropdownForWarnIfSettings() {
    
    this.fieldsDropDownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      allowSearchFilter: true,
    }

    this.columnsDropDownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'colHeading',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect',
      allowSearchFilter: true,
    }
    
  }

  OnActionSelect(event:any,i:any){
    let actionData:any
    if(event.value != undefined){
      actionData = event.value
      // this.getSelectedRule(i).get("message")?.reset()
      this.getSelectedRule(i).get("impact")?.reset()
      let length = (this.getSelectedRule(i).get("impact") as FormArray).length - 1
      this.setSelectedKeyValue(this.formValidation,"impact","validationFormType",i,length,this.formValidation.get("formType")?.value)
      this.onImpactValidationFormTypeChange(this.formValidation,"impact","validationFormType",this.getSelectedKeyValue(this.formValidation,"impact","validationFormType",i,length)?.value,i,length,false,null)
      // if(this.dataTypeId == componentTypeMap[17].id){
      //   let selectedFields = this.getSelectedKeyValue(this.formValidation, "impact", "uiImpactFieldSelect", i, 0)?.value.find((data:any)=> data.id == this.fieldId)
      //   this.setSelectedKeyValue(this.formValidation, "impact", "fields", i, 0, [selectedFields])
      // }
    }else{
      actionData = event
    }
    if(actionData == 3){
      this.setDropdownForWarnIfSettings()
    
    }else{
      this.setDropdownSettings()
    }
    
  }

  onOperatorChange(event:any,i:any,j:any,data:FormGroup){  
    let dataTypeId = ![17,18].includes(this.getSelectedKeyValue(data, 'condition', 'dataTypeId', i, j)?.value) ? this.getSelectedKeyValue(data, 'condition', 'dataTypeId', i, j)?.value : this.getSelectedKeyValue(data, 'condition', 'multiRowDataTypeId', i, j)?.value;
    
    if(dataTypeId == 6 || dataTypeId == 7 || dataTypeId == 8){
      this.setSelectedKeyValue(data, 'condition', 'isConstant', i, j, "1");
      if(this.validationRuleId){
        this.IsConstantSelected(data, 'condition', { value : "1" }, i, j,true);
      }else{
        this.IsConstantSelected(data, 'condition', { value : "1" }, i, j,false);
      }
    }
  }

  onImpactFieldSelect(data:FormGroup,key:any,dataKey:any,subKey:any,event:any,i:any,j:any){
    let eventValue:string = event.id
    if(event.id != null){
      let dataTypeId = this.getSelectedKeyValue(data,key,dataKey,i,j)?.value.find((cntry: any) => cntry.id == eventValue).dataTypeId
      let fieldData:any
      if(dataTypeId == 17){
        fieldData = this.getSelectedKeyValue(data,key,dataKey,i,j)?.value.find((cntry: any) => cntry.id == eventValue).columnData
        this.setSelectedKeyValue(data,key,subKey,i,j,fieldData)
      }else if(dataTypeId == 18){
        fieldData = this.getSelectedKeyValue(data,key,dataKey,i,j)?.value.find((cntry: any) => cntry.id == eventValue)
        let cellInfoData:any[] = []
        if(fieldData.cellInfo){
          for (let index = 1; index < fieldData.cellInfo[0].length; index++) {
            fieldData.cellInfo[0][index][0].tableColumnIndex = index
            fieldData.cellInfo[0][index][0].colHeading = fieldData.cellInfo[0][index][0].label
            cellInfoData.push(fieldData.cellInfo[0][index][0])
          }
        }
        this.setSelectedKeyValue(data,key,subKey,i,j,cellInfoData)
      }
      
    }
    else{
      this.getSelectedKeyValue(data,key,"fields",i,j)?.reset()
    }


    this.getSelectedKeyValue(data,key,"multiRowId",i,j)?.reset()
    // this.getSelectedKeyValue(data,key,"dataTypeId",i,j)?.reset()
    // this.getSelectedKeyValue(data,key,"isConstant",i,j)?.reset()
    // this.removeNewControl(data,key,i,j,"dest")
    // this.getSelectedKeyValue(data,key,"operator",i,j)?.reset()
    // this.getSelectedKeyValue(data,key,"fieldValue",i,j)?.reset()
    // this.getSelectedKeyValue(data,key,"jsonFieldValue",i,j)?.setValue({})
    // this.getSelectedKeyValue(data,key,"loperator",i,j)?.reset()
  }


  // onImpactColumnSelected(data:FormGroup,key:any,dataKey:any,event:any,i:any,j:any){

  //   let eventValue:string = event.value;  
  //   // this.getSelectedKeyValue(data,key,"dataTypeId",i,j)?.reset()
  //   this.getSelectedKeyValue(data,key,"isConstant",i,j)?.reset()
  //   this.removeNewControl(data,key,i,j,"dest")
  //   this.getSelectedKeyValue(data,key,"operator",i,j)?.reset()
  //   this.getSelectedKeyValue(data,key,"fieldValue",i,j)?.reset()
  //   this.getSelectedKeyValue(data,key,"jsonFieldValue",i,j)?.setValue({})
  //   this.getSelectedKeyValue(data,key,"loperator",i,j)?.reset()
  // }
  
  onDestVisitSelect(data:FormGroup,mainKey:any,subKey:any,event:any,i:any,j:any){
    let visitSelectedId:any
    if(event.value != undefined){
      visitSelectedId = event.value
    }else{
      visitSelectedId = event
    }
    const forms = this.visitFormMainData['visit'][visitSelectedId].forms
    let destData = this.getSelectedKeyValue(data,mainKey,subKey,i,j)
    console.log("DEST DATA (From Visit) :: ",destData);
    
    destData?.get("uiDestFormSelect")?.setValue(this.extractForms(forms))
    // this.setSelectedKeyValue(data,mainKey,subKey,i,j,this.extractForms(forms))
    
    // (((((data?.get("rule") as FormArray)
    // .get(i.toString()) as FormGroup)
    // .get(mainKey) as FormArray)
    // .get(j.toString()) as FormGroup).get(subKey) as FormGroup).get("uiDestVisitSelect")?.setValue(this.visitAllData)

    // let visitFormSelect = this.getSelectedKeyValue(data,mainKey,subKey,i,j)?.value.uiDestVisitSelect.find((all:any)=> all.visitId === visitSelectedId).forms

    // destData.visitId = visitSelectedId

    // destData.uiDestFormSelect = visitFormSelect;

    // (this.getSelectedKeyValue(data,mainKey,subKey,i,j) as FormGroup).get("uiDestFormSelect")?.setValue(visitFormSelect);

    // destData.formId = null
    // destData.fieldId = null
    // destData.dataTypeId = null
    // destData.loperator = null
  }

  onDestFormSelect(data:FormGroup,mainKey:any,subKey:any,event:any,i:any,j:any){
    let formSelectedId:any
    if(event.value != undefined){
      formSelectedId = event.value
    }else{
      formSelectedId = event
    }

    this.getFieldsByForm(formSelectedId,data,mainKey,subKey,i,j,true)

    // let destData = this.getSelectedKeyValue(data,mainKey,subKey,i,j)?.value
    
    // let formFieldSelect = this.getSelectedKeyValue(data,mainKey,subKey,i,j)?.value.uiDestFormSelect.find((all:any)=> all.formId == formSelectedId).fields

    // destData.formId = formSelectedId

    // destData.uiDestFieldSelect = formFieldSelect.filter((mainDataKey:any) => {
    //   return mainDataKey.dataTypeId == this.getSelectedKeyValue(data,mainKey,"uiFieldSelect",i,j)?.value.find((all:any) => all.id == this.getSelectedKeyValue(data,mainKey,"fieldId",i,j)?.value).dataTypeId
    // });

    // (this.getSelectedKeyValue(data,mainKey,subKey,i,j) as FormGroup).get("uiDestFieldSelect")?.setValue(formFieldSelect.filter((mainDataKey:any) => {
    //   return mainDataKey.dataTypeId == this.getSelectedKeyValue(data,mainKey,"uiFieldSelect",i,j)?.value.find((all:any) => all.id == this.getSelectedKeyValue(data,mainKey,"fieldId",i,j)?.value).dataTypeId
    // }));

    // destData.fieldId = null
    // destData.dataTypeId = null
    // destData.loperator = null
  }

  onDestFieldSelect(data:FormGroup,mainKey:any,subKey:any,event:any,i:any,j:any){
    let fieldSelectedId:any
    if(event.value != undefined){
      fieldSelectedId = event.value
    }else{
      fieldSelectedId = event
    }

    let destData = this.getSelectedKeyValue(data,mainKey,subKey,i,j)?.value
    
    let fieldSelect = this.getSelectedKeyValue(data,mainKey,subKey,i,j)?.value.uiDestFieldSelect.find((all:any)=> all.id == fieldSelectedId)
    
    if(fieldSelect != undefined){
      destData.fieldId = fieldSelectedId
      destData.dataTypeId = fieldSelect.dataTypeId
      if(fieldSelect.dataTypeId == 17){
        // let columnData:any = fieldSelect.columnData.filter((mainData:any) => {
        //   return mainData.dataTypeId == this.getSelectedKeyValue(data,mainKey,"multiRowDataTypeId",i,j)?.value
        // });
        (this.getSelectedKeyValue(data,mainKey,subKey,i,j) as FormGroup).get("dataTypeId")?.setValue(fieldSelect.dataTypeId);
        (this.getSelectedKeyValue(data,mainKey,subKey,i,j) as FormGroup).get("uiDestColumnSelect")?.setValue(fieldSelect.columnData);
      }else if(fieldSelect.dataTypeId == 18){
        
        let column = fieldSelect.cellInfo
        let columnData:any[] = []
        if(column){
          for (let index = 1; index < column[0].length; index++) {
            column[0][index][0].tableColumnIndex = index
            column[0][index][0].colHeading = column[0][index][0].label
            columnData.push(column[0][index][0])
          }
          if(columnData){
            for (let index = 0; index < columnData.length; index++) {
              columnData[index].mainDataTypeId = column[1][columnData[index].tableColumnIndex][0].dataTypeId
            }
          }
        }
        columnData = columnData.filter((mainData:any) => {
          return mainData.mainDataTypeId == this.getSelectedKeyValue(data,mainKey,"multiRowDataTypeId",i,j)?.value
        });
        (this.getSelectedKeyValue(data,mainKey,subKey,i,j) as FormGroup).get("uiDestColumnSelect")?.setValue(columnData);
      }
      
      (this.getSelectedKeyValue(data,mainKey,subKey,i,j) as FormGroup).get("dataTypeId")?.setValue(fieldSelect.dataTypeId)
  
      destData.loperator = null
    }else{
      (this.getSelectedKeyValue(data,mainKey,subKey,i,j) as FormGroup).get("uiDestColumnSelect")?.reset();
    }
    
  }
  
  onDestColumnSelect(data:FormGroup,mainKey:any,subKey:any,event:any,i:any,j:any){
    let columnSelectedId:any
    if(event.value != undefined){
      columnSelectedId = event.value
    }else{
      columnSelectedId = event
    }

    let destData = this.getSelectedKeyValue(data,mainKey,subKey,i,j)?.value

    let columnSelect = this.getSelectedKeyValue(data,mainKey,subKey,i,j)?.value.uiDestColumnSelect.find((all:any)=> all.id == columnSelectedId)
    destData.multiRowId = columnSelectedId;
    
    if(columnSelect != undefined){
      destData.multiRowDataTypeId = columnSelect.dataTypeId;
      
      (this.getSelectedKeyValue(data,mainKey,subKey,i,j) as FormGroup).get("multiRowDataTypeId")?.setValue(columnSelect.dataTypeId)
    }

    destData.loperator = null
  }

  addNewCondition(data:FormGroup,i:any,j:any) {
    let conditionData = (((data?.get('rule') as FormArray).get(i.toString()) as FormGroup).get("condition") as FormArray)
    let logMapping = this.getSelectedRuleKeyValue(data,"mappingType",i);
    if(( j === null && conditionData.length > 0) || conditionData.length > j + 1 ){
      return
    }else{
      // if(logMapping && logMapping == 1){
      //   conditionData.push(this.addCondition(i,j+1))
        
      // }else if (logMapping && logMapping == 2){
      //   conditionData.push(this.addLogToLogCondition(i,j+1))
        
      // }else{
      //   conditionData.push(this.addCondition(i,j+1))
      
      //   this.setSelectedKeyValue(this.formValidation, 'condition', 'visitId', i, j+1, this.visitId);

      //   let visitData = this.visitAllData.find((id:any)=>id.visitId == this.visitId).forms
      //   this.setSelectedKeyValue(this.formValidation, 'condition', 'uiFormSelect', i, j+1, visitData)
      //   this.setSelectedKeyValue(this.formValidation, 'condition', 'formId', i, j+1, this.formId)
        
      //   visitData = this.getSelectedKeyValue(this.formValidation,'condition','uiFormSelect',length,0)?.value.find((form:any)=>form.id == this.formId).fields
      //   this.setSelectedKeyValue(this.formValidation, 'condition', 'uiFieldSelect', i, j+1, visitData)
      //   this.setSelectedKeyValue(this.formValidation, 'condition', 'fieldId', i, j+1, this.fieldId)
        
      //   this.onFieldSelected(this.formValidation, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , i, j+1);

      // }

      conditionData.push(this.addValidationFormTypeCondition(i,j+1))
      // this.setSelectedKeyValue(data,"condition","loperator",i,j,this.getSelectedKeyValue(data,"condition","dest",i,j)?.value.loperator)
      this.setSelectedKeyValue(data,"condition","validationFormType",i,j+1,data.get("formType")?.value)
      this.onValidationFormTypeChange(this.getSelectedKeyValue(data,"condition","validationFormType",i,j+1)?.value,data,i,j+1,false)
      this.getAllFormData(data,i,j+1)
    }
  }

  addNewImpact(data:FormGroup,i:any,k:any) {
    let equations = (((data?.get('rule') as FormArray).get(i.toString()) as FormGroup).get("impact") as FormArray)
    if(( k === null && equations.length > 0) || equations.length > k ){
      return
    }else{
      equations.push(this.addValidationFormTypeImpact())
      console.log("No.2");
      this.onImpactValidationFormTypeChange(data,"impact","validationFormType",this.getSelectedKeyValue(data,"impact","validationFormType",i,k)?.value,i,k,false,null)
      // this.setSelectedKeyValue(data, 'impact', 'visit', i, k, this.visitId);

      // let visitData = this.visitAllData.find((id:any)=>id.visitId == this.visitId).forms
      // this.setSelectedKeyValue(data, 'impact', 'uiImpactFormSelect', i, k, visitData)
      // this.setSelectedKeyValue(data, 'impact', 'form', i, k, this.formId);
      
      // visitData = this.getSelectedKeyValue(data,'impact','uiImpactFormSelect',i, k)?.value.find((form:any)=>form.id == this.formId).fields
      // this.setSelectedKeyValue(data, 'impact', 'uiImpactFieldSelect', i, k, visitData)
      
      // this.onFieldSelected(data, 'impact', 'uiImpactFieldSelect', 'uiImpactColumnSelect', { value: this.fieldId} , i, k);
      // // this.getSelectedKeyValue(this.formValidation, "impact", "fields", i, k)?.reset()
      // if(this.globalFieldDataTypeId == 17){
      //   let selectedFields = this.getSelectedKeyValue(data, "impact", "uiImpactFieldSelect", i, k)?.value.find((data:any)=> data.id == this.fieldId)
      //   this.setSelectedKeyValue(data, "impact", "fields", i, k, [selectedFields])
      // }
    }
  }

  addNewControl(data:FormGroup,keyName:any,i:any,j:any,controlerName:any,controlData:any){
    return ((((data?.get("rule") as FormArray)
    .get(i.toString()) as FormGroup)
    .get(keyName) as FormArray)
    .get(j.toString()) as FormGroup)
    .addControl(controlerName,controlData)
  }

  removeNewControl(data:FormGroup,keyName:any,i:any,j:any,controlerName:any){
    return ((((data?.get("rule") as FormArray)
    .get(i.toString()) as FormGroup)
    .get(keyName) as FormArray)
    .get(j.toString()) as FormGroup)
    .removeControl(controlerName)
  }

  getSelectedRuleKeyValue(data:FormGroup,keyName:any,i:any){
    return ((data?.get("rule") as FormArray).get(i.toString()) as FormGroup).get(keyName)?.value
  }

  setSelectedRuleKeyValue(data:FormGroup,keyName:any,i:any,value:any){
    return ((data?.get("rule") as FormArray)
    .get(i.toString()) as FormGroup).get(keyName)?.setValue(value)
  }

  setSelectedKeyValue(data:FormGroup,mainKey:any,keyName:any,i:any,j:any,value:any){    
    ((((data?.get("rule") as FormArray)
    .get(i.toString()) as FormGroup)
    .get(mainKey) as FormArray)
    .get(j.toString()) as FormGroup).get(keyName)?.setValue(value)
  }

  setDestSelectedKeyValue(data:FormGroup,mainKey:any,subKey:any,keyName:any,i:any,j:any,value:any){    
    (((((data?.get("rule") as FormArray)
    .get(i.toString()) as FormGroup)
    .get(mainKey) as FormArray)
    .get(j.toString()) as FormGroup)
    .get(subKey) as FormGroup).get(keyName)?.setValue(value)
  }

  setSelectedKeyValueFormatting(jsonFormat:any,mainKey:any,keyName:any,i:any,j:any,value:any){
    jsonFormat.rule[i][mainKey][j][keyName] = value
  }

  getSelectedRule(i:any){
    return ((this.formValidation?.get("rule") as FormArray).get(i.toString()) as FormGroup)
  }

  getSelectedKeyValue(data:any,mainKey:any,keyName:any,i:any,j:any){
    
    return ((((data?.get("rule") as FormArray)
    .get(i.toString()) as FormGroup)
    .get(mainKey) as FormArray)
    .get(j.toString()) as FormGroup).get(keyName)
  }

  //Log-Mapping Methods
  addLogMappingRule():FormGroup{
    return new FormGroup({
      id:new FormControl(),
      ruleId:new FormControl(),
      name:new FormControl(),
      equation:new FormControl(),
      displayEquation:new FormControl(),
      action:new FormControl(this.validationAction[3].id),
      message:new FormControl(),
      mappingType:new FormControl(),
      isNameEditable:new FormControl(false),
      editedName:new FormControl(),
      status:new FormControl(1),
      condition:new FormArray([]),
      impact:new FormArray([this.addLogMappingImpact()]),
      isLoading:new FormControl(false)
    })
  }

  addLogMappingImpact():FormGroup{
    return new FormGroup({
      id:new FormControl(),
      form:new FormControl()
    })
  }

  addLogToLogCondition(i:any,j:any):FormGroup{
    
    return new FormGroup({
      id:new FormControl(),
      visitId:new FormControl(DEFAULT_UUID),
      formId:new FormControl(),
      fieldId:new FormControl(),
      multiRowId:new FormControl(),
      multiRowDataTypeId:new FormControl(),
      operator:new FormControl(),
      isConstant:new FormControl(),
      dataTypeId:new FormControl(),
      fieldValue:new FormControl(),
      optionId:new FormControl(),
      jsonFieldValue:new FormControl({}),
      loperator:new FormControl(""),
      eqOrderId:new FormControl(i),
      uiFormSelect:new FormControl(),
      uiFieldSelect:new FormControl(),
      uiColumnSelect: new FormControl(),
      uiOperatorSelect:new FormControl(),
      uiOptionSelect:new FormControl()
    })
  }

  addNewLogMappingValidation(){
    (this.formLogMapping.get("rule") as FormArray).push(this.addLogMappingRule())
    
    let length = (this.formLogMapping.get("rule") as FormArray).length-1
    let value = this.formLogMapping.get("name")?.value;
    
    this.setSelectedRuleKeyValue(this.formLogMapping,"name",length,value);
    
    if(this.visitId && this.visitId[0] != null && this.visitId.length > 0 && typeof(this.visitId) == 'object' ){
      this.setSelectedRuleKeyValue(this.formLogMapping,"mappingType",length.toString(),1);
    }else{
      this.setSelectedRuleKeyValue(this.formLogMapping,"mappingType",length.toString(),2);
    }

    this.OnLogMappingSelected(this.getSelectedRuleKeyValue(this.formLogMapping,"mappingType",length.toString()),length)
    this.formLogMapping.get("name")?.reset()
  }
  
  get getLogMappingRule(){
    return (this.formLogMapping.get("rule") as FormArray)
  }
  
  OnLogMappingSelected(logId:any,i:any){
    // (((this.formLogMapping?.get("rule") as FormArray).get(i.toString()) as FormGroup)
    // .get("condition") as FormArray).clear()
    this.fieldAPI.getFieldsByForm(this.formId).subscribe(res => {
      if(logId == this.logMappingDataType[0].id){
        this.setSelectedRuleKeyValue(this.formLogMapping,"mappingType",i,logId);
        (((this.formLogMapping?.get("rule") as FormArray).get(i.toString()) as FormGroup)
        .get("condition") as FormArray).push(this.addCondition(0,0))
        this.getAllFormData(this.formLogMapping,i,0);
        if(this.visitId && this.visitId[0] != null && this.visitId.length > 0 && typeof(this.visitId) == 'object' ){
          this.setSelectedKeyValue(this.formLogMapping, "condition", "validationFormType",i,0,0);
          this.setSelectedKeyValue(this.formLogMapping, 'condition', 'visitId', i, 0, this.visitId[0]);
    
          const forms = this.visitFormMainData['visit'][this.visitId[0]].forms
          this.setSelectedKeyValue(this.formLogMapping,'condition','uiFormSelect',i,0,this.extractForms(forms))
          this.setSelectedKeyValue(this.formLogMapping, 'condition', 'formId', i, 0, this.formId);
          
          this.setSelectedKeyValue(this.formLogMapping,'condition','uiFieldSelect',i,0,cloneArray(res.responseObject))
          this.setSelectedKeyValue(this.formLogMapping, 'condition', 'fieldId', i, 0, this.fieldId);
          
          this.onFieldSelected(this.formLogMapping, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , i, 0);    
        }else{
          this.setSelectedKeyValue(this.formLogMapping, "condition", "validationFormType",i,0,0);
          this.setSelectedKeyValue(this.formLogMapping, 'condition', 'visitId', i, 0, this.visitId);
          
          const forms = this.visitFormMainData['visit'][this.visitId[0]].forms
          this.setSelectedKeyValue(this.formLogMapping,'condition','uiFormSelect',i,0,this.extractForms(forms))
          this.setSelectedKeyValue(this.formLogMapping, 'condition', 'formId', i, 0, this.formId);
          
          this.setSelectedKeyValue(this.formLogMapping,'condition','uiFieldSelect',i,0,cloneArray(res.responseObject))
          this.setSelectedKeyValue(this.formLogMapping, 'condition', 'fieldId', i, 0, this.fieldId);
          
          this.onFieldSelected(this.formLogMapping, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , i, 0);    
        }
      
      }else if(logId == this.logMappingDataType[1].id){
        this.setSelectedRuleKeyValue(this.formLogMapping,"mappingType",i,logId);
        (((this.formLogMapping?.get("rule") as FormArray).get(i.toString()) as FormGroup)
        .get("condition") as FormArray).push(this.addLogToLogCondition(0,0))
        this.getAllFormData(this.formLogMapping,i,0);
        if(this.visitId && this.visitId.length > 0 && typeof(this.visitId) == 'object' ){
          if(this.visitId[0] == null || this.visitId[0] == DEFAULT_UUID){
            this.setSelectedKeyValue(this.formLogMapping, "condition", "validationFormType",i,0,1);
            this.setSelectedKeyValue(this.formLogMapping, 'condition', 'visitId', i, 0, DEFAULT_UUID);
      
            this.setSelectedKeyValue(this.formLogMapping,'condition','uiFormSelect',i,0,this.logFormFieldSelect[0].forms)
            this.setSelectedKeyValue(this.formLogMapping, 'condition', 'formId', i, 0, this.formId);
            
            let fieldSelect = this.getSelectedKeyValue(this.formLogMapping,'condition','uiFormSelect',i,0)?.value.find((form:any)=>form.id == this.formId).fields
            this.setSelectedKeyValue(this.formLogMapping,'condition','uiFieldSelect',i,0,fieldSelect)
            this.setSelectedKeyValue(this.formLogMapping, 'condition', 'fieldId', i, 0, this.fieldId);
            
            this.onFieldSelected(this.formLogMapping, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , i, 0);    
          }
        }else{
          this.setSelectedKeyValue(this.formLogMapping, "condition", "validationFormType",i,0,0);
          this.setSelectedKeyValue(this.formLogMapping, 'condition', 'visitId', i, 0, this.visitId);
    
          let visitData = this.visitAllData.find((id:any)=>id.visitId == this.visitId).forms
          this.setSelectedKeyValue(this.formLogMapping,'condition','uiFormSelect',i,0,visitData)
          this.setSelectedKeyValue(this.formLogMapping, 'condition', 'formId', i, 0, this.formId);
          
          visitData = this.getSelectedKeyValue(this.formLogMapping,'condition','uiFormSelect',i,0)?.value.find((form:any)=>form.id == this.formId).fields
          this.setSelectedKeyValue(this.formLogMapping,'condition','uiFieldSelect',i,0,visitData)
          this.setSelectedKeyValue(this.formLogMapping, 'condition', 'fieldId', i, 0, this.fieldId);
          
          this.onFieldSelected(this.formLogMapping, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , i, 0);    
        }
      }
      console.log("FORM LOG MAPPING :: ",this.formLogMapping);
      
    })
  }

  
  getLogMappingArrayRule(key:any,i:any){
    return (((this.formLogMapping.get("rule") as FormArray)
    .get(i.toString()) as FormGroup)
    .get(key) as FormArray)
  }

  removeLogMappingImpact(i:any,k:any){
    if(this.getLogMappingArrayRule("impact",i).length > 1){
      this.getLogMappingArrayRule("impact",i).removeAt(k)
    }
  }

  addNewLogMappingImpact(i:any,k:any) {
    let equations = (((this.formLogMapping?.get('rule') as FormArray).get(i.toString()) as FormGroup).get("impact") as FormArray)
    if(( k === null && equations.length > 0) || equations.length > k + 1 ){
      return
    }else{
      equations.push(this.addLogMappingImpact())
    }
  }

  //Make Equation
  getFormula(datatype:string ,value1:any,value2:any, operator:any,loperator?:any, isText:boolean = false){
    let lOperation = loperator == null ? "" : loperator;
    if(isText)
      return datatype + "('" + value1 + "','" + value2 + "') " +lOperation
    else
      return datatype + "(" + value1 + (operator ? " " + operator + " " : ", ") + value2 + ") " + lOperation
  }

  createQFormula(data:any,i:any,j:any, isDestination: boolean){
    let eq = "Q{";

    let visitId = isDestination ? data.rule[i].condition[j].dest['visitId'] : data.rule[i].condition[j].visitId
    let formId = isDestination ? data.rule[i].condition[j].dest['formId'] : data.rule[i].condition[j].formId
    let fieldId = isDestination ? data.rule[i].condition[j].dest['fieldId'] : data.rule[i].condition[j].fieldId
    
    let multiRowId:any
    if(this.dataTypeId == componentTypeMap[16].id){
      multiRowId = isDestination ? data.rule[i].condition[j].dest['multiRowId'] : data.rule[i].condition[j].multiRowId
    }else if (this.dataTypeId == componentTypeMap[17].id){
      multiRowId = isDestination ? "{"+data.rule[i].condition[j].dest['multiRowId']+"}" : "{"+data.rule[i].condition[j].multiRowId+"}"
    }
    
    let isConstant = data.rule[i].condition[j].isConstant;
    let dataType = data.rule[i].condition[j].dataTypeId;
    
    let columnDataType:any 
    if(this.dataTypeId == componentTypeMap[16].id){
      columnDataType = isDestination ? data.rule[i].condition[j].dest['multiRowDataTypeId'] : data.rule[i].condition[j].multiRowDataTypeId;
    }else if(this.dataTypeId == componentTypeMap[17].id){
      columnDataType = isDestination ? data.rule[i].condition[j].dest['multiRowDataTypeId'] : data.rule[i].condition[j].multiRowDataTypeId;
    }
    
    let option = data.rule[i].condition[j].jsonFieldValue != null ? Object.keys(data.rule[i].condition[j].jsonFieldValue)[0] : null
    
    eq += (visitId && visitId != this.defaultUuid) ? visitId + "." : this.defaultUuid + ".";
    
    eq += formId+"."+fieldId;

    if(multiRowId){
      eq += "."+multiRowId;
    }
    
    if(isConstant == 1){
      if([17].includes(dataType) && [6, 7, 8].includes(columnDataType)){
        eq += "." + data.rule[i].condition[j].optionId;
      }
      // else if([18].includes(dataType) && [6, 7, 8].includes(columnDataType)){
        // eq += "." + "{" + data.rule[i].condition[j].optionId + "}";
        // eq += "." + "{op}";

      // }
      else if([6, 7, 8].includes(dataType)){
        eq += "." + option;
      }
    }
    return eq + "}";
  }

  getDisplayEquationFormula(value1:any, value2:any, operator:any, loperator?:any){
    let lOperation = loperator == null ? "" : loperator;
    return value1 + " " + operator + " " + value2 + " " +lOperation
  }

  createDisplayEquationFormula(data:any,i:any,j:any, isDestination: boolean){
    let eq = "( ";
    let visitId = isDestination ? data.rule[i].condition[j].dest['visitId'] : data.rule[i].condition[j].visitId
    let visitName:any
    if(visitId != this.defaultUuid){
      visitName = isDestination ? data.rule[i].condition[j].dest['uiDestVisitSelect'].find((key:any) => key.id == data.rule[i].condition[j].dest['visitId']).name : this.visitAllData.find((key:any) => key.id == data.rule[i].condition[j].visitId).name
    }
    let formName = isDestination ? data.rule[i].condition[j].dest['uiDestFormSelect'].find((key:any) => key.formId == data.rule[i].condition[j].dest['formId']).formName : data.rule[i].condition[j].uiFormSelect.find((key:any) => key.formId == data.rule[i].condition[j].formId).formName
    let fieldName = isDestination ? data.rule[i].condition[j].dest['uiDestFieldSelect'].find((key:any) => key.id == data.rule[i].condition[j].dest['fieldId']).label : data.rule[i].condition[j].uiFieldSelect.find((key:any) => key.id == data.rule[i].condition[j].fieldId).label
    
    let multiRowName:any
    if(this.dataTypeId == componentTypeMap[16].id){
      multiRowName = isDestination ? data.rule[i].condition[j].dest['uiDestColumnSelect'].find((key:any) => key.id == data.rule[i].condition[j].dest['multiRowId']).colHeading : data.rule[i].condition[j].uiColumnSelect.find((key:any) => key.id == data.rule[i].condition[j].multiRowId).colHeading
    }else if (this.dataTypeId == componentTypeMap[17].id){
      multiRowName = isDestination ? data.rule[i].condition[j].dest['uiDestColumnSelect'].find((key:any) => key.tableColumnIndex == data.rule[i].condition[j].dest['multiRowId']).colHeading : data.rule[i].condition[j].uiColumnSelect.find((key:any) => key.tableColumnIndex == data.rule[i].condition[j].multiRowId).colHeading
    }

    eq += (visitId && visitId != this.defaultUuid) ? visitName + ARROW : "";
    
    eq += formName+ ARROW +fieldName;

    if(multiRowName){
      eq += ARROW + multiRowName;
    }

    return eq + " )";
  }

  dataAndOperatorsConbinations(data:any,i:any,j:any){
    let dataTypeId:any;
    let loperator:any;
    let value:any;
    let operator:any;
    let formula:any;

    let destination:any = data.rule[i].condition[j].dest
    
    // let operator = this.getSelectedKeyValue(data,"condition","operator",i,j)?.value
    operator = data.rule[i].condition[j].operator
    
    // let value = this.getSelectedKeyValue(data,"condition","fieldValue",i,j)?.value
    value = data.rule[i].condition[j].fieldValue
    
    // let loperator = this.getSelectedKeyValue(data,"condition","loperator",i,j)?.value
    loperator = data.rule[i].condition[j].loperator
    
    // let formula = this.createQFormula(data,i,j)
    formula = this.createQFormula(data, i, j, false);

    let displayEquationFormula = this.createDisplayEquationFormula(data,i,j,false)
    
    let jsonFieldValue = data.rule[i].condition[j].jsonFieldValue
    let selectedIdValue:any;
    let jsonData:any
    if(data.rule[i].condition[j].uiFieldSelect && data.rule[i].condition[j].uiFieldSelect.length > 0){
      for (let index = 0; index < data.rule[i].condition[j].uiFieldSelect.length; index++) {
        if(data.rule[i].condition[j].fieldId == data.rule[i].condition[j].uiFieldSelect[index].id){
          
          let dataTypeid = data.rule[i].condition[j].dataTypeId;
          let fieldData = data.rule[i].condition[j].uiFieldSelect;
          
          if(![17,18].includes(dataTypeid)){
            if(fieldData[index].options){
              for (let opt = 0; opt < fieldData[index].options.length; opt++){
                selectedIdValue = jsonFieldValue[data.rule[i].condition[j].uiFieldSelect[index].options[opt].id];
                if(selectedIdValue)
                  break;
              }
            }
          }else if (dataTypeid == componentTypeMap[16].id){
            if(fieldData.columnData){
              for (let colData = 0; colData < fieldData.columnData.length; colData++){
                for(let options = 0; options < fieldData.columnData[colData].options.length; options++){
                  selectedIdValue = jsonFieldValue[fieldData.columnData[colData].options[options].id]
      
                  if(selectedIdValue)
                    break;
                }
              }
            }
          }
        }
      }
    }

    if(destination != undefined){
      // subCondition = this.createSubQFormula(data,i,j)
      if(this.dataTypeId == componentTypeMap[16].id){
        dataTypeId = data.rule[i].condition[j].dest['dataTypeId'] == 17 ? data.rule[i].condition[j].dest['multiRowDataTypeId'] : data.rule[i].condition[j].dest['dataTypeId']
      }else if(this.dataTypeId == componentTypeMap[17].id){
        dataTypeId = data.rule[i].condition[j].dest['dataTypeId'] == 18 ? data.rule[i].condition[j].dest['multiRowDataTypeId'] : data.rule[i].condition[j].dest['dataTypeId']
      }else{
        dataTypeId = data.rule[i].condition[j].dest['dataTypeId']
      }
      loperator = data.rule[i].condition[j].dest["loperator"]

    }else{
      if(this.dataTypeId == componentTypeMap[16].id){
        dataTypeId = data.rule[i].condition[j].dataTypeId == 17 ? data.rule[i].condition[j].multiRowDataTypeId : data.rule[i].condition[j].dataTypeId
      }else if(this.dataTypeId == componentTypeMap[17].id){
        dataTypeId = data.rule[i].condition[j].dataTypeId == 18 ? data.rule[i].condition[j].multiRowDataTypeId : data.rule[i].condition[j].dataTypeId
      }else{
        dataTypeId = data.rule[i].condition[j].dataTypeId
      }
    }
    
    if(dataTypeId == 12){
      jsonData = jsonFieldValue['year'] + "-" + jsonFieldValue['month'] + "-" + jsonFieldValue['day']
    }
    if(dataTypeId == 8){
      jsonData = selectedIdValue
    }
    if(this.dataTypeId == componentTypeMap[17].id){
      if(dataTypeId == 6 || dataTypeId == 7){
        jsonData = selectedIdValue
      }
    }
    let equation:string = ""
    let displayEquation:string = ""
    
    let equationString = VALIDATION_OPERATORS_ENUMS[operator];
    let destFormula = this.createQFormula(data, i, j, destination ? true : false);
    let destDisplayEquationFormula = this.createDisplayEquationFormula(data, i, j, destination ? true : false);

    if (dataTypeId == 1 || dataTypeId == 2 || dataTypeId == 4 || dataTypeId == 5){
      equation = this.getFormula(equationString + "Text", formula, destination ? destFormula : value, undefined, loperator, true)
      displayEquation = this.getDisplayEquationFormula(displayEquationFormula,destination ? destDisplayEquationFormula : value, operator, loperator)
    }
    else if (dataTypeId == 3){
      if ([">", ">=", "<", "<="].includes(operator)){
        equation = this.getFormula("", formula, destination ? destFormula : Number(value), operator, loperator, false);
      }else{
        equation = this.getFormula(equationString, formula, destination ? destFormula : value , undefined, loperator, false)
      }
      displayEquation = this.getDisplayEquationFormula(displayEquationFormula,destination ? destDisplayEquationFormula : value, operator, loperator)
    }
    else if (dataTypeId == 6 || dataTypeId == 7 ){
      if(this.dataTypeId == componentTypeMap[17].id){
        equation = this.getFormula(equationString + "Text", formula, destination ? destFormula : data.rule[i].condition[j].fieldValue, undefined, loperator, true)
        displayEquation = this.getDisplayEquationFormula(displayEquationFormula, data.rule[i].condition[j].fieldValue, operator, loperator)
      }else{
        equation = this.getFormula(equationString, formula, destination ? destFormula : "true", undefined, loperator, false)
        let optionName = data.rule[i].condition[j].jsonFieldValue != null ? data.rule[i].condition[j].uiOptionSelect.find((key:any) => key.id == Object.keys(data.rule[i].condition[j].jsonFieldValue)[0]).name : null
        displayEquation = this.getDisplayEquationFormula(displayEquationFormula, optionName, operator, loperator)
      }
    }
    else if (dataTypeId == 8){
      if(this.dataTypeId == componentTypeMap[17].id){
        equation = this.getFormula(equationString + "Text", formula, destination ? destFormula : data.rule[i].condition[j].fieldValue, undefined, loperator, true)
      }else{
        equation = this.getFormula(equationString + "Text", formula, destination ? destFormula :  jsonData, undefined, loperator, true)
      }
      displayEquation = this.getDisplayEquationFormula(displayEquationFormula,destination ? destDisplayEquationFormula : value, operator, loperator)
    }
    else if (dataTypeId == 12){
      equationString = VALIDATION_OPERATORS_DATE_ENUMS[operator]
      equation = this.getFormula(equationString, formula, destination ? destFormula : jsonData, undefined, loperator, true)
      displayEquation = this.getDisplayEquationFormula(displayEquationFormula,destination ? destDisplayEquationFormula : value, operator, loperator)
    }
    
    return {equation:equation,displayEquation:displayEquation}
  }

  makeEquation(data:any){
    let ruleData = data.rule
    if(ruleData){
      for (let i = 0; i < ruleData.length; i++) {
        let equation:string = ""
        let displayEquation:string = ""
        // let ruleConditionData = (((data.get("rule") as FormArray).get(i.toString()) as FormGroup).get("condition") as FormArray).value
        for (let j = 0; j < ruleData[i].condition.length; j++) {
          equation += " " + this.dataAndOperatorsConbinations(data,i,j).equation
          displayEquation += " " + this.dataAndOperatorsConbinations(data,i,j).displayEquation
        }
        ruleData[i].equation = equation
        ruleData[i].displayEquation = displayEquation
        // this.getSelectedRule(i).get("equation")?.setValue(equation)
        // ((data.get("rule") as FormArray).get(i.toString()) as FormGroup).get("equation")?.setValue(equation)
      }
      
    }
  }

  //Validation Rule API Integration
  patchValidationRuleValue(response:any, index:any){
    // if(index >= 0){
      if(response[index].action == 4){

        (this.formLogMapping.get("rule") as FormArray).push(this.addLogMappingRule());
        this.OnLogMappingSelected(response[index].mappingType,index);
        ((this.formLogMapping.get("rule") as FormArray).get(index.toString()) as FormGroup).patchValue(response[index]);
        this.setSelectedRuleKeyValue(this.formLogMapping,"id",index,response[index].ruleId);
        this.setSelectedRuleKeyValue(this.formLogMapping,"ruleId",index,response[index].ruleId);
        
      }else{
        if(this.formValidation.get("formType")?.value == 0){
          (this.formValidation.get("rule") as FormArray).push(this.addRule());  
          ((this.formValidation.get("rule") as FormArray).get(index.toString()) as FormGroup).patchValue(response[index]);
          this.setSelectedRuleKeyValue(this.formValidation,"message",index,response[index].message);
          this.setSelectedRuleKeyValue(this.formValidation,"id",index,response[index].ruleId);
          
        }else{
          (this.formValidation.get("rule") as FormArray).push(this.addFormTypeRule());  
          //this.setSelectedRuleKeyValue("id",index,response[index].ruleId);
          this.setSelectedRuleKeyValue(this.formValidation,"id",index,response[index].ruleId);
          ((this.formValidation.get("rule") as FormArray).get(index.toString()) as FormGroup).patchValue(response[index]);
        }
      }
    // }

    // this.setSelectedRuleKeyValue("name",index,response[index].name);
    // this.setSelectedRuleKeyValue("action",index,response[index].action);
    // this.setSelectedRuleKeyValue("message",index,response[index].message);

  }

  patchValidationConditionValue(response:any, index:any, subIndex:any){
    if(this.validationRuleId != undefined || this.validationRuleId != null){
      this.visitId = response[index].condition[subIndex].visitId
      this.formId = response[index].condition[subIndex].formId
      const forms = this.visitFormMainData['visit'][response[index].condition[subIndex].visitId].forms
      const formsData = this.extractForms(forms)
      this.formType = formsData.find((findData:any) => findData.formId == response[index].condition[subIndex].formId).formType
      this.fieldId = response[index].condition[subIndex].fieldId
    }
    let id = response[index].condition[subIndex].id
    let visitId = response[index].condition[subIndex].visitId
    let formId = response[index].condition[subIndex].formId
    let fieldId = response[index].condition[subIndex].fieldId
    let dataTypeId = response[index].condition[subIndex].dataTypeId;
    let multiRowDataTypeId:any
    let multiRowId:any;
    let optionId:any;
    let tableValidationType:any;
    let operatorDataTypeId:any;
    if([17,18].includes(dataTypeId)){
      multiRowDataTypeId = response[index].condition[subIndex].multiRowDataTypeId
      multiRowId = response[index].condition[subIndex].multiRowId
      optionId = response[index].condition[subIndex].optionId
      tableValidationType = response[index].condition[subIndex].tableValidationType
      operatorDataTypeId = response[index].condition[subIndex].multiRowDataTypeId
    }else{
      multiRowDataTypeId = 0;
      multiRowId = 0;
      optionId = 0;
      tableValidationType = 0;
      operatorDataTypeId = response[index].condition[subIndex].dataTypeId
    }
    let operator = response[index].condition[subIndex].operator
    let fieldValue = response[index].condition[subIndex].fieldValue
    let jsonFieldValue = response[index].condition[subIndex].jsonFieldValue
    let loperator = response[index].condition[subIndex].loperator
    let destination = response[index].condition[subIndex].dest

    console.log("FORMMMMM :: ",formId);
    
    this.fieldAPI.getFieldsByForm(formId).subscribe(res => {
      if(response[index].action == 4){
        this.setSelectedKeyValue(this.formLogMapping,"condition","id",index,subIndex,response[index].condition[subIndex].id);
        this.setSelectedKeyValue(this.formLogMapping,"condition","ruleId",index,subIndex,response[index].condition[subIndex].ruleId);
        
        this.onValidationOperator(dataTypeId,index,subIndex,this.formLogMapping);
        if(this.getSelectedRuleKeyValue(this.formLogMapping,"mappingType",index) == 1){
          const forms = this.visitFormMainData['visit'][response[index].condition[subIndex].visitId].forms
          this.setSelectedKeyValue(this.formLogMapping,"condition","uiFormSelect",index,subIndex,this.extractForms(forms))
          
          let fields:any
          let option:any
          this.fieldAPI.getFieldsByForm(formId).subscribe(res => {
            this.setSelectedKeyValue(this.formLogMapping,"condition","uiFieldSelect",index,subIndex,cloneArray(res.responseObject));
            fields = this.getSelectedKeyValue(this.formLogMapping,"condition","uiFieldSelect",index,subIndex)?.value.find((data:any)=> data.id == fieldId)
            option = fields.options
            this.setSelectedKeyValue(this.formLogMapping,"condition","uiOptionSelect",index,subIndex,option);
          })
    
    
        }else{
          if(visitId == this.defaultUuid){
            this.setSelectedKeyValue(this.formLogMapping,"condition","validationFormType",index,subIndex,1);
          }else{
            this.setSelectedKeyValue(this.formLogMapping,"condition","validationFormType",index,subIndex,0);
  
          }
          this.setSelectedKeyValue(this.formLogMapping,"condition","uiFormSelect",index,subIndex,this.logFormFieldSelect[0].forms)
          
          let fieldData = this.getSelectedKeyValue(this.formLogMapping,"condition","uiFormSelect",index,subIndex)?.value.find((form:any)=>form.id == formId).fields
          this.setSelectedKeyValue(this.formLogMapping,"condition","uiFieldSelect",index,subIndex,fieldData);
    
          let fields = this.getSelectedKeyValue(this.formLogMapping,"condition","uiFieldSelect",index,subIndex)?.value.find((data:any)=> data.id == fieldId)
    
          let option = fields.options
          this.setSelectedKeyValue(this.formLogMapping,"condition","uiOptionSelect",index,subIndex,option);
        }
  
        if(destination != undefined){
          
          this.setSelectedKeyValue(this.formLogMapping,"condition","isConstant",index,subIndex,2)
          if(this.validationRuleId){
            this.IsConstantSelected(this.formLogMapping,"condition",2,index,subIndex,true);

          }else{
            this.IsConstantSelected(this.formLogMapping,"condition",2,index,subIndex,true);

          }
          if(response[index].condition[subIndex].dest['visitId'] == this.defaultUuid){
            (((((this.formLogMapping.get("rule") as FormArray)
            .get(index.toString()) as FormGroup)
            .get("condition") as FormArray)
            .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.setValue(1)
          }else{
            (((((this.formLogMapping.get("rule") as FormArray)
            .get(index.toString()) as FormGroup)
            .get("condition") as FormArray)
            .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.setValue(1)
          }
          this.onDestValidationFormTypeChange((((((this.formLogMapping.get("rule") as FormArray)
          .get(index.toString()) as FormGroup)
          .get("condition") as FormArray)
          .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.value,this.formLogMapping,"condition","validationFormType",index,subIndex,true,destination)
        }else{
          this.setSelectedKeyValue(this.formLogMapping,"condition","isConstant",index,subIndex,1)
          if(this.validationRuleId){
            this.IsConstantSelected(this.formLogMapping,"condition",1,index,subIndex,true)
          }else{
            this.IsConstantSelected(this.formLogMapping,"condition",1,index,subIndex,true)
          }
  
        }
  
        ((((this.formLogMapping.get("rule") as FormArray)
        .get(index.toString()) as FormGroup)
        .get("condition") as FormArray)
        .get(subIndex.toString()) as FormGroup)
        .patchValue(response[index].condition[subIndex]);
  
        if(jsonFieldValue){
          let dataTypeId = ((((this.formLogMapping.get("rule") as FormArray)
          .get(index.toString()) as FormGroup)
          .get("condition") as FormArray)
          .get(subIndex.toString()) as FormGroup)
          .get("dataTypeId")?.value;
          if(dataTypeId == 6 || dataTypeId == 7 || dataTypeId == 8){
            Object.keys(jsonFieldValue).filter((data:any)=>{
              this.setSelectedKeyValue(this.formLogMapping,"condition","jsonFieldValue",index,subIndex,data)
            });
          }
        }
        if(response[index].condition[subIndex].dest){
          if(destination != undefined){
            if(this.getSelectedRuleKeyValue(this.formLogMapping,"mappingType",index) == 1){
              if(response[index].condition[subIndex].dest['visitId'] != this.defaultUuid){
                this.onDestVisitSelect(this.formLogMapping,"condition","dest",response[index].condition[subIndex].dest['visitId'],index,subIndex)
                this.onDestFormSelect(this.formLogMapping,"condition","dest",response[index].condition[subIndex].dest['formId'],index,subIndex)
                this.onDestFieldSelect(this.formLogMapping,"condition","dest",response[index].condition[subIndex].dest['fieldId'],index,subIndex)
              }else{
                this.onDestFormSelect(this.formLogMapping,"condition","dest",response[index].condition[subIndex].dest['formId'],index,subIndex)
                this.onDestFieldSelect(this.formLogMapping,"condition","dest",response[index].condition[subIndex].dest['fieldId'],index,subIndex)
              }
              
            }else{
              if(response[index].condition[subIndex].dest['visitId'] != this.defaultUuid){
                this.onDestVisitSelect(this.formLogMapping,"condition","dest",response[index].condition[subIndex].dest['visitId'],index,subIndex)
                this.onDestFormSelect(this.formLogMapping,"condition","dest",response[index].condition[subIndex].dest['formId'],index,subIndex)
                this.onDestFieldSelect(this.formLogMapping,"condition","dest",response[index].condition[subIndex].dest['fieldId'],index,subIndex)
              }else{
                this.onDestFormSelect(this.formLogMapping,"condition","dest",response[index].condition[subIndex].dest['formId'],index,subIndex)
                this.onDestFieldSelect(this.formLogMapping,"condition","dest",response[index].condition[subIndex].dest['fieldId'],index,subIndex)
              }
            }
          }
        }
        // if(response[index].condition[subIndex].dest){
        //   this.onDestVisitSelect(this.formLogMapping,"condition","dest",response[index].condition[subIndex].dest['visitId'],index,subIndex)
        //   this.onDestFormSelect(this.formLogMapping,"condition","dest",response[index].condition[subIndex].dest['formId'],index,subIndex)
        //   this.onDestFieldSelect(this.formLogMapping,"condition","dest",response[index].condition[subIndex].dest['fieldId'],index,subIndex)
        // }
  
      }else{

        if(this.validationRuleId){
          this.formValidation.get("formType")?.setValue(this.formType)

        }
        this.setSelectedKeyValue(this.formValidation,"condition","id",index,subIndex,id);
        this.setSelectedKeyValue(this.formValidation,"condition","ruleId",index,subIndex,id);
        this.setSelectedKeyValue(this.formValidation,"condition","tableValidationType",index,subIndex,tableValidationType);
        console.log("TYPE ::::::: ",this.formValidation.get("formType")?.value);
        
        if(this.formValidation.get("formType")?.value == 0){
  
          if(response[index].condition[subIndex].visitId == this.defaultUuid){
            console.log("CALLING");
            
            this.setSelectedKeyValue(this.formValidation,"condition","validationFormType",index,subIndex,1);
            this.onValidationFormTypeChange(this.getSelectedKeyValue(this.formValidation,"condition","validationFormType",index,subIndex)?.value,
            this.formValidation,index,subIndex,true);
            this.setSelectedKeyValue(this.formValidation,"condition","uiFormSelect",index,subIndex,this.logFormFieldSelect[0].forms)
            
            let fieldData = this.getSelectedKeyValue(this.formValidation,"condition","uiFormSelect",index,subIndex)?.value.find((form:any)=>form.id == formId).fields
            this.setSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex,fieldData);
            
            let fields = this.getSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex)?.value.find((data:any)=> data.id == fieldId)
            
            if(dataTypeId == 17){
              this.setSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex, fields.columnData);
    
              let optionId = [6, 7, 8].includes(multiRowDataTypeId) ? response[index].condition[subIndex].optionId : 0;
              var options = this.getSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex)?.value.find((data:any)=> data.id == multiRowId).options;
    
              this.setSelectedKeyValue(this.formValidation,"condition","uiOptionSelect",index,subIndex, options);
            }else if(dataTypeId == 18){
              let tableDataTypeId:any
              let tableData:any
              let tableFieldData = this.getSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex)?.value.find((cntry: any) => cntry.id == this.getSelectedKeyValue(this.formValidation,"condition","fieldId",index,subIndex)?.value)
              let cellInfoData:any[] = []
              
              if(tableFieldData && tableFieldData.cellInfo){
                for (let i = 1; i < tableFieldData.cellInfo[0].length; i++) {
                  tableFieldData.cellInfo[0][i][0].tableColumnIndex = i
                  tableFieldData.cellInfo[0][i][0].colHeading = tableFieldData.cellInfo[0][i][0].label
                  cellInfoData.push(tableFieldData.cellInfo[0][i][0])
                }
              }
              this.setSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex,cellInfoData);
              let tableColumnData = this.getSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex)?.value.find((data:any) => data.tableColumnIndex == multiRowId)
              if(tableFieldData && tableFieldData.cellInfo){
                for (let i = 1; i < tableFieldData.cellInfo[0].length; i++) {
                  
                  if(tableFieldData.cellInfo[0][i][0].tableColumnIndex == tableColumnData.tableColumnIndex){
                    tableDataTypeId = tableFieldData.cellInfo[1][i][0].dataTypeId
                    tableData = tableFieldData.cellInfo[1][i][0]
                  }
                }
              }
  
              this.setSelectedKeyValue(this.formValidation,"condition","multiRowDataTypeId",index,subIndex,Number(tableDataTypeId));
          
              if([6,7,8].includes(tableDataTypeId)){
                this.setSelectedKeyValue(this.formValidation,"condition",'uiOptionSelect',index,subIndex,tableData.options);
              }
              operatorDataTypeId = tableDataTypeId
  
            }else if([6,7,8].includes(dataTypeId)){
              this.setSelectedKeyValue(this.formValidation,"condition","uiOptionSelect",index,subIndex, fields.options);
            }
            
            if(destination != undefined){
              
              this.setSelectedKeyValue(this.formValidation,"condition","isConstant",index,subIndex,2)
              if(this.validationRuleId){
                this.IsConstantSelected(this.formValidation,"condition",2,index,subIndex,true);
              }else{
                this.IsConstantSelected(this.formValidation,"condition",2,index,subIndex,true);

              }
    
              if(response[index].condition[subIndex].dest['visitId'] == this.defaultUuid){
                (((((this.formValidation.get("rule") as FormArray)
                .get(index.toString()) as FormGroup)
                .get("condition") as FormArray)
                .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.setValue(1)
              }else{
                (((((this.formValidation.get("rule") as FormArray)
                .get(index.toString()) as FormGroup)
                .get("condition") as FormArray)
                .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.setValue(0)
              }
  
              this.onDestValidationFormTypeChange((((((this.formValidation.get("rule") as FormArray)
              .get(index.toString()) as FormGroup)
              .get("condition") as FormArray)
              .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.value,
              this.formValidation,"condition","validationFormType",index,subIndex,true,destination)
      
            }else{
              this.setSelectedKeyValue(this.formValidation,"condition","isConstant",index,subIndex,1)
              if(this.validationRuleId){
                this.IsConstantSelected(this.formValidation,"condition",1,index,subIndex,true)
              }else{
                this.IsConstantSelected(this.formValidation,"condition",1,index,subIndex,true)
              }
      
            }
            this.onValidationOperator(operatorDataTypeId,index,subIndex,this.formValidation)
            // let option = fields.options
            // this.setSelectedKeyValue(this.formValidation,"condition","uiOptionSelect",index,subIndex,option);
          }else{
            console.log("MAIN CALL");
            
            this.setSelectedKeyValue(this.formValidation,"condition","validationFormType",index,subIndex,0);
  
            this.onValidationFormTypeChange(this.getSelectedKeyValue(this.formValidation,"condition","validationFormType",index,subIndex)?.value,
            this.formValidation,index,subIndex,true);
            
            // let formData:any = this.visitAllData.find((findId:any)=>findId.visitId === response[index].condition[subIndex].visitId).forms
            // this.setSelectedKeyValue(this.formValidation,"condition","uiFormSelect",index,subIndex,formData)
            const forms = this.visitFormMainData['visit'][response[index].condition[subIndex].visitId].forms
            this.setSelectedKeyValue(this.formValidation,"condition","uiFormSelect",index,subIndex,this.extractForms(forms))

            console.log("MAIN :: ",this.formValidation);
            
            // let fieldData = this.getSelectedKeyValue(this.formValidation,"condition","uiFormSelect",index,subIndex)?.value.find((form:any)=>form.id == formId).fields
            // this.setSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex,fieldData);
            
            
            let fields:any
            this.setSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex,cloneArray(res.responseObject));
            fields = this.getSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex)?.value.find((data:any)=> data.id == fieldId)
            if(dataTypeId == 17){
              this.setSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex, fields.columnData);
              
              let optionId = [6, 7, 8].includes(multiRowDataTypeId) ? response[index].condition[subIndex].optionId : 0;
              var options = this.getSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex)?.value.find((data:any)=> data.id == multiRowId).options;
    
              this.setSelectedKeyValue(this.formValidation,"condition","uiOptionSelect",index,subIndex, options);
              
            }else if(dataTypeId == 18){
              let tableDataTypeId:any
              let tableData:any
              let tableFieldData = this.getSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex)?.value.find((cntry: any) => cntry.id == fieldId)
              let cellInfoData:any[] = []
              if(tableFieldData && tableFieldData.cellInfo){
                for (let i = 1; i < tableFieldData.cellInfo[0].length; i++) {
                  tableFieldData.cellInfo[0][i][0].tableColumnIndex = i
                  tableFieldData.cellInfo[0][i][0].colHeading = tableFieldData.cellInfo[0][i][0].label
                  cellInfoData.push(tableFieldData.cellInfo[0][i][0])
                }
              }
              this.setSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex,cellInfoData);
              let tableColumnData = this.getSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex)?.value.find((data:any) => data.tableColumnIndex == multiRowId)
              if(tableFieldData && tableFieldData.cellInfo){
                for (let i = 1; i < tableFieldData.cellInfo[0].length; i++) {
                  
                  if(tableFieldData.cellInfo[0][i][0].tableColumnIndex == tableColumnData.tableColumnIndex){
                    tableDataTypeId = tableFieldData.cellInfo[1][i][0].dataTypeId
                    tableData = tableFieldData.cellInfo[1][i][0]
                  }
                }
              }
              
              this.setSelectedKeyValue(this.formValidation,"condition","multiRowDataTypeId",index,subIndex,Number(tableDataTypeId));
          
              if([6,7,8].includes(tableDataTypeId)){
                this.setSelectedKeyValue(this.formValidation,"condition",'uiOptionSelect',index,subIndex,tableData.options);
              }
              operatorDataTypeId = tableDataTypeId
            }else if([6,7,8].includes(dataTypeId)){
              this.setSelectedKeyValue(this.formValidation,"condition","uiOptionSelect",index,subIndex, fields.options);
            }
            
            if(destination != undefined){
              
              this.setSelectedKeyValue(this.formValidation,"condition","isConstant",index,subIndex,2)
              
              if(this.validationRuleId){
                this.IsConstantSelected(this.formValidation,"condition",2,index,subIndex,true);
              }else{
                this.IsConstantSelected(this.formValidation,"condition",2,index,subIndex,true);
              }
    
              if(response[index].condition[subIndex].dest['visitId'] == this.defaultUuid){
                (((((this.formValidation.get("rule") as FormArray)
                .get(index.toString()) as FormGroup)
                .get("condition") as FormArray)
                .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.setValue(1)
              }else{
                (((((this.formValidation.get("rule") as FormArray)
                .get(index.toString()) as FormGroup)
                .get("condition") as FormArray)
                .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.setValue(0)
              }
              
              this.onDestValidationFormTypeChange((((((this.formValidation.get("rule") as FormArray)
              .get(index.toString()) as FormGroup)
              .get("condition") as FormArray)
              .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.value,
              this.formValidation,"condition","validationFormType",index,subIndex,true,response[index].condition[subIndex].dest)
      
            }else{
              this.setSelectedKeyValue(this.formValidation,"condition","isConstant",index,subIndex,1)
              if(this.validationRuleId){
                this.IsConstantSelected(this.formValidation,"condition",1,index,subIndex,true)
              }else{
                this.IsConstantSelected(this.formValidation,"condition",1,index,subIndex,true)
              }
      
            }
            this.onValidationOperator(operatorDataTypeId,index,subIndex,this.formValidation)
            
            // let option = fields.options
            // this.setSelectedKeyValue(this.formValidation,"condition","uiOptionSelect",index,subIndex,option);
            
          }
  
        }else{
          if(response[index].condition[subIndex].visitId == this.defaultUuid){
            this.setSelectedKeyValue(this.formValidation,"condition","validationFormType",index,subIndex,1)
            this.setSelectedKeyValue(this.formValidation,"condition","uiFormSelect",index,subIndex,this.logFormFieldSelect[0].forms)
            this.setSelectedKeyValue(this.formValidation,"condition","formId",index,subIndex,formId)
          
            let fieldData = this.getSelectedKeyValue(this.formValidation,"condition","uiFormSelect",index,subIndex)?.value.find((form:any)=>form.id == formId).fields
            this.setSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex,fieldData);
            
            let fields = this.getSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex)?.value.find((data:any)=> data.id == fieldId)
            
            if(dataTypeId == 17){
              this.setSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex, fields.columnData);
    
              let optionId = [6, 7, 8].includes(multiRowDataTypeId) ? response[index].condition[subIndex].optionId : 0;
              var options = this.getSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex)?.value.find((data:any)=> data.id == multiRowId).options;
    
              this.setSelectedKeyValue(this.formValidation,"condition","uiOptionSelect",index,subIndex, options);
            }else if(dataTypeId == 18){
              let tableDataTypeId:any
              let tableData:any
              let tableFieldData = this.getSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex)?.value.find((cntry: any) => cntry.id == this.getSelectedKeyValue(this.formValidation,"condition","fieldId",index,subIndex)?.value)
              let cellInfoData:any[] = []
              if(tableFieldData && tableFieldData.cellInfo){
                for (let i = 1; i < tableFieldData.cellInfo[0].length; i++) {
                  tableFieldData.cellInfo[0][i][0].tableColumnIndex = i
                  tableFieldData.cellInfo[0][i][0].colHeading = tableFieldData.cellInfo[0][i][0].label
                  cellInfoData.push(tableFieldData.cellInfo[0][i][0])
                }
              }
              this.setSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex,cellInfoData);
              let tableColumnData = this.getSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex)?.value.find((data:any) => data.tableColumnIndex == multiRowId)
              if(tableFieldData && tableFieldData.cellInfo){
                for (let i = 1; i < tableFieldData.cellInfo[0].length; i++) {
                  
                  if(tableFieldData.cellInfo[0][i][0].tableColumnIndex == tableColumnData.tableColumnIndex){
                    tableDataTypeId = tableFieldData.cellInfo[1][i][0].dataTypeId
                    tableData = tableFieldData.cellInfo[1][i][0]
                  }
                }
              }
  
              this.setSelectedKeyValue(this.formValidation,"condition","multiRowDataTypeId",index,subIndex,Number(tableDataTypeId));
          
              if([6,7,8].includes(tableDataTypeId)){
                this.setSelectedKeyValue(this.formValidation,"condition",'uiOptionSelect',index,subIndex,tableData.options);
              }
              operatorDataTypeId = tableDataTypeId
            }
            this.onValidationOperator(operatorDataTypeId,index,subIndex,this.formValidation);
            
            let option = fields.options
            this.setSelectedKeyValue(this.formValidation,"condition","uiOptionSelect",index,subIndex,option);
            
            if(destination != undefined){
              
              this.setSelectedKeyValue(this.formValidation,"condition","isConstant",index,subIndex,2)
              
              if(this.validationRuleId){
                this.IsConstantSelected(this.formValidation,"condition",2,index,subIndex,true);

              }else{
                this.IsConstantSelected(this.formValidation,"condition",2,index,subIndex,true);
              }
    
              if(response[index].condition[subIndex].dest['visitId'] == this.defaultUuid){
                (((((this.formValidation.get("rule") as FormArray)
                .get(index.toString()) as FormGroup)
                .get("condition") as FormArray)
                .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.setValue(1)
              }else{
                (((((this.formValidation.get("rule") as FormArray)
                .get(index.toString()) as FormGroup)
                .get("condition") as FormArray)
                .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.setValue(0)
              }
              console.log("destination :: ",destination);
              
              this.onDestValidationFormTypeChange((((((this.formValidation.get("rule") as FormArray)
              .get(index.toString()) as FormGroup)
              .get("condition") as FormArray)
              .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.value,
              this.formValidation,"condition","validationFormType",index,subIndex,true,destination)
      
            }else{
              this.setSelectedKeyValue(this.formValidation,"condition","isConstant",index,subIndex,1)
              if(this.validationRuleId){
                this.IsConstantSelected(this.formValidation,"condition",1,index,subIndex,true)
              }else{
                this.IsConstantSelected(this.formValidation,"condition",1,index,subIndex,true)
              }
      
            }
          }else{
            console.log(this.formValidation);
            
            this.setSelectedKeyValue(this.formValidation,"condition","validationFormType",index,subIndex,0);
            this.onValidationFormTypeChange(this.getSelectedKeyValue(this.formValidation,"condition","validationFormType",index,subIndex)?.value,
            this.formValidation,index,subIndex,true);
            // let formData:any = this.visitAllData.find((findId:any)=>findId.id === response[index].condition[subIndex].visitId).forms
            // this.setSelectedKeyValue(this.formValidation,"condition","uiFormSelect",index,subIndex,formData)
            
            // let fieldData = this.getSelectedKeyValue(this.formValidation,"condition","uiFormSelect",index,subIndex)?.value.find((form:any)=>form.id == formId).fields
            // this.setSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex,cloneArray(res.responseObject));
            const forms = this.visitFormMainData['visit'][response[index].condition[subIndex].visitId].forms
            this.setSelectedKeyValue(this.formValidation,"condition","uiFormSelect",index,subIndex,this.extractForms(forms))
            let fields:any
            this.setSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex,cloneArray(res.responseObject));
            fields = this.getSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex)?.value.find((data:any)=> data.id == fieldId)

            if(dataTypeId == 17){
              this.setSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex, fields.columnData);
    
              let optionId = [6, 7, 8].includes(multiRowDataTypeId) ? response[index].condition[subIndex].optionId : 0;
              var options = this.getSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex)?.value.find((data:any)=> data.id == multiRowId).options;
    
              this.setSelectedKeyValue(this.formValidation,"condition","uiOptionSelect",index,subIndex, options);
            }else if(dataTypeId == 18){
              let tableDataTypeId:any
              let tableData:any
              let tableFieldData = this.getSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex)?.value.find((cntry: any) => cntry.id == this.getSelectedKeyValue(this.formValidation,"condition","fieldId",index,subIndex)?.value)
              let cellInfoData:any[] = []
              if(tableFieldData && tableFieldData.cellInfo){
                for (let i = 1; i < tableFieldData.cellInfo[0].length; i++) {
                  tableFieldData.cellInfo[0][i][0].tableColumnIndex = i
                  tableFieldData.cellInfo[0][i][0].colHeading = tableFieldData.cellInfo[0][i][0].label
                  cellInfoData.push(tableFieldData.cellInfo[0][i][0])
                }
              }
              
              this.setSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex,cellInfoData);
              let tableColumnData = this.getSelectedKeyValue(this.formValidation,"condition","uiColumnSelect",index,subIndex)?.value.find((data:any) => data.tableColumnIndex == multiRowId)
              
              if(tableFieldData && tableFieldData.cellInfo){
                for (let i = 1; i < tableFieldData.cellInfo[0].length; i++) {
                  
                  if(tableFieldData.cellInfo[0][i][0].tableColumnIndex == tableColumnData.tableColumnIndex){
                    tableDataTypeId = tableFieldData.cellInfo[1][i][0].dataTypeId
                    tableData = tableFieldData.cellInfo[1][i][0]
                  }
                }
              }
              this.setSelectedKeyValue(this.formValidation,"condition","multiRowDataTypeId",index,subIndex,Number(tableDataTypeId));
          
              if([6,7,8].includes(tableDataTypeId)){
                this.setSelectedKeyValue(this.formValidation,"condition",'uiOptionSelect',index,subIndex,tableData.options);
              }
              operatorDataTypeId = tableDataTypeId
            }else if([6,7,8].includes(dataTypeId)){
              fields = this.getSelectedKeyValue(this.formValidation,"condition","uiFieldSelect",index,subIndex)?.value.find((data:any)=> data.id == fieldId)
              this.setSelectedKeyValue(this.formValidation,"condition","uiOptionSelect",index,subIndex, fields.options);
            }
            
            if(destination != undefined){
              
              this.setSelectedKeyValue(this.formValidation,"condition","isConstant",index,subIndex,2)
              if(this.validationRuleId){
                this.IsConstantSelected(this.formValidation,"condition",2,index,subIndex,true);
              }else{
                this.IsConstantSelected(this.formValidation,"condition",2,index,subIndex,true);
              }
    
              if(response[index].condition[subIndex].dest['visitId'] == this.defaultUuid){
                (((((this.formValidation.get("rule") as FormArray)
                .get(index.toString()) as FormGroup)
                .get("condition") as FormArray)
                .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.setValue(1)
              }else{
                (((((this.formValidation.get("rule") as FormArray)
                .get(index.toString()) as FormGroup)
                .get("condition") as FormArray)
                .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.setValue(0)
              }
              
              this.onDestValidationFormTypeChange((((((this.formValidation.get("rule") as FormArray)
              .get(index.toString()) as FormGroup)
              .get("condition") as FormArray)
              .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.value,
              this.formValidation,"condition","validationFormType",index,subIndex,true,destination)
      
            }else{
              this.setSelectedKeyValue(this.formValidation,"condition","isConstant",index,subIndex,1)
              if(this.validationRuleId){
                this.IsConstantSelected(this.formValidation,"condition",1,index,subIndex,true)
              }else{
                this.IsConstantSelected(this.formValidation,"condition",1,index,subIndex,true)
              }
      
            }
            this.onValidationOperator(operatorDataTypeId,index,subIndex,this.formValidation)
            // let option = fields.options
            // this.setSelectedKeyValue(this.formValidation,"condition","uiOptionSelect",index,subIndex,option);
          }
        }
        
        ((((this.formValidation.get("rule") as FormArray)
        .get(index.toString()) as FormGroup)
        .get("condition") as FormArray)
        .get(subIndex.toString()) as FormGroup)
        .patchValue(response[index].condition[subIndex]);

        if(jsonFieldValue){
          let dataTypeId = ((((this.formValidation.get("rule") as FormArray)
          .get(index.toString()) as FormGroup)
          .get("condition") as FormArray)
          .get(subIndex.toString()) as FormGroup)
          .get("dataTypeId")?.value;
          if(dataTypeId == 6 || dataTypeId == 7 || dataTypeId == 8){
            Object.keys(jsonFieldValue).filter((data:any)=>{
              this.setSelectedKeyValue(this.formValidation,"condition","jsonFieldValue",index,subIndex,data)
            });
  
          }
          else if(dataTypeId == 17){
            if([6, 7, 8].includes(multiRowDataTypeId))
              this.setSelectedKeyValue(this.formValidation,"condition","jsonFieldValue",index,subIndex,optionId)
            else if(multiRowDataTypeId == 12 || multiRowDataTypeId == 13)
            this.setSelectedKeyValueFormatting(this.formValidation.value,"condition","jsonFieldValue",index,subIndex, jsonFieldValue)
          }
        }
  
        // if(destination != undefined){
        //   if(this.formValidation.get("formType")?.value == 0){
        //     if((((((this.formValidation.get("rule") as FormArray)
        //     .get(index.toString()) as FormGroup)
        //     .get("condition") as FormArray)
        //     .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.value == 0){
        //       this.onDestVisitSelect(this.formValidation,"condition","dest",response[index].condition[subIndex].dest['visitId'],index,subIndex)
        //       this.onDestFormSelect(this.formValidation,"condition","dest",response[index].condition[subIndex].dest['formId'],index,subIndex)
        //       this.onDestFieldSelect(this.formValidation,"condition","dest",response[index].condition[subIndex].dest['fieldId'],index,subIndex)
        //     }else{
        //       this.onDestFormSelect(this.formValidation,"condition","dest",response[index].condition[subIndex].dest['formId'],index,subIndex)
        //       this.onDestFieldSelect(this.formValidation,"condition","dest",response[index].condition[subIndex].dest['fieldId'],index,subIndex)
        //     }
        //   }else{
        //     if((((((this.formValidation.get("rule") as FormArray)
        //     .get(index.toString()) as FormGroup)
        //     .get("condition") as FormArray)
        //     .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.value == 0){
        //       this.onDestVisitSelect(this.formValidation,"condition","dest",response[index].condition[subIndex].dest['visitId'],index,subIndex)
        //       this.onDestFormSelect(this.formValidation,"condition","dest",response[index].condition[subIndex].dest['formId'],index,subIndex)
        //       this.onDestFieldSelect(this.formValidation,"condition","dest",response[index].condition[subIndex].dest['fieldId'],index,subIndex)
        //     }
        //   }
  
        //   if(response[index].condition[subIndex].dest['multiRowId'])
        //   if((((((this.formValidation.get("rule") as FormArray)
        //     .get(index.toString()) as FormGroup)
        //     .get("condition") as FormArray)
        //     .get(subIndex.toString()) as FormGroup).get("dest") as FormGroup).get("validationFormType")?.value == 0){
        //       this.onDestColumnSelect(this.formValidation,"condition","dest",response[index].condition[subIndex].dest['multiRowId'],index,subIndex)
  
        //     }
  
        // }
      }
  
      if(subIndex < response[index].condition.length-1){
        if(response[index].action == 4){
          this.addNewCondition(this.formLogMapping,index,subIndex)
          
        }else{
          this.addNewCondition(this.formValidation,index,subIndex)
  
        }
        // if(response[index].action != 4){
        // }else{
        //   this.addNewLogMappingCondition(index,subIndex)
        // }
      }
    });

    
  }
  
  patchValidationImpactValue(response:any, index:any, impactIndex:any){
    let visit = response[index].impact[impactIndex].visit
    let form = response[index].impact[impactIndex].form
    let fields = response[index].impact[impactIndex].fields
    response[index].impact[impactIndex].multiRowIds = response[index].impact[impactIndex].multiRowIds == '' ? [] : response[index].impact[impactIndex].multiRowIds; 
    let multiRowIds = response[index].impact[impactIndex].multiRowIds
    let id = response[index].impact[impactIndex].id

    if(response[index].action == 3){
      this.OnActionSelect(response[index].action,index)
    }
    
    if(response[index].action == 4){
      this.setSelectedKeyValue(this.formLogMapping,"impact","id",index,impactIndex,id);
      this.setSelectedKeyValue(this.formLogMapping,"impact","ruleId",index,impactIndex,id);
      
      if(response[index].impact.length > 0){
        ((((this.formLogMapping.get("rule") as FormArray)
        .get(index.toString()) as FormGroup)
        .get("impact") as FormArray)
        .get(impactIndex.toString()) as FormGroup)
        .patchValue(response[index].impact[impactIndex]);
      }

    }else{
      if(impactIndex == 0){
        this.setSelectedKeyValue(this.formValidation,"impact","ruleId",index,impactIndex,id);
        this.setSelectedKeyValue(this.formValidation,"impact","id",index,impactIndex,id);
        this.fieldAPI.getFieldsByForm(form).subscribe(res => {
          if(this.formValidation.get("formType")?.value == 0){
            
            if(visit == this.defaultUuid){
              this.setSelectedKeyValue(this.formValidation,"impact","validationFormType",index,impactIndex,1)
              console.log("No.3");
              this.onImpactValidationFormTypeChange(this.formValidation,"impact","validationFormType",this.getSelectedKeyValue(this.formValidation,"impact","validationFormType",index,impactIndex)?.value,index,impactIndex,true,response[index].impact)
              this.setSelectedKeyValue(this.formValidation,"impact","uiImpactFormSelect",index,impactIndex,this.logFormFieldSelect[0].forms)
              
              let fieldData = this.getSelectedKeyValue(this.formValidation,"impact","uiImpactFormSelect",index,impactIndex)?.value.find((data:any)=>data.id == form).fields
              this.setSelectedKeyValue(this.formValidation,"impact","uiImpactFieldSelect",index,impactIndex,fieldData);
            }else{
              this.setSelectedKeyValue(this.formValidation,"impact","validationFormType",index,impactIndex,0)
              console.log("No.4");
              this.onImpactValidationFormTypeChange(this.formValidation,"impact","validationFormType",this.getSelectedKeyValue(this.formValidation,"impact","validationFormType",index,impactIndex)?.value,index,impactIndex,true,response[index].impact)
              const forms = this.visitFormMainData['visit'][this.visitId].forms
              this.setSelectedKeyValue(this.formValidation,"impact","uiImpactFormSelect",index,impactIndex,this.extractForms(forms))
              // this.setSelectedKeyValue(this.formValidation,"impact","uiImpactFormSelect",index,impactIndex,formData)
              this.setSelectedKeyValue(this.formValidation,"impact","uiImpactFieldSelect",index,impactIndex,res.responseObject);
  
            }
    
          }else{
            if(visit == this.defaultUuid){
              this.setSelectedKeyValue(this.formValidation,"impact","validationFormType",index,impactIndex,1)
              console.log("No.5");
              this.onImpactValidationFormTypeChange(this.formValidation,"impact","validationFormType",this.getSelectedKeyValue(this.formValidation,"impact","validationFormType",index,impactIndex)?.value,index,impactIndex,true,response[index].impact)
              this.setSelectedKeyValue(this.formValidation,"impact","uiImpactFormSelect",index,impactIndex,this.logFormFieldSelect[0].forms)
              
              let fieldData = this.getSelectedKeyValue(this.formValidation,"impact","uiImpactFormSelect",index,impactIndex)?.value.find((data:any)=>data.id == form).fields
              this.setSelectedKeyValue(this.formValidation,"impact","uiImpactFieldSelect",index,impactIndex,fieldData);
            }else{
              this.setSelectedKeyValue(this.formValidation,"impact","validationFormType",index,impactIndex,0)
              console.log("No.6");
              
              this.onImpactValidationFormTypeChange(this.formValidation,"impact","validationFormType",this.getSelectedKeyValue(this.formValidation,"impact","validationFormType",index,impactIndex)?.value,index,impactIndex,true,response[index].impact)
              // let formData:any = this.visitAllData.find((findId:any)=>findId.visitId == visit).forms
              
              // this.setSelectedKeyValue(this.formValidation,"impact","uiImpactFormSelect",index,impactIndex,formData)
              
              // let fieldData = this.getSelectedKeyValue(this.formValidation,"impact","uiImpactFormSelect",index,impactIndex)?.value.find((data:any)=>data.id == form).fields
              // this.setSelectedKeyValue(this.formValidation,"impact","uiImpactFieldSelect",index,impactIndex,fieldData);
  
            }
          }
        
          if(response[index].impact.length > 0){
            ((((this.formValidation.get("rule") as FormArray)
            .get(index.toString()) as FormGroup)
            .get("impact") as FormArray)
            .get(impactIndex.toString()) as FormGroup)
            .patchValue(response[index].impact[impactIndex]);
          }
          
          if(fields && fields.length > 0){
            let selectFieldArray:any[] = []
            let selectedMultiRowIdArray:any[] = []
            for (let selectField = 0; selectField < fields.length; selectField++) {
              let selectedFields = this.getSelectedKeyValue(this.formValidation,"impact","uiImpactFieldSelect",index,impactIndex)?.value.find((data:any)=> data.id == fields[selectField])
              selectFieldArray.push(selectedFields)
              let dataTypeId = this.getSelectedKeyValue(this.formValidation,"impact","uiImpactFieldSelect",index,impactIndex)?.value.find((data:any)=> data.id == fields[selectField]).dataTypeId
              this.setSelectedKeyValue(this.formValidation,"impact","dataTypeId",index,impactIndex,dataTypeId);
              this.globalFieldDataTypeId = dataTypeId
              if(dataTypeId == 17){
                let columnData = this.getSelectedKeyValue(this.formValidation,"impact","uiImpactFieldSelect",index,impactIndex)?.value.find((data:any)=>data.id == fields[selectField]).columnData
                this.setSelectedKeyValue(this.formValidation,"impact","uiImpactColumnSelect",index,impactIndex,columnData);
              }else if(dataTypeId == 18){
                let cellInfoData = this.getSelectedKeyValue(this.formValidation,"impact","uiImpactFieldSelect",index,impactIndex)?.value.find((data:any) => data.id == fields[selectField]).cellInfo
                
                if(cellInfoData && cellInfoData.length > 0){
                  let cellInfoArray:any[] = []
                  if(cellInfoData){
                    for (let i = 1; i < cellInfoData[0].length; i++) {
                      cellInfoData[0][i][0].tableColumnIndex = i
                      cellInfoData[0][i][0].colHeading = cellInfoData[0][i][0].label
                      cellInfoArray.push(cellInfoData[0][i][0])
                    }
                  }
                  this.setSelectedKeyValue(this.formValidation,"impact","uiImpactColumnSelect",index,impactIndex,cellInfoArray);
                }
              }
            }
            
            for(let multiRowId = 0; multiRowId < multiRowIds.length; multiRowId++){
              if(this.dataTypeId == componentTypeMap[16].id){
                let selectedmultiRowIds = this.getSelectedKeyValue(this.formValidation,"impact","uiImpactColumnSelect",index,impactIndex)?.value.find((data:any)=> data.id == multiRowIds[multiRowId])
                selectedMultiRowIdArray.push(selectedmultiRowIds);
              }else if(this.dataTypeId == componentTypeMap[17].id){
                let selectedmultiRowIds = this.getSelectedKeyValue(this.formValidation,"impact","uiImpactColumnSelect",index,impactIndex)?.value.find((data:any)=> data.tableColumnIndex == multiRowIds[multiRowId])
                selectedMultiRowIdArray.push(selectedmultiRowIds);
              }
            }
            this.getSelectedKeyValue(this.formValidation,"impact","fields",index,impactIndex)?.reset()
            this.setSelectedKeyValue(this.formValidation,"impact","fields",index,impactIndex,selectFieldArray)
    
            this.getSelectedKeyValue(this.formValidation,"impact","multiRowIds",index,impactIndex)?.reset()
            this.setSelectedKeyValue(this.formValidation,"impact","multiRowIds",index,impactIndex,selectedMultiRowIdArray)
          }
        })
      }
    }

    if(impactIndex == 0){
      if(response[index].action == 4){
        this.addNewImpact(this.formLogMapping,index,impactIndex)
        
      }else{
        this.addNewImpact(this.formValidation,index,impactIndex)

      }
    }

    // if(this.globalFieldDataTypeId == 17 && impactIndex > 0){
    //   this.removeImpact(this.formValidation,index, impactIndex)
    // }
    
  }

  getValidationById(){
    this.validationAPI.getValidationById(this.validationRuleId).subscribe(res=>{
      if(res.status == 0){
        this.saveUpdateFlag = true
        let response:any = res.responseObject
        let validationData:any[] = []
        let validationIndex:any = 0
        let logMappingData:any[] = []
        let logMappingIndex:any = 0
        for (let index = 0; index < response.length; index++) {
          
          if(response[index].action == 4){
            logMappingData.push(response[index])
            
            this.getValidationDataByArray(logMappingData,logMappingIndex)
            logMappingIndex++
            
          }else{
            validationData.push(response[index])
            
            this.getValidationDataByArray(validationData,validationIndex)
            validationIndex++
            
          }
        }
      }else{
        this.saveUpdateFlag = false
      }
      
    })
  }

  getValidation(visitId:any,formId:any,fieldId:any){
    this.validationAPI.getValidation(visitId,formId,fieldId).subscribe(res=>{
      if(res.status == 0){
        this.saveUpdateFlag = true
        let response:any = res.responseObject
        let validationData:any[] = []
        let validationIndex:any = 0
        let logMappingData:any[] = []
        let logMappingIndex:any = 0
        for (let index = 0; index < response.length; index++) {
          
          if(response[index].action == 4){
            logMappingData.push(response[index])
            
            
            this.getValidationDataByArray(logMappingData,logMappingIndex)
            logMappingIndex++
            
          }else{
            validationData.push(response[index])
            let validationLength = validationData.length - 1
            
            this.getValidationDataByArray(validationData,validationIndex)
            validationIndex++
            
          }
        }
      }else{
        this.saveUpdateFlag = false
      }
      
    })
  }

  getValidationDataByArray(data:any[],length:any){
    
    this.patchValidationRuleValue(data,length)
    for (let condition = 0; condition < data[length].condition.length; condition++) {
      this.patchValidationConditionValue(data,length,condition)      
    }
    
    if(this.dataTypeId == 17 && data[length].impact.length > 0){
      data[length].impact[0].multiRowIds = data[length].impact.map((data:any) => data.multiRowIds[0]);
    }else{
      data[length].impact[0].fields = data[length].impact.map((data:any) => data.fields[0]);
    }

    if(data[length].impact.length > 0){
      for (let impact = 0; impact < data[length].impact.length; impact++) {
        // if(impact > 0){
        //   delete this.formValidation.value.rule[length].impact[impact]
        // }
        // else
          this.patchValidationImpactValue(data,length,impact);        
      }
    }
  }
  
  getOptionSelectedValue(data:any,index:any,conditionIndex:any,jsonFieldId:any){

    let value = data.rule[index].condition[conditionIndex].uiOptionSelect
    // this.getSelectedKeyValue("condition","uiOptionSelect",index,conditionIndex)?.value
    
    if(value){
      for (let val = 0; val < value.length; val++) {
        if(jsonFieldId == value[val].id){
          
          return value[val].name
        }
      }
    }
  }

  setConditionFieldIds(data:any){
    let emptyJson:any = {}
    if(data.rule){
      for (let index = 0; index < data.rule.length; index++) {
        if(data.rule[index].condition){
          for (let conditionIndex = 0; conditionIndex < data.rule[index].condition.length; conditionIndex++) {
            let prepareJson:any = {};
            let id = data.rule[index].condition[conditionIndex].id;
            let visitId = data.rule[index].condition[conditionIndex].visitId
            let dest = data.rule[index].condition[conditionIndex].dest;
            let dataTypeId = data.rule[index].condition[conditionIndex].dataTypeId == 17 ? data.rule[index].condition[conditionIndex].multiRowDataTypeId : data.rule[index].condition[conditionIndex].dataTypeId;
            let jsonFieldData = data.rule[index].condition[conditionIndex].jsonFieldValue
            if(typeof(jsonFieldData) == OBJECT){
              this.setSelectedKeyValueFormatting(data,"condition","optionId",index,conditionIndex,null)
            }else{
              if(this.dataTypeId == componentTypeMap[17].id){
                this.setSelectedKeyValueFormatting(data,"condition","optionId",index,conditionIndex,null)
              }else{
                this.setSelectedKeyValueFormatting(data,"condition","optionId",index,conditionIndex,jsonFieldData)

              }
            }
            if(!id && visitId && visitId.length > 0 && typeof(visitId) == 'object'){
              data.rule[index]["condition"][conditionIndex]["visitId"] = visitId[0];
            }
            
            let jsonFieldValueData = this.getOptionSelectedValue(data,index,conditionIndex,jsonFieldData)
            if(dataTypeId == 6 || dataTypeId == 7){
              prepareJson[jsonFieldData] = true
              this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",index,conditionIndex,prepareJson)
            }
            else if(dataTypeId == 8){
              prepareJson[jsonFieldData] = jsonFieldValueData
              this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",index,conditionIndex,prepareJson)
            }
            else if(dataTypeId == 12 || dataTypeId == 13){
              this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",index,conditionIndex,data.rule[index].condition[conditionIndex].jsonFieldValue)
            }
            // else if(dataTypeId == 17){
            //   let multiRowDataTypeId = data.rule[index].condition[conditionIndex].multiRowDataTypeId;
            //   if(dataTypeId == 6 || dataTypeId == 7){
            //     prepareJson[jsonFieldData] = true
            //     this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",index,conditionIndex,prepareJson)
            //   }
            //   if(multiRowDataTypeId == 8){
            //     prepareJson[jsonFieldData] = jsonFieldValueData
            //     this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",index,conditionIndex,prepareJson)
            //   }
            //   else if(multiRowDataTypeId == 12 || multiRowDataTypeId == 13){
            //     this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",index,conditionIndex,data.rule[index].condition[conditionIndex].jsonFieldValue)
            //   }
            //   else{
            //     this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",index,conditionIndex,emptyJson)
            //   }
            // }
            else if(dataTypeId == 18){
              this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",index,conditionIndex,data.rule[index].condition[conditionIndex].jsonFieldValue)
              
            }else{
              this.setSelectedKeyValueFormatting(data,"condition","jsonFieldValue",index,conditionIndex,emptyJson)

            }
            
            if(dest){
              let visitId = dest.visitId;
              
              if(typeof(visitId) == 'object')
                data.rule[index]["condition"][conditionIndex]["dest"]["visitId"] = visitId[0];
            }
          }
        }
        if(data.rule[index].impact){
          for (let impactIndex = 0; impactIndex < data.rule[index].impact.length; impactIndex++) {

            let id = data.rule[index].impact[impactIndex].id;
            data.rule[index].impact[impactIndex].form = Number(data.rule[index].impact[impactIndex].form)
            data.rule[index].impact[impactIndex].multiRowIds = data.rule[index].impact[impactIndex].multiRowIds == '' ? [] : data.rule[index].impact[impactIndex].multiRowIds;
            let visit = data.rule[index].impact[impactIndex].visit;
            let fields = data.rule[index].impact[impactIndex].fields;
            let multiRowIds:any

            if(fields){
              let fieldData = fields.map((item:any) => { if(item != undefined){ return Number(item.id)}else{return}})
              if(fieldData != undefined){
                this.setSelectedKeyValueFormatting(data,"impact","fields",index,impactIndex,fieldData)
              }
            }
            if(this.dataTypeId == componentTypeMap[16].id){
              multiRowIds = data.rule[index].impact[impactIndex].multiRowIds;
              if(multiRowIds){
                let columnData = multiRowIds.map((item:any) => { if(item != undefined){ return Number(item.id)}else{return}})
                if(columnData != undefined){
                  // data.rule[index]["impact"][impactIndex]["multiRowIds"] = columnData.map((s:any) => s.toString());
                  this.setSelectedKeyValueFormatting(data,"impact","multiRowIds",index,impactIndex,columnData)
                }
              }
            }else if(this.dataTypeId == componentTypeMap[17].id){
              multiRowIds = data.rule[index].impact[impactIndex].multiRowIds;
              if(multiRowIds){
                let columnData = multiRowIds.map((item:any) => { if(item != undefined){ return Number(item.tableColumnIndex)}else{return}})
                if(columnData != undefined){
                  // data.rule[index]["impact"][impactIndex]["multiRowIds"] = columnData.map((s:any) => s.toString());
                  this.setSelectedKeyValueFormatting(data,"impact","multiRowIds",index,impactIndex,columnData)
                }
              }
            }

            if(!id && visit && visit.length > 0 && typeof(visit) == 'object'){
              data.rule[index]["impact"][impactIndex]["visit"] = visit[0];
            }
          }
        }
      }
    }
  }

  //HTML All Field Validation
  validateValidationData(){
    let formType = this.formValidation.value.formType
    for (let index = 0; index < this.formValidation.value.rule.length; index++) {
      
      if(this.formValidation.value.rule[index].condition.length >= 1){
        
        for (let conditionIndex = 0; conditionIndex < this.formValidation.value.rule[index].condition.length; conditionIndex++) {

          if(formType == 0){
            if(this.formValidation.value.rule[index].condition[conditionIndex].visitId == '' || this.formValidation.value.rule[index].condition[conditionIndex].visitId == null){
              this.toastr.warning(VALIDATION_VISIT_REQUIRED_MSG)
              return false
            }

          }
          if(this.formValidation.value.rule[index].condition[conditionIndex].formId == '' || this.formValidation.value.rule[index].condition[conditionIndex].formId == null){
            this.toastr.warning(VALIDATION_FORM_REQUIRED_MSG)
            return false

          }else if(this.formValidation.value.rule[index].condition[conditionIndex].fieldId == '' || this.formValidation.value.rule[index].condition[conditionIndex].fieldId == null){
            this.toastr.warning(VALIDATION_FIELD_REQUIRED_MSG)
            return false

          }else if(this.formValidation.value.rule[index].condition[conditionIndex].dataTypeId == 17 && 
            !this.formValidation.value.rule[index].condition[conditionIndex].multiRowId){
            this.toastr.warning(VALIDATION_COLUMN_REQUIRED_MSG)
            return false

          }else if(this.formValidation.value.rule[index].condition[conditionIndex].operator == '' || this.formValidation.value.rule[index].condition[conditionIndex].operator == null){
            this.toastr.warning(VALIDATION_OPERATOR_REQUIRED_MSG)
            return false

          }else if(this.formValidation.value.rule[index].condition[conditionIndex].isConstant == 1){
            
            if(this.formValidation.value.rule[index].condition[conditionIndex].dataTypeId == 1 || this.formValidation.value.rule[index].condition[conditionIndex].dataTypeId == 2 || this.formValidation.value.rule[index].condition[conditionIndex].dataTypeId == 3 || this.formValidation.value.rule[index].condition[conditionIndex].dataTypeId == 4 || this.formValidation.value.rule[index].condition[conditionIndex].dataTypeId == 5){
              
              if(this.formValidation.value.rule[index].condition[conditionIndex].fieldValue == '' || this.formValidation.value.rule[index].condition[conditionIndex].fieldValue == null){
                this.toastr.warning(VALIDATION_FIELD_VALUE_REQUIRED_MSG)
                return false
                
              }
            
            }else if(this.formValidation.value.rule[index].condition[conditionIndex].dataTypeId == 6 || this.formValidation.value.rule[index].condition[conditionIndex].dataTypeId == 7 || this.formValidation.value.rule[index].condition[conditionIndex].dataTypeId == 8 || this.formValidation.value.rule[index].condition[conditionIndex].dataTypeId == 12){
              
              if(this.formValidation.value.rule[index].condition[conditionIndex].jsonFieldValue == '' || this.formValidation.value.rule[index].condition[conditionIndex].jsonFieldValue == null || loadashJson.isEqual(this.formValidation.value.rule[index].condition[conditionIndex].jsonFieldValue,{})){
                this.toastr.warning(VALIDATION_JSON_FIELD_VALUE_REQUIRED_MSG)
                return false
              }
            }else if(this.formValidation.value.rule[index].condition[conditionIndex].dataTypeId == 17 && 
              (this.formValidation.value.rule[index].condition[conditionIndex].multiRowDataTypeId == 6 || 
              this.formValidation.value.rule[index].condition[conditionIndex].multiRowDataTypeId == 7 ||
              this.formValidation.value.rule[index].condition[conditionIndex].multiRowDataTypeId == 8) && 
              !this.formValidation.value.rule[index].condition[conditionIndex].jsonFieldValue){
              this.toastr.warning(VALIDATION_OPTION_REQUIRED_MSG)
              return false
  
            }

          }else if(this.formValidation.value.rule[index].condition[conditionIndex].isConstant == 2){
            
            if(this.formValidation.value.rule[index].condition[conditionIndex].dest != undefined){
              
              if(formType == 0){
                if(this.formValidation.value.rule[index].condition[conditionIndex].dest['visitId'] == '' || this.formValidation.value.rule[index].condition[conditionIndex].dest['visitId'] == null){
                  this.toastr.warning(DEST_VALIDATION_VISIT_REQUIRED_MSG)
                  return false
  
                }
              }
              if(this.formValidation.value.rule[index].condition[conditionIndex].dest['formId'] == '' || this.formValidation.value.rule[index].condition[conditionIndex].dest['formId'] == null){
                this.toastr.warning(DEST_VALIDATION_FORM_REQUIRED_MSG)
                return false

              }else if(this.formValidation.value.rule[index].condition[conditionIndex].dest['fieldId'] == '' || this.formValidation.value.rule[index].condition[conditionIndex].dest['fieldId'] == null){
                this.toastr.warning(DEST_VALIDATION_FIELD_REQUIRED_MSG)
                return false

              }else if(this.formValidation.value.rule[index].condition[conditionIndex].dest['dataTypeId'] == 17 && !this.formValidation.value.rule[index].condition[conditionIndex].dest['multiRowId']){
                this.toastr.warning(DEST_VALIDATION_COLUMN_REQUIRED_MSG)
                return false

              }
            }
          }
        }
      }

      if(this.formValidation.value.rule[index].action == '' || this.formValidation.value.rule[index].action == null){
        this.toastr.warning(VALIDATION_ACTION_REQUIRED_MSG)
        return false

      }

      if(this.formValidation.value.rule[index].message == '' || this.formValidation.value.rule[index].message == null){
        this.toastr.warning(VALIDATION_MESSAGE_REQUIRED_MSG)
        return false
      }

      if(this.formValidation.value.rule[index].impact.length > 0){
        
        for (let impactIndex = 0; impactIndex < this.formValidation.value.rule[index].impact.length; impactIndex++) {
          if(formType == 0){
            if(this.formValidation.value.rule[index].impact[impactIndex].visit == '' || this.formValidation.value.rule[index].impact[impactIndex].visit == null){
              this.toastr.warning(VALIDATION_VISIT_REQUIRED_MSG)
              return false
  
            }
          }
          
          if(this.formValidation.value.rule[index].impact[impactIndex].form == '' || this.formValidation.value.rule[index].impact[impactIndex].form == null){
            this.toastr.warning(VALIDATION_FORM_REQUIRED_MSG)
            return false

          }else if(this.formValidation.value.rule[index].impact[impactIndex].fields == '' || this.formValidation.value.rule[index].impact[impactIndex].fields == null || this.formValidation.value.rule[index].impact[impactIndex].fields.length == 0){
            this.toastr.warning(VALIDATION_FIELD_REQUIRED_MSG)
            return false
          }

        }
      }
    }
    return true
  }

  validateLogMappingData(){
    for (let index = 0; index < this.formLogMapping.value.rule.length; index++) {
      if(this.formLogMapping.value.rule[index].condition.length >= 1){
        for (let conditionIndex = 0; conditionIndex < this.formLogMapping.value.rule[index].condition.length; conditionIndex++) {
          if(this.formLogMapping.value.rule[index].condition[conditionIndex].mappingType == 1 && this.formLogMapping.value.formType == 0){
            if((this.formLogMapping.value.rule[index].condition[conditionIndex].visitId == '' || this.formLogMapping.value.rule[index].condition[conditionIndex].visitId == null) && this.formLogMapping.value.rule[index].mappingType != 2){
              this.toastr.warning(LOGMAPPING_VISIT_REQUIRED_MSG)
              return false
            }
          }
          if(this.formLogMapping.value.rule[index].condition[conditionIndex].formId == '' || this.formLogMapping.value.rule[index].condition[conditionIndex].formId == null){
            this.toastr.warning(LOGMAPPING_FORM_REQUIRED_MSG)
            return false
          }else if(this.formLogMapping.value.rule[index].condition[conditionIndex].fieldId == '' || this.formLogMapping.value.rule[index].condition[conditionIndex].fieldId == null){
            this.toastr.warning(LOGMAPPING_FIELD_REQUIRED_MSG)
            return false
          }else if(this.formLogMapping.value.rule[index].condition[conditionIndex].operator == '' || this.formLogMapping.value.rule[index].condition[conditionIndex].operator == null){
            this.toastr.warning(LOGMAPPING_OPERATOR_REQUIRED_MSG)
            return false
          }else if(this.formLogMapping.value.rule[index].condition[conditionIndex].isConstant == 1){
            
            if(this.formLogMapping.value.rule[index].condition[conditionIndex].dataTypeId == 1 || this.formLogMapping.value.rule[index].condition[conditionIndex].dataTypeId == 2 || this.formLogMapping.value.rule[index].condition[conditionIndex].dataTypeId == 3 || this.formLogMapping.value.rule[index].condition[conditionIndex].dataTypeId == 4 || this.formLogMapping.value.rule[index].condition[conditionIndex].dataTypeId == 5){
              
              if(this.formLogMapping.value.rule[index].condition[conditionIndex].fieldValue == '' || this.formLogMapping.value.rule[index].condition[conditionIndex].fieldValue == null){
                this.toastr.warning(LOGMAPPING_FIELD_VALUE_REQUIRED_MSG)
                return false
                
              }
            
            }else if(this.formLogMapping.value.rule[index].condition[conditionIndex].dataTypeId == 6 || this.formLogMapping.value.rule[index].condition[conditionIndex].dataTypeId == 7 || this.formLogMapping.value.rule[index].condition[conditionIndex].dataTypeId == 8 || this.formLogMapping.value.rule[index].condition[conditionIndex].dataTypeId == 12){
              
              if(this.formLogMapping.value.rule[index].condition[conditionIndex].jsonFieldValue == '' || this.formLogMapping.value.rule[index].condition[conditionIndex].jsonFieldValue == null){
                this.toastr.warning(LOGMAPPING_JSON_FIELD_VALUE_REQUIRED_MSG)
                return false
              }
            }

          }else if(this.formLogMapping.value.rule[index].condition[conditionIndex].isConstant == 2){
            
            if(this.formLogMapping.value.rule[index].condition[conditionIndex].dest != undefined){
              if(this.formLogMapping.value.rule[index].condition[conditionIndex].mappingType == 1 && this.formLogMapping.value.formType == 0){
                if(this.formLogMapping.value.rule[index].condition[conditionIndex].dest['visitId'] == '' || this.formLogMapping.value.rule[index].condition[conditionIndex].dest['visitId'] == null){
                  this.toastr.warning(DEST_LOG_MAPPING_VISIT_REQUIRED_MSG)
                  return false
  
                }
              }
              if(this.formLogMapping.value.rule[index].condition[conditionIndex].dest['formId'] == '' || this.formLogMapping.value.rule[index].condition[conditionIndex].dest['formId'] == null){
                this.toastr.warning(DEST_LOG_MAPPING_FORM_REQUIRED_MSG)
                return false

              }else if(this.formLogMapping.value.rule[index].condition[conditionIndex].dest['fieldId'] == '' || this.formLogMapping.value.rule[index].condition[conditionIndex].dest['fieldId'] == null){
                this.toastr.warning(DEST_LOG_MAPPING_FIELD_REQUIRED_MSG)
                return false

              }
            }
          }
        }
      }
      if(this.formLogMapping.value.rule[index].action == '' || this.formLogMapping.value.rule[index].action == null){
        this.toastr.warning(LOGMAPPING_ACTION_REQUIRED_MSG)
        return false
      }
      if(this.formLogMapping.value.rule[index].action == 3){
        if(this.formLogMapping.value.rule[index].message == '' || this.formLogMapping.value.rule[index].message == null){
          this.toastr.warning(LOGMAPPING_MESSAGE_REQUIRED_MSG)
          return false
        }
        return true
      }
      if(this.formLogMapping.value.rule[index].impact.length >= 1){
        for (let impactIndex = 0; impactIndex < this.formLogMapping.value.rule[index].impact.length; impactIndex++) {
          if(this.formLogMapping.value.rule[index].impact[impactIndex].form == '' || this.formLogMapping.value.rule[index].impact[impactIndex].form == null){
            this.toastr.warning(LOGMAPPING_FORM_REQUIRED_MSG)
            return false
          }
        }
      }
    }
    return true
  }

  warnIfImpact(data:any){
    if(data.rule){
      
      for (let i = 0; i < data.rule.length; i++) {
        
        if(data.rule[i].action == 3){
          
          for (let j = 0; j < data.rule[i].impact.length; j++) {
            if(data.rule[i].impact[j].form != this.formId && data.rule[i].impact[j].visit != this.visitId[0]){
              data.rule[i].impact[j].form = this.formId
              data.rule[i].impact[j].visit = this.visitId[0]
            }
          }

        }

      }

    }

  }

  addValidation(){
    let rawValidationJson:any
    let data:any
    
    if(this.formValidation.value.rule.length == 0 && this.formLogMapping.value.rule.length == 0){
      this.toastr.warning("Please atleast one validation or logMapping")
      return
    }

    if(this.formValidation.value.rule.length > 0){
      if(!this.validateValidationData()){
        return
      }
      rawValidationJson = this.formValidation.value
      data = this.formValidation
    }
    
    if(this.formLogMapping.value.rule.length > 0){
      if(!this.validateLogMappingData()){
        return
      }
      if(rawValidationJson){
        
        rawValidationJson.rule = [
          ...rawValidationJson.rule,
          ...((this.formLogMapping.get("rule") as FormArray).value)
        ]
        
      }else{
        rawValidationJson = this.formLogMapping.value
      }
      data = this.formLogMapping
    }
    //this.warnIfImpact(rawValidationJson)
    this.setConditionFieldIds(rawValidationJson)
    this.makeEquation(rawValidationJson)
    
    for(let i = 0; i < rawValidationJson.rule.length; i++){
      let item0 = rawValidationJson.rule;

      for(let j = 0; j < item0[i].condition.length; j++){

        let item1 = item0[i].condition[j];

        delete item1.uiColumnSelect;
        delete item1.uiFieldSelect;
        delete item1.uiFormSelect;
        delete item1.uiOperatorSelect;
        delete item1.uiOptionSelect;
      }

      for(let j = 0; j < item0[i].impact.length; j++){

        let item1 = item0[i].impact[j];

        delete item1.uiImpactFormSelect;
        delete item1.uiImpactFieldSelect;
        delete item1.uiImpactColumnSelect;
      }
    }

    // if(!this.saveUpdateFlag){
    //   this.saveValidationLoader = true
    //   this.validationAPI.addValidation(rawValidationJson).subscribe(res=>{
    //     if(res.status != 0 ){
    //       this.toastr.error(res.message)
    //     }else{
    //       this.toastr.success(res.message)
    //       this.closePopup()
    //     }
    //     this.saveValidationLoader = false
    //   })
    // }else{
    //   this.updateValidationLoader = true
    //   this.validationAPI.updateValidation(rawValidationJson).subscribe(res=>{
    //     if(res.status != 0){
    //       this.toastr.error(res.message)
    //     }else{
    //       this.toastr.success(res.message)
    //       this.closePopup()
    //     }
    //     this.updateValidationLoader = false
    //   })
    // }


  }
  
  closePopup() {
    this.modalService.dismissAll("Dismiss");
    this.modalValidationClose.emit(0)
  }

  enableDisableValidation(event:any,status:any,ruleId:any,data:any,i:any){    
    let statusData:any
    if(ruleId != undefined){
      let r = confirm(VALIDATION_STATUS_CONFIRMATION)
      if(r){
        if(status == 1){
          event.source.checked = false
          event.checked = false
          statusData = 0
        }else{
          event.source.checked = true
          event.checked = true
          statusData = 1
        }
        this.setSelectedRuleKeyValue(data,"status",i,statusData)
        let requestBody:any = {
          ruleIds:[ruleId],
          status: statusData
        }
        this.validationAPI.updateValidationStatus(requestBody).subscribe(res=>{
          if(res.status == 0){
            this.toastr.success(res.message)
          }else{
            this.toastr.error(res.message)
          }
        })
      }else{
        if(status == 1){
          event.source.checked = true
          event.checked = true
          statusData = 1
        }else{
          event.source.checked = false
          event.checked = false
          statusData = 0
        }
        this.setSelectedRuleKeyValue(data,"status",i,statusData)
      }
    }else{
      if(status == 1){
        event.source.checked = false
        event.checked = false
        statusData = 0
      }else{
        event.source.checked = true
        event.checked = true
        statusData = 1
      }
      this.setSelectedRuleKeyValue(data,"status",i,statusData)
    }
  }

  //HTML Single Field Validation
  validateSingleValidationData(data:any,formType:any){
    // let formType = this.formValidation.value.formType
    
    for (let conditionIndex = 0; conditionIndex < data.condition.length; conditionIndex++) {

      if(formType == 0){
        if(data.condition[conditionIndex].visitId == '' || data.condition[conditionIndex].visitId == null){
          this.toastr.warning(VALIDATION_VISIT_REQUIRED_MSG)
          return false
        }

      }
      if(data.condition[conditionIndex].formId == '' || data.condition[conditionIndex].formId == null){
        this.toastr.warning(VALIDATION_FORM_REQUIRED_MSG)
        return false

      }else if(data.condition[conditionIndex].fieldId == '' || data.condition[conditionIndex].fieldId == null){
        this.toastr.warning(VALIDATION_FIELD_REQUIRED_MSG)
        return false

      }else if(data.condition[conditionIndex].dataTypeId == 17 && 
        !data.condition[conditionIndex].multiRowId){
        this.toastr.warning(VALIDATION_COLUMN_REQUIRED_MSG)
        return false

      }else if(data.condition[conditionIndex].operator == '' || data.condition[conditionIndex].operator == null){
        this.toastr.warning(VALIDATION_OPERATOR_REQUIRED_MSG)
        return false

      }else if(data.condition[conditionIndex].isConstant == 1){
        
        if(data.condition[conditionIndex].dataTypeId == 1 || data.condition[conditionIndex].dataTypeId == 2 || data.condition[conditionIndex].dataTypeId == 3 || data.condition[conditionIndex].dataTypeId == 4 || data.condition[conditionIndex].dataTypeId == 5){
          
          if(data.condition[conditionIndex].fieldValue == '' || data.condition[conditionIndex].fieldValue == null){
            this.toastr.warning(VALIDATION_FIELD_VALUE_REQUIRED_MSG)
            return false
            
          }
        
        }else if(data.condition[conditionIndex].dataTypeId == 6 || data.condition[conditionIndex].dataTypeId == 7 || data.condition[conditionIndex].dataTypeId == 8 || data.condition[conditionIndex].dataTypeId == 12){
          
          if(data.condition[conditionIndex].jsonFieldValue == '' || data.condition[conditionIndex].jsonFieldValue == null || loadashJson.isEqual(data.condition[conditionIndex].jsonFieldValue,{})){
            this.toastr.warning(VALIDATION_JSON_FIELD_VALUE_REQUIRED_MSG)
            return false
          }
        }else if(data.condition[conditionIndex].dataTypeId == 17 && 
          (data.condition[conditionIndex].multiRowDataTypeId == 6 || 
          data.condition[conditionIndex].multiRowDataTypeId == 7 ||
          data.condition[conditionIndex].multiRowDataTypeId == 8) && 
          !data.condition[conditionIndex].jsonFieldValue){
          this.toastr.warning(VALIDATION_OPTION_REQUIRED_MSG)
          return false

        }

      }else if(data.condition[conditionIndex].isConstant == 2){
        
        if(data.condition[conditionIndex].dest != undefined){
          
          if(formType == 0){
            if(data.condition[conditionIndex].dest['visitId'] == '' || data.condition[conditionIndex].dest['visitId'] == null){
              this.toastr.warning(DEST_VALIDATION_VISIT_REQUIRED_MSG)
              return false

            }
          }
          if(data.condition[conditionIndex].dest['formId'] == '' || data.condition[conditionIndex].dest['formId'] == null){
            this.toastr.warning(DEST_VALIDATION_FORM_REQUIRED_MSG)
            return false

          }else if(data.condition[conditionIndex].dest['fieldId'] == '' || data.condition[conditionIndex].dest['fieldId'] == null){
            this.toastr.warning(DEST_VALIDATION_FIELD_REQUIRED_MSG)
            return false

          }else if(data.condition[conditionIndex].dest['dataTypeId'] == 17 && !data.condition[conditionIndex].dest['multiRowId']){
            this.toastr.warning(DEST_VALIDATION_COLUMN_REQUIRED_MSG)
            return false

          }
        }
      }
    }

    if(data.action == '' || data.action == null){
      this.toastr.warning(VALIDATION_ACTION_REQUIRED_MSG)
      return false

    }

    if(data.message == '' || data.message == null){
      this.toastr.warning(VALIDATION_MESSAGE_REQUIRED_MSG)
      return false
    }

    for (let impactIndex = 0; impactIndex < data.impact.length; impactIndex++) {
      if(formType == 0){
        if(data.impact[impactIndex].visit == '' || data.impact[impactIndex].visit == null){
          this.toastr.warning(VALIDATION_VISIT_REQUIRED_MSG)
          return false

        }
      }
      
      if(data.impact[impactIndex].form == '' || data.impact[impactIndex].form == null){
        this.toastr.warning(VALIDATION_FORM_REQUIRED_MSG)
        return false

      }else if(data.impact[impactIndex].fields == '' || data.impact[impactIndex].fields == null || data.impact[impactIndex].fields.length == 0){
        this.toastr.warning(VALIDATION_FIELD_REQUIRED_MSG)
        return false
      }

    }
    
    return true
  }

  validateSingleLogMappingData(data:any,formType:any){
    if(data.condition.length >= 1){
      for (let conditionIndex = 0; conditionIndex < data.condition.length; conditionIndex++) {
        if(data.condition[conditionIndex].mappingType == 1 && this.formLogMapping.value.formType == 0){
          if((data.condition[conditionIndex].visitId == '' || data.condition[conditionIndex].visitId == null) && data.mappingType != 2){
            this.toastr.warning(LOGMAPPING_VISIT_REQUIRED_MSG)
            return false
          }
        }
        if(data.condition[conditionIndex].formId == '' || data.condition[conditionIndex].formId == null){
          this.toastr.warning(LOGMAPPING_FORM_REQUIRED_MSG)
          return false
        }else if(data.condition[conditionIndex].fieldId == '' || data.condition[conditionIndex].fieldId == null){
          this.toastr.warning(LOGMAPPING_FIELD_REQUIRED_MSG)
          return false
        }else if(data.condition[conditionIndex].operator == '' || data.condition[conditionIndex].operator == null){
          this.toastr.warning(LOGMAPPING_OPERATOR_REQUIRED_MSG)
          return false
        }else if(data.condition[conditionIndex].isConstant == 1){
          
          if(data.condition[conditionIndex].dataTypeId == 1 || data.condition[conditionIndex].dataTypeId == 2 || data.condition[conditionIndex].dataTypeId == 3 || data.condition[conditionIndex].dataTypeId == 4 || data.condition[conditionIndex].dataTypeId == 5){
            
            if(data.condition[conditionIndex].fieldValue == '' || data.condition[conditionIndex].fieldValue == null){
              this.toastr.warning(LOGMAPPING_FIELD_VALUE_REQUIRED_MSG)
              return false
              
            }
          
          }else if(data.condition[conditionIndex].dataTypeId == 6 || data.condition[conditionIndex].dataTypeId == 7 || data.condition[conditionIndex].dataTypeId == 8 || data.condition[conditionIndex].dataTypeId == 12){
            
            if(data.condition[conditionIndex].jsonFieldValue == '' || data.condition[conditionIndex].jsonFieldValue == null){
              this.toastr.warning(LOGMAPPING_JSON_FIELD_VALUE_REQUIRED_MSG)
              return false
            }
          }

        }else if(data.condition[conditionIndex].isConstant == 2){
          
          if(data.condition[conditionIndex].dest != undefined){
            if(data.condition[conditionIndex].mappingType == 1 && this.formLogMapping.value.formType == 0){
              if(data.condition[conditionIndex].dest['visitId'] == '' || data.condition[conditionIndex].dest['visitId'] == null){
                this.toastr.warning(DEST_LOG_MAPPING_VISIT_REQUIRED_MSG)
                return false

              }
            }
            if(data.condition[conditionIndex].dest['formId'] == '' || data.condition[conditionIndex].dest['formId'] == null){
              this.toastr.warning(DEST_LOG_MAPPING_FORM_REQUIRED_MSG)
              return false

            }else if(data.condition[conditionIndex].dest['fieldId'] == '' || data.condition[conditionIndex].dest['fieldId'] == null){
              this.toastr.warning(DEST_LOG_MAPPING_FIELD_REQUIRED_MSG)
              return false

            }
          }
        }
      }
    }
    if(data.action == '' || data.action == null){
      this.toastr.warning(LOGMAPPING_ACTION_REQUIRED_MSG)
      return false
    }
    if(data.action == 3){
      if(data.message == '' || data.message == null){
        this.toastr.warning(LOGMAPPING_MESSAGE_REQUIRED_MSG)
        return false
      }
      return true
    }
    if(data.impact.length >= 1){
      for (let impactIndex = 0; impactIndex < data.impact.length; impactIndex++) {
        if(data.impact[impactIndex].form == '' || data.impact[impactIndex].form == null){
          this.toastr.warning(LOGMAPPING_FORM_REQUIRED_MSG)
          return false
        }
      }
    }
    return true
  }

  //Single Save/Update API
  addSingleValidation(data:any,formType:any,ruleIndex:any,isValidation:boolean = true){
    let response:any = {
      id:data.value.id,
      rule:[data.value]
    }
    let responseBody = cloneArray(response)
    if( isValidation && !this.validateSingleValidationData(data.value,formType)){
      return
    }
    if( !isValidation && !this.validateSingleLogMappingData(data.value,formType) ){
      return
    }
    this.setConditionFieldIds(responseBody)
    this.makeEquation(responseBody)
    
    delete responseBody.rule[0].editedName
    delete responseBody.rule[0].isLoading
    delete responseBody.rule[0].isNameEditable

    for(let j = 0; j < responseBody.rule[0].condition.length; j++){

      let item1 = responseBody.rule[0].condition[j];

      if(typeof(item1.visitId) == 'object'){
        item1.visitId = item1.visitId[0]
      }

      delete item1.uiColumnSelect;
      delete item1.uiFieldSelect;
      delete item1.uiFormSelect;
      delete item1.uiOperatorSelect;
      delete item1.uiOptionSelect;

      if(item1.dest){
        if(typeof(item1.dest.visitId) == 'object'){
          item1.dest.visitId = item1.dest.visitId[0]
        }
        delete item1.dest.uiDestVisitSelect;
        delete item1.dest.uiDestFormSelect;
        delete item1.dest.uiDestFieldSelect;
        delete item1.dest.uiDestColumnSelect;
      }
    }

    for(let j = 0; j < responseBody.rule[0].impact.length; j++){

      let item1 = responseBody.rule[0].impact[j];
      
      if(typeof(item1.visit) == 'object'){
        item1.visit = item1.visit[0]
      }

      delete item1.uiImpactFormSelect;
      delete item1.uiImpactFieldSelect;
      delete item1.uiImpactColumnSelect;
    }
    
    if(data.value.id == null){
      if(isValidation){
        this.setSelectedRuleKeyValue(this.formValidation,"isLoading",ruleIndex,true)
      }else{
        this.setSelectedRuleKeyValue(this.formLogMapping,"isLoading",ruleIndex,true)
      }
      this.validationAPI.addValidation(responseBody).subscribe(res=>{
        if(res.status != 0 ){
          this.toastr.error(res.message)
        }else{
          this.toastr.success(res.message)
          if(isValidation){
            this.setSelectedRuleKeyValue(this.formValidation,"id",ruleIndex,res.responseObject.ruleId)
            this.setSelectedRuleKeyValue(this.formValidation,"ruleId",ruleIndex,res.responseObject.ruleId)
            let ruleLength = (this.formValidation.get("rule") as FormArray)
            if(ruleLength.length > 0){
              for (let i = 0; i < ruleLength.length; i++) {
                let conditionLength = (((this.formValidation.get("rule") as FormArray).get(i.toString()) as FormGroup).get("condition") as FormArray)
                let impactLength = (((this.formValidation.get("rule") as FormArray).get(i.toString()) as FormGroup).get("impact") as FormArray)
                if(conditionLength.length > 0){
                  for (let j = 0; j < conditionLength.length; j++) {
                    if(res.responseObject.conditionIds){
                      for (let k = 0; k < res.responseObject.conditionIds.length; k++) {
                        this.setSelectedKeyValue(this.formValidation,"condition","id",i,j,res.responseObject.conditionIds[k])
                        this.setSelectedKeyValue(this.formValidation,"condition","ruleId",i,j,res.responseObject.ruleId)
                      }
                    }
                  }
                }
                if(impactLength.length > 0){
                  for (let k = 0; k < impactLength.length; k++) {
                    if(res.responseObject.impactIds){
                      for (let l = 0; l < res.responseObject.impactIds.length; l++) {
                        this.setSelectedKeyValue(this.formValidation,"impact","id",i,k,res.responseObject.impactIds[l])
                        this.setSelectedKeyValue(this.formValidation,"impact","ruleId",i,k,res.responseObject.ruleId)
                      }
                    }
                  }
                }
              }
            }
            this.setSelectedRuleKeyValue(this.formValidation,"isLoading",ruleIndex,true)
          }else{
            this.setSelectedRuleKeyValue(this.formLogMapping,"id",ruleIndex,res.responseObject.ruleId)
            this.setSelectedRuleKeyValue(this.formLogMapping,"isLoading",ruleIndex,true)
          }
          // this.closePopup()
        }
        if(isValidation){
          this.setSelectedRuleKeyValue(this.formValidation,"isLoading",ruleIndex,false)
        }else{
          this.setSelectedRuleKeyValue(this.formLogMapping,"isLoading",ruleIndex,false)
        }
      })
    }else{
      if(isValidation){
        this.setSelectedRuleKeyValue(this.formValidation,"isLoading",ruleIndex,true)
      }else{
        this.setSelectedRuleKeyValue(this.formLogMapping,"isLoading",ruleIndex,true)
      }
      this.validationAPI.updateValidation(responseBody).subscribe(res=>{
        if(res.status != 0){
          this.toastr.error(res.message)
        }else{
          this.toastr.success(res.message)
          // this.closePopup()
        }
        if(isValidation){
          this.setSelectedRuleKeyValue(this.formValidation,"isLoading",ruleIndex,false)
        }else{
          this.setSelectedRuleKeyValue(this.formLogMapping,"isLoading",ruleIndex,false)
        }
      })
    }
  }

  onValidationFormTypeChange(event:any,data:any,i:any,j:any,isUpdate?:boolean){
    let conditionData = ((((data?.get('rule') as FormArray).get(i.toString()) as FormGroup).get("condition") as FormArray).get(j.toString()) as FormGroup)
    let logMapping = this.getSelectedRuleKeyValue(data,"mappingType",i);
    let validationFormType:any

    if(event.value != undefined){
      validationFormType = event.value
    }else{
      validationFormType = event
    }

    if( Object.keys(conditionData.value).length > 0 ){
      Object.keys(conditionData.value).find((data:any) => {
        if(!['id','ruleId','eqOrderId','validationFormType'].includes(data)){
          conditionData.removeControl(data);
        }
      })
    }

    if(validationFormType == this.validationFormTypeData[0].id){
      if(logMapping && logMapping == 1){
        for (let index = 0; index < this.conditionKeys.length; index++) {
          if(this.conditionKeys[index] == 'jsonFieldValue'){
            conditionData.addControl(this.conditionKeys[index],new FormControl({}))
          }else if(this.conditionKeys[index] == 'tableValidationType'){
            conditionData.addControl(this.conditionKeys[index],new FormControl(1))
          }else if(this.conditionKeys[index] == 'eqOrderId'){
            conditionData.addControl(this.conditionKeys[index],new FormControl(i))
          }else{
            conditionData.addControl(this.conditionKeys[index],new FormControl())
          }
          
        }

        if(data.get("formType")?.value == 0){
          this.setSelectedKeyValue(data, 'condition', 'visitId', i, j, this.visitId);
      
          this.setSelectedKeyValue(data, 'condition', 'formId', i, j, this.formId)
          const forms = this.visitFormMainData['visit'][this.visitId].forms
          this.setSelectedKeyValue(data,"condition","uiFormSelect",i,j,this.extractForms(forms))
          //this.getFieldsByForm(this.formId,data,"condition","uiFormSelect","uiFieldSelect",i,j,false);
          // this.getFieldsByForm(this.formId,data,"condition","uiFieldSelect",i,j,false,"uiColumnSelect")
          this.fieldAPI.getFieldsByForm(this.formId).subscribe(res => {
            this.setSelectedKeyValue(data,"condition","uiFieldSelect",i,j,res.responseObject)
            this.setSelectedKeyValue(data,"condition","fieldId",i,j,this.fieldId)
            //Uncomment because in new condition the datatype 17 OR 18 data column seletionbox is not getting filled
            let dataTypeId = this.getSelectedKeyValue(data,"condition","uiFieldSelect",i,j)?.value.find((field:any) => field.id == this.fieldId).dataTypeId
            if(isUpdate != undefined && !isUpdate){
              this.onFieldSelected(data, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId } , i, j);
            }
          })
        }
      }else if (logMapping && logMapping == 2){
        for (let index = 0; index < this.conditionKeys.length; index++) {
          if(this.conditionKeys[index] == 'visitId'){
            conditionData.addControl(this.conditionKeys[index],new FormControl(DEFAULT_UUID))
          }else if(this.conditionKeys[index] == 'jsonFieldValue'){
            conditionData.addControl(this.conditionKeys[index],new FormControl({}))
          }else if(this.conditionKeys[index] == 'tableValidationType'){
            conditionData.addControl(this.conditionKeys[index],new FormControl(1))
          }else if(this.conditionKeys[index] == 'eqOrderId'){
            conditionData.addControl(this.conditionKeys[index],new FormControl(i))
          }else if(this.conditionKeys[index] == 'uiFormSelect'){
            conditionData.addControl(this.conditionKeys[index],new FormControl(this.logFormFieldSelect[0].forms))
          }else{
            conditionData.addControl(this.conditionKeys[index],new FormControl())
          }
          
        }

        if(data.get("formType")?.value == 1){
          this.setSelectedKeyValue(data, 'condition', 'uiFormSelect', i, j, this.logFormFieldSelect[0].forms)
          this.setSelectedKeyValue(data, 'condition', 'formId', i, j, this.formId)
          
          // let fieldsData = this.getSelectedKeyValue(data,'condition','uiFormSelect',length,0)?.value.find((form:any)=>form.id == this.formId).fields
          // this.setSelectedKeyValue(data, 'condition', 'uiFieldSelect', i, j, fieldsData)
          // this.getFieldsByForm(this.formId,data,"condition","uiFieldSelect",i,j,false)
          // this.setSelectedKeyValue(data, 'condition', 'fieldId', i, j, this.fieldId)
          this.fieldAPI.getFieldsByForm(this.formId).subscribe(res => {
            this.setSelectedKeyValue(data,"condition","uiFieldSelect",i,j,res.responseObject)
            this.setSelectedKeyValue(data,"condition","fieldId",i,j,this.fieldId)
            let dataTypeId = this.getSelectedKeyValue(data,"condition","uiFieldSelect",i,j)?.value.find((field:any) => field.id == this.fieldId).dataTypeId
            if(isUpdate != undefined && !isUpdate){
              this.onFieldSelected(data, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , i, j);

            }
            
          })
        }
      }else{
        for (let index = 0; index < this.conditionKeys.length; index++) {
          if(this.conditionKeys[index] == 'jsonFieldValue'){
            conditionData.addControl(this.conditionKeys[index],new FormControl({}))
          }else if(this.conditionKeys[index] == 'tableValidationType'){
            conditionData.addControl(this.conditionKeys[index],new FormControl(1))
          }else if(this.conditionKeys[index] == 'eqOrderId'){
            conditionData.addControl(this.conditionKeys[index],new FormControl(i))
          }else{
            conditionData.addControl(this.conditionKeys[index],new FormControl())
          }
          
        }
        if(data.get("formType")?.value == 0){
          this.setSelectedKeyValue(data, 'condition', 'visitId', i, j, this.visitId);
      
          this.setSelectedKeyValue(data, 'condition', 'formId', i, j, this.formId)
          const forms = this.visitFormMainData['visit'][this.visitId].forms
          this.setSelectedKeyValue(data,"condition","uiFormSelect",i,j,this.extractForms(forms))
          //this.getFieldsByForm(this.formId,data,"condition","uiFormSelect","uiFieldSelect",i,j,false);
          // this.getFieldsByForm(this.formId,data,"condition","uiFieldSelect",i,j,false,"uiColumnSelect")
          this.fieldAPI.getFieldsByForm(this.formId).subscribe(res => {
            this.setSelectedKeyValue(data,"condition","uiFieldSelect",i,j,res.responseObject)
            this.setSelectedKeyValue(data,"condition","fieldId",i,j,this.fieldId)
            //Uncomment because in new condition the datatype 17 OR 18 data column seletionbox is not getting filled
            let dataTypeId = this.getSelectedKeyValue(data,"condition","uiFieldSelect",i,j)?.value.find((field:any) => field.id == this.fieldId).dataTypeId
            if(isUpdate != undefined && !isUpdate){
              this.onFieldSelected(data, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , i, j);
            }
          })
        }
    
      }
    }else{
      for (let index = 0; index < this.conditionKeys.length; index++) {
        if(this.conditionKeys[index] == 'visitId'){
          conditionData.addControl(this.conditionKeys[index],new FormControl(this.logFormFieldSelect[0].id))
        }else if(this.conditionKeys[index] == 'jsonFieldValue'){
          conditionData.addControl(this.conditionKeys[index],new FormControl({}))
        }else if(this.conditionKeys[index] == 'tableValidationType'){
          conditionData.addControl(this.conditionKeys[index],new FormControl(1))
        }else if(this.conditionKeys[index] == 'eqOrderId'){
          conditionData.addControl(this.conditionKeys[index],new FormControl(i))
        }else if(this.conditionKeys[index] == 'uiFormSelect'){
          conditionData.addControl(this.conditionKeys[index],new FormControl(this.logFormFieldSelect[0].forms))
        }else{
          conditionData.addControl(this.conditionKeys[index],new FormControl())
        }
        
      }
      if(data.get("formType")?.value == 1){
        this.setSelectedKeyValue(data, 'condition', 'uiFormSelect', i, j, this.logFormFieldSelect[0].forms)
        this.setSelectedKeyValue(data, 'condition', 'formId', i, j, this.formId)
        
        // let fieldsData = this.getSelectedKeyValue(data,'condition','uiFormSelect',length,0)?.value.find((form:any)=>form.id == this.formId).fields
        // this.setSelectedKeyValue(data, 'condition', 'uiFieldSelect', i, j, fieldsData)
        // this.getFieldsByForm(this.formId,data,"condition","uiFieldSelect",i,j,false)
        // this.setSelectedKeyValue(data, 'condition', 'fieldId', i, j, this.fieldId)
        this.fieldAPI.getFieldsByForm(this.formId).subscribe(res => {
          this.setSelectedKeyValue(data,"condition","uiFieldSelect",i,j,res.responseObject)
          this.setSelectedKeyValue(data,"condition","fieldId",i,j,this.fieldId)
          let dataTypeId = this.getSelectedKeyValue(data,"condition","uiFieldSelect",i,j)?.value.find((field:any) => field.id == this.fieldId).dataTypeId
          if(isUpdate != undefined && !isUpdate){
            this.onFieldSelected(data, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , i, j);
          }
          
        })
      }
    }
    // if(data.get("formType")?.value == 0){
    //   this.setSelectedKeyValue(data, 'condition', 'visitId', i, j, this.visitId);
  
    //   this.setSelectedKeyValue(data, 'condition', 'formId', i, j, this.formId)
    //   const forms = this.visitFormMainData['visit'][this.visitId].forms
    //   this.setSelectedKeyValue(data,"condition","uiFormSelect",i,j,this.extractForms(forms))
    //   //this.getFieldsByForm(this.formId,data,"condition","uiFormSelect","uiFieldSelect",i,j,false);
    //   // this.getFieldsByForm(this.formId,data,"condition","uiFieldSelect",i,j,false,"uiColumnSelect")
    //   this.fieldAPI.getFieldsByForm(this.formId).subscribe(res => {
    //     this.setSelectedKeyValue(data,"condition","uiFieldSelect",i,j,res.responseObject)
    //     this.setSelectedKeyValue(data,"condition","fieldId",i,j,this.fieldId)
        
    //   })
    //   //Uncomment because in new condition the datatype 17 OR 18 data column seletionbox is not getting filled
    //   // this.onFieldSelected(data, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , i, j);
    // }else{
    //   this.setSelectedKeyValue(data, 'condition', 'uiFormSelect', i, j, this.logFormFieldSelect)
    //   this.setSelectedKeyValue(data, 'condition', 'formId', i, j, this.formId)
      
    //   // let fieldsData = this.getSelectedKeyValue(data,'condition','uiFormSelect',length,0)?.value.find((form:any)=>form.id == this.formId).fields
    //   // this.setSelectedKeyValue(data, 'condition', 'uiFieldSelect', i, j, fieldsData)
    //   // this.getFieldsByForm(this.formId,data,"condition","uiFieldSelect",i,j,false)
    //   // this.setSelectedKeyValue(data, 'condition', 'fieldId', i, j, this.fieldId)
    //   this.fieldAPI.getFieldsByForm(this.formId).subscribe(res => {
    //     this.setSelectedKeyValue(data,"condition","uiFieldSelect",i,j,res.responseObject)
    //     this.setSelectedKeyValue(data,"condition","fieldId",i,j,this.fieldId)
        
    //   })
    //   // this.onFieldSelected(data, 'condition', 'uiFieldSelect', 'uiColumnSelect', { value: this.fieldId} , i, j);
    // }
    console.log("FORM VALIDATION :: ",this.formValidation,this.formLogMapping);
    
  }

  onDestValidationFormTypeChange(event:any,data:any,mainKey:any,subKey:any,i:any,j:any,isUpdate?:any,updateResponseData?:any){
    let formTypeData:any
    if(event.value != undefined){
      formTypeData = event.value
    }else{
      formTypeData = event
    }
    
    let destKeyData = (((((data?.get("rule") as FormArray)
    .get(i.toString()) as FormGroup)
    .get(mainKey) as FormArray)
    .get(j.toString()) as FormGroup)
    .get("dest") as FormGroup)
    
    if(Object.keys(destKeyData.value).length > 0){
      Object.keys(destKeyData.value).find((data:any) => {
        if(!['id','validationFormType'].includes(data)){
          destKeyData.removeControl(data)
        }
      })
    }
    console.log("FORM TYPE :: ",formTypeData);
    
    if(formTypeData == 0){
      
      destKeyData.addControl('id', new FormControl())
      destKeyData.addControl('validationFormType', new FormControl())
      destKeyData.addControl('visitId', new FormControl(formTypeData == 0 ? this.getSelectedKeyValue(data,mainKey,"visitId",i,j)?.value : null))
      destKeyData.addControl('formId', new FormControl(formTypeData == 0 ? this.getSelectedKeyValue(data,mainKey,"formId",i,j)?.value : null))
      destKeyData.addControl('multiRowId',new FormControl())
      destKeyData.addControl('multiRowDataTypeId',new FormControl())
      destKeyData.addControl('loperator', new FormControl())
      destKeyData.addControl('uiDestVisitSelect', new FormControl(this.visitAllData))
      destKeyData.addControl('uiDestFormSelect', new FormControl())
      destKeyData.addControl('uiDestFieldSelect', new FormControl())
      let forms:any
      console.log("DEST :: ",destKeyData) ;
      
      if(this.visitId){
        if(this.validationRuleId){
          forms = this.visitFormMainData['visit'][this.visitId.toString()].forms
        }else{
          forms = this.visitFormMainData['visit'][this.visitId[0].toString()].forms
        }
      }else{
        forms = this.visitFormMainData['visit'][updateResponseData.visitId.toString()].forms
        console.log(forms);
        
      }
      console.log("VISIT :: ",this.visitId,this.validationRuleId,forms);
      console.log("DEST :: ",destKeyData) ;

      destKeyData.addControl('uiDestFormSelect', new FormControl(this.extractForms(forms)))
      console.log(isUpdate);
      if (isUpdate == true) {
        destKeyData.get("uiDestFormSelect")?.setValue(forms);
        destKeyData.addControl('formId', new FormControl(updateResponseData.formId))
        destKeyData.addControl('fieldId', new FormControl(updateResponseData.fieldId))
        this.getFieldsByForm(updateResponseData.formId,data,mainKey,subKey,i,j,true,updateResponseData)
      }else{
        
        destKeyData.addControl('fieldId', new FormControl(this.fieldId))
        this.getFieldsByForm(this.getSelectedKeyValue(data,mainKey,"formId",i,j)?.value,data,mainKey,subKey,i,j,true)
      }
      // destKeyData.addControl('dataTypeId', new FormControl(dataTypeId))
      this.fieldAPI.getFieldsByForm(this.getSelectedKeyValue(data,mainKey,"formId",i,j)?.value).subscribe(res => {
        destKeyData.addControl('uiDestFieldSelect', new FormControl(cloneArray(res.responseObject)))
        let fieldData:any = cloneArray(res.responseObject)
        let dataTypeId:any
        
        // this.onDestFieldSelect(data,mainKey,subKey,{value: this.fieldId},i,j)
        if(this.getSelectedKeyValue(data,mainKey,"visitId",i,j)?.value != this.defaultUuid){
          dataTypeId = fieldData.find((mainData:any) => mainData.id == this.getSelectedKeyValue(data,mainKey,"fieldId",i,j)?.value).dataTypeId
          fieldData = fieldData.filter((data:any) => {
            return data.dataTypeId == dataTypeId
          })
        }
        
        let columnData:any[] = []
        destKeyData.addControl('dataTypeId',new FormControl(Number(dataTypeId)))
        if(dataTypeId == 17){
          columnData = fieldData.find((mainData:any) => mainData.id == this.getSelectedKeyValue(data,mainKey,"fieldId",i,j)?.value).columnData
          columnData = columnData.filter((mainData:any) => {
            return mainData.dataTypeId == this.getSelectedKeyValue(data,mainKey,"multiRowDataTypeId",i,j)?.value
          })
          destKeyData.addControl('uiDestColumnSelect',new FormControl(columnData))
          
        }else if(dataTypeId == 18){
          let column = fieldData.find((mainData:any) => mainData.id == this.getSelectedKeyValue(data,mainKey,"fieldId",i,j)?.value).cellInfo 
          if(column){
            for (let index = 1; index < column[0].length; index++) {
              column[0][index][0].tableColumnIndex = index
              column[0][index][0].colHeading = column[0][index][0].label
              columnData.push(column[0][index][0])
            }
            if(columnData){
              for (let index = 0; index < columnData.length; index++) {
                columnData[index].mainDataTypeId = column[1][columnData[index].tableColumnIndex][0].dataTypeId
              }
            }
          }
          columnData = columnData.filter((mainData:any) => {
            return mainData.mainDataTypeId == this.getSelectedKeyValue(data,mainKey,"multiRowDataTypeId",i,j)?.value
          })
          destKeyData.addControl('uiDestColumnSelect',new FormControl(columnData))
        }
      })
      
    }else{
      console.log("ELSE PART :: ",this.getSelectedRuleKeyValue(data,"mappingType",i));
      
      if(this.getSelectedRuleKeyValue(data,"mappingType",i) == 1){
        let formData = this.visitAllData.find((mainData:any) => mainData.visitId == this.getSelectedKeyValue(data,mainKey,"visitId",i,j)?.value).forms
        let fieldData = formData.find((mainData:any) => mainData.id == this.getSelectedKeyValue(data,mainKey,"formId",i,j)?.value).fields
        let dataTypeId = fieldData.find((mainData:any) => mainData.id == this.getSelectedKeyValue(data,mainKey,"fieldId",i,j)?.value).dataTypeId
        
        let columnData:any[] = []
        
        if(dataTypeId == 17){
          columnData = fieldData.find((mainData:any) => mainData.id == this.getSelectedKeyValue(data,mainKey,"fieldId",i,j)?.value).columnData
          columnData = columnData.filter((mainData:any) => {
            return mainData.dataTypeId == this.getSelectedKeyValue(data,mainKey,"multiRowDataTypeId",i,j)?.value
          })
          destKeyData.addControl('uiDestColumnSelect',new FormControl(columnData))

        }else if(dataTypeId == 18){
          let column = fieldData.find((mainData:any) => mainData.id == this.getSelectedKeyValue(data,mainKey,"fieldId",i,j)?.value).cellInfo 
          
          if(column){
            for (let index = 1; index < column[0].length; index++) {
              column[0][index][0].tableColumnIndex = index
              column[0][index][0].colHeading = column[0][index][0].label
              columnData.push(column[0][index][0])
            }
            if(columnData){
              for (let index = 0; index < columnData.length; index++) {
                columnData[index].mainDataTypeId = column[1][columnData[index].tableColumnIndex][0].dataTypeId
              }
            }
          }
          columnData = columnData.filter((mainData:any) => {
            return mainData.mainDataTypeId == this.getSelectedKeyValue(data,mainKey,"multiRowDataTypeId",i,j)?.value
          })
          destKeyData.addControl('uiDestColumnSelect',new FormControl(columnData))
        }
        destKeyData.addControl('id', new FormControl())
        destKeyData.addControl('validationFormType', new FormControl())
        destKeyData.addControl('visitId', new FormControl(this.getSelectedKeyValue(data,mainKey,"visitId",i,j)?.value))
        destKeyData.addControl('formId', new FormControl(this.getSelectedKeyValue(data,mainKey,"formId",i,j)?.value))
        destKeyData.addControl('fieldId', new FormControl([17,18].includes(dataTypeId) ? this.getSelectedKeyValue(data,mainKey,"fieldId",i,j)?.value : null ))
        destKeyData.addControl('multiRowId',new FormControl())
        destKeyData.addControl('multiRowDataTypeId',new FormControl())
        destKeyData.addControl('loperator', new FormControl())
        destKeyData.addControl('uiDestVisitSelect', new FormControl(this.visitAllData))
        destKeyData.addControl('uiDestFormSelect', new FormControl(formData))
        destKeyData.addControl('uiDestFieldSelect', new FormControl(fieldData))

        
      }else{
        destKeyData.addControl('id', new FormControl())
        destKeyData.addControl('validationFormType', new FormControl())
        destKeyData.addControl('visitId', new FormControl(DEFAULT_UUID))
        destKeyData.addControl('formId', new FormControl())
        destKeyData.addControl('fieldId', new FormControl())
        destKeyData.addControl('dataTypeId', new FormControl())
        destKeyData.addControl('multiRowId',new FormControl())
        destKeyData.addControl('multiRowDataTypeId',new FormControl())
        destKeyData.addControl('loperator', new FormControl())
        destKeyData.addControl('uiDestFormSelect', new FormControl(this.logFormFieldSelect[0].forms))
        console.log("INNER ELSE");
        
        if(data.value.formType != 0){
          console.log("INNER INNER ELSE :: ",updateResponseData,destKeyData.get("formId")?.value);
          let fieldData = this.testLogFormFields.find((mainData:any) => mainData.id == destKeyData.get("formId")?.value).fields
          let dataTypeId = fieldData.find((mainData:any) => mainData.id == updateResponseData.fieldId).dataTypeId
          
          let columnData:any[] = []
          
          if(dataTypeId == 17){
            columnData = fieldData.find((mainData:any) => mainData.id == this.getSelectedKeyValue(data,mainKey,"fieldId",i,j)?.value).columnData
            columnData = columnData.filter((mainData:any) => {
              return mainData.dataTypeId == this.getSelectedKeyValue(data,mainKey,"multiRowDataTypeId",i,j)?.value
            })
            destKeyData.addControl('uiDestColumnSelect',new FormControl(columnData))
  
          }else if(dataTypeId == 18){
            let column = fieldData.find((mainData:any) => mainData.id == this.getSelectedKeyValue(data,mainKey,"fieldId",i,j)?.value).cellInfo 
            
            if(column){
              for (let index = 1; index < column[0].length; index++) {
                column[0][index][0].tableColumnIndex = index
                column[0][index][0].colHeading = column[0][index][0].label
                columnData.push(column[0][index][0])
              }
              if(columnData){
                for (let index = 0; index < columnData.length; index++) {
                  columnData[index].mainDataTypeId = column[1][columnData[index].tableColumnIndex][0].dataTypeId
                }
              }
            }
            columnData = columnData.filter((mainData:any) => {
              return mainData.mainDataTypeId == this.getSelectedKeyValue(data,mainKey,"multiRowDataTypeId",i,j)?.value
            })
            destKeyData.addControl('uiDestColumnSelect',new FormControl(columnData))
          }
          destKeyData.addControl('dataTypeId', new FormControl(dataTypeId))
          destKeyData.addControl('uiDestFieldSelect', new FormControl(fieldData))
          destKeyData.addControl('formId', new FormControl(this.formId))
          destKeyData.addControl('fieldId', new FormControl(this.fieldId))
        }else{
          console.log("INNER INNER INNER ELSE :: ",isUpdate,updateResponseData);
          if(isUpdate){
            destKeyData.addControl('formId', new FormControl(updateResponseData['formId']))
            destKeyData.addControl('fieldId', new FormControl(updateResponseData['fieldId']))
            destKeyData.addControl('uiDestFieldSelect', new FormControl(this.testLogFormFields.find((formData:any) => formData.id == destKeyData.value.formId).fields))
          }else{
            destKeyData.addControl('formId', new FormControl())
            destKeyData.addControl('fieldId', new FormControl())
            destKeyData.addControl('uiDestFieldSelect', new FormControl())
            
          }
        }

      }
    }
    
    // this.addNewControl(data,mainKey,i,j,"dest",new FormGroup(destJson));
    
    // let logMapping = this.getSelectedRuleKeyValue(data,"mappingType",i);
    
    // if(!logMapping){
    //   (this.getSelectedKeyValue(data,mainKey,'dest',i,j) as FormGroup).get("visitId")?.setValue(this.visitId[0]);
    //   this.onDestVisitSelect(data, 'condition', 'dest', { value: this.visitId[0] } , i, j);
      
    //   (this.getSelectedKeyValue(data,mainKey,'dest',i,j) as FormGroup).get("formId")?.setValue(this.formId);
    //   this.onDestFormSelect(data, 'condition', 'dest', { value: this.formId } , i, j);

    //   (this.getSelectedKeyValue(data,mainKey,'dest',i,j) as FormGroup).get("fieldId")?.setValue(this.fieldId);
    //   this.onDestFieldSelect(data, 'condition', 'dest', { value: this.fieldId } , i, j);
    // }
  }

  onImpactValidationFormTypeChange(data:FormGroup,mainKey:any,subKey:any,event:any,i:any,k:any,isUpdate?:any,updateResponseData?:any){
    let impactData = ((((data?.get("rule") as FormArray)
    .get(i.toString()) as FormGroup)
    .get(mainKey) as FormArray)
    .get(k.toString()) as FormGroup)
    
    if( Object.keys(impactData.value).length > 0 ){
      Object.keys(impactData.value).find((data:any) => {
        if(!['id','ruleId','fields','validationFormType'].includes(data)){
          impactData.removeControl(data);
        }
      })
    }

    if(event == 0){
      for (let index = 0; index < this.impactKeys.length; index++) {
        impactData.addControl(this.impactKeys[index],new FormControl())
      }
    }else{
      for (let index = 0; index < this.impactKeys.length; index++) {
        if(this.impactKeys[index] == 'visit'){
          impactData.addControl(this.impactKeys[index],new FormControl(this.defaultUuid))
        }else if (this.impactKeys[index] == 'uiImpactFormSelect'){
          impactData.addControl(this.impactKeys[index],new FormControl(this.logFormFieldSelect[0].forms))
        }else{
          impactData.addControl(this.impactKeys[index],new FormControl())
        }
      }
    }
    
    this.fieldAPI.getFieldsByForm(this.formId).subscribe(res => {
      
    })
    if(data.get("formType")?.value == 0){
      if(event == 0){
        this.fieldAPI.getFieldsByForm(this.formId).subscribe((res:any) => {
          data.get('rule')?.get(i.toString())?.get("impact")?.get("0")?.get("visit")?.setValue(this.visitId)
          let forms:any
          if(this.validationRuleId){
            forms = this.visitFormMainData['visit'][this.visitId.toString()].forms
          }else{
            forms = this.visitFormMainData['visit'][this.visitId[0].toString()].forms
  
          }
          this.setSelectedKeyValue(data,'impact','uiImpactFormSelect',i,0,this.extractForms(forms))
          
          data.get('rule')?.get(i.toString())?.get("impact")?.get("0")?.get("form")?.setValue(this.formId)
          // this.getFieldsByForm(this.formId,data,mainKey,"uiImpactFieldSelect",i,k,false)
          this.setSelectedKeyValue(data,'impact','uiImpactFieldSelect',i,0,cloneArray(res.responseObject))
        })
      }else{
        this.fieldAPI.getFieldsByForm(updateResponseData[0].form).subscribe((res:any) => {
          data.get('rule')?.get(i.toString())?.get("impact")?.get("0")?.get("visit")?.setValue(this.defaultUuid)
          this.setSelectedKeyValue(data,'impact','uiImpactFormSelect',i,0,this.logFormFieldSelect[0].forms)
          
          data.get('rule')?.get(i.toString())?.get("impact")?.get("0")?.get("form")?.setValue(updateResponseData[0].form)
          
          // let fieldSelect = this.getSelectedKeyValue(data,'impact','uiImpactFormSelect',length,0)?.value.find((form:any)=>form.id == this.formId).fields
          this.setSelectedKeyValue(data,'impact','uiImpactFieldSelect',length,0,cloneArray(res.responseObject))
        })
      }
    }else{
      if(event == 0){
        this.fieldAPI.getFieldsByForm(this.formId).subscribe((res:any) => {
          data.get('rule')?.get(i.toString())?.get("impact")?.get("0")?.get("visit")?.setValue(this.visitId)
          const forms = this.visitFormMainData['visit'][this.visitId[0].toString()].forms
          this.setSelectedKeyValue(data,'impact','uiImpactFormSelect',i,0,this.extractForms(forms))
          
          data.get('rule')?.get(i.toString())?.get("impact")?.get("0")?.get("form")?.setValue(this.formId)
          
          // visitData = this.getSelectedKeyValue(data,'impact','uiImpactFormSelect',i,0)?.value.find((form:any)=>form.id == this.formId).fields
          this.setSelectedKeyValue(data,'impact','uiImpactFieldSelect',i,0,cloneArray(res.responseObject))
        })
      }else{
        data.get('rule')?.get(i.toString())?.get("impact")?.get("0")?.get("visit")?.setValue(this.defaultUuid)
        this.setSelectedKeyValue(data,'impact','uiImpactFormSelect',i,0,this.logFormFieldSelect[0].forms)
        
        data.get('rule')?.get(i.toString())?.get("impact")?.get("0")?.get("form")?.setValue(this.formId)
        
        let fieldSelect = this.getSelectedKeyValue(data,'impact','uiImpactFormSelect',i,0)?.value.find((form:any)=>form.id == this.formId).fields
        
        this.setSelectedKeyValue(data,'impact','uiImpactFieldSelect',i,0,fieldSelect)
      }
    }
    if( [componentTypeMap[16].id,componentTypeMap[17].id].includes(this.dataTypeId) ){
      this.fieldAPI.getFieldsByForm(this.formId).subscribe((res:any) => {
        this.setSelectedKeyValue(data, "impact", "uiImpactFieldSelect", i, 0,cloneArray(res.responseObject))
        console.log("MAIN DATA :: ",this.getSelectedKeyValue(data, "impact", "uiImpactFieldSelect", i, 0)?.value,this.fieldId);
        let selectedFields = this.getSelectedKeyValue(data, "impact", "uiImpactFieldSelect", i, 0)?.value.find((data:any)=> data.id == this.fieldId)
        this.setSelectedKeyValue(data, "impact", "fields", i, 0, [selectedFields])
        // this.setSelectedKeyValue(data, "impact", "uiImpactColumnSelect", i, 0, selectedFields.columnData)
        this.onImpactFieldSelect(data, 'impact', 'uiImpactFieldSelect', 'uiImpactColumnSelect', { id: this.fieldId } , i, 0);  
      });
    }
  }
}