import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import { baseURL , VERSION } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})
export class RolesStudyService {
  constructor(private http : HttpClient) { }
  getRolesStudy():Observable<any>{
    console.log("Getting Roles and Studies...");
    return this.http.get(`${baseURL}users/${VERSION}/roles-study`);
  }

}
