<div class="modal-content">
	<div class="modal-header">
		<h3 class="modal-title">{{title}} - ({{rowData.name}})</h3>
		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
	</div>
    <div class="modal-body">
        <form *ngIf="importStudyForm" [formGroup]="importStudyForm">
            <ul class="nav nav-tabs" (click)="onTabsChange($event)">
                <li class="nav-items" *ngFor="let item of tabsData;let i = index" (click)="onSetTab(i)">
                    <a class="nav-link " [ngClass]="activateTab == i ? 'active' : ''">{{item}}</a>
                </li>
            </ul>
            <br>
			<h5>Import : </h5>
            <div *ngIf="isTabValidation">
                <div class="form-check form-check-inline" *ngFor="let item of importData;let i = index">
                    <input class="form-check-input" type="radio" formControlName="type" id="radioBtn" [value]="item.id">
                    <label class="form-check-label" for="radioBtn">{{item.name}}</label>
                </div>
            </div>
            <div *ngIf="!isTabValidation">
                <div class="form-check form-check-inline" *ngFor="let item of importReviewData;let i = index">
                    <input class="form-check-input" type="radio" formControlName="type" id="radioBtn" [value]="item.id">
                    <label class="form-check-label" for="radioBtn">{{item.name}}</label>
                </div>
            </div>
            <div class="row" *ngIf="importStudyForm.get('type')?.value != undefined">
                <div class="col">
                    <label for="formFile" class="form-label"><b>File:</b></label>
                    <input class="form-control" type="file" id="formFile" (change)="onFileSelect($event.target)">
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="closeModal()"><b>Close</b></button>
        <button [hidden]="isLocked == 1" type="button" class="btn btn-outline-success" (click)="importStudyData()">
            <b>
                Import
            </b>
        </button>
    </div>
</div>