import { Component } from '@angular/core';
import {enableProdMode} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'webapp-edc';
  sideBarOpen=true;
  date: Date | undefined;

ngOnInit(){}

sideBarToggler(){
  this.sideBarOpen=!this.sideBarOpen;
}
}