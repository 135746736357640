import { Injectable } from '@angular/core';
import { Role } from 'src/app/models/Role';
import { LoggedInUser } from 'src/app/models/user';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService {
    private user: LoggedInUser = {};

    constructor() {}
    isAuthorized(): boolean {
      var roleId:any = sessionStorage.getItem('role')
      this.user = { role: JSON.parse(roleId) as Role  };
      return !!this.user;
    }

    hasRole(role: Role): boolean {
      var roleId:any = sessionStorage.getItem('role')
      this.user = { role: JSON.parse(roleId) as Role };
      return this.isAuthorized() && this.user.role === role;
    }

    logout(): void {
      this.user = {};
    }
}
