import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseURL , VERSION } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})
export class FormulaService {

  constructor(private http:HttpClient) { }

  postFieldFormulaRelation(data:any):Observable<any>{
    return this.http.post(`${baseURL}fieldFormula/${VERSION}`,data);
  }

  getFieldFormula():Observable<any>{
    return this.http.get(`${baseURL}fieldFormula/${VERSION}/list`)
  }

  updateFieldFormula(data:any):Observable<any>{
    return this.http.put<any>(`${baseURL}fieldFormula/${VERSION}`,data);
  }

  deleteFieldFormula(id:string):Observable<any>{
    const params = new HttpParams({ fromObject: { id: id } })
    return this.http.delete(`${baseURL}fieldFormula/${VERSION}?id=${id}`);
  }

  getFieldFormulaCount(formId:any):Observable<any>{
    return this.http.get(`${baseURL}formulas/${VERSION}/count?formId=${formId}`)
  }

  getFormulaByFormulaIdAndSubjectId(formulaIds:any[],subjectId:any):Observable<any>{
    return this.http.get(`${baseURL}formulas/${VERSION}/details?formulaIds=${formulaIds}&subjectId=${subjectId}`)
  }
}
