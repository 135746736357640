import { ToastrService } from 'ngx-toastr';
import { Role } from '../../models/Role';
import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { getTokenData } from 'src/app/constant/globalFunction';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from '../popup/change-password/change-password.component';
import { CaptchaService } from '../shared/captcha/captcha.service';
import { CAPTCHA_CONFIG } from 'src/app/utils/captcha-config';
import { CaptchaComponent } from '../shared/captcha/captcha.component';
import { CAPTCHA_MSG, PASSWORD_REQUIRED_MSG, USER_NAME_ERROR_MSG } from 'src/app/constant/responseMessage';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit,OnChanges {

  @ViewChild(CaptchaComponent) captchaCompo:any;
  formGroup?: FormGroup;
  loading:boolean = false
  isVisiblePassword: boolean = false;
  type: string = "password";

  captchaStatus:any = null;
  captchaConfig:any = CAPTCHA_CONFIG

  constructor(private loginAPI:LoginService,private route:Router,
    private toastr:ToastrService,private modalService:NgbModal,private captchaService:CaptchaService) { }
  
  ngOnChanges(): void {
    this.captchaConfig = CAPTCHA_CONFIG
  }

  ngOnInit(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.initForm()
    getTokenData("token");
    this.captchaService.setCaptchaStatus(null);
  }
  initForm(){
    this.formGroup = new FormGroup({
      username: new FormControl('',[Validators.required]),
      password: new FormControl('',[Validators.required]),
      grant_type:new FormControl('password'),
      captchaData:new FormControl()
    })
  }
  validateLoginCredentials(){
    let data = this.formGroup?.value
    let dataStatus:boolean = true
    this.captchaService.captchStatus.subscribe((status:any) => {
      console.log("STATUS :: ",status);
      
      this.formGroup?.get("captchaData")?.setValue(status)
      if(!this.formGroup?.get("captchaData")?.value){
        dataStatus = status
        return dataStatus
      }
      return dataStatus
    })
    if(data.username == null || data.username == ''){
      this.toastr.warning(USER_NAME_ERROR_MSG)
      dataStatus = false
      this.captchaCompo.createCaptcha();
      return dataStatus
    }else if(data.password == null || data.password == ''){
      this.toastr.warning(PASSWORD_REQUIRED_MSG)
      this.captchaCompo.createCaptcha();
      dataStatus = false
      return dataStatus
    }
    return dataStatus
  }
  loginProcess(){
    var a = "username="+this.formGroup?.value.username+"&password="+this.formGroup?.value.password+"&grant_type=password"
    var userName = this.formGroup?.value.username
    this.loading = true
    if(this.validateLoginCredentials()){
      this.loginAPI.login(a).subscribe(result=>{
        sessionStorage.setItem("token",result.access_token);
        sessionStorage.setItem("refresh_token",result.refresh_token);
        sessionStorage.setItem("userId",result.userId);
        sessionStorage.setItem("userName",userName);
  
        if(result.access_token && (userName == "Admin" || userName == "admin")){
          sessionStorage.setItem("role",JSON.stringify(Role.admin));
          this.route.navigate(['/layout'])
        }else{
          if(!result.passwordChanged){
            let modal = this.modalService.open(ChangePasswordComponent,{size:'md',backdrop:'static'})
            modal.componentInstance.fromLogin = true
            // this.route.navigate(['changePassword'],{queryParams:{fromLogin:true}})
          }else{
            this.route.navigate(['RolesStudy'])
          }
        }
        this.loading = false
      },err=>{
        let jsonData = JSON.parse(err.error['error_description'])
        if(jsonData && jsonData.status == 2){
          this.toastr.error(jsonData.message)
          let modal = this.modalService.open(ChangePasswordComponent,{size:'md',backdrop:'static'})
          modal.componentInstance.userIdData = jsonData.userId
          // modal.componentInstance.btnClicked.subscribe((recivedData:any) => {
          //   let doc = document.getElementById("userName")
          //   if(doc){
          //     doc.setAttribute("autofocus",recivedData);
          //     // doc.autofocus = recivedData
          //   }
            
          // })
          
        }else{
          this.toastr.error(jsonData.message)
  
        }
        this.formGroup?.reset()
        this.loading = false
        this.captchaCompo.createCaptcha();
      })
    }else{
      let captchaData = this.formGroup?.get("captchaData")?.value
      console.log("CAPTCHA DATA :: ",captchaData);
      
      if(captchaData == null){
        this.toastr.warning(CAPTCHA_MSG)
      }
      this.loading = false
    }
  }
}


// import { ToastrService } from 'ngx-toastr';
// import { Role } from '../../models/Role';
// import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { Router } from '@angular/router';
// import { LoginService } from 'src/app/services/login.service';
// import { getTokenData } from 'src/app/constant/globalFunction';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { ChangePasswordComponent } from '../popup/change-password/change-password.component';
// import { CaptchaService } from '../shared/captcha/captcha.service';
// import { CAPTCHA_CONFIG } from 'src/app/utils/captcha-config';
// import { CaptchaComponent } from '../shared/captcha/captcha.component';
// import { CAPTCHA_MSG, PASSWORD_REQUIRED_MSG, USER_NAME_ERROR_MSG } from 'src/app/constant/responseMessage';
// @Component({
//   selector: 'app-login',
//   templateUrl: './login.component.html',
//   styleUrls: ['./login.component.css']
// })

// export class LoginComponent implements OnInit,OnChanges {

//   formGroup?: FormGroup;
//   loading:boolean = false
//   isVisiblePassword: boolean = false;
//   type: string = "password";

//   captchaStatus:any = null;
//   captchaConfig:any = CAPTCHA_CONFIG

//   constructor(private loginAPI:LoginService,private route:Router,
//     private toastr:ToastrService,private modalService:NgbModal,private captchaService:CaptchaService) { }
  
//   ngOnChanges(): void {
//     this.captchaConfig = CAPTCHA_CONFIG
//   }

//   ngOnInit(): void {
//     sessionStorage.clear();
//     localStorage.clear();
//     this.initForm()
//     getTokenData("token");
//   }
//   initForm(){
//     this.formGroup = new FormGroup({
//       username: new FormControl('',[Validators.required]),
//       password: new FormControl('',[Validators.required]),
//       grant_type:new FormControl('password'),
//       captchaData:new FormControl()
//     })
//   }
//   validateLoginCredentials(){
//     let data = this.formGroup?.value
//     let dataStatus:boolean = true
//     this.captchaService.captchStatus.subscribe((status:any) => {
//       console.log("STATUS :: ",status);
      
//       this.formGroup?.get("captchaData")?.setValue(status)
//       if(!this.formGroup?.get("captchaData")?.value){
//         dataStatus = status
//         return dataStatus
//       }
//       return dataStatus
//     })
//     if(data.username == null || data.username == ''){
//       this.toastr.warning(USER_NAME_ERROR_MSG)
//       dataStatus = false
//       return dataStatus
//     }else if(data.password == null || data.password == ''){
//       this.toastr.warning(PASSWORD_REQUIRED_MSG)
//       dataStatus = false
//       return dataStatus
//     }
//     return dataStatus
//   }
//   loginProcess(){
//     var a = "username="+this.formGroup?.value.username+"&password="+this.formGroup?.value.password+"&grant_type=password"
//     var userName = this.formGroup?.value.username
//     this.loading = true
//     if(this.validateLoginCredentials()){
//       this.loginAPI.login(a).subscribe(result=>{
//         sessionStorage.setItem("token",result.access_token);
//         sessionStorage.setItem("refresh_token",result.refresh_token);
//         sessionStorage.setItem("userId",result.userId);
//         sessionStorage.setItem("userName",userName);
  
//         if(result.access_token && (userName == "Admin" || userName == "admin")){
//           sessionStorage.setItem("role",JSON.stringify(Role.admin));
//           this.route.navigate(['/layout'])
//         }else{
//           if(!result.passwordChanged){
//             let modal = this.modalService.open(ChangePasswordComponent,{size:'md',backdrop:'static'})
//             modal.componentInstance.fromLogin = true
//             // this.route.navigate(['changePassword'],{queryParams:{fromLogin:true}})
//           }else{
//             this.route.navigate(['RolesStudy'])
//           }
//         }
//         this.loading = false
//       },err=>{
//         let jsonData = JSON.parse(err.error['error_description'])
//         if(jsonData && jsonData.status == 2){
//           this.toastr.error(jsonData.message)
//           let modal = this.modalService.open(ChangePasswordComponent,{size:'md',backdrop:'static'})
//           modal.componentInstance.userIdData = jsonData.userId
//           // modal.componentInstance.btnClicked.subscribe((recivedData:any) => {
//           //   let doc = document.getElementById("userName")
//           //   if(doc){
//           //     doc.setAttribute("autofocus",recivedData);
//           //     // doc.autofocus = recivedData
//           //   }
            
//           // })
          
//         }else{
//           this.toastr.error(jsonData.message)
  
//         }
//         this.formGroup?.reset()
//         this.loading = false
//       })
//     }else{
//       let captchaData = this.formGroup?.get("captchaData")?.value
//       console.log("CAPTCHA DATA :: ",captchaData);
      
//       if(captchaData == null){
//         this.toastr.warning(CAPTCHA_MSG)
//       }
//       this.loading = false
//     }
//   }
// }
