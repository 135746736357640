<!-- Condition Key -->
<div *ngIf="validationAndLogMapping" [formGroup]="validationAndLogMapping">
    <div formArrayName="rule">
        <div class="row" [formGroupName]="ruleLength">
            <!-- Condition Design -->
            <div formArrayName="condition">
                <div *ngFor="let con of getConditionOrImpactArrayRule('condition',ruleLength).controls;let j=index">
                    <div [formGroupName]="j">
                        <div *ngIf="j == 0">
                            <h5>IF</h5>
                        </div>
                        <div class="row">
                            <!-- Select Form Type -->
                            <div class="col">
                                <div class="form-floating mb-3">
                                    <select class="form-select" id="validationFormType" formControlName="validationFormType" (change)="onValidationFormTypeSelect($event.target,'condition',ruleLength,j,false)">
                                        <option [value]="null" selected>--Select--</option>
                                        <option *ngFor="let item of validationFormTypeData" [value]="item.id"><b>{{item.name}}</b></option>
                                    </select>
                                    <label for="validationFormType"><b>Form-Type</b></label>
                                </div>
                            </div>
                            <!-- Select Form Type Ended-->
                            <!-- Select Visit Started -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value == 0">
                                <div class="form-floating mb-3">
                                    <select class="form-select" id="visitId" formControlName="visitId" (change)="onVisitSelect($event.target,'condition',ruleLength,j,false)">
                                        <option [value]="null" selected>--Select--</option>
                                        <option *ngFor="let item of visitListData" [value]="item.id"><b>{{item.name}}</b></option>
                                    </select>
                                    <label for="visitId"><b>Visit</b></label>
                                </div>
                            </div>
                            <!-- Select Visit Ended -->
                            <!-- Select Form Started -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('condition','visitId',ruleLength,j)?.value != null">
                                <div class="form-floating mb-3">
                                    <select class="form-select" id="formSelect" formControlName="formId" (change)="onFormSelect($event.target,'condition',ruleLength,j,false)">
                                        <option [value]="null" selected>--Select--</option>
                                        <option *ngFor="let item of getSelectedKeyValueForConditionAndImpact('condition','uiFormSelect',ruleLength,j)?.value" [value]="item.formId"><b>{{item.formName}}</b></option>
                                    </select>
                                    <label for="formSelect"><b>Form</b></label>
                                </div>
                            </div>
                            <!-- Select Form Ended -->
                            <!-- Select Field Started -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('condition','formId',ruleLength,j)?.value != null">
                                <div class="form-floating mb-3">
                                    <select  class="form-select" formControlName="fieldId" (change)="onFieldSelect($event.target,'condition',ruleLength,j,false)">
                                        <option [value]="null" selected>--Select--</option>
                                        <option *ngFor="let item of getSelectedKeyValueForConditionAndImpact('condition','uiFieldSelect',ruleLength,j)?.value" [value]="item.id"><b>{{item.label}}</b></option>
                                    </select>
                                    <label for="fieldsSelect"><b>Field</b></label>
                                </div>
                            </div>
                            <!-- Select Field Ended -->
                            <!-- Select Column Started ( For Multi-Row Component ) -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('condition','fieldId',ruleLength,j)?.value != undefined && [17].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value)">
                                <div class="form-floating mb-3">
                                    <select class="form-select" formControlName="multiRowId" (change)="onMultiRowColumnSelect($event.target,'condition',ruleLength,j,false)">
                                        <option [value]="null" selected>--Select--</option>
                                        <option *ngFor="let item of getSelectedKeyValueForConditionAndImpact('condition','uiColumnSelect',ruleLength,j)?.value" [value]="item.id">
                                            <b>{{item.colHeading}}</b>
                                        </option>
                                    </select>
                                    <label for="columnSelect"><b>Column</b></label>
                                </div>
                            </div>
                            <!-- Select Column Ended ( For Multi-Row Compoenent ) -->
                            <!-- Select Column Heading Started ( For Table Component ) -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('condition','fieldId',ruleLength,j)?.value != undefined && [18].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value)">
                                <div class="form-floating mb-3">
                                    <select class="form-select" formControlName="multiRowId" (change)="onTableComponentColumnSelect($event.target,'condition',ruleLength,j,false)">
                                        <option [value]="null" selected>--Select--</option>
                                        <option *ngFor="let item of getSelectedKeyValueForConditionAndImpact('condition','uiColumnSelect',ruleLength,j)?.value" [value]="item.tableColumnIndex">
                                            <b>{{item.colHeading}}</b>
                                        </option>
                                    </select>
                                    <label for="columnSelect"><b>Column Heading</b></label>
                                </div>
                            </div>
                            <!-- Select Column Heading Ended ( For Table Component ) -->
                            <!-- Select Operator Started -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null && (![17,18].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) && getSelectedKeyValueForConditionAndImpact('condition','fieldId',ruleLength,j)?.value != undefined) || ([17,18].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) && getSelectedKeyValueForConditionAndImpact('condition','multiRowId',ruleLength,j)?.value != undefined)">
                                <div class="col">
                                    <div class="form-floating mb-3">
                                        <select class="form-select" id="operator" formControlName="operator" (change)="onOperatorSelect($event.target,'condition',ruleLength,j)">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let operator of getSelectedKeyValueForConditionAndImpact('condition','uiOperatorSelect',ruleLength,j)?.value" value="{{operator.name}}"><b>{{operator.name}}</b></option>
                                        </select>
                                        <label for="operator"><b>Operator</b></label>
                                    </div>
                                </div>
                            </div>
                            <!-- Select Operator Ended -->

                            <!-- Constant Days for window started -->

                            <div class="col" *ngIf="isWindowPeriodShown && [12].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value)">
                                <div class="col">
                                    <div class="form-floating mb-3">
                                        <input type="number" 
                                                class="form-control"
                                                id="visitOffset"
                                                formControlName="visitOffset"
                                                min="0"
                                                value="{{getSelectedKeyValueForConditionAndImpact('condition', 'visitOffset', ruleLength, j)?.value}}"
                                                (change)="onChangeWindowPeriod($event.target, 'visitOffset', ruleLength, j)">
                                        <label><b>Days</b></label>
                                    </div>
                                </div>
                            </div>

                            <!-- Constant Days for window end -->

                            <!-- Select Is-Constant Started -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('condition','operator',ruleLength,j)?.value != undefined && ( (![17,18].includes( getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) && ![6, 7, 8].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) ) || ([17,18].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) && (![6, 7, 8].includes(getSelectedKeyValueForConditionAndImpact('condition','multiRowDataTypeId',ruleLength,j)?.value))))">
                                <div class="col" [hidden]="isWindowPeriodShown">
                                    <div class="form-floating mb-3">
                                        <select class="form-select" id="isConstant" formControlName="isConstant" (change)="onIsConstantSelect($event.target,'condition',ruleLength,j,false)">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let constant of isConstantSelectionBoxData" value="{{constant.id}}"><b>{{constant.name}}</b></option>
                                        </select>
                                        <label for="isConstant"><b>Is Constant</b></label>
                                    </div>
                                </div>
                            </div>
                            <!-- Select Is-Constant Ended -->
                            <!-- Select Option Started -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null && (getSelectedKeyValueForConditionAndImpact('condition','isConstant',ruleLength,j)?.value == 1 && ([6, 7, 8].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) || ([17,18].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) && [6, 7, 8].includes(getSelectedKeyValueForConditionAndImpact('condition','multiRowDataTypeId',ruleLength,j)?.value)))) ">
                                <div class="form-floating mb-3">
                                    <select class="form-select" id="option" formControlName="jsonFieldValue">
                                        <option [ngValue]="null" selected>--Select--</option>
                                        <option *ngFor="let option of getSelectedKeyValueForConditionAndImpact('condition','uiOptionSelect',ruleLength,j)?.value" [value]="option.id"><b>{{option.name}}</b></option>
                                    </select>
                                    <label for="option"><b>Option</b></label>
                                </div>
                            </div>
                            <!-- Select Option Ended -->
                            <!-- Add Value Started -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null &&  getSelectedKeyValueForConditionAndImpact('condition','isConstant',ruleLength,j)?.value == 1 && ([1,2,3,4,5].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) || ([17,18].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) &&  ([1,2,3,4,5].includes(getSelectedKeyValueForConditionAndImpact('condition','multiRowDataTypeId',ruleLength,j)?.value))))">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" placeholder="Enter Value" id="fieldValue" formControlName="fieldValue" max="512" maxlength="512">
                                    <label for="fieldValue"><b>Value</b></label>
                                </div>
                            </div>
                            <!-- Add Value Ended -->
                            <!-- If the dataTypeId is Date Component then Select Date Started -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null &&  getSelectedKeyValueForConditionAndImpact('condition','isConstant',ruleLength,j)?.value == 1 && ([12,19].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) || ([17,18].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) &&  [12,19].includes(getSelectedKeyValueForConditionAndImpact('condition','multiRowDataTypeId',ruleLength,j)?.value)))">
                                <div class="form-floating mb-3">
                                    <div class="input-group">
                                        <input class="form-control" placeholder="Select Date" formControlName="jsonFieldValue" ngbDatepicker #dynamicDate="ngbDatepicker" [readOnly]="true" [dayTemplate]="customDay" (click)="dynamicDate.toggle()">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="dynamicDate.toggle()" type="button">
                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
                                        <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" [class.text-muted]="date.month !== currentMonth">
                                            {{ date.day }}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                            <!-- If the dataTypeId is Date Componwnt then Select Date Ended -->
                            <!-- If the dataTypeId is Time Component then Select Time Started -->
                            
                            <!-- Hour-12 Selection Box Started -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','uiFormattingKey',ruleLength,j)?.value != null && (getSelectedKeyValueForConditionAndImpact('condition','uiFormattingKey',ruleLength,j)?.value.hourFormat == '12 hrs' && getSelectedKeyValueForConditionAndImpact('condition','uiFormattingKey',ruleLength,j)?.value.hour == 'true') && getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null && getSelectedKeyValueForConditionAndImpact('condition','isConstant',ruleLength,j)?.value == 1 && ([13,19].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) ||  ([17,18].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) &&  [13,19].includes(getSelectedKeyValueForConditionAndImpact('condition','multiRowDataTypeId',ruleLength,j)?.value)))">
                                <div class="form-floating mb-3">
                                    <select class="form-select" id="hour" formControlName="uiHour">
                                        <option [ngValue]="null" selected>--Select--</option>
                                        <option *ngFor="let hourData of hours12" [value]="hourData"><b>{{hourData}}</b></option>
                                    </select>
                                    <label for="hour"><b>Hour</b></label>
                                </div>
                            </div>
                            <!-- Hour-12 Selection Box Ended -->
                            <!-- Hour-24 Selection Box Started -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','uiFormattingKey',ruleLength,j)?.value != null && (getSelectedKeyValueForConditionAndImpact('condition','uiFormattingKey',ruleLength,j)?.value.hourFormat == '24 hrs' && getSelectedKeyValueForConditionAndImpact('condition','uiFormattingKey',ruleLength,j)?.value.hour == 'true') && getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null &&  getSelectedKeyValueForConditionAndImpact('condition','isConstant',ruleLength,j)?.value == 1 && ([13,19].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) ||  ([17,18].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) &&  [13,19].includes(getSelectedKeyValueForConditionAndImpact('condition','multiRowDataTypeId',ruleLength,j)?.value)))">
                                <div class="form-floating mb-3">
                                    <select class="form-select" id="hour" formControlName="uiHour">
                                        <option [ngValue]="null" selected>--Select--</option>
                                        <option *ngFor="let hourData of hours24" [value]="hourData"><b>{{hourData}}</b></option>
                                    </select>
                                    <label for="hour"><b>Hour</b></label>
                                </div>
                            </div>
                            <!-- Hour-24 Selection Box Ended -->
                            <!-- Minute Selection Box Started -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','uiFormattingKey',ruleLength,j)?.value != null && (getSelectedKeyValueForConditionAndImpact('condition','uiFormattingKey',ruleLength,j)?.value.minute == 'true') && getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null &&  getSelectedKeyValueForConditionAndImpact('condition','isConstant',ruleLength,j)?.value == 1 && ([13,19].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) ||  ([17,18].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) &&  [13,19].includes(getSelectedKeyValueForConditionAndImpact('condition','multiRowDataTypeId',ruleLength,j)?.value)))">
                                <div class="form-floating mb-3">
                                    <select class="form-select" id="minute" formControlName="uiMinute">
                                        <option [ngValue]="null" selected>--Select--</option>
                                        <option *ngFor="let minuteData of minutes" [value]="minuteData"><b>{{minuteData}}</b></option>
                                    </select>
                                    <label for="minute"><b>Minute</b></label>
                                </div>
                            </div>
                            <!-- Minute Selection Box Ended -->
                            <!-- Second Selection Box Started -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','uiFormattingKey',ruleLength,j)?.value != null && (getSelectedKeyValueForConditionAndImpact('condition','uiFormattingKey',ruleLength,j)?.value.second == 'true') && getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null &&  getSelectedKeyValueForConditionAndImpact('condition','isConstant',ruleLength,j)?.value == 1 && ([13,19].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) ||  ([17,18].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) &&  [13,19].includes(getSelectedKeyValueForConditionAndImpact('condition','multiRowDataTypeId',ruleLength,j)?.value)))">
                                <div class="form-floating mb-3">
                                    <select class="form-select" id="second" formControlName="uiSecond">
                                        <option [ngValue]="null" selected>--Select--</option>
                                        <option *ngFor="let secondData of seconds" [value]="secondData"><b>{{secondData}}</b></option>
                                    </select>
                                    <label for="second"><b>Second</b></label>
                                </div>
                            </div>
                            <!-- Second Selection Box Ended -->
                            <!-- MidDay Selection Box Started -->
                            <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','uiFormattingKey',ruleLength,j)?.value != null && (getSelectedKeyValueForConditionAndImpact('condition','uiFormattingKey',ruleLength,j)?.value.midDay == 'true') && getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null &&  getSelectedKeyValueForConditionAndImpact('condition','isConstant',ruleLength,j)?.value == 1 && ([13,19].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) ||  ([17,18].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value) &&  [13,19].includes(getSelectedKeyValueForConditionAndImpact('condition','multiRowDataTypeId',ruleLength,j)?.value)))">
                                <div class="form-floating mb-3">
                                    <select class="form-select" id="midDay" formControlName="uiMidDay">
                                        <option [ngValue]="null" selected>--Select--</option>
                                        <option *ngFor="let midDayData of midDays" [value]="midDayData"><b>{{midDayData}}</b></option>
                                    </select>
                                    <label for="midDay"><b>MidDay</b></label>
                                </div>
                            </div>
                            <!-- MidDay Selection Box Ended -->
                            <!-- If the dataTypeId is Time Component then Select Time Ended -->
                            <!-- If isConstant is NO then Destination Design Started -->
                            <div class="row" formGroupName="dest" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value != undefined">
                                <div class="col-1"></div>
                                <!-- In Destination Select Validation Form Type Started -->
                                <div class="col">
                                    <input type="hidden" name="id" formControlName="id">
                                    <div class="form-floating mb-3">
                                        <select class="form-select" id="validationFormType" formControlName="validationFormType" (change)="onValidationFormTypeSelect($event.target,'condition',ruleLength,j,true)">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of validationFormTypeData" [value]="item.id"><b>{{item.name}}</b></option>
                                        </select>
                                        <label for="validationFormType"><b>Form-Type</b></label>
                                    </div>
                                </div>
                                <!-- In Destination Select ValidationFormType Ended -->
                                <!-- In Destination Select Visit Started -->
                                <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.validationFormType != null && getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.validationFormType == 0">
                                    <div class="form-floating mb-3">
                                        <select class="form-select" id="visitId" formControlName="visitId" (change)="onVisitSelect($event.target,'condition',ruleLength,j,true)">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of visitListData" [value]="item.id"><b>{{item.name}}</b></option>
                                        </select>
                                        <label for="visitId"><b>Visit</b></label>
                                    </div>
                                </div>
                                <!-- In Destination Select Visit Ended -->
                                <!-- In Destination Select Form Started -->
                                <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.validationFormType != null && getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.visitId != null">
                                    <div class="form-floating mb-3">
                                        <select class="form-select" id="formId" formControlName="formId" (change)="onFormSelect($event.target,'condition',ruleLength,j,true)">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.uiFormSelect" [value]="item.formId"><b>{{item.formName}}</b></option>
                                        </select>
                                        <label for="formId"><b>Form</b></label>
                                    </div>
                                </div>
                                <!-- In Destination Select Form Ended -->
                                <!-- In Destination Select Field Started -->
                                <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.validationFormType != null && getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.formId != null">
                                    <div class="form-floating mb-3">
                                        <select class="form-select" id="fieldId" formControlName="fieldId" (change)="onFieldSelect($event.target,'condition',ruleLength,j,true)">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.uiFieldSelect" [value]="item.id"><b>{{item.label}}</b></option>
                                        </select>
                                        <label for="fieldId"><b>Field</b></label>
                                    </div>
                                </div>
                                <!-- In Destination Select Field Ended -->
                                <!-- In Destination Select Column Started -->
                                <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.validationFormType != null && getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.fieldId != null && getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.dataTypeId == 17">
                                    <div class="form-floating mb-3">
                                        <select class="form-select" formControlName="multiRowId" (change)="onMultiRowColumnSelect($event.target,'condition',ruleLength,j,true)">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.uiColumnSelect" [value]="item.id">
                                                <b>{{item.colHeading}}</b>
                                            </option>
                                        </select>
                                        <label for="columnSelect"><b>Column</b></label>
                                    </div>
                                </div>
                                <!-- In Destination Select Column Ended -->
                                <!-- In Destination Select Column Heading Started -->
                                <div class="col" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.validationFormType != null && getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.fieldId != null && getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.dataTypeId == 18">
                                    <div class="form-floating mb-3">
                                        <select class="form-select" formControlName="multiRowId" (change)="onTableComponentColumnSelect($event.target,'condition',ruleLength,j,true)">
                                            <option [value]="null" selected>--Select--</option>
                                            <option *ngFor="let item of getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.uiColumnSelect" [value]="item.tableColumnIndex">
                                                <b>{{item.colHeading}}</b>
                                            </option>
                                        </select>
                                        <label for="columnSelect"><b>Column Heading</b></label>
                                    </div>
                                </div>
                                <!-- In Destination Select Column Heading Ended -->
                                <!-- In Destination Select Logical Operator Started -->
                                <div class="col" [hidden]="isWindowPeriodShown" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.validationFormType != null && getSelectedKeyValueForConditionAndImpact('condition','dest',ruleLength,j)?.value.fieldId != null">
                                    <div class="form-floating mb-3">
                                        <select class="form-select" id="loperator" formControlName="loperator" (change)="onLogicalOperatorSelect($event.target,'condition',ruleLength,j)">
                                            <option [ngValue]="null" selected>--Select--</option>
                                            <option *ngFor="let vlo of logicalOperatorSelectionBoxData" [value]="vlo.name"><b>{{vlo.name}}</b></option>
                                        </select>
                                        <label for="lOPerator"><b>and/or</b></label>
                                    </div>
                                </div>
                                <!-- In Destination Select Logical Operator Ended -->
                            </div>
                            <!-- If isConstant is NO then Desitnation Design Ended -->
                            <!-- Select Logical Operator Started -->
                            <div class="col" [hidden]="isWindowPeriodShown" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null &&  getSelectedKeyValueForConditionAndImpact('condition','isConstant',ruleLength,j)?.value == 1 && (getSelectedKeyValueForConditionAndImpact('condition','fieldValue',ruleLength,j)?.value != null || getSelectedKeyValueForConditionAndImpact('condition','jsonFieldValue',ruleLength,j)?.value != emptyJson)">
                                <div class="form-floating mb-3">
                                    <select class="form-select" id="loperator" formControlName="loperator" (change)="onLogicalOperatorSelect($event.target,'condition',ruleLength,j)">
                                        <option [ngValue]="null" selected>--Select--</option>
                                        <option *ngFor="let losbd of logicalOperatorSelectionBoxData" [value]="losbd.name"><b>{{losbd.name}}</b></option>
                                    </select>
                                    <label for="loperator"><b>and/or</b></label>
                                </div>
                            </div>
                            <!-- Select Logical Operator Ended -->

                            <!-- window period checkbox started  -->

            <div class="d-flex" *ngIf="isWindowPeriodShown && [12].includes(getSelectedKeyValueForConditionAndImpact('condition','dataTypeId',ruleLength,j)?.value)">
                <div class="m-2">
                    <input name="windowPeriod"
                            id="isWindowPeriodChecked" 
                            formControlName="isWindowPeriodChecked"
                            value="{{getSelectedKeyValueForConditionAndImpact('condition','isWindowPeriodChecked',ruleLength,j)?.value}}"
                            type="checkbox" 
                            class="m-2"
                            (change)="onChangeWindowPeriodCheckbox($event, ruleLength, j)">
                    <label for="windowPeriod">Window Period</label> 
                </div>
                <div class="width-76 m-2" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','higherWindowPeriod',ruleLength,j)?.value || getSelectedKeyValueForConditionAndImpact('condition','higherWindowPeriod',ruleLength,j)?.value == 0 || getSelectedKeyValueForConditionAndImpact('condition','lowerWindowPeriod',ruleLength,j)?.value || getSelectedKeyValueForConditionAndImpact('condition','lowerWindowPeriod',ruleLength,j)?.value == 0">
                    <div class="col">
                        <div class="form-floating mb-3">
                            <input type="number" 
                                    class="form-control" 
                                    min="0"
                                    id="higherWindowPeriod"
                                    formControlName="higherWindowPeriod"
                                    value="{{getSelectedKeyValueForConditionAndImpact('condition', 'higherWindowPeriod', this.ruleLength, j)?.value}}"
                                    (change)="onChangeWindowPeriod($event.target, 'higherWindowPeriod', ruleLength, j)">
                            <label>+</label>
                        </div>
                    </div>
                </div>
                <div class="width-76 m-2" *ngIf="getSelectedKeyValueForConditionAndImpact('condition','higherWindowPeriod',ruleLength,j)?.value || getSelectedKeyValueForConditionAndImpact('condition','higherWindowPeriod',ruleLength,j)?.value == 0 || getSelectedKeyValueForConditionAndImpact('condition','lowerWindowPeriod',ruleLength,j)?.value || getSelectedKeyValueForConditionAndImpact('condition','lowerWindowPeriod',ruleLength,j)?.value == 0">
                    <div class="col">
                        <div class="form-floating mb-3">
                            <input type="number" 
                                    class="form-control"
                                    min="0"
                                    id="lowerWindowPeriod"
                                    formControlName="lowerWindowPeriod"
                                    value="{{getSelectedKeyValueForConditionAndImpact('condition', 'lowerWindowPeriod', this.ruleLength, j)?.value}}"
                                    (change)="onChangeWindowPeriod($event.target, 'lowerWindowPeriod', ruleLength, j)">
                            <label>-</label>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- window period checkbox end  -->

                            <!-- Remove Condition Button Started -->
                            <div class="col mt-3" *ngIf="j != 0 && getSelectedKeyValueForConditionAndImpact('condition','validationFormType',ruleLength,j)?.value != null">
                                <button class="btn-close" title="Remove Condition" (click)="removeCondition(ruleLength,j)"></button>
                            </div>
                            <!-- Remove Condition Button Ended -->
                        </div>
                    </div>
                </div>
            </div>
            
            <hr>
            <!-- Impact Design -->
            <div *ngIf="tabName == 'validation'">
                <app-validation-rule-impact [formRuleValidation]="validationAndLogMapping" [fetchedDataTypeId]="fetchedDataTypeId" [formType]="formType" [ruleLength]="ruleLength" [tabName]="tabName" [fetchedVisitId]="fetchedVisitId" [fetchedFormId]="fetchedFormId" [fetchedFieldId]="fetchedFieldId" [fetchValidationData]="fetchValidationData" [visitListMainData]="visitListMainData" [visitListData]="visitListData" [logVisitListMainData]="logVisitListMainData" [logVisitListData]="logVisitListData"></app-validation-rule-impact>
            </div>
            <div *ngIf="tabName == 'logMapping'">
                <app-logmapping-impact [formRuleValidation]="validationAndLogMapping" [formType]="formType" [ruleLength]="ruleLength" [tabName]="tabName" [fetchedVisitId]="fetchedVisitId" [fetchedFormId]="fetchedFormId" [fetchedFieldId]="fetchedFieldId" [fetchValidationData]="fetchValidationData"></app-logmapping-impact>
            </div>
        </div>
    </div>
</div>