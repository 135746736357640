<div class="modal-content">
	<div class="modal-header">
		<h3 class="modal-title">
			Audit Trail 
			<span *ngIf="fieldName">({{fieldName}})</span>
		</h3>
		<button type="button" class="btn-close" (click)="closeModal()"></button>
	</div>
	<div class="modal-body">
		<div class="table-responsive" *ngIf="fieldLevelData.length; else noData">
			<table id="myTable" class="table table-hover table-light">
				<thead>
					<tr>
						<!-- <th>Sr.No</th> -->
						<th>Date & Time</th>
						<th>Old Value</th>
						<th>New Value</th>
						<th>Reason</th>
						<th>Comment</th>
						<th>Added/Modified by</th>
					</tr>
				</thead>
				<tbody *ngFor="let fld of fieldLevelData;let i=index">
					<tr>
						<!-- <td>{{i+1}}</td> -->
						<td>{{fld.createdDate | date : studyDateFormat}}</td>
						<td>{{fld.oldValue}}</td>
						<td>{{fld.newValue}}</td>
						<td>{{fld.reason}}</td>
						<td>{{fld.comment}}</td>
						<td>{{fld.createdByName}}</td>
					</tr>
				</tbody>
				<tfoot>
					<ngb-pagination [collectionSize]="totalPages" [(page)]="page" [boundaryLinks]="true" [rotate]="true" [ellipses]="false" (pageChange)="onChangePagination()">
						<ng-template ngbPaginationPages let-page let-pages="pages">
							<li class="ngb-custom-pages-item" *ngIf="pages.length > 0">
								<div class="mb-3 d-flex flex-nowrap px-2">
									<label id="paginationInputLabel" for="paginationInput" class="col-form-label me-2 ms-1">Page</label>
									<input
									#i
									type="text"
									inputmode="numeric"
									pattern="[0-9]*"
									class="form-control custom-pages-input"
									id="paginationInput"
									[value]="page"
									(keyup.enter)="selectPage(i.value)"
									(blur)="selectPage(i.value)"
									(input)="formatInput($any($event).target)"
									aria-labelledby="paginationInputLabel paginationDescription"
									style="width: 2.5rem"
									/>
									<span id="paginationDescription" class="col-form-label text-nowrap px-2"> of {{pages.length}}</span>
								</div>
							</li>
						</ng-template>
					</ngb-pagination>
				</tfoot>
			</table>
		</div>
		<ng-template #noData>
			<h5>No Records Found!</h5>
		</ng-template>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-danger" (click)="closeModal()">
			<b>Close</b>
		</button>
	</div>
</div>