<div class="container-fluid">
  <!-- Heading -->
  <div class="row">
    <div class="col-12">
      <div class="jumbotron jumbotron-fluid">
        <p> <span class="fs-22"> {{visitName}} </span> <span class="fs-22">> </span> <span class="fs-22" >{{message1}} <sup [title]="'Annotation Name : '+message2"><img src="../../../assets/icons/info.svg" height="18" width="18" alt="Infomation Icon for Annotation Name" /></sup></span> </p>
        <h4 class="lead">{{title}}</h4>
      </div>
    </div>
  </div>
  <hr>
  <div class="row" *ngFor="let item of envData; let i =index;" [ngSwitch]="envData[i].dataTypeId">
    <div class="col-10">
      <!-- TextView -->
      <div class="form-group row" *ngSwitchCase="dataType[0].id">
        <label class="col-sm-5 col-form-label" [for]="item.label" >
          <b>{{item.label}} </b>
        </label>
        <div class="col">
          <input type="text" class="form-control" [id]="item.label" [name]="item.annotatedName"
          [placeholder]="item.placeholder? item.placeholder:''" [required]="item.required" 
          [minLength]="item.minValue" [maxLength]="item.maxValue"
          [min]="item.minValue" [max]="item.maxValue" autocomplete="off">
          <div *ngIf="item.note" class="row" style="margin-bottom: 15px;">
            <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{item.note}}</i></span>
          </div>
        </div>
        <div *ngIf="item.unit" class="col-2 mt-2">
          <span><b>{{item.unit}}</b></span>
        </div>
      </div>
      <!-- TextArea -->
      <div class="form-group row" *ngSwitchCase="dataType[1].id">
        <label class="col-form-label col-sm-5" [for]="item.annotatedName" >
          <b>{{item.label}} </b>
        </label>
        <div class="col">
          <textarea class="form-control" autocomplete="off" [name]="item.annotatedName" [id]="item.annotatedName"
          [placeholder]="item.placeholder? item.placeholder:''" [required]="item.required" [minLength]="item.minValue" 
          [maxLength]="item.maxValue"></textarea>
          <div class="row" *ngIf="item.note" style="margin-bottom: 15px;">
            <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{item.note}}</i></span>
          </div>
        </div>
      </div>
      <!-- Number -->
      <div class="form-group row" *ngSwitchCase="dataType[2].id">
        <label class="col-form-label col-sm-5 " [for]="item.label" >
          <b>{{item.label}} </b>
        </label>
        <div class="col">
          <input type="number" class="form-control" [id]="item.label" [name]="item.annotatedName" autocomplete="off"
          [placeholder]="item.placeholder? item.placeholder:''" [disabled]="item.isFormula"  
          [step]="item.decimalPoints" [required]="item.required" [minLength]="item.minValue" 
          [maxLength]="item.maxValue" [min]="item.minValue" [max]="item.maxValue">
          <div class="row" *ngIf="item.note" style="margin-bottom: 15px;">
            <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{item.note}}</i></span>
          </div>
        </div>
        <div *ngIf="item.unit" class="col-2 mt-2">
          <span><b>{{item.unit}}</b></span>
        </div>
      </div>
      <!-- Email -->
      <div class="form-group row" *ngSwitchCase="dataType[3].id">
        <label class="col-form-label col-sm-5" [for]="item.label" >
          <b>{{item.label}} </b>
        </label>
        <div class="col">
          <input type="email" class="form-control" [id]="item.label" [name]="item.annotatedName"
          [placeholder]="item.placeholder? item.placeholder:''" [required]="item.required" [minLength]="item.minValue" 
          [maxLength]="item.maxValue" [min]="item.minValue" [max]="item.maxValue">
          <div class="row" *ngIf="item.note" style="margin-bottom: 15px;">
            <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{item.note}}</i></span>
          </div>
        </div>
      </div>
      <!-- Passsword -->
      <div class="form-group row" *ngSwitchCase="dataType[4].id">
        <label class="col-form-label col-sm-5 " [for]="item.label" >
          <b>{{item.label}} </b>
        </label>
        <div class="col">
          <input type="password" class="form-control" [id]="item.label" [name]="item.annotatedName"
          [placeholder]="item.placeholder? item.placeholder:''" [required]="item.required" [minLength]="item.minValue" 
          [maxLength]="item.maxValue" [min]="item.minValue" [max]="item.maxValue">
          <div class="row" *ngIf="item.note" style="margin-bottom: 15px;">
            <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{item.note}}</i></span>
          </div>
        </div>
      </div>
      <!-- CheckBox -->
      <div class="form-group row" *ngSwitchCase="dataType[5].id">
        <label [for]="item.name" class="form-check-label col-sm-5 " >
          <b>{{item.label}} </b>
        </label>
        <div class="col">
          <div class="form-check" *ngFor="let option of item.options">
            <input id="option.name" class="form-check-input" type="checkbox" [name]="'check-'+i" 
            [value]="option.value" style="margin-bottom: 15px;" [required]="item.required">
            <label for="option.name" class="form-check-label">{{option.name}}</label>
          </div>
          <div class="row" *ngIf="item.note" style="margin-bottom: 15px;">
            <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{item.note}}</i></span>
          </div>
        </div>
      </div>
      <!-- Radio Button -->
      <div class="form-group row" *ngSwitchCase="dataType[6].id">
        <label [for]="item.name" class="form-check-label col-sm-5" >
          <b>{{item.label}} </b>
        </label>
        <div class="col">
          <div class="form-check" *ngFor="let option of item.options">
            <input [id]="option.name" class="form-check-input" type="radio" [name]="'radio-'+i" [value]="option.value"
            style="margin-bottom: 15px;" [name]="'radio-'+i" [required]="item.required">
            <label [for]="option.name" class="form-check-label">{{option.name}}</label>
          </div>
          <div class="row" *ngIf="item.note" style="margin-bottom: 15px;">
            <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{item.note}}</i></span>
          </div>
        </div>
      </div>
      <!-- Selection-Box -->
      <div class="form-group row" *ngSwitchCase="dataType[7].id">
        <label [for]="item.name" class="form-check-label col-sm-5" >
          <b>{{item.label}} </b>
        </label>
        <div class="col">
          <select class="form-select" [required]="item.required">
            <option selected disabled>Select</option>
            <option *ngFor="let option of item.options" [value]="option.name">{{option.name}}</option>
          </select>
          <div class="row" *ngIf="item.note">
            <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{item.note}}</i></span>
          </div>
        </div>
      </div>
      <!-- Button -->
      <div class="form-group rowcol-sm-12" *ngSwitchCase="dataType[8].id">
        <input [type]="item.type" class="btn btn-outline-success" [value]="item.value" [name]="item.name"
        style="margin-bottom: 15px;">
      </div>
      <!-- Heading -->
      <div class="form-group row" *ngSwitchCase="dataType[9].id">
        <div [ngSwitch]="item.formatting.headingSize" style="margin-bottom: 15px;">
          <div *ngSwitchCase="'Large'" >
            <h1>{{item.label}}</h1>
          </div>
          <div *ngSwitchCase="'Medium'" >
            <h3>{{item.label}}</h3>
          </div>
          <div *ngSwitchCase="'Small'" >
            <h5>{{item.label}}</h5>
          </div>
          <div *ngIf="item.formatting.divider">
            <hr>
          </div>
        </div>
      </div>
      <!-- Sub-Heading -->
      <div class="form-group row" *ngSwitchCase="dataType[10].id">
        <label class="col-form-label col-sm-12 " [style.text-decoration]="item.formatting.underline"
        [style.font-style]="item.formatting.italic" [style.font-weight]="item.formatting.bold"
        [style.color]="item.formatting.color" [style.text-align]="item.formatting.alignment" >
        {{item.label}}
        </label>
      </div>
      <!-- Date -->
      <div class="form-group row" *ngSwitchCase="dataType[11].id">
        <label class="col-form-label col-sm-5" [for]="item.label" >
          <b>{{item.label}} </b>
        </label>
        <div class="col">
          <div class="form-group" *ngIf="datePicker">
            <div class="input-group">
              <input class="form-control" [placeholder]="item.formatting.dateFormat? item.formatting.dateFormat:''" [name]="item.annotatedName" 
              [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" [required]="item.required" 
              [minDate]="getMinDate(item)" [maxDate]="getMaxDate(item)" [readOnly]="true" [dayTemplate]="customDay" (click)="d.toggle()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="d.toggle()" [disabled]="item.isFormula">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
              let-disabled="disabled" let-focused="focused">
              <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                [class.text-muted]="date.month !== currentMonth">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
          <div class="form-group" *ngIf="datePickerSelection">
            <div class="input-group">
              <div class="form-floating mb-3">
                <select class="form-select" id="date" [required]="item.required">
                  <option selected value="UK">UK</option>
                  <option *ngFor="let item of dateData" [value]="item"><b>{{item}}</b></option>
                </select>
                <label for="date"><b>Date </b></label>
              </div>&nbsp;&nbsp;
              <div class="form-floating mb-3">
                <select class="form-select" id="month" [required]="item.required">
                  <option value="UNK" selected>UNK</option>
                  <option *ngFor="let item of monthData" [value]="item"><b>{{item}}</b></option>
                </select>
                <label for="month"><b>Month </b></label>
              </div>&nbsp;&nbsp;
              <div class="form-floating mb-3">
                <select class="form-select" id="year" [required]="item.required">
                  <option value="UNKK" selected>UNKK</option>
                  <option *ngFor="let item of yearData" [value]="item"><b>{{item}}</b></option>
                </select>
                <label for="year"><b>Year </b></label>
              </div>&nbsp;&nbsp;
            </div>
          </div>
          <div class="row" *ngIf="item.note">
            <span><b>Note:</b>&nbsp;<i [style.color]="noteColor">{{item.note}}</i></span>
          </div>
          <br>
          <div class="row" *ngIf="checkboxData">
            <div class="form-check-inline">
              <input class="form-check-input" type="checkbox" [name]="'unknownDate-'+i" [id]="'unknownDate-'+i" [disabled]="item.isFormula"
              value="unknownDate" (change)="onCheckboxUnknownDateSelected($event)">&nbsp;
              <label class="form-check-label" [for]="'unknownDate-'+i">
                <b>Unknown Date?</b>
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- Time -->
      <div class="form-group row" *ngSwitchCase="dataType[12].id">
        <label class="col-form-label col-sm-5" [for]="item.label" >
          <b>{{item.label}} </b>
        </label>
        <div class="col-sm-7">
          <div class="form-group" [ngSwitch]="item.formatting.hourFormat">
            <!-- 12 Hrs -->
            <div class="row" *ngSwitchCase="'12 hrs'">
              <!-- hours -->
              <div class="col-3" *ngIf="item.formatting.hour == true || item.formatting.hour == 'true'">
                <div class="form-floating">
                  <select class="form-select" id="hours" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UK" selected>UK</option>
                    <option *ngFor="let hr of hours12" [value]="hr">{{hr}}</option>
                  </select>
                  <label for="hours">Hrs </label>
                </div>
              </div>
              <!-- minutes -->
              <div class="col-3" *ngIf="item.formatting.minute == true || item.formatting.minute == 'true'">
                <div class="form-floating">
                  <select class="form-select" id="minutes" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UM" selected>UM</option>
                    <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                  </select>
                  <label for="minutes">Min </label>
                </div>
              </div>
              <!-- Seconds -->
              <div class="col-3" *ngIf="item.formatting.second == true || item.formatting.second == 'true'">
                <div class="form-floating">
                  <select class="form-select" id="minutes" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UN" selected>UN</option>
                    <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                  </select>
                  <label for="minutes">Sec </label>
                </div>
              </div>
              <!-- midday -->
              <div class="col-3" *ngIf="item.formatting.hourFormat == '12 hrs' && item.formatting.midDay == true || item.formatting.midDay == 'true'">
                <div class="form-floating">
                  <select class="form-select" id="midday" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UM" selected>UM</option>
                    <option *ngFor="let md of midday" [value]="md">{{md}}</option>
                  </select>
                  <label for="midday">AM/PM </label>
                </div>
              </div>
            </div>
            <!-- 24 Hrs -->
            <div class="row" *ngSwitchCase="'24 hrs'">
              <!-- hours -->
              <div class="col-4" *ngIf="item.formatting.hour == true || item.formatting.hour == 'true'">
                <div class="form-floating">
                  <select class="form-select" id="hours" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UK" selected>UK</option>
                    <option *ngFor="let hr of hours24" [value]="hr">{{hr}}</option>
                  </select>
                  <label for="hours">Hrs </label>
                </div>
              </div>
              <!-- minutes -->
              <div class="col-4" *ngIf="item.formatting.minute == true || item.formatting.minute == 'true'">
                <div class="form-floating">
                  <select class="form-select" id="minutes" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UM" selected>UM</option>
                    <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                  </select>
                  <label for="minutes">Min </label>
                </div>
              </div>
              <!-- Second -->
              <div class="col-4" *ngIf="item.formatting.second == true || item.formatting.second == 'true'">
                <div class="form-floating">
                  <select class="form-select" id="minutes" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UN" selected>UN</option>
                    <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                  </select>
                  <label for="minutes">Sec </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="item.note">
            <span><b>Note:</b>&nbsp;<i [style.color]="noteColor">{{item.note}}</i></span>
          </div>
        </div>
      </div>
      <!-- Image -->
      <div class="form-group row" *ngSwitchCase="dataType[13].id">
        <label class="col-form-label col-sm-3 " [for]="item.label"><b>{{item.label}}</b></label>
        <div class="col-sm-9">
          <div class="rowtext-end">
            <div *ngIf="!item.formatting.isdefault">
              <input #file type="file" accept="image/png, image/jpeg" class="form-control" id="image"name="image" (change)="preview(file.files)">
            </div>
            <div class="row" *ngIf="item.formatting.isdefault">
              <div class="col" [style.text-align-last]="item.formatting.alignment">
                <!-- <div class="resize-container">
                  <span class="resize-handle resize-handle-nw" (dragend)="onImageReSize($event,item)"></span>
                  <span class="resize-handle resize-handle-ne"></span>
                  <img class="resize-image" src="{{'data:image/png;base64,'+item.imagePath}}" [id]="'imgData'+i" alt="Image" (load)="onImageDimention($event,item)" [ngStyle]="{'width':item.reSize != null ? item.reSize.width+'px' : '500px','height':item.reSize != null ? item.reSize.height+'px' : '300px'}">
                  <span class="resize-handle resize-handle-se"></span>
                  <span class="resize-handle resize-handle-sw"></span>
                </div> -->

                <img class="imageDefaultSize" 
										*ngIf="!isImageApiRunning"
										[src]="'data:image/png;base64,'+item.imagePath" 
										[id]="'imgData'+i"									
										alt="Image not available">
								<span class="spinner-border spinner-border-lg" 
										*ngIf="!item?.imagePath && isImageApiRunning"		
										role="status" 
										aria-hidden="true">
								</span>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="item.note">
            <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{item.note}}</i></span>
          </div>
        </div>
      </div>
      <!-- Label -->
      <div class="form-group row" *ngSwitchCase="dataType[14].id">
        <div class="col-sm-12">
          <label class="col-form-label label-underline" [for]="item.label" ><b>{{item.label}}</b></label>
        </div>
      </div>
      <!-- HyperLink -->
      <div class="form-group row" *ngSwitchCase="dataType[15].id">
        <label class="col-form-label col-sm-3 " [for]="item.label"><b>{{item.label}}</b></label>
        <div class="col-sm-9 mt-1">
          <a class="" [id]="item.label" [name]="item.annotatedName" href="{{item.link}}" target="_blank">{{item.link}}</a>
        </div>
      </div>
      <!-- Multi-Row -->
      <div class="form-group row" *ngSwitchCase="dataType[16].id">
        <div class="col-sm-12" *ngIf="item.multiRowView == 1">
          <div class="table-responsive">
            <table class="table table-striped caption-top">
              <caption><b>{{item.label}}</b></caption>
              <tr>
                <td *ngFor="let col of item.columnData" ><b>{{col.colHeading}}</b></td>
                <td><button class="btn btn-outline-primary" (click)="addColumnRows(item)">Add</button></td>
              </tr>
              <tr *ngFor="let multi of item.dataArray;let dataIndex = index">
                <td *ngFor="let col of item.columnData">
                  <!-- TextView -->
                  <div class="form-group row" *ngIf="col.type == 'TextView'">
                    <input type="text" [(ngModel)]="multi[col.colHeading]" class="form-control" [id]="col.colHeading" [name]="col.annotatedName"
                      [placeholder]="col.placeholder? col.placeholder:''" [required]="col.required" autocomplete="off"
                      [minLength]="col.minValue" [maxLength]="col.maxValue"
                      [min]="col.minValue" [max]="col.maxValue">
                      <div class="row" *ngIf="col.note">
                        <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                      </div>
                  </div>
                  <!-- TextArea -->
                  <div class="form-group row" *ngIf="col.type == 'TextArea'">
                    <textarea [(ngModel)]="multi[col.colHeading]" class="form-control" [id]="col.colHeading" [name]="col.annotatedName"
                      [placeholder]="col.placeholder?col.placeholder:''" [required]="col.required" autocomplete="off"
                      [minLength]="col.minValue" [maxLength]="col.maxValue"
                    ></textarea>
                    <div class="row" *ngIf="col.note">
                      <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                    </div>
                  </div>
                  <!-- Check-Box -->
                  <div *ngIf="col.type == 'CheckBox'">
                    <div class="form-check" *ngFor="let option of col.options">
                      <input id="option.name" class="form-check-input" type="checkbox" [name]="'check-'+dataIndex"
                      [value]="option.value" [required]="col.required">
                      <label for="option.name" class="form-check-label">{{option.name}}</label>
                    </div>
                    <div class="row" *ngIf="col.note">
                      <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                    </div>
                  </div>
                  <!-- Radio-Button -->
                  <div *ngIf="col.type == 'RadioButton'">
                    <div class="form-check" *ngFor="let option of col.options">
                      <input id="option.name" class="form-check-input" type="radio" [name]="'radio-' + dataIndex"
                      [value]="option.value" [required]="col.required">
                      <label for="option.name" class="form-check-label">{{option.name}}</label>
                    </div>
                    <div class="row" *ngIf="col.note">
                      <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                    </div>
                  </div>
                  <!-- Email -->
                  <div *ngIf="col.type == 'Email'">
                    <input type="email" [(ngModel)]="multi[col.colHeading]" class="form-control" [id]="col.colHeading" [name]="col.annotatedName"
                      [placeholder]="col.placeholder? col.placeholder:''" [required]="col.required" [minLength]="col.minValue" 
                      [maxLength]="col.maxValue" [min]="col.minValue" [max]="col.maxValue">
                      <div class="row" *ngIf="col.note">
                        <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                      </div>
                  </div>
                  <!-- Number -->
                  <div class="row" *ngIf="col.type == 'Number'">
                    <div class="col">
                      <input type="number" class="form-control" [id]="col.colHeading" [name]="col.annotatedName" autocomplete="off"
                      [placeholder]="col.placeholder? col.placeholder:''" [disabled]="col.isFormula" [required]="col.required"
                      [step]="col.decimalPoints" [min]="col.minValue" [max]="col.maxValue"
                      [minLength]="col.minValue" [maxLength]="col.maxValue">
                    </div>
                    <div *ngIf="col.unit" class="col-sm-2 mt-2">
                      <span><b>{{col.unit}}</b></span>
                    </div>
                    <div class="row" *ngIf="col.note">
                      <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                    </div>
                  </div>
                  <!-- Selection-Box -->
                  <div *ngIf="col.type == 'SelectionBox'">
                    <select class="form-select" [required]="col.required">
                      <option selected disabled>Select</option>
                      <option *ngFor="let option of col.options" [value]="option.name">{{option.name}}</option>
                    </select>
                    <div class="row" *ngIf="col.note">
                      <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                    </div>
                  </div>
                  <!-- Date -->
                  <div *ngIf="col.type == 'Date'">
                    <div class="form-group" *ngIf="datePicker">
                      <div class="input-group">
                        <input class="form-control" [placeholder]="col.formatting.dateFormat? col.formatting.dateFormat:''" [name]="col.annotatedName"
                        [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" [required]="col.required" 
                        [minDate]="getMinDate(col)" [maxDate]="getMaxDate(col)" [readOnly]="true" [dayTemplate]="customDay" (click)="d.toggle()">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button" [disabled]="col.isFormula">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                        let-disabled="disabled" let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                          [class.text-muted]="date.month !== currentMonth">
                          {{ date.day }}
                        </span>
                      </ng-template>
                    </div>
                    <div class="form-group" *ngIf="datePickerSelection">
                      <div class="input-group">
                        <div class="form-floating mb-3">
                          <select class="form-select" id="date" [required]="col.required">
                            <option selected value="UK">UK</option>
                            <option *ngFor="let item of dateData" [value]="item"><b>{{item}}</b></option>
                          </select>
                          <label for="date"><b>Date <span [style.color] = "errorIconColor" *ngIf="col.required" title="required">*</span></b></label>
                        </div>&nbsp;&nbsp;
                        <div class="form-floating mb-3">
                          <select class="form-select" id="month" [required]="col.required">
                            <option value="UNK" selected>UNK</option>
                            <option *ngFor="let item of monthData" [value]="item"><b>{{item}}</b></option>
                          </select>
                          <label for="month"><b>Month <span [style.color] = "errorIconColor" *ngIf="col.required" title="required">*</span></b></label>
                        </div>&nbsp;&nbsp;
                        <div class="form-floating mb-3">
                          <select class="form-select" id="year" [required]="col.required">
                            <option value="UNKK" selected>UNKK</option>
                            <option *ngFor="let item of yearData" [value]="item"><b>{{item}}</b></option>
                          </select>
                          <label for="year"><b>Year <span [style.color] = "errorIconColor" *ngIf="col.required" title="required">*</span></b></label>
                        </div>&nbsp;&nbsp;
                      </div>
                    </div>
                    <span *ngIf="col.note"><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                    <br>
                    <div class="row" *ngIf="checkboxData">
                      <div class="form-check-inline">
                        <input class="form-check-input" type="checkbox" [name]="'unknownDate-'+dataIndex" [id]="'unknownDate-'+dataIndex" [disabled]="col.isFormula"
                        value="unknownDate" (change)="onCheckboxUnknownDateSelected($event)">&nbsp;
                        <label class="form-check-label" [for]="'unknownDate-'+dataIndex">
                          <b>Unknown Date?</b>
                        </label>
                      </div>
                    </div>
                  </div>
                  <!-- Time -->
                  <div *ngIf="col.type == 'Time'">
                    <div class="form-group" [ngSwitch]="col.formatting.hourFormat">
                      <!-- 12 Hrs -->
                      <div class="row" *ngSwitchCase="'12 hrs'">
                        <!-- hours -->
                        <div class="col-3" *ngIf="col.formatting.hour == true || col.formatting.hour == 'true'">
                          <div class="form-floating">
                            <select class="form-select" id="hours" [required]="col.required">
                              <option selected disabled>Select</option>
                              <option value="UK" selected>UK</option>
                              <option *ngFor="let hr of hours12" [value]="hr">{{hr}}</option>
                            </select>
                            <label for="hours">Hrs <span [style.color] = "errorIconColor" *ngIf="col.required" title="required">*</span></label>
                          </div>
                        </div>
                        <!-- minutes -->
                        <div class="col-3" *ngIf="col.formatting.minute == 'true' || col.formatting.minute == true">
                          <div class="form-floating">
                            <select class="form-select" id="minutes" [required]="col.required">
                              <option selected disabled>Select</option>
                              <option value="UM" selected>UM</option>
                              <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                            </select>
                            <label for="minutes">Min <span [style.color] = "errorIconColor" *ngIf="col.required" title="required">*</span></label>
                          </div>
                        </div>
                        <!-- Seconds -->
                        <div class="col-3" *ngIf="col.formatting.second == 'true' || col.formatting.second == true">
                          <div class="form-floating">
                            <select class="form-select" id="minutes" [required]="col.required">
                              <option selected disabled>Select</option>
                              <option value="UN" selected>UN</option>
                              <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                            </select>
                            <label for="minutes">Sec <span [style.color] = "errorIconColor" *ngIf="col.required" title="required">*</span></label>
                          </div>
                        </div>
                        <!-- midday -->
                        <div class="col-3" *ngIf="col.formatting.hourFormat == '12 hrs' && col.formatting.midDay == true || col.formatting.midDay == 'true'">
                          <div class="form-floating">
                            <select class="form-select" id="midday" [required]="col.required">
                              <option selected disabled>Select</option>
                              <option value="UM" selected>UM</option>
                              <option *ngFor="let md of midday" [value]="md">{{md}}</option>
                            </select>
                            <label for="midday">AM/PM <span [style.color] = "errorIconColor" *ngIf="col.required" title="required">*</span></label>
                          </div>
                        </div>
                      </div>
                      <!-- 24 Hrs -->
                      <div class="row" *ngSwitchCase="'24 hrs'">
                        <!-- hours -->
                        <div class="col-4" *ngIf="col.formatting.hour == 'true' || col.formatting.hour == true">
                          <div class="form-floating">
                            <select class="form-select" id="hours" [required]="col.required">
                              <option selected disabled>Select</option>
                              <option value="UK" selected>UK</option>
                              <option *ngFor="let hr of hours24" [value]="hr">{{hr}}</option>
                            </select>
                            <label for="hours">Hrs <span [style.color] = "errorIconColor" *ngIf="col.required" title="required">*</span></label>
                          </div>
                        </div>
                        <!-- minutes -->
                        <div class="col-4" *ngIf="col.formatting.minute == true || col.formatting.minute == 'true'">
                          <div class="form-floating">
                            <select class="form-select" id="minutes" [required]="col.required">
                              <option selected disabled>Select</option>
                              <option value="UM" selected>UM</option>
                              <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                            </select>
                            <label for="minutes">Min <span [style.color] = "errorIconColor" *ngIf="col.required" title="required">*</span></label>
                          </div>
                        </div>
                        <!-- Second -->
                        <div class="col-4" *ngIf="col.formatting.second == 'true' || col.formatting.second == true">
                          <div class="form-floating">
                            <select class="form-select" id="minutes" [required]="col.required">
                              <option selected disabled>Select</option>
                              <option value="UN" selected>UN</option>
                              <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                            </select>
                            <label for="minutes">Sec <span [style.color] = "errorIconColor" *ngIf="col.required" title="required">*</span></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="col.note">
                      <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                    </div>
                  </div>
                  <!-- Label -->
                  <div *ngIf="col.type == 'Label'">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label label-underline" [for]="col.colHeading" ><b>{{col.colHeading}}</b></label>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <button class="btn btn-outline-warning">Update</button> &nbsp;&nbsp;
                  <button class="btn btn-outline-danger" (click)="deleteColumnData(item.dataArray,dataIndex)">Delete</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-sm-12" *ngIf="item.multiRowView == 2">
          <div class="card">
            <div class="card-header" >
              {{item.label}}
              <button class="control-buttons text-end" title="View Data" (click)="toggleTableData()">
                <span class="dataTableIcon"></span>
              </button>
            </div>
            <div class="card-body">
              <table class="table" *ngIf="toggleTable">
                <thead>
                  <tr>
                    <th>Row-ID</th>
                    <th *ngFor="let col of item.columnData" >{{col.colHeading}}</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let multi of mainMultiRowArray()?.value">
                    <td>{{multi.rowId}}</td>
                    <td *ngFor="let col of item.columnData">{{multi[col.colHeading]}}</td>
                    <td>
                      <!-- Edit -->
                      <button class="control-buttons" title="Update">
                        <span class="updateIcon"></span>
                      </button>
                      <!-- Delete -->
                      <button class="control-buttons" title="Delete">
                        <span class="deleteIcon"></span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-1 text-center">
                  <h4>1</h4>
                </div>
                <div class="col-11 text-center">
                  <hr>
                </div>
                <div *ngFor="let col of item.columnData;let dataIndex = index" [ngSwitch]="col.dataTypeId">
                  <!-- TextView -->
                  <div class="form-group row" *ngSwitchCase="1">
                    <label class="col-2 col-form-label" [for]="col.colHeading" >
                      <b>{{col.colHeading}} </b>
                    </label>
                    <div class="col">
                      <input type="text" class="form-control" autocomplete="off"
                      [id]="col.colHeading" [name]="col.annotatedName" [placeholder]="col.placeholder? col.placeholder:''" 
                      [minLength]="col.minValue == '' ? 0 : col.minValue" 
                      [maxLength]="col.maxValue == '' ? 32000 : col.maxValue" [required]="col.required">
                      <div *ngIf="col.note" class="row">
                        <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                      </div>
                    </div>
                    <div *ngIf="col.unit" class="col-sm-2 mt-2">
                      <span><b>{{col.unit}}</b></span>
                    </div>
                  </div>
                  <!-- TextArea -->
                  <div class="form-group row" *ngSwitchCase="2">
                    <label class="col-2 col-form-label" [for]="col.colHeading" >
                      <b>{{col.colHeading}} </b>
                    </label>
                    <div class="col">
                      <textarea class="form-control" autocomplete="off"
                      [id]="col.colHeading" [name]="col.annotatedName" [placeholder]="col.placeholder? col.placeholder:''" 
                      [minLength]="col.minValue == '' || col.minValue == null ? 0 : col.minValue" 
                      [maxLength]="col.maxValue == '' || col.maxValue == null ? 32000 : col.maxValue" [required]="col.required"></textarea>
                      <div *ngIf="col.note" class="row">
                        <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                      </div>
                    </div>
                    <div *ngIf="col.unit" class="col-sm-2 mt-2">
                      <span><b>{{col.unit}}</b></span>
                    </div>
                  </div>
                  <!-- Number -->
                  <div class="form-group row" *ngSwitchCase="3">
                    <label class="col-2 col-form-label" [for]="col.colHeading" >
                      <b>{{col.colHeading}} </b>
                    </label>
                    <div [ngClass]=" col.isFormula ? 'col-9' : 'col' ">
                      <input type="number" class="form-control" autocomplete="off"
                      [id]="col.colHeading" [name]="col.annotatedName" [placeholder]="col.placeholder? col.placeholder:''" 
                      [disabled]="col.isFormula" [required]="col.required" 
                      [step]="col.decimalPoints" [minLength]="col.minValue == '' ? 0 : col.minValue" 
                      [maxLength]="col.maxValue == '' ? 32000 : col.maxValue" 
                      [min]="col.minValue == '' ? 0 : col.minValue" 
                      [max]="col.maxValue == '' ? 32000 : col.maxValue">
                      <div *ngIf="col.note" class="row">
                        <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                      </div>
                    </div>
                    <div class="col-1" *ngIf="col.isFormula">
                      <button class="control-buttons" title="Add Formula" *ngIf="col.isFormula" (click)="addFormula(item,item.formulaId,col)">
                        <i class="fa fa-fw fa-f" *ngIf="formulaCountData == null || formulaCountData[item.id][col.id] == undefined || formulaCountData[item.id][col.id] == 0"></i>
                        <i class="fa fa-fw fa-f" *ngIf="formulaCountData != null && formulaCountData[item.id][col.id] > 0" [style.color] = "errorIconColor"></i>
                      </button>
                    </div>
                    <div *ngIf="col.unit" class="col-sm-2 mt-2">
                      <span><b>{{col.unit}}</b></span>
                    </div>
                  </div>
                  <!-- Email -->
                  <div class="form-group row " *ngSwitchCase="4">
                    <label class="col-2 col-form-label" [for]="col.colHeading" >
                      <b>{{col.colHeading}}</b>
                    </label>
                    <div class="col">
                      <input type="email" class="form-control" [id]="col.colHeading" [name]="col.annotatedName"
                      [placeholder]="col.placeholder? col.placeholder:''" [required]="col.required" [minLength]="col.minValue == '' ? 0 : col.minValue" 
                      [maxLength]="col.maxValue == '' ? 32000 : col.maxValue">
                      <div class="row" *ngIf="col.note">
                        <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                      </div>
                    </div>
                  </div>
                  <!-- CheckBox -->
                  <div class="form-group row" *ngSwitchCase="6">
                    <label [for]="col.name" class="col-2 form-check-label" >
                      <b>{{col.colHeading}} </b>
                    </label>
                    <div class="col">
                      <div class="form-check" *ngFor="let option of col.options">
                        <input id="option.name" class="form-check-input" type="CheckBox" [name]="'check-'+i"
                        [value]="option.value" [required]="col.required">
                        <label for="option.name" class="form-check-label">{{option.name}}</label>
                      </div>
                      <div *ngIf="col.note" class="row">
                        <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                      </div>
                    </div>
                  </div>
                  <!-- Radio Button -->
                  <div class="form-group row" *ngSwitchCase="7">
                    <label [for]="col.name" class="col-2 form-check-label" >
                      <b>{{col.colHeading}} </b>
                    </label>
                    <div class="col">
                      <div class="form-check" *ngFor="let option of col.options">
                        <input id="option.name" class="form-check-input" type="radio"
                        [value]="option.value" [name]="'radio-'+i" [required]="col.required">
                        <label for="option.name" class="form-check-label">{{option.name}}</label>
                      </div>
                      <div *ngIf="col.note" class="row">
                        <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                      </div>
                    </div>
                  </div>
                  <!-- Selection-Box -->
                  <div class="form-group row" *ngSwitchCase="8">
                    <label [for]="col.colHeading" class="col-2 form-check-label" >
                      <b>{{col.colHeading}} </b>
                    </label>
                    <div class="col">
                      <select class="form-select" [required]="col.required">
                        <option selected disabled>Select</option>
                        <option *ngFor="let option of col.options" [value]="option.name">
                          {{option.name}}
                        </option>
                      </select>
                      <div *ngIf="col.note" class="row">
                        <span><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                      </div>
                    </div>
                  </div>
                  <!-- Date -->
                  <div class="form-group row " *ngSwitchCase="12">
                    <label class="col-2 col-form-label" [for]="col.colHeading" >
                      <b>{{col.colHeading}} </b>
                    </label>
                    <div [ngClass]=" col.isFormula ? 'col-9' : 'col' ">
                      <div class="form-group" *ngIf="datePicker">
                        <div class="input-group">
                          <input class="form-control" [placeholder]="col.formatting.dateFormat? col.formatting.dateFormat:''" [name]="col.annotatedName"
                          [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" [required]="col.required"
                          [minDate]="getMinDate(col)" [maxDate]="getMaxDate(col)" [readOnly]="true" [dayTemplate]="customDay" (click)="d.toggle()">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button" [disabled]="col.isFormula">
                              <i class="fa fa-calendar" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                        <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                          let-disabled="disabled" let-focused="focused">
                          <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                            [class.text-muted]="date.month !== currentMonth">
                            {{ date.day }}
                          </span>
                        </ng-template>
                      </div>
                      <div class="form-group" *ngIf="datePickerSelection">
                        <div class="input-group">
                          <div class="form-floating mb-3">
                            <select class="form-select" id="date" [required]="col.required">
                              <option selected value="UK">UK</option>
                              <option *ngFor="let date of dateData" [value]="date"><b>{{date}}</b></option>
                            </select>
                            <label for="date"><b>Date</b></label>
                          </div>&nbsp;&nbsp;
                          <div class="form-floating mb-3">
                            <select class="form-select" id="month" [required]="col.required">
                              <option value="UNK" selected>UNK</option>
                              <option *ngFor="let month of monthData" [value]="month"><b>{{month}}</b></option>
                            </select>
                            <label for="month"><b>Month</b></label>
                          </div>&nbsp;&nbsp;
                          <div class="form-floating mb-3">
                            <select class="form-select" id="year" [required]="col.required">
                              <option value="UNKK" selected>UNKK</option>
                              <option *ngFor="let year of yearData" [value]="year"><b>{{year}}</b></option>
                            </select>
                            <label for="year"><b>Year </b></label>
                          </div>&nbsp;&nbsp;
                        </div>
                      </div>
                      <span *ngIf="col.note"><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                      <br>
                      <div class="row" *ngIf="checkboxData">
                        <div class="form-floating mb-3">
                          <div class="form-check-inline">
                            <input class="form-check-input" type="checkbox" [name]="'unknownDate-'+dataIndex" [id]="'unknownDate-'+dataIndex" [disabled]="col.isFormula"
                            value="unknownDate" (change)="onCheckboxUnknownDateSelected($event)">&nbsp;
                            <label [for]="'unknownDate-'+dataIndex">
                              <b>Unknown Date?</b>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-1" *ngIf="col.isFormula">
                      <button class="control-buttons" title="Add Formula" *ngIf="col.isFormula" (click)="addFormula(item,item.formulaId,col)">
                        <i class="fa fa-fw fa-f" *ngIf="formulaCountData == null || formulaCountData[col.id] == undefined || formulaCountData[col.id] == 0"></i>
                        <i class="fa fa-fw fa-f" *ngIf="formulaCountData != null && formulaCountData[col.id] > 0" [style.color] = "errorIconColor"></i>
                      </button>
                    </div>
                  </div>
                  <!-- Time -->
                  <div class="form-group row " *ngSwitchCase="13">
                    <label class="col-2 col-form-label" [for]="col.colHeading" >
                      <b>{{col.colHeading}} </b>
                    </label>
                    <div class="col">
                      <div class="form-group" [ngSwitch]="col.formatting.hourFormat">
                        <!-- 12 Hrs -->
                        <div class="row" *ngSwitchCase="'12 hrs'">
                          <!-- hours -->
                          <div class="col-3" *ngIf="col.formatting.hour == true || col.formatting.hour == 'true'">
                            <div class="form-floating">
                              <select class="form-select" id="hours" [required]="col.required">
                                <option selected disabled>Select</option>
                                <option value="UK" selected>UK</option>
                                <option *ngFor="let hr of hours12" [value]="hr">{{hr}}</option>
                              </select>
                              <label for="hours">Hrs </label>
                            </div>
                          </div>
                          <!-- minutes -->
                          <div class="col-3" *ngIf="col.formatting.minute == 'true' || col.formatting.minute == true">
                            <div class="form-floating">
                              <select class="form-select" id="minutes" [required]="col.required">
                                <option selected disabled>Select</option>
                                <option value="UM" selected>UM</option>
                                <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                              </select>
                              <label for="minutes">Min </label>
                            </div>
                          </div>
                          <!-- Seconds -->
                          <div class="col-3" *ngIf="col.formatting.second == 'true' || col.formatting.second == true">
                            <div class="form-floating">
                              <select class="form-select" id="minutes" [required]="col.required">
                                <option selected disabled>Select</option>
                                <option value="UN" selected>UN</option>
                                <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                              </select>
                              <label for="minutes">Sec </label>
                            </div>
                          </div>
                          <!-- midday -->
                          <div class="col-3" *ngIf="col.formatting.hourFormat == '12 hrs' && col.formatting.midDay == true || col.formatting.midDay == 'true'">
                            <div class="form-floating">
                              <select class="form-select" id="midday" [required]="col.required">
                                <option selected disabled>Select</option>
                                <option value="UM" selected>UM</option>
                                <option *ngFor="let md of midday" [value]="md">{{md}}</option>
                              </select>
                              <label for="midday">AM/PM </label>
                            </div>
                          </div>
                        </div>
                        <!-- 24 Hrs -->
                        <div class="row" *ngSwitchCase="'24 hrs'">
                          <!-- hours -->
                          <div class="col-4" *ngIf="col.formatting.hour == 'true' || col.formatting.hour == true">
                            <div class="form-floating">
                              <select class="form-select" id="hours" [required]="col.required">
                                <option selected disabled>Select</option>
                                <option value="UK" selected>UK</option>
                                <option *ngFor="let hr of hours24" [value]="hr">{{hr}}</option>
                              </select>
                              <label for="hours">Hrs</label>
                            </div>
                          </div>
                          <!-- minutes -->
                          <div class="col-4" *ngIf="col.formatting.minute == true || col.formatting.minute == 'true'">
                            <div class="form-floating">
                              <select class="form-select" id="minutes" [required]="col.required">
                                <option selected disabled>Select</option>
                                <option value="UM" selected>UM</option>
                                <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                              </select>
                              <label for="minutes">Min </label>
                            </div>
                          </div>
                          <!-- Second -->
                          <div class="col-4" *ngIf="col.formatting.second == 'true' || col.formatting.second == true">
                            <div class="form-floating">
                              <select class="form-select" id="minutes" [required]="col.required">
                                <option selected disabled>Select</option>
                                <option value="UN" selected>UN</option>
                                <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                              </select>
                              <label for="minutes">Sec </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span *ngIf="col.note"><b>Note:</b>&nbsp;<i [style.color] = "noteColor">{{col.note}}</i></span>
                    </div>
                  </div>
                  <!-- Label -->
                  <div *ngSwitchCase="15">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label label-underline" [for]="col.colHeading" ><b>{{col.colHeading}}</b></label>
                      </div>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
            <div class="card-footer text-end">
              <button class="btn btn-outline-primary" [hidden]="roles == readOnly" (click)="addColumnRows(item)"><b>Save</b></button>&nbsp;&nbsp;
              <button class="btn btn-outline-danger" [hidden]="roles == readOnly" ><b>Reset</b></button>
            </div>
          </div>
        </div>
      </div>
      <!-- Table Component -->
      <div class="form-group row" *ngSwitchCase="dataType[17].id">
        <div class="row">
          <div class="col" style="overflow-y:auto">
            <table class="table table-bordered caption-top">
              <caption >{{item.label}}</caption>
              <tbody>
                <tr *ngFor="let a of getRowInfo(i);let rowIndex = index">
                  <td *ngFor="let b of getColumnInfo(i,rowIndex);let columnIndex = index" 
                    [hidden]="getCellInfoData(i,rowIndex,columnIndex,0).isHidden"
                    [attr.colspan]="getCellInfoData(i,rowIndex,columnIndex,0).colspan" 
                    [attr.rowspan]="getCellInfoData(i,rowIndex,columnIndex,0).rowspan">
                    <div class="container" *ngFor="let cellArray of getCellInfo(i,rowIndex,columnIndex);let cellIndex = index">
                      <!-- TextView -->
                      <div *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).dataTypeId == tableType[0].id">
                        <div class="row">
                          <input class="form-control" type="text" 
                          [placeholder]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).placeholder != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).placeholder : ''"
                          [minLength]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue : 0"
                          [maxLength]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue : 999999999">
                        </div>
                        <div class="row" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).note != undefined">
                          <label>{{getCellInfoData(i,rowIndex,columnIndex,cellIndex).note}}</label>
                        </div>
                      </div>
                      <!-- TextArea -->
                      <div *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).dataTypeId == tableType[1].id">
                        <div class="row">
                          <textarea class="form-control" autocomplete="off"
                          [name]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).annotatedName" [id]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).annotatedName"
                          [placeholder]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).placeholder != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).placeholder : ''" 
                          [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false" 
                          [minLength]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue : 0"
                          [maxLength]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue : 999999999"></textarea>
                        </div>
                        <div class="row" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).note != undefined" class="row ">
                          <i>{{getCellInfoData(i,rowIndex,columnIndex,cellIndex).note}}</i>
                        </div>
                      </div>
                      <!-- Number -->
                      <div *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).dataTypeId == tableType[2].id">
                        <div class="row">
                          <div class="col">
                            <input type="number" class="form-control" [id]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).annotatedName" 
                            [name]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).annotatedName" autocomplete="off"
                            [placeholder]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).placeholder != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).placeholder : ''" 
                            [disabled]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).isFormula != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).isFormula : false" 
                            [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false" 
                            [step]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).decimalPoints != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).decimalPoints : 0" 
                            [minLength]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue : 0" 
                            [maxLength]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue : 9999999999" 
                            [min]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue : 0" 
                            [max]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue : 9999999999">
                          </div>
                          <div class="col-sm-2 mt-2" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).unit != undefined">
                            <span><b>{{getCellInfoData(i,rowIndex,columnIndex,cellIndex).unit}}</b></span>
                          </div>
                        </div>
                        <div class="row" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).note != undefined">
                          <i>{{getCellInfoData(i,rowIndex,columnIndex,cellIndex).note}}</i>
                        </div>
                      </div>
                      <!-- Email -->
                      <div *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).dataTypeId == tableType[3].id">
                        <div class="row">
                          <div class="col">
                            <input type="email" class="form-control" 
                            [id]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).annotatedName" 
                            [name]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).annotatedName"
                            [placeholder]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).placeholder != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).placeholder : ''" 
                            [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false" 
                            [minLength]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue : 0" 
                            [maxLength]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue : 9999999999" 
                            [min]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue : 0" 
                            [max]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue : 9999999999">
                          </div>
                        </div>
                        <div class="row" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).note != undefined">
                          <i>{{getCellInfoData(i,rowIndex,columnIndex,cellIndex).note}}</i>
                        </div>
                      </div>
                      <!-- Password -->
                      <div *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).dataTypeId == tableType[4].id">
                        <div class="row">
                          <div class="col">
                            <input type="password" class="form-control" 
                            [id]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).annotatedName" 
                            [name]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).annotatedName"
                            [placeholder]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).placeholder != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).placeholder : ''" 
                            [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false" 
                            [minLength]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue : 0" 
                            [maxLength]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue : 9999999999" 
                            [min]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).minValue : 0" 
                            [max]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).maxValue : 9999999999">
                          </div>
                        </div>
                        <div class="row" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).note != undefined">
                          <i>{{getCellInfoData(i,rowIndex,columnIndex,cellIndex).note}}</i>
                        </div>
                      </div>
                      <!-- Checkbox -->
                      <div *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).dataTypeId == tableType[5].id">
                        <div class="row">
                          <div class="form-check" *ngFor="let option of getCellInfoData(i,rowIndex,columnIndex,cellIndex).options;let check = index">
                            <input id="option.name" class="form-check-input" type="checkbox" [name]="'check-'+check"
                            [value]="option.value" [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false">
                            <label for="option.name" class="form-check-label">{{option.name}}</label>
                          </div>
                        </div>
                        <div class="row" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).note != undefined">
                          <i>{{getCellInfoData(i,rowIndex,columnIndex,cellIndex).note}}</i>
                        </div>
                      </div>
                      <!-- Radiobutton -->
                      <div *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).dataTypeId == tableType[6].id">
                        <div class="row">
                          <div class="form-check" *ngFor="let option of getCellInfoData(i,rowIndex,columnIndex,cellIndex).options;let radio = index">
                            <input id="option.name" class="form-check-input" type="radio" [name]="'radio-'+radio"
                            [value]="option.value" [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false">
                            <label for="option.name" class="form-check-label">{{option.name}}</label>
                          </div>
                        </div>
                        <div class="row" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).note != undefined">
                          <i>{{getCellInfoData(i,rowIndex,columnIndex,cellIndex).note}}</i>
                        </div>
                      </div>
                      <!-- Selectionbox -->
                      <div *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).dataTypeId == tableType[7].id">
                        <div class="row">
                          <select class="form-select" [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false">
                            <option [ngValue]="null" selected>Select</option>
                            <option *ngFor="let option of getCellInfoData(i,rowIndex,columnIndex,cellIndex).options" [value]="option.name">
                              {{option.name}}
                            </option>
                          </select>
                        </div>
                        <div class="row" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).note != undefined">
                          <i>{{getCellInfoData(i,rowIndex,columnIndex,cellIndex).note}}</i>
                        </div>
                      </div>
                      <!-- Sub-Heading -->
                      <div class="row" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).dataTypeId == tableType[8].id">
                        <label class="col-form-label col-sm-12 " 
                        [style.text-decoration]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.underline != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.underline : ''"
                        [style.font-style]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.italic != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.italic : ''" 
                        [style.font-weight]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.bold != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.bold : ''"
                        [style.color]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.color != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.color : ''" 
                        [style.text-align]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.alignment != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.alignment : ''">
                        {{getCellInfoData(i,rowIndex,columnIndex,cellIndex).label}}</label>
                      </div>
                      <!-- Date -->
                      <div *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).dataTypeId == tableType[9].id">
                        <div class="row">
                          <div class="form-group" *ngIf="!getCellInfoData(i,rowIndex,columnIndex,cellIndex).isUnknownDate">
                            <div class="input-group">
                              <input class="form-control" 
                              [placeholder]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.dateFormat != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.dateFormat : ''" 
                              [name]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).annotatedName"
                              [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" 
                              [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false"
                              [readOnly]="true" [dayTemplate]="customDay" (click)="d.toggle()">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                  <i class="fa fa-calendar" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                            <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
                              let-disabled="disabled" let-focused="focused">
                              <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                                [class.text-muted]="date.month !== currentMonth">
                                {{ date.day }}
                              </span>
                            </ng-template>
                          </div>
                          <div class="form-group" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).isUnknownDate">
                            <div class="input-group">
                              <div class="form-floating mb-3">
                                <select class="form-select" id="date" [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false">
                                  <option selected value="UK">UK</option>
                                  <option *ngFor="let item of dateData" [value]="item"><b>{{item}}</b></option>
                                </select>
                                <label for="date"><b>Date </b></label>
                              </div>&nbsp;&nbsp;
                              <div class="form-floating mb-3">
                                <select class="form-select" id="month" [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false">
                                  <option value="UNK" selected>UNK</option>
                                  <option *ngFor="let item of monthData" [value]="item"><b>{{item}}</b></option>
                                </select>
                                <label for="month"><b>Month </b></label>
                              </div>&nbsp;&nbsp;
                              <div class="form-floating mb-3">
                                <select class="form-select" id="year" [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false">
                                  <option value="UNKK" selected>UNKK</option>
                                  <option *ngFor="let item of yearData" [value]="item"><b>{{item}}</b></option>
                                </select>
                                <label for="year"><b>Year </b></label>
                              </div>&nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                        <i *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).note != undefined">{{getCellInfoData(i,rowIndex,columnIndex,cellIndex).note}}</i>
                        <br>
                        <div class="row">
                          <div class="form-floating mb-3">
                            <div class="form-check-inline">
                              <input class="form-check-input" type="checkbox" [name]="'isUnknownDate-'+cellIndex" [id]="'isUnknownDate-'+cellIndex"
                              value="isUnknownDate" (change)="checkDateIsUnkown($event,i,rowIndex,columnIndex,cellIndex)">&nbsp;
                              <label [for]="'isUnknownDate-'+cellIndex">
                                <b>Unknown Date?</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Time -->
                      <div *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).dataTypeId == tableType[10].id">
                        <div class="row">
                          <div class="form-group" [ngSwitch]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.hourFormat">
                            <!-- 12 Hrs -->
                            <div class="row" *ngSwitchCase="'12 hrs'">
                              <!-- hours -->
                              <div class="col-3" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.hour == true || getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.hour == 'true'">
                                <div class="form-floating">
                                  <select class="form-select" id="hours" [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false">
                                    <option selected disabled>Select</option>
                                    <option value="UK" selected>UK</option>
                                    <option *ngFor="let hr of hours12" [value]="hr">{{hr}}</option>
                                  </select>
                                  <label for="hours">Hrs </label>
                                </div>
                              </div>
                              <!-- minutes -->
                              <div class="col-3" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.minute == 'true' || getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.minute == true">
                                <div class="form-floating">
                                  <select class="form-select" id="minutes" [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false">
                                    <option selected disabled>Select</option>
                                    <option value="UM" selected>UM</option>
                                    <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                                  </select>
                                  <label for="minutes">Min </label>
                                </div>
                              </div>
                              <!-- Seconds -->
                              <div class="col-3" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.second == 'true' || getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.second == true">
                                <div class="form-floating">
                                  <select class="form-select" id="minutes" [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false">
                                    <option selected disabled>Select</option>
                                    <option value="UN" selected>UN</option>
                                    <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                                  </select>
                                  <label for="minutes">Sec </label>
                                </div>
                              </div>
                              <!-- midday -->
                              <div class="col-3" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.hourFormat == '12 hrs' && getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.midDay == true || getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.midDay == 'true'">
                                <div class="form-floating">
                                  <select class="form-select" id="midday" [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false">
                                    <option selected disabled>Select</option>
                                    <option value="UM" selected>UM</option>
                                    <option *ngFor="let md of midday" [value]="md">{{md}}</option>
                                  </select>
                                  <label for="midday">AM/PM </label>
                                </div>
                              </div>
                            </div>
                            <!-- 24 Hrs -->
                            <div class="row" *ngSwitchCase="'24 hrs'">
                              <!-- hours -->
                              <div class="col-4" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.hour == 'true' || getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.hour == true">
                                <div class="form-floating">
                                  <select class="form-select" id="hours" [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false">
                                    <option selected disabled>Select</option>
                                    <option value="UK" selected>UK</option>
                                    <option *ngFor="let hr of hours24" [value]="hr">{{hr}}</option>
                                  </select>
                                  <label for="hours">Hrs</label>
                                </div>
                              </div>
                              <!-- minutes -->
                              <div class="col-4" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.minute == true || getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.minute == 'true'">
                                <div class="form-floating">
                                  <select class="form-select" id="minutes" [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false">
                                    <option selected disabled>Select</option>
                                    <option value="UM" selected>UM</option>
                                    <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                                  </select>
                                  <label for="minutes">Min </label>
                                </div>
                              </div>
                              <!-- Second -->
                              <div class="col-4" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.second == 'true' || getCellInfoData(i,rowIndex,columnIndex,cellIndex).formatting.second == true">
                                <div class="form-floating">
                                  <select class="form-select" id="minutes" [required]="getCellInfoData(i,rowIndex,columnIndex,cellIndex).required != undefined ? getCellInfoData(i,rowIndex,columnIndex,cellIndex).required : false">
                                    <option selected disabled>Select</option>
                                    <option value="UN" selected>UN</option>
                                    <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                                  </select>
                                  <label for="minutes">Sec </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <i *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).note != undefined">{{getCellInfoData(i,rowIndex,columnIndex,cellIndex).note}}</i>
                      </div>
                      <!-- Label -->
                      <div class="row" *ngIf="getCellInfoData(i,rowIndex,columnIndex,cellIndex).dataTypeId == tableType[11].id">
                        <label>{{getCellInfoData(i,rowIndex,columnIndex,cellIndex).label}}</label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Date & Time -->
      <div class="form-group row" *ngSwitchCase="dataType[18].id">
        <label class="col-form-label col-sm-5" [for]="item.label" >
          <b>{{item.label}} </b>
        </label>
        <!-- Date -->
        <div class="col-sm-3">
          <div class="form-group" *ngIf="datePicker">
            <div class="input-group">
              <input class="form-control" [placeholder]="item.formatting.dateFormat? item.formatting.dateFormat:''" [name]="item.annotatedName"
              [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" [required]="item.required"
              [minDate]="getMinDate(item)" [maxDate]="getMaxDate(item)" [readOnly]="true" [dayTemplate]="customDay" (click)="d.toggle()">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected"
              let-disabled="disabled" let-focused="focused">
              <span class="custom-day" [class.focused]="focused" [class.bg-primary]="selected" 
                [class.text-muted]="date.month !== currentMonth">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
          <div class="form-group" *ngIf="datePickerSelection">
            <div class="input-group">
              <div class="form-floating mb-3">
                <select class="form-select" id="date" [required]="item.required">
                  <option selected value="UK">UK</option>
                  <option *ngFor="let item of dateData" [value]="item"><b>{{item}}</b></option>
                </select>
                <label for="date"><b>Date </b></label>
              </div>&nbsp;&nbsp;
              <div class="form-floating mb-3">
                <select class="form-select" id="month" [required]="item.required">
                  <option value="UNK" selected>UNK</option>
                  <option *ngFor="let item of monthData" [value]="item"><b>{{item}}</b></option>
                </select>
                <label for="month"><b>Month </b></label>
              </div>&nbsp;&nbsp;
              <div class="form-floating mb-3">
                <select class="form-select" id="year" [required]="item.required">
                  <option value="UNKK" selected>UNKK</option>
                  <option *ngFor="let item of yearData" [value]="item"><b>{{item}}</b></option>
                </select>
                <label for="year"><b>Year </b></label>
              </div>&nbsp;&nbsp;
            </div>
          </div>
          <div class="row" *ngIf="checkboxData">
            <div class="form-floating mb-3">
              <div class="form-check-inline">
                <input class="form-check-input" type="checkbox" [name]="'unknownDate-'+i" [id]="'unknownDate-'+i"
                value="unknownDate" (change)="onCheckboxUnknownDateSelected($event)">&nbsp;
                <label [for]="'unknownDate-'+i">
                  <b>Unknown Date?</b>
                </label>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="item.note">
            <span><b>Note:</b>&nbsp;<i [style.color]="noteColor">{{item.note}}</i></span>
          </div>
        </div>
        <!-- Time -->
        <div class="col-sm-4">
          <div class="form-group" [ngSwitch]="item.formatting.hourFormat">
            <!-- 12 Hrs -->
            <div class="row" *ngSwitchCase="'12 hrs'">
              <!-- hours -->
              <div class="col-3" *ngIf="item.formatting.hour == true || item.formatting.hour == 'true'">
                <div class="form-floating">
                  <select class="form-select" id="hours" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UK" selected>UK</option>
                    <option *ngFor="let hr of hours12" [value]="hr">{{hr}}</option>
                  </select>
                  <label for="hours">Hrs </label>
                </div>
              </div>
              <!-- minutes -->
              <div class="col-3" *ngIf="item.formatting.minute == 'true' || item.formatting.minute == true">
                <div class="form-floating">
                  <select class="form-select" id="minutes" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UM" selected>UM</option>
                    <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                  </select>
                  <label for="minutes">Min </label>
                </div>
              </div>
              <!-- Seconds -->
              <div class="col-3" *ngIf="item.formatting.second == 'true' || item.formatting.second == true">
                <div class="form-floating">
                  <select class="form-select" id="minutes" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UN" selected>UN</option>
                    <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                  </select>
                  <label for="minutes">Sec </label>
                </div>
              </div>
              <!-- midday -->
              <div class="col-3" *ngIf="item.formatting.hourFormat == '12 hrs' && item.formatting.midDay == true || item.formatting.midDay == 'true'">
                <div class="form-floating">
                  <select class="form-select" id="midday" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UM" selected>UM</option>
                    <option *ngFor="let md of midday" [value]="md">{{md}}</option>
                  </select>
                  <label for="midday">AM/PM </label>
                </div>
              </div>
            </div>
            <!-- 24 Hrs -->
            <div class="row" *ngSwitchCase="'24 hrs'">
              <!-- hours -->
              <div class="col-4" *ngIf="item.formatting.hour == 'true' || item.formatting.hour == true">
                <div class="form-floating">
                  <select class="form-select" id="hours" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UK" selected>UK</option>
                    <option *ngFor="let hr of hours24" [value]="hr">{{hr}}</option>
                  </select>
                  <label for="hours">Hrs</label>
                </div>
              </div>
              <!-- minutes -->
              <div class="col-4" *ngIf="item.formatting.minute == true || item.formatting.minute == 'true'">
                <div class="form-floating">
                  <select class="form-select" id="minutes" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UM" selected>UM</option>
                    <option *ngFor="let min of minutes" [value]="min">{{min}}</option>
                  </select>
                  <label for="minutes">Min </label>
                </div>
              </div>
              <!-- Second -->
              <div class="col-4" *ngIf="item.formatting.second == 'true' || item.formatting.second == true">
                <div class="form-floating">
                  <select class="form-select" id="minutes" [required]="item.required">
                    <option selected disabled>Select</option>
                    <option value="UN" selected>UN</option>
                    <option *ngFor="let sec of seconds" [value]="sec">{{sec}}</option>
                  </select>
                  <label for="minutes">Sec </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>
    <div class="col-1">
      <div class="row">
        <div class="col-6">
          <!-- <button class="control-buttons" title="Add Validation"
          [hidden]="[9,10,11,13,14,15,16].includes(item.dataTypeId)"
          *ngIf="!item.isdefault" (click)="addValidation(item,i)">
            <i class="fa fa-fw fa-check" *ngIf="validationCountData == null || validationCountData[item.id] == undefined || validationCountData[item.id] == 0"></i>
            <i class="fa fa-fw fa-check" *ngIf="validationCountData != null && validationCountData[item.id] > 0" [style.color] = "errorIconColor"></i>
          </button> -->
          <button class="control-buttons" title="TEST VALIDTION"
          [hidden]="[9,10,11,14,15,16].includes(item.dataTypeId)"
          *ngIf="!item.isdefault" (click)="addValidation(item,i)">
            <i class="checkIcon" *ngIf="validationCountData == null || validationCountData[item.id] == undefined || validationCountData[item.id] == 0"></i>
            <i class="checkIcon" *ngIf="validationCountData != null && validationCountData[item.id] > 0" [style.color] = "errorIconColor"></i>
          </button>
        </div>
        <div class="col-6">
          <button class="control-buttons" title="Add Formula" *ngIf="item.isFormula && !item.isdefault" (click)="addFormula(item,item.formulaId,null)">
            <i class="fa fa-fw fa-f" *ngIf="formulaCountData == null || formulaCountData[item.id] == undefined || formulaCountData[item.id] == 0"></i>
            <i class="fa fa-fw fa-f" *ngIf="formulaCountData != null && formulaCountData[item.id] > 0" [style.color] = "errorIconColor"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Control Buttons -->
  <div class="row text-center mt-3">
    <div class="col-12">
      <button class="btn btn-outline-danger " (click)="finishForm()">
        <b *ngIf="!isPreviewButtonClicked">
          Finish Form
        </b>
        <b *ngIf="isPreviewButtonClicked">
          Close
        </b>
      </button>
      &nbsp;
      <button class="btn btn-outline-primary" (click)="backPressed()" [hidden]="isLocked == 1">
        <b *ngIf="!isPreviewButtonClicked">
          Back
        </b>
        <b *ngIf="isPreviewButtonClicked">
          Form Design 
        </b>
      </button>
    </div>
  </div>
