import { DEFAULT_UUID, MAX_CHARACTER_SHOW, MAX_DATE_CONSTANT, MIN_DATE_CONSTANT, TABLE_COMPONENT_TYPES, componentTypeMap, tempFormData } from './../../constant/constant';
import { AddValidatonPopupComponent } from './../popup/add-validaton-popup/add-validaton-popup.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDate, NgbDateStruct, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormService } from 'src/app/services/form.service';
import { FormulaPopupComponent } from '../popup/formula-popup/formula-popup.component';
import { jsonData } from 'src/app/constant/constant';
import { FORM_VALIDATION_FORMULA, IMAGE_SUPPORT_MSG } from 'src/app/constant/responseMessage';
import { Location } from '@angular/common';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import * as loadashJson from 'lodash';
import { ERROR_ICON_COLOR, NOTE_COLOR, SUCCESS_ICON_COLOR } from 'src/app/constant/color-code';
import { ValidationLogMappingComponent } from '../validation-log-mapping/validation-log-mapping.component';
import { forkJoin } from 'rxjs';
import { FormulaService } from 'src/app/services/formula.service';
import { ValidationRuleService } from 'src/app/services/validation-rule.service';
import { Role } from 'src/app/models/Role';
@Component({
  selector: 'app-validation-formula',
  templateUrl: './validation-formula.component.html',
  styleUrls: ['./validation-formula.component.css']
})
export class ValidationFormulaComponent implements OnInit {
  
  //For title of the component
  title = FORM_VALIDATION_FORMULA

  envData: any = jsonData;
  message1: any;
  message2: any;
  message3: any;
  isImageApiRunning: boolean = false;
  formId:any
  isLogFormData:any
  datePickerCheckbox: any[] = ["Is Date Known?", "Is Month Known?", "Is Year Known?"]
  timePickerCheckbox: any[] = ["Is Hours Known?", "Is Minutes Known?"]
  model?: NgbDateStruct;

  formTypeData:any
  formType:any
  
  datePicker?: boolean = true;
  datePickerSelection?: boolean;
  checkboxData?: boolean = true;

  showFormula: boolean = false;

  dateData?: any[] = [];
  monthData?: any[] = ["Jan", "Feb", "Mar", "Apr",
  "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  yearData?: any[] = [];

  hours12: any[] = [];
  hours24: any[] = [];
  minutes: any[] = [];
  seconds:any[] = [];
  midday: any[] = ["AM", "PM"];
  data:any;

  //Preview Form Operations
  previewFormId:any
  isPreviewButtonClicked:boolean = false

  //Image Component
  imagePath: any;
  imgURL: any;
  message: any;
  imgData: any;

  //Multi-Row Component
  multiRowComponent:any[] = []
  multiRowData:any
  toggleTable:boolean = false
  multiRowForm:FormGroup

  //For Back Button
  mainFormId:any

  // Min/Max Date
  minConstant:any = MIN_DATE_CONSTANT
  maxConstant:any = MAX_DATE_CONSTANT
  
  dataType:any = componentTypeMap
  //For Table Component Design
  tableType:any = TABLE_COMPONENT_TYPES

  maxCharacter:any = MAX_CHARACTER_SHOW

  isLocked:any

  successIconColor:any = SUCCESS_ICON_COLOR
  errorIconColor:any = ERROR_ICON_COLOR
  noteColor:any = NOTE_COLOR

  formulaCountData:any
  validationCountData:any

  userName:any
  studyId:any
  visitName:any

  roles:any
  readOnly:any;

  constructor(private modalService: NgbModal, private router: Router,
  private api: FormService,private location:Location,private config: NgbModalConfig,private formulaAPI:FormulaService,
  private validationAPI:ValidationRuleService) {
    this.multiRowForm = new FormGroup({
      visitId:new FormControl(),
      formId:new FormControl(),
      subjectId:new FormControl(),
      fieldId:new FormControl(),
      formData:new FormArray([])
    })
  }

  ngOnInit(): void {
    this.roles = sessionStorage.getItem('role');
    this.readOnly = Role.ReadOnly;
    this.localStorageData()
    if(this.previewFormId != null){
      this.envData = []
      tempFormData.splice(0,tempFormData.length)
      this.editForm(this.previewFormId)
    }else{
      // this.getFormDataDB()
      this.timeDataInit()
      this.getDateYear()
    }
    this.location.subscribe(res =>{
      this.envData = []
      tempFormData.splice(0,tempFormData.length)
    })
    this.config.backdrop = 'static';
		this.config.keyboard = false;
    // this.onImageResize()
  }

  localStorageData(){
    this.message1 = localStorage.getItem("formName")
    this.message2 = localStorage.getItem("formAnnotatedName")
    this.formId = localStorage.getItem("formId")
    this.data = localStorage.getItem("fieldsData");
    this.envData = JSON.parse(this.data);
    this.imgData = localStorage.getItem("imgData");
    this.previewFormId = localStorage.getItem("previewId")
    let visitIds:any = localStorage.getItem("visitIds")
    this.message3 = JSON.parse(visitIds)
    this.isLocked = sessionStorage.getItem("isLocked")
    this.formType = localStorage.getItem("formType")
    this.userName = sessionStorage.getItem("userName")
    this.studyId = localStorage.getItem("tempStudyId")
  }

  editForm(formId: any) {
    this.api.getFieldValidationData(formId,this.message3[0]).subscribe(res => {
      console.log("RESPONSE :: ",res);
      this.mainFormId = res.responseObject.id
      this.formTypeData = res.responseObject.formType
      this.envData = res.responseObject.fieldValidationResponseDTOS;

      if (this.envData.some((field: any) => field?.dataTypeId === componentTypeMap[13]?.id)) {
        this.callImageDataApi(formId);  
      }

      for (let i = 0; i < this.envData.length; i++) {
        tempFormData.push(this.envData[i]);
      }
      this.envData = tempFormData;
      this.isPreviewButtonClicked = true
      this.visitName = res.responseObject.visitName;
      this.onParallelApiCalled(this.previewFormId)
      // this.getFormDataDB()
    })
  }

  callImageDataApi = (formId: any): void => {
    this.isImageApiRunning = true;
    this.api.getImageData(formId).subscribe({
      next: (res) => {
        this.isImageApiRunning = false;
        const imageDataList = res?.responseObject;
        imageDataList?.forEach((imageData: any) => {
          const index = this.envData.findIndex((fieldData: any) => fieldData?.id === imageData?.fieldId);
          this.envData[index]['imagePath'] = imageData?.imagePath;
        });
      },
      error: (error) => {
        this.isImageApiRunning = false;
      }
    })
  }

  onParallelApiCalled(formId:any){
    forkJoin({
      formulaCount:this.formulaAPI.getFieldFormulaCount(formId),
      validationCount:this.validationAPI.getValidationCount(formId)
    }).subscribe(res => {
      this.validationCountData = loadashJson.isEqual(res.validationCount.responseObject,{}) ? null : res.validationCount.responseObject
      this.formulaCountData = loadashJson.isEqual(res.formulaCount.responseObject,{}) ? null : res.formulaCount.responseObject
    })
  }

  onKeyPressed(event: any) {
    let input = document.querySelector('input');
    input?.addEventListener('input', function () {
      this.value = this.value.replace(/[^0-9 \,\.]/, '')
    })
  }

  getFormDataDB(inputType:any,fieldId:any,index:any) {
    
    // for (let index = 0; index < this.envData.length; index++) {
      if(this.envData[index].dataTypeId == '6' && inputType == '6' && this.envData[index].id == fieldId){
        localStorage.setItem("checkBoxData",JSON.stringify(this.envData[index].options))
      }else if(this.envData[index].dataTypeId == 6 && inputType == 6 && this.envData[index].id == fieldId){
        localStorage.setItem("checkBoxData",JSON.stringify(this.envData[index].options))
      }

      if(this.envData[index].dataTypeId == '7' && inputType == '7' && this.envData[index].id == fieldId){
        localStorage.setItem("radioButtonData",JSON.stringify(this.envData[index].options))
      }else if(this.envData[index].dataTypeId == 7 && inputType == 7 && this.envData[index].id == fieldId){
        localStorage.setItem("radioButtonData",JSON.stringify(this.envData[index].options))
      }

      if(this.envData[index].dataTypeId == '8' && inputType == '8' && this.envData[index].id == fieldId){
        localStorage.setItem("selectionBoxData",JSON.stringify(this.envData[index].options))
      }else if(this.envData[index].dataTypeId == 8 && inputType == 8 && this.envData[index].id == fieldId){
        localStorage.setItem("selectionBoxData",JSON.stringify(this.envData[index].options))
      }
    // }
  }

  finishForm() {
    this.envData = []
    tempFormData.splice(0,tempFormData.length)
    jsonData.splice(0,jsonData.length)
    console.log("MESSGAE # :: ",this.message3);
    
    if(this.formType != 2){
      let params:any = {}
      params['visitId'] = typeof(this.message3) == 'object' && this.message3 != null ? this.message3[0] : this.message3 && this.message3.length > 0 && this.message3[0] == null ? DEFAULT_UUID : this.message3
      if(this.userName == 'admin'){
        params['studyId'] = this.studyId
      }
      this.router.navigate(['layout/forms'],{ queryParams: params });
    }
    localStorage.clear();
  }

  cancelForm() {
    this.router.navigate(['layout/formPreview']);
  }

  getDateYear() {
    for (let i = 1; i <= 31; i++) {
      this.dateData?.push(i);
    }
    for (let i = 1920; i <= 2050; i++) {
      this.yearData?.push(i);
    }
    for (let sec = 0; sec < 60; sec++) {
      this.seconds.push(sec);
    }
  }

  onCheckboxUnknownDateSelected(event: any) {
    if (event.target.checked) {
      this.checkboxData = true;
      this.datePicker = false;
      this.datePickerSelection = true;
      (<HTMLInputElement>document.getElementById("date")).disabled = true;
      (<HTMLInputElement>document.getElementById("date")).value = "NU";
    } else {
      this.datePicker = true;
      this.datePickerSelection = false;
      (<HTMLInputElement>document.getElementById("date")).disabled = false;
    }
  }

  triggerModal(Component: any, fieldId?:any, fieldName?:any, size?:any, formulaId?:any,formType?:any, dataTypeId?:any) {
    let modal = this.modalService.open(Component, { ariaLabelledBy: 'modal-basic-title',size:size });
    modal.componentInstance.fieldId = fieldId;
    modal.componentInstance.fieldName = fieldName;
    modal.componentInstance.formulaId = formulaId;
    modal.componentInstance.formType = formType;
    modal.componentInstance.dataTypeId = dataTypeId;
  }

  // addValidation(item:any,index:any) {
  //   if(this.formTypeData == undefined){
  //     this.formTypeData = localStorage.getItem("formType")
      
  //   }
  //   this.triggerModal(AddValidatonPopupComponent, item.id, item.label, 'xl',null,this.formTypeData, item.dataTypeId);
  // }

  addValidation(item:any,index:any) {
    if(this.formTypeData == undefined){
      this.formTypeData = localStorage.getItem("formType")
      
    }
    this.triggerModal(ValidationLogMappingComponent, item.id, item.label, 'xl',null,this.formTypeData, item.dataTypeId);
  }

  addFormula(item: any,formulaId:any, columnData:any) {
    console.log("FORM TYPE :: ",this.formTypeData,columnData);
    
    if(this.formTypeData == undefined){
      this.formTypeData = localStorage.getItem("formType")
    }
    let modal = this.modalService.open(FormulaPopupComponent, { ariaLabelledBy: 'modal-basic-title',size:'xl' });
    modal.componentInstance.fieldId = item.id;
    modal.componentInstance.fieldName = null;
    modal.componentInstance.formulaId = formulaId;
    modal.componentInstance.formType = this.formTypeData;
    modal.componentInstance.dataTypeId = item.dataTypeId;
    if(columnData){
      modal.componentInstance.multiRowId = columnData.id;
      modal.componentInstance.multiRowDataTypeId = columnData.dataTypeId;
    }
    modal.componentInstance.modalFormulaCount.subscribe((recivedData:any) => {
      console.log("RECIVED DATA :: ",recivedData,this.envData,"formulaCount");
      if(this.envData && recivedData){
        for (let index = 0; index < this.envData.length; index++) {
          if(this.envData[index].id == recivedData.fieldId){
            this.envData[index].formulaCount = recivedData.formulaCount
          }
        }
      }
    })
  }

  timeDataInit() {//Inetialize the time
    for (let min = 0; min < 60; min++) {
      this.minutes.push(min);
    }
    for (let hr12 = 0; hr12 < 12; hr12++) {
      this.hours12.push(hr12);
    }
    for (let hr24 = 0; hr24 < 24; hr24++) {
      this.hours24.push(hr24);
    }
  }
  //For Image Preview
  showImgData() {
    this.imgData = localStorage.getItem("imgData");
  }

  preview(files: any) {

    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = IMAGE_SUPPORT_MSG;
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      reader.result
    }
  }

  //Multi-Row Component
  toggleTableData(){
    this.toggleTable = !this.toggleTable
  }

  mainMultiRowArray(){
    return (this.multiRowForm.get("formData") as FormArray)
  }
  addColumnRows(item:any){
    const dataJson:any = {}
    if(!item.columnData){
      return false;
    }
    for (let index = 0; index < item.columnData.length; index++) {
      let testVar = item.columnData[index].colHeading
      dataJson['rowId'] = (this.multiRowForm.get("formData") as FormArray).length + 1
      dataJson[testVar] = ""
    }
    console.log(dataJson);

    if(!item.dataArray){
      item.dataArray = []
    }
    item.dataArray.push(dataJson)
    return true;
  }
  deleteColumnData(dataArray:any,index:any){
    dataArray.splice(index,1)
  }

  //Back Press Button
  backPressed(){
    if(this.previewFormId){
      localStorage.setItem("editFormId",this.previewFormId)
      localStorage.setItem("formType",this.formTypeData)
      localStorage.setItem("formId",this.previewFormId)
    }else{
      localStorage.setItem("formType",this.formTypeData)
      localStorage.setItem("editFormId",this.formId)
      localStorage.setItem("formId",this.formId)
    }
    this.router.navigate(['layout/dragDrop'])
    tempFormData.splice(0,tempFormData.length)
  }

  getMinDate(item:any){
    if(loadashJson.isEqual(item.minDate,{}) || item.minDate == null){
      return this.minConstant as NgbDate
    }else{
      return new NgbDate(Number(item.minDate['year']),Number(item.minDate['month']),Number(item.minDate['day'])) 
    }
  }

  getMaxDate(item:any){
    if(loadashJson.isEqual({},item.maxDate) || item.maxDate == null){
      return this.maxConstant as NgbDate
    }else{
      return new NgbDate(Number(item.maxDate['year']),Number(item.maxDate['month']),Number(item.maxDate['day'])) 
       
    }
  }

  //For Table Component
  getRowInfo(i:any){
    return this.envData[i].cellInfo
  }

  getColumnInfo(i:any,rowIndex:any){
    return this.envData[i].cellInfo[rowIndex]
  }

  getCellInfo(i:any,rowIndex:any,columnIndex:any){
    return this.envData[i].cellInfo[rowIndex][columnIndex]
  }

  getCellInfoData(i:any,rowIndex:any,columnIndex:any,cellIndex:any){
    return this.envData[i].cellInfo[rowIndex][columnIndex][cellIndex]
  }

  checkDateIsUnkown(event:any,i:any,rowIndex:any,columnIndex:any,cellIndex:any){
    this.envData[i].cellInfo[rowIndex][columnIndex][cellIndex].isUnknownDate = event.target.checked
  }

  /*
  // add width and heigth of image in same json.
  onImageDimention(event:any,item:any){
    let resize:any = {}
    resize['width'] = event.target.width
    resize['height'] = event.target.height
    item.reSize = resize
    console.log("imageTag :: ",event.target.width,item);
  }

  // set dimentions when user drag span tag from design
  onImageReSize(event:any,item:any){
    console.log(event.offsetX,event.offsetY,item);
    item.reSize['width'] = item.reSize.width - event.offsetX
    item.reSize['height'] = item.reSize.height - event.offsetY

  }*/
}
