import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseURL, VERSION } from 'src/app/constant/constant';
import { getTimeZoneHeader } from '../constant/globalFunction';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  private withdrawBodyData = new BehaviorSubject<any>("");
  public isSubjectSelected = new BehaviorSubject<boolean>(false);
  withdrawBodyDataIndex = this.withdrawBodyData.asObservable()

  constructor(private http:HttpClient) { }

  postWithdrawBody(currentIndex: any) {
    this.withdrawBodyData.next(currentIndex);
  }

  postSubject(data:any):Observable<any>{
    return this.http.post(`${baseURL}subjects/${VERSION}`,data);
  }

  getSubject(pageNo:any,pageSize:any,siteIds?:any,subjectIds?:any,status?:any,isLocked?:any):Observable<any>{
    let url:string = `${baseURL}subjects/${VERSION}/list?pageNo=${pageNo}&pageSize=${pageSize}`
    
    if(siteIds && siteIds.length > 0){
      url = url.concat(`&siteIds=${siteIds}`)
    }
    if(subjectIds && subjectIds.length > 0){
      url = url.concat(`&subjectIds=${subjectIds}`)
    }
    if(status && status.length > 0){
      url = url.concat(`&subjectStatus=${status}`)
    }
    if(isLocked && isLocked.length > 0){
      url = url.concat(`&isLocked=${isLocked}`)
    }
    return this.http.get(url);
  }

  updateSubject(data:any):Observable<any>{
    return this.http.put(`${baseURL}subjects/${VERSION}`,data);
  }

  deleteSubject(id:string):Observable<any>{
    return this.http.delete(`${baseURL}subjects/${VERSION}?id=${id}`);
  }
  
  export():Observable<any>{
    return this.http.get(`${baseURL}subjects/${VERSION}/export`,{headers:getTimeZoneHeader()});
  }

  withdrawSubject(data:any):Observable<any>{
    return this.http.put(`${baseURL}subjects/${VERSION}/withdraw`,data);
  }

  defaultSubjectId(siteId:any):Observable<any>{
    return this.http.get(`${baseURL}subjects/${VERSION}/default?siteId=${siteId}`);
    
  }

  getSubjectIdList():Observable<any>{
    return this.http.get(`${baseURL}subjects/${VERSION}/subjectIdList`)
  }

  getSubjectQueryCount(subjectId: number): Observable<any>{
    return this.http.get(`${baseURL}subjects/${VERSION}/${subjectId}/queryCount`)
  }
}
