import { Observable } from 'rxjs';
import { baseURL , VERSION } from 'src/app/constant/constant';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  constructor(private http:HttpClient) { }

  postFormStatus(data:any):Observable<any>{
    return this.http.put(`${baseURL}review/${VERSION}/form/status`,data);
  }

  postFieldStatus(data:any):Observable<any>{
    return this.http.put(`${baseURL}review/${VERSION}`,data);
  }

  // updateFieldStatus(data:any):Observable<any>{
  //   return this.http.put(`${baseURL}review/${VERSION}/fieldStatus`,data);
  // }

  // submitForReviewData(data:any):Observable<any>{
  //   return this.http.put(`${baseURL}review/${VERSION}/form/crc`,data)
  // }

  // monitorLockData(data:any):Observable<any>{
  //   return this.http.put(`${baseURL}review/${VERSION}/form/monitor`,data)
  // }

  getReviewStatus(formId:any,visitId:any,subjectId:any):Observable<any>{
    return this.http.get(`${baseURL}review/${VERSION}/status?formId=${formId}&visitId=${visitId}&subjectId=${subjectId}`);
  }

  getReviewerTableData(sites:any,subjects:any,visits:any,forms:any,status:any,pageNo:any,pageSize:any): Observable<any> {
    return this.http.get(`${baseURL}review/${VERSION}/filter/form/status?sites=${sites}&subjects=${subjects}&visits=${visits}&forms=${forms}&status=${status}&pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  setSoftLock(data:any): Observable<any> {
    return this.http.put(`${baseURL}review/${VERSION}/softLock`,data);
  }

  setSubjectLevelSoftLock(subjectId:any,data:any): Observable<any> {
    return this.http.put(`${baseURL}review/${VERSION}/subject/${subjectId}/softLock`,data);
  }

  setLogFormSoftLock(subjectId:any): Observable<any> {
    return this.http.get(`${baseURL}review/${VERSION}/status/subject/${subjectId}`);
  }
}
