import { jsonData } from 'src/app/constant/constant';

export class Form {
  id?: any;
  name?: any;
  annotatedName?: any;
  visitIds?: any;
  studyId:any
  formType:any
  formTypeData:any
  fields?: any[] = jsonData;
  srcVisitId?: any[]
  srcFormId: any
  isFormulaCopy: any
  isValidationCopy: any 
}
