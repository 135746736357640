export const CRA_REVIEW_STATUS = [
    {id:0,name:'Non SDV'},
    {id:1,name:'Partial SDV'},
    {id:2,name:'SDV'},
];

export const MONITOR_DM_REVIEW_STATUS = [
    {id:0,name:'DM Unreview'},
    {id:1,name:'Partial DM Review'},
    {id:2,name:'DM Review'},
];

export const INVESTIGATOR_REVIEW_STATUS = [
    {id:0,name:'PI not Signed'},
    {id:2,name:'PI Sign'},
];