import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VERSION, baseURL } from '../constant/constant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhaseService {

  constructor(private http: HttpClient) { }

  getPhaseList(): Observable<any>{
    return this.http.get(`${baseURL}phases/${VERSION}/list`)
  }

  
  getPhaseById(id:string):Observable<any>{
    return this.http.get(`${baseURL}phases/${VERSION}?id=${id}`);
  }
  
  savePhase(payload:any):Observable<any>{
    return this.http.post(`${baseURL}phases/${VERSION}`,payload);
  }

  
  updatePhase(payload: any): Observable<any> {
    return this.http.put(`${baseURL}phases/${VERSION}`, payload);
  }

  deletePhase(id: string):Observable<any>{
    return this.http.delete(`${baseURL}phases/${VERSION}?id=${id}`);
  }
}
